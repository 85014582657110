
import { ModoSelecao } from "./ModoSelecao";
import { TipoSelecao } from "./TipoSelecao";
import { ParametrosCaneta } from "./UserData";

export class OpcaoToolbar {
    public descricao: string;
    public icone: string;
    public modo: ModoSelecao;
    public tipo: TipoSelecao;
    public cor: string;
    public hover: boolean;
    public ativo: boolean;
    public realcarMarcacao: boolean;
    public toolbar: boolean;

    constructor() {
        this.ativo = false;
        this.toolbar = true;
    }

    public static carregarItensMenu(
        parametrosCaneta: ParametrosCaneta
    ): OpcaoToolbar[] {
        const retorno = new Array<OpcaoToolbar>();

        const ferramentaCaneta1 = new OpcaoToolbar();
        ferramentaCaneta1.descricao = parametrosCaneta.descricaoCaneta1;
        ferramentaCaneta1.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta1.tipo = TipoSelecao.Caneta1;
        ferramentaCaneta1.icone = "ellipse";
        ferramentaCaneta1.ativo = false;
        ferramentaCaneta1.cor = parametrosCaneta.corCaneta1;
        retorno.push(ferramentaCaneta1);

        const ferramentaCaneta2 = new OpcaoToolbar();
        ferramentaCaneta2.descricao = parametrosCaneta.descricaoCaneta2;
        ferramentaCaneta2.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta2.tipo = TipoSelecao.Caneta2;
        ferramentaCaneta2.icone = "ellipse";
        ferramentaCaneta2.ativo = false;
        ferramentaCaneta2.cor = parametrosCaneta.corCaneta2;
        retorno.push(ferramentaCaneta2);

        const ferramentaCaneta3 = new OpcaoToolbar();
        ferramentaCaneta3.descricao = parametrosCaneta.descricaoCaneta3;
        ferramentaCaneta3.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta3.tipo = TipoSelecao.Caneta3;
        ferramentaCaneta3.icone = "ellipse";
        ferramentaCaneta3.ativo = false;
        ferramentaCaneta3.cor = parametrosCaneta.corCaneta3;
        retorno.push(ferramentaCaneta3);

        const ferramentaCaneta4 = new OpcaoToolbar();
        ferramentaCaneta4.descricao = parametrosCaneta.descricaoCaneta4;
        ferramentaCaneta4.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta4.tipo = TipoSelecao.Caneta4;
        ferramentaCaneta4.icone = "ellipse";
        ferramentaCaneta4.ativo = false;
        ferramentaCaneta4.cor = parametrosCaneta.corCaneta4;
        retorno.push(ferramentaCaneta4);

        const ferramentaCaneta5 = new OpcaoToolbar();
        ferramentaCaneta5.descricao = parametrosCaneta.descricaoCaneta5;
        ferramentaCaneta5.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta5.tipo = TipoSelecao.Caneta5;
        ferramentaCaneta5.icone = "ellipse";
        ferramentaCaneta5.ativo = false;
        ferramentaCaneta5.cor = parametrosCaneta.corCaneta5;
        retorno.push(ferramentaCaneta5);

        const ferramentaComentarios = new OpcaoToolbar();
        ferramentaComentarios.descricao = "Comentários";
        ferramentaComentarios.modo = ModoSelecao.Marcacao;
        ferramentaComentarios.tipo = TipoSelecao.Comentario;
        ferramentaComentarios.icone = "message-plus";
        ferramentaComentarios.ativo = false;
        ferramentaComentarios.cor = parametrosCaneta.corTagComentario;
        ferramentaComentarios.realcarMarcacao = parametrosCaneta.realcarComentarios;
        ferramentaComentarios.toolbar = false;
        retorno.push(ferramentaComentarios);

        const ferramentaMnemonico = new OpcaoToolbar();
        ferramentaMnemonico.descricao = "Título";
        ferramentaMnemonico.modo = ModoSelecao.Marcacao;
        ferramentaMnemonico.tipo = TipoSelecao.Mnemonico;
        ferramentaMnemonico.icone = "tag";
        ferramentaMnemonico.ativo = false;
        ferramentaMnemonico.cor = parametrosCaneta.corTagMnemonico;
        ferramentaMnemonico.realcarMarcacao = parametrosCaneta.realcarMnemonicos;
        ferramentaMnemonico.toolbar = false;
        retorno.push(ferramentaMnemonico);

        return retorno;
    }
}
