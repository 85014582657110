import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogoModel } from "src/app/models/DialogoModel";

@Component({
    selector: "app-dialogo",
    templateUrl: "./dialogo.component.html",
    styleUrls: ["./dialogo.component.scss"]
})
export class DialogoComponent  {

    constructor(
        public dialogRef: MatDialogRef<DialogoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogoModel
    ) { }
}
