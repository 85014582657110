import { Injectable } from "@angular/core";
import { EstatisticasLeitura } from "../../models/usuario/EstatisticasLeitura";
import {
    SignalrService,
    OperacaoSignalR,
    EnumTipoObjeto,
} from "../signalr.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AppConfig } from "src/app/app.config";
import { HttpClient, HttpParams } from "@angular/common/http";
import { StorageHelper } from "src/app/helpers/storage.helper";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { ErrorHandlerService } from "../errorHandler.service";

@Injectable()
export class UsuarioEstatisticasService {
    readonly databaseName = "svdm";
    readonly collectionName = "estatisticas";
    private leisFavoritas: string[] = [];
    private leisFavoritas$ = new BehaviorSubject<string[]>(this.leisFavoritas);

    constructor(
        private signalrService: SignalrService,
        private auth: AuthService,
        private errorHandlerService: ErrorHandlerService,
        private httpClient: HttpClient
    ) {}

    public async carregarEstatisticasServicePorLei(
        leiId: string
    ): Promise<EstatisticasLeitura> {
        const url = `${AppConfig.apiEndpoint}/DadosCadastroUsuario/EstatisticasLeituraPorLei`;
        let requestParams = new HttpParams();
        let estatisticaLeitura;
        const estatisticasLeituralocal =
      await StorageHelper.getByKey<EstatisticasLeitura>(
          leiId,
          this.databaseName,
          this.collectionName
      );

        requestParams = requestParams.append("leiId", leiId);
        requestParams = requestParams.append("usuarioId", this.auth.userID);
        await this.httpClient
            .get(url, { params: requestParams })
            .toPromise()
            .then((result: EstatisticasLeitura) => {
                if (result) {
                    this.atualizar(result);
                    estatisticaLeitura = result;
                } else {
                    if (estatisticasLeituralocal) {
                        estatisticaLeitura = estatisticasLeituralocal;
                    } else {
                        estatisticaLeitura = null;
                    }
                }
            })
            .catch((err) => {
                this.errorHandlerService.handleError(err);
                if (estatisticasLeituralocal) {
                    estatisticaLeitura = estatisticasLeituralocal;
                } else {
                    estatisticaLeitura = null;
                }
            });
        return estatisticaLeitura;
    }

    public async fromNuvem(estatisticas: EstatisticasLeitura[]): Promise<void> {
        try {
            await StorageHelper.upsertMany(
                estatisticas,
                this.databaseName,
                this.collectionName,
                false
            );
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    public async salvarLote(
        listaEstatisticas: EstatisticasLeitura[],
        sync = true
    ): Promise<void> {
        for (const estatisticas of listaEstatisticas) {
            await this.atualizar(estatisticas);
        }

        if (sync) {
            const mensagens = listaEstatisticas.map((estatisticas) => {
                const msg = new OperacaoSignalR();
                msg.dados = estatisticas;
                return msg;
            });

            await this.signalrService.enviarMensagens(
                mensagens,
                EnumTipoObjeto.Estatisticas
            );
        }
    }

    public async marcarLido(
        idLei: string,
        idLinha: string
    ): Promise<EstatisticasLeitura> {
        const estatisticas = await this.buscar(idLei);

        const iLinha = estatisticas.linhasLidas.findIndex((l) => l === idLinha);
        if (iLinha === -1) {
            estatisticas.linhasLidas.push(idLinha);
        } else {
            estatisticas.linhasLidas.splice(iLinha, 1);
        }

        await this.atualizar(estatisticas);
        return estatisticas;
    }

    public async marcarVariosLidos(
        idLei: string,
        idsLinhas: string[]
    ): Promise<EstatisticasLeitura> {
        const estatistica = await this.buscar(idLei);

        idsLinhas.forEach((idLinha) => {
            const iLinha = estatistica.linhasLidas.findIndex((l) => l === idLinha);
            if (iLinha === -1) {
                estatistica.linhasLidas.push(idLinha);
            }
        });

        await this.atualizar(estatistica);
        return estatistica;
    }

    public async marcarFavorito(id: string): Promise<EstatisticasLeitura> {
        const estatisticas = await this.buscar(id);
        estatisticas.favorito = !estatisticas.favorito;

        await this.atualizar(estatisticas);
        await this.listar();

        return estatisticas;
    }

    public async alterarPosicaoLeitura(idLei: string, idLinha: string) {
        const estatisticas = await this.buscar(idLei);
        estatisticas.idUltimoItemLido = idLinha;

        await this.atualizar(estatisticas);
    }

    public async atualizar(
        estatistica: EstatisticasLeitura,
        sync = true
    ): Promise<void> {
        try {
            const url = `${AppConfig.apiEndpoint}/DadosCadastroUsuario/EstatisticasLeituras`;

            await this.httpClient.post(url, estatistica).toPromise();

            await StorageHelper.upsert(
                estatistica,
                this.databaseName,
                this.collectionName
            );
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    public async buscar(idLei = null): Promise<EstatisticasLeitura> {
        try {
            if (!idLei) {
                return;
            }
            const estatistica = await StorageHelper.getByKey<EstatisticasLeitura>(
                idLei,
                this.databaseName,
                this.collectionName
            );
            if (estatistica) {
                return estatistica;
            } else {
                return new EstatisticasLeitura();
            }
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    async listar(): Promise<EstatisticasLeitura[]> {
        try {
            const listaEstatisticas = await StorageHelper.list<EstatisticasLeitura>(
                this.databaseName,
                this.collectionName
            );

            this.leisFavoritas = listaEstatisticas
                .filter((l) => l.favorito)
                .map((l) => l.id);
            this.updateLeisFavoritas();
            return listaEstatisticas;
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    updateLeisFavoritas() {
        this.leisFavoritas$.next(this.leisFavoritas);
    }

    getLeisFavoritas(): Observable<string[]> {
        return this.leisFavoritas$.asObservable();
    }
}
