import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { SelectionTexto } from "../models/SelectionTexto";
import { ModoSelecao } from "../models/ModoSelecao";

@Injectable()
export class SelectionService {
    public selection: SelectionTexto = new SelectionTexto();
    private selection$ = new BehaviorSubject<SelectionTexto>(this.selection);

    constructor() {}
    public getSelectionValue() {
        return this.selection;
    }

    atualizar(selection: SelectionTexto) {
        this.selection = selection;
        this.updateSelection();
    }

    resetaSelector() {
        this.selection.selector = null;
        this.updateSelection();
    }

    updateSelection() {
        this.selection$.next(this.selection);
    }

    getSelection(): Observable<SelectionTexto> {
        return this.selection$.asObservable();
    }
}
