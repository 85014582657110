import * as pako from "pako";

export class CompressedResult {
    public data: string;
}

export class CompressionHelper {
    public static unzip(obj: CompressedResult) {
        return this.strUnzip(obj.data);
    }

    public static zip(unzippedStr: string): CompressedResult {
        const retorno = new CompressedResult();
        retorno.data = pako.gzip(btoa(unzippedStr));

        return retorno;
    }

    public static strUnzip(zippedStr: string): string {
        const x = atob(zippedStr);
        return pako.inflate(x, { to: "string" });

        // const result = pako.inflateRaw(zippedStr, { to: 'string' });
        // return result;

        const uint = pako.ungzip(atob(zippedStr));
        return this.Utf8ArrayToStr(uint);
    }

    private static Utf8ArrayToStr(array: Uint8Array) {
        let out, i, len, c;
        let char2, char3;

        out = "";
        len = array.length;
        i = 0;
        while (i < len) {
            const arrayItem = array[i];
            if (i % 2 === 0 && arrayItem > 31 && arrayItem !== 127) {
                out += String.fromCharCode(arrayItem);
            }

            i++;
            // console.log(i);

            // c = array[i++];
            // switch (c >> 4) {
            //     case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
            //         // 0xxxxxxx
            //         if (i % 2 === 0)
            //             out += String.fromCharCode(c);
            //         break;
            //     case 12: case 13:
            //         // 110x xxxx   10xx xxxx
            //         char2 = array[i++];
            //         if (i % 2 === 0)
            //             out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
            //         break;
            //     case 14:
            //         // 1110 xxxx  10xx xxxx  10xx xxxx
            //         char2 = array[i++];
            //         char3 = array[i++];
            //         if (i % 2 === 0)
            //             out += String.fromCharCode(((c & 0x0F) << 12) |
            //                 ((char2 & 0x3F) << 6) |
            //                 ((char3 & 0x3F) << 0));
            //         break;
            // }
        }

        return out;
    }
}
