import { ProvaDados } from "./ProvaDados";

// azure card 358 Desativar botão caiu em prova
export class FuncoesProva {
    public static getLabelProva(prova: ProvaDados) {
        if (!prova) {
            return "";
        }

        const instituicao = prova.instituicao ? prova.instituicao.descricao : "";
        const banca = prova.banca ? prova.banca.descricao : "";
        const ano = prova.ano ? prova.ano.descricao : "";
        const tipo = prova.tipo ? prova.tipo.descricao : "";
        const cargo = prova.cargo ? prova.cargo.descricao : "";

        const labelarray = [instituicao, banca, ano, tipo, cargo].filter(
            String
        );
        let label = labelarray.join(" - ");

        if (label === "") {
            label = "Prova sem descrição";
        }

        return label;
    }
}
