import { ContatoIndicacao, StatusIndicacao } from "src/app/models/ContatoIndicacao";

export class IndicacoesModel {
    public enderecosEmailIndicar: string;

    public valorTabelaDesconto: number;
    public valorMaximoDesconto: number;
    public valorPlano: number;
    public dataProximoVencimento: Date;

    public indicacoes: ContatoIndicacao[];

    constructor() {
        this.indicacoes = new Array<ContatoIndicacao>();
    }

    public get valorDescontoIndicacoes(): number {
        let valor = 0;

        const qtdIndicacoes = this.indicacoes.filter(i => i.status.codigo === StatusIndicacao.aprovado).length;
        valor = qtdIndicacoes * this.valorTabelaDesconto;

        return (valor > this.valorMaximoDesconto) ? this.valorMaximoDesconto : valor;
    }
}


