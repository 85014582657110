export enum TipoSelecao {
    // CaiuProva = 0,
    // ErrouProva = 1,
    // Importante = 2,
    // Dificil = 3,
    // Comentario = 4,
    // Caneta1 = 5,
    // Caneta2 = 6,
    // Caneta3 = 7,
    // Caneta4 = 8,
    // Caneta5 = 9

    Prova = 0,
    Comentario = 1,
    Mnemonico = 2,
    Caneta1 = 3,
    Caneta2 = 4,
    Caneta3 = 5,
    Caneta4 = 6,
    Caneta5 = 7,
}
