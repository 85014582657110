<div
  class="marcacoes"
  [style.marginTop.px]="marginTop"
  [style.marginBottom.px]="marginBottom"
>
  <span
    id="hide-resize-helper"
    style="
      position: absolute !important;
      font-size: 10pt;
      height: 0 !important;
      overflow: hidden !important;
    "
  ></span>

  <!-- REFERENCIAGERENCIADOS-->
  <span
    *ngIf="
      posicaoAtual === 'Abaixo' && this.linha.referenciasGerenciado?.length > 0
    "
  >
    <div>
      <app-editor-referencia
        [referenciaReadOnly]="referenciagerenciadasReadOnly"
        [referenciasGerenciadasReadOnly]="referenciagerenciadasLinhaAtual"
        class="referencias"
      ></app-editor-referencia>
    </div>
  </span>

  <!-- REFERENCIA-->
  <span
    *ngIf="
      posicaoAtual === 'Abaixo' &&
      (this.linha.referencias?.length > 0 || isCriarReferencia)
    "
  >
    <div *ngIf="isAdm">
      <app-editor-referencia
        [referencia]="getCriarReferencia"
        [referencias]="referenciaLinhaAtual"
        class="referencias"
        (on_salvarReferencia)="salvarReferencia($event)"
        (on_fechaReferencia)="fechaReferencia()"
        (on_deletaReferencia)="deletaReferencia($event)"
      ></app-editor-referencia>
    </div>
  </span>

  <!-- COMENTARIOSGERENCIADOS-->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoComentario ||
          opcoesPonteiros.posicionamentoComentario === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoComentario === 'Abaixo')) &&
      (configuracoes$ | async)?.preferenciasUsuario?.exibirComentariosSvm
    "
  >
    <app-editor-comentario
      id="coment-sup-input-{{ comentario.id }}"
      *ngFor="let comentario of comentariosGerenciados"
      [comentarioReadOnly]="comentario"
    ></app-editor-comentario>
  </span>
  <!-- MNEMONICOGERENCIADOS -->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoMnemonico ||
          opcoesPonteiros.posicionamentoMnemonico === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoMnemonico === 'Abaixo')) &&
      (configuracoes$ | async)?.preferenciasUsuario?.exibirComentariosSvm
    "
  >
    <app-editor-comentario
      id="mne-sup-input-{{ mnemonico.id }}"
      *ngFor="let mnemonico of mnemonicosGerenciados"
      [comentarioReadOnly]="mnemonico"
    ></app-editor-comentario>
  </span>

  <!-- COMENTARIOS -->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoComentario ||
          opcoesPonteiros.posicionamentoComentario === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoComentario === 'Abaixo')) &&
      (configuracoes$ | async)?.preferenciasUsuario?.exibirMeusComentarios
    "
  >
    <app-editor-comentario
      id="coment-sup-input-{{ comentario.id }}"
      *ngFor="let comentario of comentarios"
      [comentarioEditar]="comentario"
      (commit)="salvarComentario($event)"
    ></app-editor-comentario>
  </span>
  <!-- MNEMONICO -->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoMnemonico ||
          opcoesPonteiros.posicionamentoMnemonico === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoMnemonico === 'Abaixo')) &&
      (configuracoes$ | async)?.preferenciasUsuario?.exibirMeusComentarios
    "
  >
    <app-editor-comentario
      id="mne-sup-input-{{ mnemonico.id }}"
      *ngFor="let mnemonico of mnemonicos"
      [comentarioEditar]="mnemonico"
      (commit)="salvarComentario($event)"
    ></app-editor-comentario>
  </span>

  <!-- PROVA-->

  <!-- // azure card 358 Desativar botão caiu em prova-->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoProva ||
          opcoesPonteiros.posicionamentoProva === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoProva === 'Abaixo')) &&
      (configuracoes$ | async)?.preferenciasUsuario?.exibirMeusComentarios
    "
  >
    <div
      *ngFor="let prova of marcacoesLinhaAtual"
      id="prov-sup-div-{{ prova.id }}"
      class="custom-chip border-light prova"
      backgroundContrast
      [backgroundColor]="opcoesPonteiros.corTagProva"
      [style.border-color]="opcoesPonteiros.corTagProva"
      appAutoWidht
      [textoAutoWidhtDirective]="funcoesProva.getLabelProva(prova.dados)"
      (mouseenter)="realcarProva(prova.id)"
      (mouseleave)="blurRealce()"
    >
      <ng-container>
        <input
          id="prov-sup-input-{{ prova.id }}"
          [ngModel]="funcoesProva.getLabelProva(prova.dados)"
        />

        <mat-icon
          class="statusbar-icon"
          svgIcon="close"
          title="Excluir"
          [style.background]="opcoesPonteiros.corTagProva"
          (click)="confirmarDelecaoProva(prova)"
        ></mat-icon>
      </ng-container>
    </div>
  </span>
</div>
