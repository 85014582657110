import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { QuillConfiguration } from "src/app/quill-configuration";
import { TextFormatingService } from "src/app/services/ui/textFormating.service";

@Component({
    selector: "app-rich-text",
    templateUrl: "./rich-text.component.html",
    styleUrls: ["./rich-text.component.scss"],
})
export class RichTextComponent implements OnInit {
    @Input() dicaEstudo: string;
    @Input() editorDicaEstudo: boolean;
    @Output() on_editDicas = new EventEmitter<string>();
    public quillConfiguration = QuillConfiguration;

    public fontIncrement$: Observable<number>;

    constructor(private textFormatingService: TextFormatingService) {}

    async ngOnInit(): Promise<void> {
        this.fontIncrement$ = this.textFormatingService.getFontIncrement();
    }

    editDicas(event) {
        this.dicaEstudo = event.html;
        this.on_editDicas.emit(this.dicaEstudo);
    }
}
