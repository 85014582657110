import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ErroPopupDataModel } from "src/app/models/ErroPopupDataModel";

@Component({
    selector: "app-erro-popup",
    templateUrl: "./erro-popup.component.html",
    styleUrls: ["./erro-popup.component.scss"],
    animations: [
        trigger("msgCarregamento", [
            transition("* <=> *", [style({ opacity: 0 }), animate(1000)]),
        ]),
    ],
})

export class ErroPopUpComponent  {
    constructor(
        public dialogRef: MatDialogRef<ErroPopUpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ErroPopupDataModel
    ) {}

    public finalizar() {
        this.dialogRef.close();
    }
}
