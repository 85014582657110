<div
  #Pagina
  id="pagina-container"
  class="pagina-container"
  [style.cursor]="cursor"
>
  <app-cabecalho
    [indexLinhaAtual]="indexPrimeiroItem"
    [tituloAtual]="ultimoTitulo"
    [idLei]="conteudo?.idLei"
    (focoChange)="carregarConteudo($event)"
  >
  </app-cabecalho>
  <ng-container
    *ngIf="
      conteudo && conteudo?.idLei && !(carregandoConteudo$ | async);
      else carregando
    "
  >
    <div id="pagina shadow" class="pagina">
      <!-- FIM LOADING LINES -->
      <span *ngIf="isPrintAdm">
        <button (click)="print()">print</button>
      </span>

      <div *ngIf="!printing" class="viewport" id="viewport">
        <div *uiScroll="let item of linhasPaginaDataSource">
          <span
            *ngIf="
              exibirRevogados ||
              (!exibirRevogados &&
                !item.versoes[item.versoes.length - 1].revogado)
            "
          >
            <app-marcacao-superior
              [setLinha]="item"
              [opcoesPonteiros]="opcoesCoresPonteiros"
              [posicaoAtual]="'Acima'"
            >
            </app-marcacao-superior>
            <div
              [id]="'popover' + item.id"
              [appPopoverTrigger]="appPopover"
              closeOnClickOutside="true"
              [style.marginLeft.px]="marginLeftPopOver"
              style="pointer-events: initial"
            ></div>
            <app-linha
              *ngIf="
                item.versoes[item.versoes?.length - 1].revogado
                  ? exibirRevogados
                  : !exibirApenasItensComentados ||
                    ((item.comentarios?.length > 0 ||
                      item.comentariosGerenciados?.length > 0) &&
                      exibirApenasItensComentados)
              "
              [id]="'linha' + item.id"
              [linha]="item"
              [comentarioCriar]="
                editandoComentario(item.id) ? comentarioComentando : null
              "
              (on_cancelarComentario)="cancelarComentario()"
              (on_salvarComentario)="salvarComentario($event)"
            ></app-linha>
            <app-marcacao-superior
              [setLinha]="item"
              [opcoesPonteiros]="opcoesCoresPonteiros"
              [posicaoAtual]="'Abaixo'"
              (on_salvarReferencia)="salvarReferencia($event)"
              [setCriarReferencia]="
                editandoReferencia(item.id) ? criarReferencia : null
              "
            >
            </app-marcacao-superior>
          </span>
        </div>
      </div>
      <div *ngIf="printing" id="print-section">
        <div *ngFor="let item of conteudo?.linhas">
          <span
            *ngIf="
              exibirRevogados ||
              (!exibirRevogados &&
                !item.versoes[item.versoes.length - 1].revogado)
            "
          >
            <app-marcacao-superior
              [setLinha]="item"
              [opcoesPonteiros]="opcoesCoresPonteiros"
              [posicaoAtual]="'Acima'"
            >
            </app-marcacao-superior>
            <div
              [id]="'popover' + item.id"
              [appPopoverTrigger]="appPopover"
              closeOnClickOutside="true"
              [style.marginLeft.px]="marginLeftPopOver"
              style="pointer-events: initial"
            ></div>
            <app-linha
              *ngIf="
                item.versoes[item.versoes?.length - 1].revogado
                  ? exibirRevogados
                  : !exibirApenasItensComentados ||
                    ((item.comentarios?.length > 0 ||
                      item.comentariosGerenciados?.length > 0) &&
                      exibirApenasItensComentados)
              "
              [id]="'linha' + item.id"
              [linha]="item"
              [comentarioCriar]="
                editandoComentario(item.id) ? comentarioComentando : null
              "
              (on_cancelarComentario)="cancelarComentario()"
              (on_salvarComentario)="salvarComentario($event)"
            ></app-linha>
            <app-marcacao-superior
              [setLinha]="item"
              [opcoesPonteiros]="opcoesCoresPonteiros"
              [posicaoAtual]="'Abaixo'"
            >
            </app-marcacao-superior>
          </span>
        </div>
      </div>
      <mat-progress-bar
        *ngIf="this.carregandoConteudo$ | async"
        class="infinite-progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
  </ng-container>

  <!-- POP OVER MARCAÇÕES -->
  <div
    *ngIf="popOverAberta"
    class="popover-bgd"
    (click)="fecharPopOvers()"
  ></div>
  <ng-template #appPopover>
    <app-popover-container>
      <!-- BOTÕES MARCAÇÕES -->
      <div *ngIf="!comentarioComentando && !provaMarcando">
        <button
          md-button
          class="btn-popover mat-raised-button material-icon-copy-popover"
          (click)="copyText()"
          (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
          (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
          [style.border-color]="'#c6c2d9'"
          [style.color]="'#c6c2d9'"
          title="Copiar"
        >
          <mat-icon class="icon material-icons-outlined icon-copy icon-popover">
            <span class="material-icons-outlined"> copy_all </span></mat-icon
          >
        </button>
        <button
          md-button
          class="btn-popover mat-raised-button"
          (click)="processarSelecaoMarcacaoComentario(true)"
          style="margin-left: 5px"
          (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
          (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
          [style.border-color]="opcoesCoresPonteiros.corTagMnemonico"
          [style.color]="opcoesCoresPonteiros.corTagMnemonico"
          title="Título"
        >
          <mat-icon
            class="material-icons-outlined icon icon-titulo icon-popover"
            >title</mat-icon
          >
        </button>

        <button
          md-button
          class="btn-popover mat-raised-button"
          (click)="processarSelecaoMarcacaoComentario(false)"
          style="margin-left: 5px"
          (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
          (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
          [style.border-color]="opcoesCoresPonteiros.corTagComentario"
          [style.color]="
            opcoesCoresPonteiros.corTagComentario === '#E9EBF5'
              ? '#0E4875'
              : opcoesCoresPonteiros.corTagComentario
          "
          title="Comentário"
        >
          <div class="icon icon-comentario icon-popover"></div>
        </button>

        <button
          *ngIf="selection?.selector?.selected?.length == 1 && isAdm"
          md-button
          class="btn-popover mat-raised-button material-icon-referencia-popover"
          (click)="referencia()"
          style="margin-left: 5px"
          (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
          (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
          [style.border-color]="'#c6c2d9'"
          [style.color]="'#c6c2d9'"
          title="referencia"
        >
          <mat-icon
            class="icon material-icons-outlined icon-referencia icon-popover"
          >
            new_label</mat-icon
          >
        </button>
      </div>
    </app-popover-container>
  </ng-template>
  <!-- FINAL POP OVER MARCAÇÕES -->

  <app-placeholder-paineis></app-placeholder-paineis>

  <ng-template #carregando>
    <!-- LOADING LINES -->
    <div class="viewport">
      <div class="viewport-carregando">
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-titulo">
          <div></div>
          <div></div>
        </div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
        <div class="background-masker"></div>
        <div class="background-masker-linha-termino" style="width: 20%"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
        <div class="background-masker"></div>
        <div class="background-masker"></div>
        <div class="background-masker-linha-termino" style="width: 50%"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
        <div class="background-masker"></div>
        <div class="background-masker-linha-termino" style="width: 20%"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
      </div>
    </div>
  </ng-template>
</div>
