import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { LeiLookup } from "src/app/models/lei/lei.lookup";
import {
    EstatisticasLeitura,
    Tempo,
} from "src/app/models/usuario/EstatisticasLeitura";
import { UsuarioPreferenciasService } from "src/app/services/data-services/usuario.preferencias.service";

@Component({
    selector: "app-item-navegacao-pesquisa-leis",
    templateUrl: "./item-navegacao-pesquisa-leis.component.html",
    styleUrls: ["./item-navegacao-pesquisa-leis.component.scss"],
})
export class ItemNavegacaoPesquisaLeisComponent  {
    @Output() favorito_mudou: EventEmitter<boolean> = new EventEmitter();

    item: LeiLookup;
    tempoRestante: Tempo;
    dataAlteracao: string;

    constructor(
        public dialog: MatDialog,
        public datePipe: DatePipe,
        private usuarioPreferenciasService: UsuarioPreferenciasService
    ) {}

    @Input() set setItem(setItem: LeiLookup) {
        this.item = setItem;
        this.tempoRestante = EstatisticasLeitura.CalcularTempoRestante(
            this.usuarioPreferenciasService.getConfiguracoesvalue.palavrasMinuto,
            this.item.progresso,
            this.item.quantidadePalavras
        );
        this.dataAlteracao = this.item.dataHoraUltimaModificacaoOficial
            ? "Última alteração em: " +
      this.datePipe.transform(
          this.item.dataHoraUltimaModificacaoOficial,
          "dd/MM/yyyy"
      )
            : "Última alteração indefinida";
    }

    public btnFavorito_click(evt: Event): void {
        evt.stopPropagation();
        this.favorito_mudou.emit(!this.item.favorita);
    }

    pad(num, size) {
        let s = num + "";
        while (s.length < size) {
            s = "0" + s;
        }
        return s;
    }
}
