import { Injectable } from "@angular/core";
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    IHttpConnectionOptions,
} from "@microsoft/signalr";
import { AppConfig } from "../app.config";
import { Observable, Subject } from "rxjs";
import { StatusService } from "./status.service";
import { EntitiesHelper } from "../helpers/entities.helper";
import { CompressionHelper } from "../helpers/compression.helper";
import { AuthService } from "../modules/shared/services/auth.service";

@Injectable()
export class SignalrService {
    private hubConnection: HubConnection;
    private actionUrl: string;

    private mensagem: OperacaoSignalR = null;
    private mensagem$ = new Subject<OperacaoSignalR>();

    private pendingChanges: number = this.countPendingChanges();
    private pendingChanges$ = new Subject<number>();

    private firstConnectionEstabilished = false;

    constructor(private statusService: StatusService, private auth: AuthService) {
        this.actionUrl = `${AppConfig.apiEndpoint}/hub`;

        this.initializeService();

        statusService.getAppOffline().subscribe(async (offline) => {
            if (this.firstConnectionEstabilished && !offline) {
                await this.conectar();
                this.processarFila();
            } else if (this.firstConnectionEstabilished) {
                await this.desconectar();
            }
        });
    }

    private get ready(): boolean {
        return (
            this.statusService.isAppOnline &&
  this.hubConnection &&
  this.hubConnection.state == HubConnectionState.Connected
        );
    }

    private get fila(): OperacaoSignalR[] {
        let retorno: OperacaoSignalR[] = [];
        const json = localStorage.getItem("fila-envio");

        if (json && JSON.parse(json)) {
            retorno = JSON.parse(json);
        }

        return retorno;
    }

    private set fila(value: OperacaoSignalR[]) {
        this.pendingChanges = value ? value.length : 0;

        this.updatePendingChanges();

        localStorage.setItem("fila-envio", value ? JSON.stringify(value) : null);
    }

    async conectar(): Promise<void> {
        const userId = this.auth.userID;

        if (
            this.statusService.isAppOnline &&
      this.hubConnection &&
      this.hubConnection.state !== HubConnectionState.Connected &&
      userId
        ) {
            await this.hubConnection.start();
            await this.hubConnection.invoke("JoinGroup", userId);
            await this.processarFila();

            this.firstConnectionEstabilished = true;
        }
    }

    public async enviarMensagem(
        mensagem: OperacaoSignalR,
        tipoObjeto: EnumTipoObjeto
    ): Promise<void> {
        mensagem.userId = this.auth.userID;
        if (!mensagem.tipoObjeto) {
            mensagem.tipoObjeto = tipoObjeto;
        }

        this.incluirMensagemFila(mensagem);

        if (this.ready) {
            await this.hubConnection.send("Sync", JSON.stringify(mensagem));
        }
    }

    public async enviarMensagens(
        mensagens: OperacaoSignalR[],
        tipoObjeto: EnumTipoObjeto
    ): Promise<void> {
        mensagens.forEach(async (mensagem) => {
            await this.enviarMensagem(mensagem, tipoObjeto);
        });
    }


    updateMensagem() {
        this.mensagem$.next(this.mensagem);
    }

    getMensagem(): Observable<OperacaoSignalR> {
        return this.mensagem$.asObservable();
    }

    updatePendingChanges() {
        this.pendingChanges$.next(this.pendingChanges);
    }

    getPendingChanges(): Observable<number> {
        return this.pendingChanges$.asObservable();
    }

    private initializeService() {
        const options: IHttpConnectionOptions = {};

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.actionUrl, options)
            .build();

        this.hubConnection.onclose(async () => await this.conectar());

        this.hubConnection.on("Sync", (message) => this.mensagemRecebida(message));
        this.hubConnection.on("success", (data) => this.sucessoSincronizacao(data));
        this.hubConnection.on("error", (data) => console.error(data));

        this.conectar();
    }



    private async desconectar() {
        if (
            this.hubConnection &&
    this.hubConnection.state === this.hubConnection.state
        ) {
            await this.hubConnection.stop();
        }
    }

    private async processarFila(): Promise<void> {
        const fila = this.fila;

        if (!fila || fila.length === 0) {
            return;
        }

        await this.enviarMensagens(fila, null);
    }

    private mensagemRecebida(mensagem: string): void {
        const json = CompressionHelper.strUnzip(mensagem);
        const msgObj = <OperacaoSignalR>JSON.parse(JSON.parse(json));
        this.mensagem = msgObj;
        this.updateMensagem();
    }

    private sucessoSincronizacao(data: string): void {
        const json = CompressionHelper.strUnzip(data);
        const mensagemGravada: OperacaoSignalR = JSON.parse(json);

        let fila = this.fila;
        if (fila) {
            fila = fila.filter((i) => i.operationId === mensagemGravada.operationId);
        }

        this.fila = fila;
    }

    private incluirMensagemFila(mensagem: OperacaoSignalR) {
        const fila = this.fila;

        if (fila.findIndex((i) => i.operationId === mensagem.operationId) === -1) {
            fila.push(mensagem);
        }

        this.fila = fila;
    }

    private countPendingChanges(): number {
        const fila = this.fila;
        return fila.length;
    }


}

export class OperacaoSignalR {
    public operationId: string;
    public userId: string;
    public tipoObjeto: EnumTipoObjeto;
    public dados: any;

    constructor() {
        this.operationId = EntitiesHelper.generateGuid();
    }
}

export enum EnumTipoObjeto {
    Instituicao = 0,
    Banca = 1,
    Ano = 2,
    Tipo = 3,
    Cargo = 4,
    Guias = 5,
    Marcacoes = 6,
    Comentarios = 7,
    Grifos = 8,
    Estatisticas = 9,
    Apontamentos = 10,
    Preferencias = 11,
    Referencias = 12,
}
