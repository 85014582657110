<span class="placeholder flex-row center-start">
  <span class="favorito">
    <button mat-icon-button (click)="btnFavorito_click($event)">
      <mat-icon
        class="item-navegacao-favorito-button-icon"
        [svgIcon]="item.favorita ? 'star' : 'star-borda implementar'"
      ></mat-icon>
    </button>
  </span>
  <span (click)="selecionarLei($event)" class="descricao flex-column flex-1">
    <span class="titulo">{{ item.titulo }}</span>
  </span>
</span>
