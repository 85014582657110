import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Marcacao } from "../../models/Marcacao";
import { StorageHelper } from "../../helpers/storage.helper";
import {
    SignalrService,
    EnumTipoObjeto,
    OperacaoSignalR,
} from "../signalr.service";
import { LoggingService } from "../logging.service";

@Injectable()
export class UsuarioMarcacoesService {
    readonly databaseName = "userdata";
    readonly collectionName = "marcacoes";

    public modificadoMarcacao$ = new Subject<Marcacao>();
    private modificadoMarcacao: Marcacao = null;

    constructor(
        private signalrService: SignalrService,
        private loggingService: LoggingService
    ) {
        signalrService
            .getMensagem()
            .subscribe((msg) => this.onMessageReceived(msg));
    }


    public fromNuvem(marcacoes: Marcacao[]): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsertMany(
                marcacoes,
                this.databaseName,
                this.collectionName,
                false
            )
                .then(() => {
                    onsuccess();
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public atualizar(marcacao: Marcacao, sync = true): Promise<Marcacao> {
        return new Promise((onsuccess, onerror) => {
            if (sync) {
                const mensagem = new OperacaoSignalR();
                mensagem.dados = marcacao;
                this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Marcacoes);
            }

            StorageHelper.upsert(marcacao, this.databaseName, this.collectionName)
                .then((marcacaoResult) => {
                    const novo = !marcacaoResult.dataHoraModificacao;

                    this.modificadoMarcacao = marcacaoResult;

                    this.updateModificadoMarcacao();

                    onsuccess(marcacaoResult);

                    if (novo) {
                        this.loggingService.LogEvent(
                            "Leitor - Marcar prova",
                            marcacaoResult.idLei,
                            marcacaoResult.id
                        );
                    }
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public remover(marcacao: Marcacao): Promise<Marcacao> {
        marcacao.removido = true;
        return this.atualizar(marcacao);
    }

    public buscarLei(idLei: string): Promise<Marcacao[]> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<Marcacao>(
                this.databaseName,
                this.collectionName,
                (m) => m.idLei === idLei
            )
                .then((marcacoes) => {
                    onsuccess(marcacoes);
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public listar(): Promise<Marcacao[]> {
        return new Promise((onsuccess) => {
            StorageHelper.list<Marcacao>(this.databaseName, this.collectionName)
                .then((marcacoes) => onsuccess(marcacoes))
                .catch((err) => {
                    throw err;
                });
        });
    }

    public buscarLinhaVersao(
        idLinha: string,
        versaoLinha: number
    ): Promise<Marcacao[]> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<Marcacao>(
                this.databaseName,
                this.collectionName,
                (m) => {
                    m.range.idItens.forEach((id) => {
                        if (id.idItem === idLinha && id.idImportacao === versaoLinha) {
                            return true;
                        }
                    });

                    return false;
                }
            )
                .then((marcacoes) => {
                    onsuccess(marcacoes);
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public buscarLinha(idLinha: string): Promise<Marcacao[]> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<Marcacao>(
                this.databaseName,
                this.collectionName,
                (m) => {
                    m.range.idItens.forEach((id) => {
                        if (id.idItem === idLinha) {
                            return true;
                        }
                    });

                    return false;
                }
            )
                .then((marcacoes) => {
                    onsuccess(marcacoes);
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    updateModificadoMarcacao() {
        this.modificadoMarcacao$.next(this.modificadoMarcacao);
    }

    getModificadoMarcacao(): Observable<Marcacao> {
        return this.modificadoMarcacao$.asObservable();
    }


    private onMessageReceived(msg: OperacaoSignalR): void {
        if (msg && msg.tipoObjeto === EnumTipoObjeto.Marcacoes) {
            this.atualizar(msg.dados, false);
        }
    }
}
