import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ListaUfs } from "src/app/constants/lista.ufs";
import { MaskHelper } from "src/app/helpers/mask.helper";
import { WebserviceHelper } from "src/app/helpers/webservice.helper";
import { CadastroUsuarioPlanoRequest } from "src/app/modules/auth/models/CadastroUsuarioPlanoRequest.model";
import { ItemLookupPlano } from "src/app/modules/auth/models/item-lookup-plano.model";
import { StatusService } from "src/app/services/status.service";
import { DetalhesAssinaturaResponse } from "../../arguments/assinatura/DetalhesAssinaturaResponse";
import { AssinaturaService } from "../../services/assinatura.service";
import { AuthService } from "../../services/auth.service";
import { FormControlValidators } from "../../validators/FormControlValidators";


@Component({
    selector: "app-indicador-trial",
    templateUrl: "./indicador-trial.component.html",
    styleUrls: ["./indicador-trial.component.scss"],
})
export class IndicadorTrialComponent implements OnInit {
    masks = MaskHelper;
    problemaPagamento = false;
    showModal = false;
    plano: DetalhesAssinaturaResponse;
    diasRestantesAvaliacao: number;
    percentualIndicador: number;
    strDiasRestantes: string;
    formStep = 1;

    applySubscriptionForm: FormGroup;

    planos: ItemLookupPlano[];

    public listaUfs = ListaUfs;

    bandeirasCartao = [
        { id: 1, descricao: "VISA" },
        { id: 2, descricao: "AMEX" },
        { id: 3, descricao: "MASTERCARD" },
        { id: 4, descricao: "ELO" },
        { id: 5, descricao: "HIPERCARD" },
        { id: 6, descricao: "DINERS" },
    ];

    timeout: any;

    constructor(
        private auth: AuthService,
        private assinaturaService: AssinaturaService,
        private statusService: StatusService,
        private matSnackBar: MatSnackBar    ) {
        this.applySubscriptionForm = new FormGroup({
            dadosPessoais: new FormControl({
                telefone: ["", [Validators.required]],
                cep: ["", [FormControlValidators.cep]],
                logradouro: ["", [Validators.required]],
                numeroLogradouro: ["", [Validators.required]],
                complemento: [""],
                bairro: ["", [Validators.required]],
                cidade: ["", [Validators.required]],
                uf: ["", [Validators.required]],
                siglaPais: ["BR", [Validators.required]],
            }),
            pagamento: new FormControl({
                cpf: ["", [FormControlValidators.cpf]],
                dataNascimento: ["", [FormControlValidators.diaMesAno]],
                idPlano: ["", [Validators.required]],
                numeroCartao: ["", [Validators.required]],
                meioPagamento: ["", [Validators.required]],
                nomeTitularCartao: ["", [Validators.required]],
                vencimentoCartao: ["", [Validators.required]],
                codigoSegurancaCartao: ["", [Validators.required]],
                deAcordoTermoCompra: [false, [Validators.requiredTrue]],
            }),
        });
    }

    async ngOnInit() {
        this.problemaPagamento = this.auth.accessToken().problemaPagamento;
        this.carregarAssinatura();
        this.planos = await this.assinaturaService.listarPlanos(false);
        this.checkProblemaPagamento();
    }

    async carregarAssinatura() {
        clearTimeout(this.timeout);
        this.plano = await this.assinaturaService.carregarPlanoAtual();

        this.diasRestantesAvaliacao = Math.floor(this.plano.diasRestantesAvaliacao);
        this.strDiasRestantes = this.diasRestantesAvaliacao == 1 ? "dia" : "dias";
        this.percentualIndicador =
      100 -
      (this.plano.diasTesteUtilizados / this.plano.tempoAvaliacaoGratuita) *
        100;

        if (this.diasRestantesAvaliacao < 0) {
            this.diasRestantesAvaliacao = 0;
        }

        if (this.plano.trialGratuito && this.diasRestantesAvaliacao <= 0) {
            this.showModal = true;
        }

        this.timeout = setTimeout(() => {
            this.carregarAssinatura();
        }, 30 * 60 * 1000);
    }

    indicador_clicked() {
        this.showModal = true;
    }
    checkProblemaPagamento() {
        this.showModal = this.problemaPagamento;
    }

    modal_clicked(event: MouseEvent) {
        this.fecharPopup();
    }

    dialog_clicked(event: MouseEvent) {
        event.stopImmediatePropagation();
    }

    async form_submit(event: Event) {
        event.preventDefault();

        if (!this.applySubscriptionForm.valid) {
            return;
        }

        const taskname = "login-succeded";
        try {
            this.statusService.mostrarMensagemProgresso(
                "Realizando seu cadastro...",
                taskname
            );

            let payload = <CadastroUsuarioPlanoRequest>(
        Object.assign(
            new CadastroUsuarioPlanoRequest(),
            this.applySubscriptionForm.controls["dadosPessoais"].value
        )
      );
            payload = <CadastroUsuarioPlanoRequest>(
        Object.assign(
            payload,
            this.applySubscriptionForm.controls["pagamento"].value
        )
      );

            if (this.problemaPagamento) {
                await this.assinaturaService.alterarPlano(payload);
                this.auth.atualizaProblemaPagamento(this.auth.accessToken());
                this.problemaPagamento = false;
            } else {
                await this.assinaturaService.assinarPlano(payload);
                await this.carregarAssinatura();
            }

            this.formNext();
        } catch (ex) {
            this.matSnackBar.open(ex.error, null, { duration: 5000 });
        } finally {
            this.statusService.ocultarMensagemProgresso(taskname);
        }
    }

    async buscarCep() {
        const group = <FormGroup>(
      this.applySubscriptionForm.controls["dadosPessoais"]
    );
        let cep = <string>group.controls["cep"].value;
        cep = cep.replace(".", "").replace("-", "");

        const endereco = await WebserviceHelper.consultaCep(cep);
        if (endereco) {
            group.patchValue({
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
                cidade: endereco.cidade,
                uf: endereco.estado,
            });
        }
    }

    formNext() {
        this.formStep += 1;
    }

    formPrevious() {
        this.formStep -= 1;
    }

    fecharPopup() {
        if (!this.plano.trialGratuito || this.diasRestantesAvaliacao > 0) {
            this.showModal = false;
        }
    }
    fecharProblemaPopup() {
        this.showModal = false;
    }
}
