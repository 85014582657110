import { environment } from "../../environments/environment";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { Injectable } from "@angular/core";

@Injectable()
export class LoggingService {
  constructor(private analytics: AngularFireAnalytics) {}

  /**
   * Loggar eventos e dados via google analytics
   * @param evento Nome do evento a ser logado ex: Carregou pagina x
   * @param key Nome da informação adicional que será passada ex: email (!IMPORTANTE: o parâmetro deve ser adicionado ao relatório de parâmetros do evento no google analytics para ser exibido)
   * @param description Informação adicional
   */
  public LogEvent(evento: string, key: string, description: string) {
    try {
      this.analytics.logEvent(evento, { key: key, description: description });
    } catch (error) {
      console.error(error);
    }
  }
}
