<app-leitor-content-panelbusca
  class="leitor-content-panelbusca"
  *ngIf="mostrarPainelBusca"
></app-leitor-content-panelbusca>
 <!-- *ngIf="!(breakpointMobileLg$ | async)" -->
<app-leitor-side-menu

  class="leitor-content-toolbar"
></app-leitor-side-menu>

<div class="leitor-content-container">
  <app-barra-navegacao
    class="leitor-content-barra-navegacao"
  ></app-barra-navegacao>

  <ng-container *ngIf="mostrarResultadoBuscaAvancada">
    <app-leitor-content-panelbuscaavancada
      class="leitor-content-busca-avancada"
    ></app-leitor-content-panelbuscaavancada>
  </ng-container>

  <ng-container *ngIf="!mostrarNovaGuia; else elseNovaGuia">
    <app-pagina class="leitor-content-pagina"></app-pagina>
  </ng-container>
</div>

<app-leitor-side-tools
  *ngIf="!(breakpointMobileLg$ | async)"
  class="leitor-content-side-tools"
></app-leitor-side-tools>

<ng-template #elseBuscaAvancada> </ng-template>

<ng-template #elseNovaGuia>
  <div class="leitor-content-nova-guia">nova guia</div>
</ng-template>
