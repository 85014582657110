import { Component } from "@angular/core";

@Component({
    selector: "app-popover-container",
    templateUrl: "./popover-container.component.html",
    styleUrls: ["./popover-container.component.scss"],
})
export class PopoverContainerComponent  {
    constructor() {}


}
