import { Injectable } from "@angular/core";
import { Intervalo } from "../../../models/Intervalo";
import { Observable, BehaviorSubject } from "rxjs";
import { ConteudoService } from "../../../services/conteudo.service";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { KeyValue } from "@angular/common";
import { TipoIntervalo } from "../../../enums/tipo.intervalo";
import { Guia } from "../../../models/Guia";
import { Conteudo } from "src/app/models/pagina/conteudo";

@Injectable({ providedIn: "root" })
export class BuscaRapidaService {
    private indice: KeyValue<string, number>[];
    private idGuiaAtiva: string;
    private numeroBuscar: number;

    private matchBuscaRapida: Intervalo = null;
    private matchBuscaRapida$ = new BehaviorSubject<Intervalo>(
        this.matchBuscaRapida
    );

    private conteudo;

    constructor(
        private conteudoService: ConteudoService,
        private guiaService: UsuarioGuiasService
    ) {
        this.conteudoService
            .getIndiceLei()
            .subscribe((indice) => this.indice_changed(indice));
        this.guiaService
            .getGuiaAtiva()
            .subscribe((guia) => this.guiaAtiva_changed(guia));

        this.conteudoService
            .getConteudo()
            .subscribe((conteudo) => this.conteudo_changed(conteudo));
    }
    conteudo_changed(conteudo: Conteudo): void {
        this.conteudo = conteudo ? conteudo : new Conteudo();
    }

    buscar(numero: number = null): void {
        this.matchBuscaRapida = null;
        this.updateMatchBuscaRapida();

        if (numero) {
            this.numeroBuscar = numero;
        }

        if (!this.indice || !this.numeroBuscar) {
            return;
        }

        const linha = this.indice.find((i) => i.value == this.numeroBuscar);
        if (!linha) {
            return;
        }

        const iLinha = this.conteudo.linhas.findIndex((l) => l.id === linha.key);
        if (iLinha === -1 || this.conteudo.linhas[iLinha].versoes.length === 0) {
            return;
        }

        const iVersao = this.conteudo.linhas[iLinha].versoes.length - 1;
        const trimPrefixo =
      this.conteudo.linhas[iLinha].versoes[iVersao].prefixo.trim();
        const match = new Intervalo(
            this.conteudo.linhas[iLinha].id,
            TipoIntervalo.ResultadoBusca,
            true,
            0,
            trimPrefixo.length - 1,
            new Date(),
            null
        );
        this.matchBuscaRapida = match;
        this.updateMatchBuscaRapida();
    }
    updateMatchBuscaRapida() {
        this.matchBuscaRapida$.next(this.matchBuscaRapida);
    }

    getMatchBuscaRapida(): Observable<Intervalo> {
        return this.matchBuscaRapida$.asObservable();
    }

    private indice_changed(indice: KeyValue<string, number>[]): void {
        this.indice = indice;
        this.buscar();
    }

    private guiaAtiva_changed(g: Guia): void {
        if (!g || this.idGuiaAtiva === g.id) {
            return;
        }

        this.idGuiaAtiva = g.id;
        this.matchBuscaRapida = null;
        this.updateMatchBuscaRapida();
        this.numeroBuscar = null;
    }

}
