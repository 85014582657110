<div class="navegador-busca-placehlder flex-column center-center relative">
  <div
    class="painelBuscaMinimizado shadow flex-column"
    *ngIf="
      params &&
      params.estadoJanelaBusca === estadoJanelaBusca['resultadoBuscaSimples']
    "
    (click)="params.estadoJanelaBusca = estadoJanelaBusca['maximizado']"
  >
    <div class="flex-row center-center" style="cursor: pointer">
      <span>Resultados da busca</span>
      <span class="flex-1"></span>
      <mat-icon class="btn-fechar" (click)="fechar($event)">close</mat-icon>
    </div>
    <div class="flex-row" style="margin-top: 8px">
      <span
        class="flex-column center-center icon icon-left"
        style="cursor: pointer"
        (click)="resultadoAnterior($event)"
      ></span>
      <span class="flex-column center-center flex-1" style="text-align: center"
        >{{ indexMatchBuscaAtual }} de {{ totalMatchsBuscaAtual }}</span
      >
      <span
        class="flex-column center-center icon icon-right"
        style="cursor: pointer"
        (click)="proximoResultado($event)"
      ></span>
    </div>
  </div>
</div>

<div
  class="painelLargo shadow"
  *ngIf="
    params &&
    (params.estadoJanelaBusca === estadoJanelaBusca['maximizado'] ||
      params.estadoJanelaBusca === estadoJanelaBusca['resultadoBuscaAvancada'])
  "
>
  <div class="title flex-row center-start">
    <span>Buscar</span>
    <span class="flex-1"></span>
    <mat-icon class="clickable" (click)="fechar($event)">close</mat-icon>
  </div>
  <div class="body flex-column">
    <div class="conteudo flex-column">
      <!-- Busca -->
      <div class="flex-row center-start">
        <div class="div-textobuscar flex-1 flex-column spaced">
          <mat-form-field class="textobuscar">
            <input
              matInput
              #textoBuscarInput
              type="text"
              name="texto-busca"
              maxlength="100"
              [placeholder]="'Digite o termo que deseja pesquisar'"
              [(ngModel)]="params.textoBuscar"
              (keyup.enter)="btnBuscar_click()"
            />
          </mat-form-field>
          <mat-slide-toggle
            *ngIf="!(appOffline$ | async)"
            [disabled]="!existeDocumentoAberto"
            [(ngModel)]="params.buscarTodosDocumentos"
            >Pesquisar em toda a legislação</mat-slide-toggle
          >
        </div>
        <mat-icon
          matTooltip="Aqui você pode digitar termos que deseja buscar e pode também estender a busca à todos os documentos"
          matTooltipPosition="right"
          matTooltipClass="mat-tooltip-informacao"
          style="padding-left: 32px; color: #0a83ba; cursor: pointer"
          >info</mat-icon
        >
      </div>

      <!-- Marcações -->
      <div>
        <div class="flex-row center-start">
          <div class="flex-1 flex-column spaced">
            <div class="titulo-secao-busca">
              Buscar por comentário ou mnemônicos
            </div>

            <div
              class="flex-row center-start"
              [ngClass]="{
                'li-anotacoes': true,
                'ativo-click': params.comentariosComentado.ativo,
                ativo: !params.comentariosComentado
              }"
            >
              <div class="divpanelbusca-left">
                <a (click)="toggleMarcacao(params.comentariosComentado)">
                  <div
                    class="icon icon-comentario btn-image btn-image-marcacao"
                    [style.color]="
                      params.comentariosComentado.cor == '#E9EBF5'
                        ? '#0E4875'
                        : params.comentariosComentado.cor
                    "
                  ></div>
                </a>
              </div>

              <mat-slide-toggle [(ngModel)]="params.comentariosComentado.ativo">
                {{
                  params.textoBuscar.length == 0
                    ? "Carregar todos os comentários"
                    : "Pesquisar em todos os comentários"
                }}
              </mat-slide-toggle>
            </div>

            <div
              class="flex-row center-start"
              [ngClass]="{
                'li-anotacoes': true,
                'ativo-click': params.mnemonicosOpcao.ativo,
                ativo: !params.mnemonicosOpcao
              }"
            >
              <div class="divpanelbusca-left">
                <a (click)="toggleMarcacao(params.mnemonicosOpcao)">
                  <div
                    class="icon btn-image btn-image-marcacao"
                    [style.color]="params.mnemonicosOpcao.cor"
                  >
                    <mat-icon
                      class="material-icons-outlined icon icon-titulo icon-popover"
                      >title</mat-icon
                    >
                  </div>
                </a>
              </div>

              <mat-slide-toggle [(ngModel)]="params.mnemonicosOpcao.ativo">
                {{
                  params.textoBuscar.length == 0
                    ? "Carregar todos os títulos"
                    : "Pesquisar em todos os mnemônicos"
                }}
              </mat-slide-toggle>
            </div>
          </div>
          <mat-icon
            matTooltip="Aqui você pode clicar nos ícones de título e comentário para buscar por suas marcações, ou digitar termos específicos de títulos e comentários que deseja buscar"
            [matTooltipPosition]="'right'"
            [matTooltipClass]="'mat-tooltip-informacao'"
            style="padding-left: 32px; color: #0a83ba; cursor: pointer"
            >info</mat-icon
          >
        </div>

        <div class="flex-row center-start">
          <div class="flex-column flex-1">
            <div class="titulo-secao-busca">Buscar por marca texto</div>
            <div class="param-secao-busca flex-row param-secao-busca ulcanetas">
              <div
                class="check-caneta flex-column center-center"
                *ngFor="let marcacaoCaneta of params.marcacoesCaneta"
                (click)="toggle(marcacaoCaneta)"
                [ngClass]="{ licanetas: true }"
                [style.background-color]="
                  marcacaoCaneta.ativo ? marcacaoCaneta.cor : ''
                "
              >
                <div
                  class="icon icon-marcador btn-image btn-image-grifo"
                  [title]="marcacaoCaneta.descricao"
                  [style.color]="
                    marcacaoCaneta.ativo ? '#FFFFFF' : marcacaoCaneta.cor
                  "
                ></div>
              </div>
            </div>
          </div>

          <mat-icon
            matTooltip="Aqui você pode selecionar um ou mais marca texto para buscar por suas marcações"
            [matTooltipPosition]="'right'"
            [matTooltipClass]="'mat-tooltip-informacao'"
            style="padding-left: 32px; color: #0a83ba; cursor: pointer"
            >info</mat-icon
          >
        </div>
      </div>
    </div>

    <button
      mat-raised-button
      color="primary"
      class="btn-pesquisar"
      (click)="btnBuscar_click()"
    >
      Pesquisar
    </button>
  </div>
</div>
<div
  class="navegador-busca-placehlder busca-avancada-minimizado flex-column center-center relative"
>
  <div
    *ngIf="
      params &&
      params.estadoJanelaBusca ===
        estadoJanelaBusca['resultadoBuscaAvancadaMinimizado']
    "
    class="painelBuscaAvancadaMinimizado"
  >
    <div
      class="painelBuscaMinimizado shadow flex-column"
      (click)="
        params.estadoJanelaBusca = estadoJanelaBusca['resultadoBuscaAvancada']
      "
    >
      <div class="flex-row center-center" style="cursor: pointer">
        <div class="text-center">Resultados da busca</div>
        <span class="flex-1"></span>
        <mat-icon class="btn-fechar" (click)="fechar($event)">close</mat-icon>
      </div>
      <div style="font-size: small" class="text-center">
        (clique para alterar)
      </div>
    </div>
  </div>
</div>
