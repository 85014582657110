import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-sim-nao-dialog",
    templateUrl: "./sim-nao-dialog.component.html",
    styleUrls: ["./sim-nao-dialog.component.scss"]
})
export class SimNaoDialogComponent  {

    constructor(
        public dialogRef: MatDialogRef<SimNaoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string) { }



    public btnNao_click(): void{
        this.dialogRef.close(false);
    }

    public btnSim_click(): void{
        this.dialogRef.close(true);
    }
}
