<div #abaLeitor class="bar flex-row center-start">
  <div class="space"></div>
  <app-aba-leitor
    *ngFor="let guia of guias$ | async; let i = index"
    [guia]="guia"
    [guiaIndex]="i"
    [ativaIndex]="indexGuiaAtiva$ | async"
    [hoverIndex]="indexMouseOver$ | async"
    [totalGuias]="quantidadeguia$ | async"
    [larguraAba]="larguraAba$ | async"
    (mouseover)="guia.hover = true"
    (mouseleave)="guia.hover = false"
    (click)="alternarPara(guia)"
    (aoFecharAba)="fecharGuia($event)"
  ></app-aba-leitor>
  <div class="btn-nova-guia-wrapper flex-row center-center"></div>
  <div class="flex-1 space"></div>
</div>
