import { Component, OnInit, OnDestroy } from "@angular/core";
import {
    ConteudoService,
    ProgressoLeitura,
} from "../../services/conteudo.service";
import { Tempo } from "../../models/usuario/EstatisticasLeitura";
import { Subscription, Observable, of } from "rxjs";
import { Conteudo } from "../../models/pagina/conteudo";
import { StatusService } from "../../services/status.service";
import { SignalrService } from "../../services/signalr.service";

@Component({
    selector: "app-leitor-statusbar",
    templateUrl: "./leitor-statusbar.component.html",
    styleUrls: ["./leitor-statusbar.component.scss"],
})
export class LeitorStatusbarComponent implements OnInit, OnDestroy {
    public progresso: number;
    public tempoRestante: Tempo;
    public mostrar: boolean;
    public conteudo$: Observable<Conteudo> = of(new Conteudo());
    public appOffline$: Observable<boolean> = of(false);
    public pendingChanges: number;
    private subscriptions: Subscription[] = [];
    private progressoLeitura: ProgressoLeitura;

    constructor(
        private conteudoService: ConteudoService,
        private statusService: StatusService,
        private signalrService: SignalrService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.conteudoService
                .getConteudo()
                .subscribe((c) => this.conteudo_changed(c))
        );

        this.appOffline$ = this.statusService.getAppOffline();

        this.signalrService
            .getPendingChanges()
            .subscribe((pendingChanges) => (this.pendingChanges = pendingChanges));
        this.conteudo$ = this.conteudoService.getConteudo();
    }

    conteudo_changed(c: Conteudo): void {
        this.mostrar = c ? true : false;

        if (this.mostrar && !(this.progressoLeitura === c.progressoLeitura)) {
            this.progresso_changed(c.progressoLeitura);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    private progresso_changed(progresso: ProgressoLeitura) {
        this.progressoLeitura = progresso;
        if (progresso) {
            this.progresso = progresso.progresso;
            this.tempoRestante = progresso.tempoRestante;
        } else {
            this.progresso = null;
            this.tempoRestante = null;
        }
    }
}
