import { ConfiguracoesUsuario } from "./usuario/ConfiguracoesUsuario";

export class NavbarModel {
    public user_nome: string;
    public user_foto: string;

    public static fromUserData(user: ConfiguracoesUsuario) {
        const ret = new NavbarModel();

        if (user) {
            ret.user_nome = user.nome;
            ret.user_foto = user.foto;
        }

        return ret;
    }
}
