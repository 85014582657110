export class StringHelper {
    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463
    // https://stackoverflow.com/questions/4374822/remove-all-special-characters-with-regexp
    public static RemoverAcentosCaracteresEspeciais(texto: string): string {
    // remover acentos
        texto = this.RemoverAcentos(texto);

        // remover carateres especiais
        texto = texto.replace(/[^\w\s]/gi, "");

        return texto;
    }

    public static RemoverAcentos(texto: string): string {
    // remover acentos
        texto = texto.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");

        return texto;
    }

    public static Normalize(value: string): string {
        return value.toLowerCase().replace(/\s/g, "");
    }
}
