<div class="indice-tree">
  <ng-container
    *ngIf="
      !(carregandoConteudo$ | async) && indiceSistematicoCompleto.length >= 1;
      else empty
    "
  >
    <mat-tree
      *ngIf="indiceSistematicoCompleto && indiceSistematicoCompleto.length > 0"
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="indice-mat-tree"
    >
      <mat-tree-node
        class="indice-tree-node"
        *matTreeNodeDef="let node"
        matTreeNodeToggle
      >
        <span mat-button class="texto" (click)="irParaItem(node)">{{
          node.texto
        }}</span>
      </mat-tree-node>
      <mat-nested-tree-node
        class="indice-tree-node"
        *matTreeNodeDef="let node; when: hasChild"
      >
        <div class="mat-tree-node">
          <span mat-button class="texto" (click)="irParaItem(node)">{{
            node.texto
          }}</span>

          <button
            class="indice-tree-node-button"
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.texto"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
        </div>

        <div
          [class.node-tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </ng-container>

  <ng-template #empty>
    <h4>Não existem itens para índice neste documento :(</h4>
  </ng-template>
</div>
