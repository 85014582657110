import { Pipe } from "@angular/core";
import { Injectable, ApplicationRef } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import {
  interval,
  concat,
  BehaviorSubject,
  Observable,
  combineLatest,
  Subject,
} from "rxjs";
import { concatAll, first } from "rxjs/operators";
import { DadosService } from "./dados.service";
import { StatusService } from "./status.service";

@Injectable()
export class CheckForUpdateService {
  private updateAvailable: boolean = false;
  private updateAvailable$ = new BehaviorSubject<boolean>(this.updateAvailable);

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private statusService: StatusService,
    private dadosService: DadosService
  ) {}

  initialize() {
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everyThirdMinutes$ = interval(0.5 * 60 * 60 * 1000);

    const everyThirdMinutesOnceAppIsStable$ = combineLatest([
      appIsStable$,
      everyThirdMinutes$,
    ]).pipe(concatAll());

    everyThirdMinutesOnceAppIsStable$.subscribe(() => {
      if (this.updates.isEnabled) this.updates.checkForUpdate();
    });

    this.updates.available.subscribe(() => {
      this.updates.activateUpdate().then(() => {
        this.updateAvailable = true;
        this.updateUpdateAvailable();
      });
    });
  }
  public getHistoricoAtualizacoesLeis(): Observable<boolean> {
    return this.updateAvailable$.asObservable();
  }

  async updateApplication() {
    const taskname = "app-update";
    this.statusService.mostrarMensagemProgresso(
      "sincronizando dados...",
      taskname
    );
    await this.dadosService.baixar();

    this.updateAvailable = this.updateAvailable;
    this.updateUpdateAvailable();

    this.statusService.mostrarMensagemProgresso("Tudo pronto!", taskname);
    document.location.reload();
  }

  updateUpdateAvailable() {
    this.updateAvailable$.next(this.updateAvailable);
  }

  getUpdateAvailable(): Observable<boolean> {
    return this.updateAvailable$.asObservable();
  }
}
