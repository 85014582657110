import { Injectable } from "@angular/core";
import { TagConteudo } from "../interfaces/TagConteudo";
import { Observable, BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class HoverService {
    private itens: TagConteudo[] = [];
    private itens$ = new BehaviorSubject<TagConteudo[]>(this.itens);

    constructor() { }

    get atual(): TagConteudo[] {
        return this.itens;
    }

    public zerar(): void {
        this.itens = null;
        this.updateItens();
    }

    public destacar(itens: TagConteudo[]): void {
        this.itens = itens;
        this.updateItens();
    }

    updateItens() {
        this.itens$.next(this.itens);
    }

    getItens(): Observable<TagConteudo[]> {
        return this.itens$.asObservable();
    }
}
