import { Injectable } from "@angular/core";

import { Lei } from "../models/Lei";
import { ItemVerificar } from "./storage-services/itens.verificar.service";
import { UserdataDTO } from "../models/UserdataSync";

@Injectable()
export class SynchronizationService {}

export class SyncResult {
  userData: UserdataDTO;
  leis: Lei[];
  itensVerificar: ItemVerificar[];
}

export class StatusSincronizacao {
  sincronizando: boolean;
  primeiraSincronizacao: boolean;
  progresso: number;
  mensagem: string;
  progressoIndeterminado: boolean;

  constructor() {
    this.sincronizando = true;
    this.progresso = 0;
    this.mensagem = "";
  }
}
