import { ModoSelecao } from "./ModoSelecao";
import { Selector } from "./Selector";
import { TipoSelecao } from "./TipoSelecao";

export class SelectionTexto {
    modo: ModoSelecao;
    tipo: TipoSelecao;
    selector: Selector;

    constructor() {
        this.modo = ModoSelecao.Padrao;
        this.tipo = null;
    }
}
