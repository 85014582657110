import { Component, OnDestroy, Input, OnInit } from "@angular/core";
import { KeyValue } from "@angular/common";
import { Observable, of, Subscription } from "rxjs";
import { NumberHelper } from "../../../helpers/numbers.helper";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { ConteudoService } from "../../../services/conteudo.service";
import { BuscaRapidaService } from "./busca.rapida.service";
import { Intervalo } from "src/app/models/Intervalo";

@Component({
    selector: "app-form-busca-artigo-documento-atual",
    templateUrl: "./form-busca-artigo-documento-atual.component.html",
    styleUrls: ["./form-busca-artigo-documento-atual.component.scss"],
})
export class FormBuscaArtigoDocumentoAtualComponent
implements OnDestroy, OnInit {
    @Input() prefixoBusca: string;
    @Input() tooltipBusca: string;

    readonly acceptedChars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "I",
        "V",
        "X",
        "L",
        "C",
        "D",
        "M",
    ];

    indice: KeyValue<string, number>[];
    found: boolean;
    public matchBuscaRapida$: Observable<Intervalo> = of(null);
    public numeroArtigo = "";
    private subscriptions: Subscription[] = [];

    constructor(
        public buscaRapidaService: BuscaRapidaService,
        private guiaService: UsuarioGuiasService,
        private conteudoService: ConteudoService
    ) {}
    ngOnInit(): void {
        this.subscriptions.push(
            this.guiaService.getGuiaAtiva().subscribe(() => (this.numeroArtigo = ""))
        );
        this.subscriptions.push(
            this.conteudoService.getIndiceLei().subscribe((i) => (this.indice = i))
        );
        this.matchBuscaRapida$ = this.buscaRapidaService.getMatchBuscaRapida();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    txtBuscaChanged(): void {
        let txt = this.numeroArtigo;

        if (!txt || txt.length <= 0) {
            txt = "-1";
        }

        const num = NumberHelper.isNumber(txt)
            ? parseInt(txt, 10)
            : NumberHelper.unRomanize(txt);
        this.buscaRapidaService.buscar(num);
    }

    validate(event) {
        const char = event?.key.toUpperCase();

        if (!char) {
            return false;
        }

        if (this.acceptedChars.indexOf(char) === -1) {
            event.preventDefault();
        }
    }
}
