import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    OnDestroy,
    ElementRef,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
} from "@angular/core";
import { Guia } from "../../../models/Guia";
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    of,
    Subscription,
} from "rxjs";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { map } from "rxjs/operators";

@Component({
    selector: "app-barra-navegacao",
    templateUrl: "./barra-navegacao.component.html",
    styleUrls: ["./barra-navegacao.component.scss"],
})
export class BarraNavegacaoComponent
implements OnInit, OnDestroy, AfterViewInit {
    @Output() expandirPainelNovaGuia = new EventEmitter<boolean>();
    @ViewChild("abaLeitor") abaLeitor: ElementRef;

    public foreground: string;

    public guias$: Observable<Guia[]> = of([]);

    public indexGuiaAtiva$: Observable<number> = of(0);

    public indexMouseOver$: Observable<number> = of(0);
    larguraAba = 180;

    quantidadeguia$: Observable<number> = of(0);
    larguraAba$: Observable<string> = of("");

    resizeObserver: ResizeObserver;

    private larguraComponet: number = null;
    private larguraComponet$ = new BehaviorSubject<number>(this.larguraComponet);
    private subscriptions: Subscription[] = [];


    constructor(
        private usuarioGuiasService: UsuarioGuiasService,
        private changeDetector: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.resizeObserver = new ResizeObserver((entries) => {
            this.larguraComponet = entries[0].contentRect.width;

            this.updatelarguraComponet();
            this.changeDetector.detectChanges();
        });

        this.resizeObserver.observe(this.abaLeitor?.nativeElement);
    }

    async ngOnInit() {
        this.guias$ = this.usuarioGuiasService.getGuias();

        this.indexGuiaAtiva$ = this.usuarioGuiasService.getIndexGuiaAtiva();

        this.indexMouseOver$ = this.usuarioGuiasService.getIndexMouseOver();
        this.quantidadeguia$ = this.guias$.pipe(map((guias) => guias.length));

        this.larguraAba$ = combineLatest([
            this.quantidadeguia$,
            this.larguraComponet$,
        ]).pipe(
            map(([aba, larguraComponet]) => (larguraComponet - 32) / aba < this.larguraAba
                ? `${(larguraComponet - 32) / aba}px`
                : `${this.larguraAba}px`)
        );

        this.usuarioGuiasService.carregar();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];

        this.resizeObserver.unobserve(this.abaLeitor.nativeElement);
    }

    public async alternarPara(guia: Guia) {
        await this.usuarioGuiasService.alterarGuiaAtiva(guia.id);
    }

    public fecharGuia(idGuia: string) {
        this.usuarioGuiasService.fecharGuia(idGuia);
    }

    updatelarguraComponet() {
        this.larguraComponet$.next(this.larguraComponet);
    }

    getlarguraComponet(): Observable<number> {
        return this.larguraComponet$.asObservable();
    }
}
