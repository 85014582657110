import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { Marcacao } from "../models/Marcacao";
import { Comentario } from "../models/Comentario";

@Injectable()
export class PaineisService {
  private painelMarcacoesAberto: boolean = false;
  private painelMarcacoesAberto$ = new BehaviorSubject<boolean>(
    this.painelMarcacoesAberto
  );

  private painelMarcacaoHover: Marcacao = null;
  private painelMarcacaoHover$ = new BehaviorSubject<Marcacao>(
    this.painelMarcacaoHover
  );

  private painelComentarioHover: Comentario = null;
  private painelComentarioHover$ = new BehaviorSubject<Comentario>(
    this.painelComentarioHover
  );

  private painelNovidades: boolean = null;
  private painelNovidades$ = new BehaviorSubject<boolean>(this.painelNovidades);

  private painelSpinner: boolean = null;
  private painelSpinner$ = new BehaviorSubject<boolean>(this.painelSpinner);

  private algumPainelAberto: boolean = null;
  private algumPainelAberto$ = new BehaviorSubject<boolean>(
    this.algumPainelAberto
  );

  constructor() {}

  private updatePanelInfo() {
    if (this.painelAberto) {
      this.painelMarcacoesAberto = true;
      this.updatePainelMarcacoesAberto();
    } else {
      this.painelMarcacoesAberto = false;
      this.updatePainelMarcacoesAberto();
    }
  }

  get painelAberto() {
    return null;
  }

  abrirPainelNovidades() {
    this.fecharPaineis();
    this.painelNovidades = true;
    this.updatePainelNovidades();
    this.algumPainelAberto = false;
    this.updateAlgumPainelAberto();
  }

  fecharPainelNovidades() {
    this.painelNovidades = null;
    this.updatePainelNovidades();
    this.algumPainelAberto = false;
    this.updateAlgumPainelAberto();
  }

  abrirPainelSpinner() {
    this.fecharPaineis();
    this.painelSpinner = true;
    this.updatePainelSpinner();

    this.algumPainelAberto = false;
    this.updateAlgumPainelAberto();
  }

  fecharPainelSpinner() {
    this.painelSpinner = null;
    this.updatePainelSpinner();
    this.algumPainelAberto = false;
    this.updateAlgumPainelAberto();
  }

  fecharPaineis() {
    this.fecharPainelNovidades();
    this.fecharPainelSpinner();
  }

  updatePainelSpinner() {
    this.painelSpinner$.next(this.painelSpinner);
  }

  getPainelSpinner(): Observable<boolean> {
    return this.painelSpinner$.asObservable();
  }
  updateAlgumPainelAberto() {
    this.algumPainelAberto$.next(this.algumPainelAberto);
  }

  getAlgumPainelAbertoo(): Observable<boolean> {
    return this.algumPainelAberto$.asObservable();
  }
  updatePainelMarcacaoHover() {
    this.painelMarcacaoHover$.next(this.painelMarcacaoHover);
  }

  getPainelMarcacaoHover(): Observable<Marcacao> {
    return this.painelMarcacaoHover$.asObservable();
  }
  updatePainelComentarioHover() {
    this.painelComentarioHover$.next(this.painelComentarioHover);
  }

  getPainelComentarioHover(): Observable<Comentario> {
    return this.painelComentarioHover$.asObservable();
  }
  updatePainelNovidades() {
    this.painelNovidades$.next(this.painelNovidades);
  }

  getPainelNovidades(): Observable<boolean> {
    return this.painelNovidades$.asObservable();
  }
  updatePainelMarcacoesAberto() {
    this.painelMarcacoesAberto$.next(this.painelMarcacoesAberto);
  }

  getPainelMarcacoesAberto(): Observable<boolean> {
    return this.painelMarcacoesAberto$.asObservable();
  }
}
