import locale from "@angular/common/locales/pt";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LeitorComponent } from "./components/leitor/leitor.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { IndicatorsComponent } from "./components/indicators/indicators.component";
import { SynchronizationService } from "./services/synchronization.service";
import { IndicatorsService } from "./services/indicators.service";
import { LeitorSideMenuComponent } from "./components/leitor-side-menu/leitor-side-menu.component";
import { LeitorContentComponent } from "./components/leitor-content/leitor-content.component";
import { LeitorStatusbarComponent } from "./components/leitor-statusbar/leitor-statusbar.component";
import { SelectionService } from "./services/selection.service";
import { PaineisService } from "./services/paineis.service";
import { LeitorContentPanelBuscaComponent } from "./components/leitor-content-panelbusca/leitor-content-panelbusca.component";
import { StatusService } from "./services/status.service";
import { BuscaService } from "./services/busca.service";
import { BarraNavegacaoComponent } from "./components/leitor/barra-navegacao/barra-navegacao.component";
import { ConteudoService } from "./services/conteudo.service";
import { PaginaComponent } from "./components/leitor/pagina/pagina.component";
import { LinhaComponent } from "./components/leitor/pagina/linha/linha.component";
import { TrechoTextoComponent } from "./components/leitor/pagina/linha/trecho-texto/trecho-texto.component";
import { HoverService } from "./services/hover.service";
import { PlaceholderPaineisComponent } from "./components/leitor/placeholder-paineis/placeholder-paineis.component";
import { PlaceholderPaineisService } from "./components/leitor/placeholder-paineis/placeholder-paineis.service";
import { PainelComentariosComponent } from "./components/leitor/placeholder-paineis/painel-comentarios/painel-comentarios.component";

import { TagPickerComponent } from "./components/leitor/placeholder-paineis/painel-marcacoes-prova/tag-picker/tag-picker.component";
import { InfoPanelComponent } from "./components/leitor-statusbar/info-panel/info-panel.component";
import { IndicadorLeituraComponent } from "./components/leitor-statusbar/indicador-leitura/indicador-leitura.component";
import { TimerComponent } from "./components/navbar/timer/timer.component";

import { NovidadesComponent } from "./components/novidades/novidades.component";
import { TimerService } from "./services/timer.service";
import { ButtonLeitorSideMenuComponent } from "./components/leitor-side-menu/button-leitor-side-menu/button-leitor-side-menu.component";
import { LeitorContentPanelBuscaAvancadaComponent } from "./components/leitor-content-panelbuscaavancada/leitor-content-panelbuscaavancada.component";
import { UiService } from "./services/ui.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { PaginadorComponent } from "./components/paginador/paginador.component";
import { UiScrollModule } from "ngx-ui-scroll";
import { ItensVerificarService } from "./services/storage-services/itens.verificar.service";
import { ShortcutsService } from "./services/shortcuts.service";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { PrimeiroLoginComponent } from "./components/popups/primeiro-login/primeiro-login.component";
import { PopupsComponent } from "./components/popups/popups.component";
import { PopupsService } from "./components/popups/popups.service";
import { PerfilComponent } from "./components/popups/perfil/perfil.component";
import { FormIdentificacaoComponent } from "./components/popups/perfil/form-identificacao/form-identificacao.component";
import { FormAlterarSenhaComponent } from "./components/popups/perfil/form-alterar-senha/form-alterar-senha.component";
import { FormPagamentoComponent } from "./components/popups/perfil/form-pagamento/form-pagamento.component";
import { PreferenciasComponent } from "./components/popups/preferencias/preferencias.component";
import { SimNaoDialogComponent } from "./components/dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { ColorPickerComponent } from "./components/dialogs/color-picker/color-picker.component";
import { FormCancelamentoComponent } from "./components/popups/perfil/form-cancelamento/form-cancelamento.component";
import { FormIndicacoesComponent } from "./components/popups/perfil/form-indicacoes/form-indicacoes.component";
import { DatePipe } from "@angular/common";

import { LeitorContentPanelBuscaProgressoComponent } from "./components/leitor-content-panelbuscaprogresso/leitor-content-panelbuscaprogresso.component";
import { UsuarioGuiasService } from "./services/data-services/usuario.guias.service";
import { UsuarioPreferenciasService } from "./services/data-services/usuario.preferencias.service";
import { ErroPopUpComponent } from "./components/popups/mensagem/erro-popup.component";
import { UsuarioEstatisticasService } from "./services/data-services/usuario.estatisticas.service";
import { UsuarioMarcacoesService } from "./services/data-services/usuario.marcacoes.service";
import { UsuarioComentariosService } from "./services/data-services/usuario.comentarios.service";
import { UsuarioGrifosService } from "./services/data-services/usuario.grifos.service";
import { UsuarioAnotacoesService } from "./services/data-services/usuario.anotacoes.service";
import { UsuarioApontamentosService } from "./services/data-services/usuario.apontamentos.service";
// azure card 358 Desativar botão caiu em prova
import { UsuarioProvasService } from "./services/data-services/usuario.provas.service";
import { DialogoComponent } from "./components/popups/dialogo/dialogo.component";
import { DialogoService } from "./components/popups/dialogo/dialogo.service";
import { SignalrService } from "./services/signalr.service";
import { DadosService } from "./services/dados.service";
import { ViewLabComponent } from "./components/view-lab/view-lab.component";
import { MaterialModule } from "./helpers/material.module";
import { routing } from "./helpers/app.routing";
import { PopupCarregamentoComponent } from "./components/popups/popup-carregamento/popup-carregamento.component";

import {
    MarcacaoSuperiorComponent,
    ResizableDivDirective,
    AutoGrowDivDirective as AutoGrowDivDirective,
    AutoWidhtDirective,
} from "./components/leitor/pagina/marcacao-superior/marcacao-superior.component";
import { RefreshTokenInterceptor } from "./interceptors/refresh-token.interceptor";
import { LoggingService } from "./services/logging.service";
import { AbaLeitorComponent } from "./components/leitor/barra-navegacao/aba-leitor/aba-leitor.component";
import { BackgroundContrastDirective } from "./directives/background-contrast.directive";
// azure card 358 Desativar botão caiu em prova import { ChipProvaComponent } from "./components/leitor/pagina/marcacao-superior/chip-prova/chip-prova.component";
import { FeedbackComponent } from "./components/popups/feedback/feedback.component";
import { TextFormatingService } from "./services/ui/textFormating.service";
import { ControleFonteComponent } from "./controls/leitor/controle-fonte/controle-fonte.component";
import { IncompatibleBrowserComponent } from "./pages/incompatible-browser/incompatible-browser.component";
import { CheckForUpdateService } from "./services/checkForUpdateService";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { dbConfig } from "./idb.config";
import { registerLocaleData } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LeiRepositorio } from "./repositorios/lei.repositorio";
import { DataControleRepositorio } from "./repositorios/dataControle.repositorio";
import { LeiService } from "./services/lei.service";
import { FormBuscaArtigoDocumentoAtualComponent } from "./controls/busca/form-busca-artigo-documento-atual/form-busca-artigo-documento-atual.component";
import { SortByPipe } from "./pipes/sort.by.type.pipe";
import { EditorComentarioComponent } from "./components/leitor/editors/editor-comentario/editor-comentario.component";
import { CabecalhoComponent } from "./components/leitor/pagina/cabecalho/cabecalho.component";
import { IndiceSistematicoComponent } from "./components/popups/indice-sistematico/indice-sistematico.component";
import { ErrorHandlerService } from "./services/errorHandler.service";
import { BrowserModule } from "@angular/platform-browser";
import { StarRatingComponent } from "./components/star-rating/star-rating.component";
import { EditorTagsComponent } from "./components/leitor/editors/editor-tags/editor-tags.component";

import { UsuarioComentariosGerenciadosService } from "./services/data-services/usuario.comentarios.gerenciados.service";
import { ConfigDialogComponent } from "./components/popups/config-dialog/config-dialog.component";
import { EditorReferenciaComponent } from "./components/leitor/editors/editor-referencias/editor-referencia.component";
import { UsuarioReferenciaService } from "./services/data-services/usuario.referencia.service";
import { ReferenciaDialogComponent } from "./components/popups/referencia-dialog/referencia-dialog.component";
import { FeatureFlagService } from "./services/feature-flag-service.service";
import { DicaEstudosComponent } from "./components/popups/dica-estudos/dica-estudos.component";
import { QuillModule } from "ngx-quill";
import { RichTextComponent } from "./components/rich-text/rich-text.component";
import { UsuarioReferenciasGerenciadasService } from "./services/data-services/usuario.referencias.gerenciadas.service";
import { ApplicationInsightsService } from "./services/application-insights.service";
import { TituloComponent } from "./components/leitor/editors/editor-comentario/titulo/titulo.component";
import { ComentarioComponent } from "./components/leitor/editors/editor-comentario/comentario/comentario.component";
import { CommonModule } from "@angular/common";
import { HistoricoAtualizacoesComponent } from "./components/navbar/historico-atualizacoes/historico-atualizacoes.component";
import { HistoricoAtualizacoesDialogComponent } from "./components/popups/historico-atualizacoes-dialog/historico-atualizacoes-dialog.component";
import { NavegacaoPesquisaLeisComponent } from "./components/navbar/navegacao-pesquisa-leis/navegacao-pesquisa-leis.component";

import { ItemNavegacaoPesquisaLeisComponent } from "./components/navbar/navegacao-pesquisa-leis/item-navegacao-pesquisa-leis/item-navegacao-pesquisa-leis.component";

import { NavegacaoLeisRamosDireitoComponent } from "./components/navbar/navegacao-leis-ramos-direito/navegacao-leis-ramos-direito.component";
import { ItemNavegacaoFavoritosComponent } from "./components/navbar/navegacao-favoritos/item-navegacao-favoritos/item-navegacao-favoritos.component";
import { NavegacaoFavoritosComponent } from "./components/navbar/navegacao-favoritos/navegacao-favoritos.component";

import { PopoverService } from "./components/popover/popover.service";
import { SharedModule } from "./modules/shared/shared.module";
import { PopoverContainerComponent } from "./components/popover/popover-container/popover-container.component";
import { LeitorSideToolsComponent } from "./components/leitor-side-tools/leitor-side-tools.component";
import { MathFloorPipe } from "./pipes/math-floor.pipe";
import { NgxMaskModule } from "ngx-mask";
import { maskConfig } from "./mask.config";

import {
    ScreenTrackingService,
    UserTrackingService,
} from "@angular/fire/analytics";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { AngularFireModule } from "@angular/fire/compat";
import { InfoLeiComponent } from "./components/leitor/nova-guia/info-lei/info-lei.component";
import { PopoverDirective } from "./directives/popover.directive";

registerLocaleData(locale);

@NgModule({
    declarations: [
        AppComponent,
        LeitorComponent,
        NavbarComponent,
        IndicatorsComponent,
        LeitorContentComponent,
        LeitorStatusbarComponent,
        BarraNavegacaoComponent,
        LeitorContentPanelBuscaComponent,
        PaginaComponent,
        LinhaComponent,
        TrechoTextoComponent,
        PlaceholderPaineisComponent,
        PainelComentariosComponent,
        // azure card 358 Desativar botão caiu em prova PainelMarcacoesProvaComponent,
        TagPickerComponent,
        InfoPanelComponent,
        PaginadorComponent,
        IndicadorLeituraComponent,
        TimerComponent,

        NovidadesComponent,

        LeitorContentPanelBuscaAvancadaComponent,

        PopupsComponent,
        PrimeiroLoginComponent,
        PerfilComponent,
        FormIdentificacaoComponent,
        FormAlterarSenhaComponent,
        FormPagamentoComponent,
        FormCancelamentoComponent,
        FormIndicacoesComponent,
        PreferenciasComponent,
        SimNaoDialogComponent,
        ColorPickerComponent,
        InfoLeiComponent,
        LeitorContentPanelBuscaProgressoComponent,
        ErroPopUpComponent,
        DialogoComponent,
        ViewLabComponent,
        PopupCarregamentoComponent,
        MarcacaoSuperiorComponent,
        FeedbackComponent,
        ResizableDivDirective,
        AutoGrowDivDirective,
        AutoWidhtDirective,
        AbaLeitorComponent,
        BackgroundContrastDirective,
        // azure card 358 Desativar botão caiu em prova  ChipProvaComponent,
        ControleFonteComponent,
        IncompatibleBrowserComponent,
        FormBuscaArtigoDocumentoAtualComponent,
        SortByPipe,
        EditorComentarioComponent,
        CabecalhoComponent,
        IndiceSistematicoComponent,
        SortByPipe,
        StarRatingComponent,
        EditorTagsComponent,
        ConfigDialogComponent,
        EditorReferenciaComponent,
        ReferenciaDialogComponent,
        DicaEstudosComponent,
        RichTextComponent,
        TituloComponent,
        ComentarioComponent,
        HistoricoAtualizacoesComponent,
        HistoricoAtualizacoesDialogComponent,
        NavegacaoPesquisaLeisComponent,
        ItemNavegacaoPesquisaLeisComponent,
        NavegacaoLeisRamosDireitoComponent,
        ItemNavegacaoFavoritosComponent,
        NavegacaoFavoritosComponent,
        PopoverContainerComponent,
        PopoverDirective,
        LeitorSideToolsComponent,
        MathFloorPipe,
        LeitorSideMenuComponent,
        ButtonLeitorSideMenuComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        SharedModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        ReactiveFormsModule,
        routing,
        UiScrollModule,
        VirtualScrollerModule,

        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        BrowserAnimationsModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        QuillModule.forRoot(),
        NgxMaskModule.forRoot(maskConfig),
    ],
    providers: [
    // Repositórios
        LeiRepositorio,
        DataControleRepositorio,
        // Serviços
        LeiService,
        BuscaService,
        ConteudoService,
        DadosService,
        DatePipe,
        DialogoService,
        HoverService,
        IndicatorsService,
        ItensVerificarService,
        LoggingService,

        PaineisService,
        PlaceholderPaineisService,
        PopupsService,
        SelectionService,
        ShortcutsService,
        SignalrService,

        StatusService,
        SynchronizationService,
        TextFormatingService,
        TimerService,
        UiService,
        UsuarioGuiasService,
        UsuarioPreferenciasService,
        UsuarioEstatisticasService,
        UsuarioMarcacoesService,
        UsuarioComentariosService,
        UsuarioGrifosService,
        UsuarioAnotacoesService,
        UsuarioApontamentosService,
        // azure card 358 Desativar botão caiu em prova
        UsuarioProvasService,
        ApplicationInsightsService,
        ErrorHandlerService,
        { provide: LOCALE_ID, useValue: "pt" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        CheckForUpdateService,
        UsuarioComentariosGerenciadosService,
        UsuarioReferenciaService,
        FeatureFlagService,
        UsuarioReferenciasGerenciadasService,
        PopoverService,
        ScreenTrackingService,
        UserTrackingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
