import { Injectable } from "@angular/core";
import {
    Observable,
    BehaviorSubject,
    Subscription,
    fromEvent,
} from "rxjs";
import { Tema } from "../models/tema";
import { TemasApp } from "../constants/lista.temas";
import { UsuarioPreferenciasService } from "./data-services/usuario.preferencias.service";
import { map } from "rxjs/operators";

@Injectable()
export class UiService {
    private tema: Tema = null;
    private tema$ = new BehaviorSubject<Tema>(this.tema);

    private isMobile$: Subscription = Subscription.EMPTY;

    private mobile: boolean = window.innerWidth <= 1024;
    private mobile$ = new BehaviorSubject<boolean>(this.mobile);

    private breakpointMobileSm: boolean = window.innerWidth <= 430;
    private breakpointMobileSm$ = new BehaviorSubject<boolean>(
        this.breakpointMobileSm
    );

    private breakpointMobileMd: boolean = window.innerWidth <= 744;
    private breakpointMobileMd$ = new BehaviorSubject<boolean>(
        this.breakpointMobileMd
    );

    private breakpointMobileLg: boolean = window.innerWidth <= 1024;
    private breakpointMobileLg$ = new BehaviorSubject<boolean>(
        this.breakpointMobileLg
    );

    private larguraTela: number = window.innerWidth;
    private larguraTela$ = new BehaviorSubject<number>(this.larguraTela);

    private alturaTela: number = window.innerHeight;
    private alturaTela$ = new BehaviorSubject<number>(this.larguraTela);

    private idTema: number;

    constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {
        this.usuarioPreferenciasService.getConfiguracoes().subscribe((config) => {
            if (config && this.idTema !== config.idTema) {
                this.idTema = config.idTema;
                this.updateTema();
            }
        });

        this.isMobile$ = fromEvent(window, "resize")
            .pipe(map(() => window))
            .subscribe((status) => {
                this.breakpointMobileSm = status.innerWidth <= 430;
                this.breakpointMobileMd = status.innerWidth <= 744;
                this.breakpointMobileLg = status.innerWidth <= 1024;
                this.mobile = status.innerWidth <= 1024;
                this.larguraTela = status.innerWidth;
                this.alturaTela = status.innerHeight;
                this.updateAlturaTela();
                this.updateMobile();
                this.updateBreakpointMobileSm();
                this.updateBreakpointMobileMd();
                this.updateBreakpointMobileLg();
                this.updateLarguraTela();
            });
    }

    public get isMobile(): boolean {
        return this.mobile;
    }

    public get isBreakpointMobileMd(): boolean {
        return this.breakpointMobileMd;
    }

    public get isBreakpointMobileLg(): boolean {
        return this.breakpointMobileLg;
    }

    public getMobile(): Observable<boolean> {
        return this.mobile$.asObservable();
    }

    public getBreakpointMobileLg(): Observable<boolean> {
        return this.breakpointMobileLg$.asObservable();
    }

    public getBreakpointMobileSm(): Observable<boolean> {
        return this.breakpointMobileSm$.asObservable();
    }

    public getBreakpointMobileMd(): Observable<boolean> {
        return this.breakpointMobileMd$.asObservable();
    }

    public alterarTema(tema: Tema): void {
        this.tema$.next(tema);
    }

    public getLarguraTela(): Observable<number> {
        return this.larguraTela$.asObservable();
    }

    public getAlturaTela(): Observable<number> {
        return this.alturaTela$.asObservable();
    }

    public getTema(): Observable<Tema> {
        return this.tema$.asObservable();
    }

    private updateTema(): void {
        this.tema$.next(TemasApp[this.idTema]);
    }

    private updateAlturaTela(): void {
        this.alturaTela$.next(this.alturaTela);
    }

    private updateMobile(): void {
        this.mobile$.next(this.mobile);
    }

    private updateBreakpointMobileSm(): void {
        this.breakpointMobileSm$.next(this.breakpointMobileSm);
    }
    private updateBreakpointMobileMd(): void {
        this.breakpointMobileMd$.next(this.breakpointMobileMd);
    }
    private updateBreakpointMobileLg(): void {
        this.breakpointMobileLg$.next(this.breakpointMobileLg);
    }
    private updateLarguraTela(): void {
        this.larguraTela$.next(this.larguraTela);
    }


}
