<div class="popup-placeholder noselect">
  <div class="header">
    <img class="logo" src="assets\ui\logo-h-50.png" />
    <h3>Seja bem vindo a sua nova maneira de estudar!</h3>
  </div>

  <div class="body">
    <h4>Vamos definir algumas configurações antes de começar:</h4>
    <hr />

    <div class="painel-selecao-perfil">
      <mat-form-field style="width: 100%">
        <mat-select
          placeholder="Seu perfil de uso"
          [(ngModel)]="opcaoSelecionada"
          name="selModoUso"
          style="width: 100%"
        >
          <mat-option *ngFor="let opcao of opcoesModoUso" [value]="opcao">
            {{ opcao.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p class="dica" *ngIf="opcaoSelecionada && opcaoSelecionada.dica">
        {{ opcaoSelecionada.dica }}
      </p>
    </div>

    <div
      class="check-box-placeholder"
      *ngIf="
        opcaoSelecionada &&
        (opcaoSelecionada.tipo === 1 || opcaoSelecionada.tipo === 2)
      "
    >
      <mat-checkbox
        [(ngModel)]="mostrarComentarios"
        title="Define se o leitor irá apresentar os trechos comentados"
        >Mostrar comentários</mat-checkbox
      >
      <mat-checkbox
        [(ngModel)]="mostrarMnemonicos"
        title="Define se o leitor irá apresentar os trechos com mnemônicos definidos"
        >Mostrar mnemônicos</mat-checkbox
      >
    </div>

    <div class="painel-definicao-palavras-minutos" *ngIf="opcaoSelecionada">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <label class="rating-star-label">Velocidade de leitura</label>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <app-star-rating
            placeholder="Velocidade de leitura"
            [rating]="palavrasMinuto"
            (ratingUpdated)="updatedRating($event)"
          ></app-star-rating>
        </div>
      </div>
      <p class="dica">
        Sua capacidade de leitura estimada. Através desse numero calcularemos o
        tempo necessário para leitura das leis
      </p>
    </div>
    <hr />

    <h6>
      *Você também poderá alterar esses parâmetros posteriormente através do
      menu <i>configurações</i>
    </h6>
    <button
      mat-raised-button
      disabled="{{ !mostrarBotaoAvancar }}"
      (click)="salvarPerfil()"
    >
      Avançar
    </button>
  </div>
</div>
