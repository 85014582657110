import { Component, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";

@Component({
    selector: "app-leitor-side-tools",
    templateUrl: "./leitor-side-tools.component.html",
    styleUrls: ["./leitor-side-tools.component.scss"],
})
export class LeitorSideToolsComponent  {
    @ViewChild(MatAccordion) accordion: MatAccordion;

    public panelindice = false;
    public paneltimer = false;
    public panelbrain = false;
    public panelcircle = false;
    public panelmessage = false;
    public paneltags = false;
    public panelplay = false;
    public openHover = false;

    constructor() {}
}
