<router-outlet></router-outlet>

<div *ngIf="carregarAplicativo">
  <app-indicators></app-indicators>
  <app-novidades></app-novidades>
  <app-popups
    *ngIf="showPopup"
    (click)="tryClosePopup($event)"
    [@transitionPopup]="showPopup"
  ></app-popups>
</div>
