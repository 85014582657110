import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SimNaoDialogComponent } from "src/app/components/dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { Comentario } from "src/app/models/Comentario";
import { UsuarioComentariosService } from "src/app/services/data-services/usuario.comentarios.service";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";
import { UiService } from "src/app/services/ui.service";

@Component({
    selector: "app-comentario",
    templateUrl: "./comentario.component.html",
    styleUrls: ["./comentario.component.scss"],
})
export class ComentarioComponent implements OnInit {
    @Input() public currentTagsComentario: string[] = [];
    @Input() public comentario: Comentario;
    @Input() public novoComentario: boolean;
    @Input() public corComentario: string;
    @Input() public currentTextoComentario: string;
    @Input() public editandoComentario: boolean;
    @Input() public email: string;
    @Input() public comentarioGerenciadoReadOnly: boolean;
    @Input() public comentarioGerenciadoPlus: boolean;

    @Output() comentarioCommited = new EventEmitter();
    @Output() comentarioCancel = new EventEmitter();
    @Output() changeEditando = new EventEmitter();

    public isAdm = false;
    public newtags: string[] = [];
    public limiteCaracteresComentario = 3000;
    public limiteExtendidoCaracteresComentario = 15000;
    public editorElement: HTMLDivElement = null;
    public viewerElement: HTMLSpanElement = null;
    private cancelled = false;


    constructor(
        private dialog: MatDialog,
        private usuarioComentariosService: UsuarioComentariosService,
        private changeDetector: ChangeDetectorRef,
        private featureFlagService: FeatureFlagService,
        public uiService: UiService
    ) {

    }

    @ViewChild("editor", { static: false }) set editor(editor: ElementRef) {
        this.editorElement = editor ? <HTMLDivElement>editor.nativeElement : null;
        if (this.editorElement) {

            if (!this.uiService.isBreakpointMobileLg) {
                this.editorElement.focus();
            }
        } else {
            this.editorElement = null;
        }
    }

    @ViewChild("viewer", { static: false }) set viewer(viewer: ElementRef) {
        this.viewerElement = viewer ? <HTMLSpanElement>viewer.nativeElement : null;
        if (this.viewerElement) {
        } else {
            this.viewerElement = null;
        }
    }

    ngOnInit(): void {
        this.isAdm = this.featureFlagService.IsAdm(this.email);
    }

    enterPressed(e): void {
        if (this.comentario) {
            e.preventDefault();
            e.stopPropagation();
            this.editorElement.blur();
        }
    }

    escPressed(): void {
        this.cancelled = true;
        this.editorElement.blur();
    }

    onBlur(event): void {
        if (event?.relatedTarget?.classList.contains("comentario-tags")) {
            return;
        }

        this.cancelled =
      this.cancelled ||
      this.editorElement.innerHTML.length === 0 ||
      (this.editorElement.innerHTML === this.currentTextoComentario &&
        this.newtags === this.currentTagsComentario);

        if (!this.novoComentario) {
            this.mouseupEdicao();
        }

        if (this.cancelled) {
            this.cancel();
        } else {
            this.commit();
        }
    }

    commit(): void {
        if (this.editorElement) {
            const innerHTML = this.editorElement.innerHTML;
            const textData = innerHTML;

            this.comentario.texto = textData;

            // 4 = padding atual definido no css
            this.comentario.alturaExibicao = this.editorElement.clientHeight - 4;
            this.comentario.larguraExibicao = this.editorElement.clientWidth - 4;
            if (this.newtags?.length) {
                this.comentario.tags = this.newtags;
                this.currentTagsComentario = [...this.newtags];
            }
        }

        this.comentarioCommited.emit(this.comentario);
    }

    cancel(): void {
        this.comentarioCancel.emit();
    }

    public confirmarDelecaoComentario(event: MouseEvent, comentario: Comentario) {
        event.preventDefault();
        event.stopPropagation();

        const tipoComentario = "comentário";
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja mesmo excluir o " + tipoComentario + "?",
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.usuarioComentariosService.remover(comentario);
            }
        });
    }

    keyPressed(e: KeyboardEvent): void {

        const tamanhoTexto = (!this.editorElement || !this.editorElement.innerHTML) ? 0 : this.editorElement?.innerText?.length;
        if (

            tamanhoTexto >= (this.isAdm
                ? this.limiteExtendidoCaracteresComentario
                : this.limiteCaracteresComentario)
        ) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.changeDetector.detectChanges();
    }

    mouseupEdicao(): void {

        if (!this.editandoComentario) {
            this.cancelled = false;
        }

        this.changeEditando.emit();
    }

    updatetags(value: string[]) {
        this.newtags = value;
    }

    onPaste(e: ClipboardEvent): void {
        e.preventDefault();
        let clipboardData = e.clipboardData.getData("text/plain");

        if (this.comentario) {
            clipboardData = clipboardData.replace(/\n|\r/g, "");
        }

        this.comentario.texto += clipboardData;
        this.comentario.texto = this.comentario.texto.substr(
            0,
            this.isAdm
                ? this.limiteExtendidoCaracteresComentario
                : this.limiteCaracteresComentario
        );
    }

}
