import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../../../models/usuario/ConfiguracoesUsuario";
import { IdentificacaoModel } from "src/app/models/IdentificacaoModel";

@Component({
    selector: "app-form-identificacao",
    templateUrl: "./form-identificacao.component.html",
    styleUrls: ["./form-identificacao.component.scss"],
})
export class FormIdentificacaoComponent implements OnInit, OnDestroy {
    @Output() public onsaving = new EventEmitter();
    @Output() public onsaved = new EventEmitter<string>();
    @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);
    @ViewChild("fileInput") fileInputRef: ElementRef;

    public defaultPhoto = "assets\\ui\\user.svg";
    public model: IdentificacaoModel;
    public email: string;
    private s_userdata: Subscription;

    constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) { }

    public ngOnInit(): void {
        this.s_userdata = this.usuarioPreferenciasService
            .getConfiguracoes()
            .subscribe((config) => {
                this.model = new IdentificacaoModel(config);
                this.email = config.email;
            });
    }

    public ngOnDestroy(): void {
        this.s_userdata.unsubscribe();
    }

    public abrir(): void {
        this.cancelar();
    }

    public fechar(): void { }

    public async cancelar(): Promise<void> {
        this.model = new IdentificacaoModel(
            this.usuarioPreferenciasService.getConfiguracoesvalue
        );
    }

    public salvar(): void {
        this.onsaving.emit();
        const salvarDadosUsuario = (): Promise<void> => new Promise((onSuccess, onFail) => {
            const prefs = <ConfiguracoesUsuario>(
        this.usuarioPreferenciasService.getConfiguracoesvalue
      );

            prefs.nome = this.model.nome;
            prefs.sobrenome = this.model.sobrenome;
            prefs.foto = this.model.foto;

            this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
            onSuccess();
        });

        salvarDadosUsuario()
            .then(() => {
                this.onsaved.emit("Dados atualizados com sucesso!");
            })
            .catch((err) => {
                this.onsaved.emit(err);
            });
    }

    public pickPhoto(): void {
        const el = <HTMLInputElement>this.fileInputRef.nativeElement;
        el.click();
    }

    public onFileChanged(evt: Event): void {
        const resizedataURL = (datas): Promise<string> => {
            const maxWidth = 128;
            const maxHeight = 128;
            return new Promise(async function (resolve, reject) {
                // We create an image to receive the Data URI
                const img = document.createElement("img");

                // When the event "onload" is triggered we can resize the image.
                img.onload = function () {
                    // We create a canvas and get its context.
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    const ratio = imgWidth / imgHeight;

                    let finalImageWidth = maxWidth;
                    let finalImageHeight = maxHeight;

                    if (ratio > 1) {
                        finalImageHeight = maxHeight;
                        finalImageWidth = finalImageHeight * ratio;
                    } else if (ratio < 1) {
                        finalImageWidth = maxWidth;
                        finalImageHeight = finalImageWidth / ratio;
                    }

                    // We set the dimensions at the wanted size.
                    canvas.width = maxWidth;
                    canvas.height = maxHeight;

                    // We resize the image with the canvas method drawImage();
                    ctx.drawImage(
                        img,
                        canvas.width / 2 - finalImageWidth / 2,
                        canvas.height / 2 - finalImageHeight / 2,
                        finalImageWidth,
                        finalImageHeight
                    );

                    const dataURI = canvas.toDataURL();
                    resolve(dataURI);
                };

                // We put the Data URI in the image's src attribute
                img.src = datas;
            });
        };

        const toBase64 = (f: File): Promise<string> => new Promise((onSuccess, onFail) => {
            const reader = new FileReader();
            reader.readAsDataURL(f);

            reader.onload = () => {
                const originalB64 = reader.result.toString();

                resizedataURL(originalB64).then((img) => {
                    onSuccess(img);
                });
            };
            reader.onerror = (e) => onFail(e);
        });

        const el = <HTMLInputElement>evt.target;
        const file = el.files[0];

        toBase64(file)
            .then((str) => {
                this.model.foto = str;
            })
            .catch((err) => {
                alert(`Erro: ${err}`);
                throw err;
            });
    }
}

