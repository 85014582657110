import { Component, OnInit } from "@angular/core";
import { PerfilUsuario } from "../../../models/UserData";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { OpcaoModoUso } from "src/app/models/OpcaoModoUso";
import { ListaOpcoesModoUso } from "src/app/models/ListaOpcoesModoUso";


@Component({
    selector: "app-primeiro-login",
    templateUrl: "./primeiro-login.component.html",
    styleUrls: ["../popups-styles.scss", "./primeiro-login.component.scss"],
})
export class PrimeiroLoginComponent  {
    public opcoesModoUso = ListaOpcoesModoUso;
    public opcaoSelecionada: OpcaoModoUso = null;
    public palavrasMinuto = 100;
    // azure card 358 Desativar botão caiu em prova
    public mostrarMarcacoesProva = false;
    public mostrarComentarios = true;
    public mostrarMnemonicos = true;

    constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {}

    public get mostrarBotaoAvancar(): boolean {
        return this.opcaoSelecionada ? true : false;
    }

    public async salvarPerfil(): Promise<void> {
        const prefs = this.usuarioPreferenciasService.getConfiguracoesvalue;

        prefs.perfilUsuario = this.opcaoSelecionada.tipo;
        prefs.palavrasMinuto = this.palavrasMinuto;
        // azure card 358 Desativar botão caiu em prova
        switch (this.opcaoSelecionada.tipo) {
            case PerfilUsuario.Estudante:
                prefs.parametrosCaneta.realcarMarcacoesProva =
          this.mostrarMarcacoesProva;
                prefs.parametrosCaneta.realcarComentarios = this.mostrarComentarios;
                prefs.parametrosCaneta.realcarMnemonicos = this.mostrarMnemonicos;
                break;
            case PerfilUsuario.Concurseiro:
                prefs.parametrosCaneta.realcarMarcacoesProva =
          this.mostrarMarcacoesProva;
                prefs.parametrosCaneta.realcarComentarios = this.mostrarComentarios;
                prefs.parametrosCaneta.realcarMnemonicos = this.mostrarMnemonicos;
                break;
            case PerfilUsuario.Advogado:
                break;
        }

        this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
    }
    updatedRating(rating: number) {
        this.palavrasMinuto = rating;
    }
}
