import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { DialogoModel } from "src/app/models/DialogoModel";

@Injectable()
export class DialogoService {
    private model: DialogoModel = null;
    private model$ = new BehaviorSubject<DialogoModel>(this.model);

    constructor() {}

    public mostrarDialogo(model: DialogoModel): void {
        this.model = model;
        this.updateModel();
    }

    public fecharDialogo(): void {
        this.model = null;
        this.updateModel();
    }

    updateModel() {
        this.model$.next(this.model);
    }

    getModel(): Observable<DialogoModel> {
        return this.model$.asObservable();
    }
}
