import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OpcaoToolbar } from "src/app/models/opcaoToolbar";

@Component({
    selector: "app-button-leitor-side-menu",
    templateUrl: "./button-leitor-side-menu.component.html",
    styleUrls: ["./button-leitor-side-menu.component.scss"],
})
export class ButtonLeitorSideMenuComponent  {

    @Input()
    public opcao: OpcaoToolbar;

    @Input()
    public ativo: boolean;

    @Output() toggleButtonLeitor = new EventEmitter<OpcaoToolbar>();

    constructor() {}

    selectionOpcaoToolbar() {
        this.toggleButtonLeitor.emit(this.opcao);
    }

}
