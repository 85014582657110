import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class ItensVerificarService {
  private itens: ItemVerificar[] = this.carregarItens();
  private itens$ = new BehaviorSubject<ItemVerificar[]>(this.itens);

  constructor() {}

  public get Itens(): ItemVerificar[] {
    if (!this.itens) {
      this.Itens = this.carregarItens();
    }

    return this.itens;
  }

  public set Itens(value: ItemVerificar[]) {
    localStorage.setItem("itens-verificar", JSON.stringify(value));
    this.itens = value;
  }

  private carregarItens(): ItemVerificar[] {
    return JSON.parse(localStorage.getItem("itens-verificar"));
  }

  updateItens() {
    this.itens$.next(this.itens);
  }

  getItens(): Observable<ItemVerificar[]> {
    return this.itens$.asObservable();
  }
}

export class ItemVerificar {
  public id: string;
  public tipoItem: TipoItem;
}

export enum TipoItem {
  Marcacao = 0,
  Comentario = 1,
  Grifo = 2,
}
