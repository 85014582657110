import { TipoSelecao } from "./TipoSelecao";

export class PaletaCoresModel {
    public tipo: TipoSelecao;
    public corAtual: string;
    public cores: string[];

    constructor() {
        this.cores = [
            // Amarelo
            "#FFC71F", "#FFDF80", "#FFEEBD"
            // Vermelho
            , "#FF6161", "#FF9999", "#FFD1D1"
            // Rosa
            , "#F06694", "#F797B7", "#FAD1DE"
            // Verde
            , "#5DB761", "#80C684", "#C1E2C7"
            // Violeta
            , "#C980D6", "#D8A2E2", "#E9CDEF"
            // Azul
            , "#47A7F5", "#76BEF9", "#BCE0FB","#E9EBF5"
            // Cinza
            , "#969696", "#B3B3B3", "#DBDBDB"
            // Laranja
            , "#EB7500", "#FF9C38", "#FFC285"
            // Ciano
            , "#9EFFFF", "#C7FFFF", "#E5FFFF"

            // '#f5554a', '#eb3472', '#a53cb7', '#764dbe', '#5262bc', '#37a0f4', '#4ba9f5', '#19c2d8', '#19a093', '#5db761', '#96c95c',
            // '#d2df4c', '#ffed4e', '#ffc71f', '#ffa219', '#ff6738'
        ];
    }
}
