import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-item-navegacao-favoritos",
    templateUrl: "./item-navegacao-favoritos.component.html",
    styleUrls: ["./item-navegacao-favoritos.component.scss"],
})
export class ItemNavegacaoFavoritosComponent  {
    @Output() favoritoMudou: EventEmitter<boolean> = new EventEmitter();
    @Output() favoritoSelecionarLei: EventEmitter<boolean> = new EventEmitter();
    item: any;

    constructor() {}

    @Input() set setItem(setItem) {
        this.item = setItem;
    }

    public btnFavorito_click(evt: Event): void {
        evt.stopPropagation();
        this.favoritoMudou.emit(!this.item.favorita);
    }

    public selecionarLei(evt: Event): void {
        evt.stopPropagation();
        this.favoritoSelecionarLei.emit(this.item);
    }
}
