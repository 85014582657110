import {
    Component,
    ElementRef,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl } from "@angular/forms";
import {
    MatAutocomplete,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
    selector: "app-editor-tags",
    templateUrl: "./editor-tags.component.html",
    styleUrls: ["./editor-tags.component.scss"],
})
export class EditorTagsComponent  {

    @Input() color: string;
    @Output() newtagArray = new EventEmitter<string[]>();
    @Output() onblurEmitte = new EventEmitter();
    @Input() editandoTag: boolean;
    @ViewChild("tagsInput") tagsInput: ElementRef<HTMLInputElement>;
    @ViewChild("auto") matAutocomplete: MatAutocomplete;

    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagsCtrl = new FormControl();
    tagArray: string[] = [];

    constructor() { }

    @Input() set setTagArray(setTagArray: string[]) {
        this.tagArray = setTagArray ? setTagArray : [];
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || "").trim() && this.tagArray.length < 20) {
            this.tagArray.push(value.trim());
            this.newtags(this.tagArray);
        }

        if (input) {
            input.value = "";
        }

        this.tagsCtrl.setValue(null);
    }

    remove(tag: string): void {
        const index = this.tagArray.indexOf(tag);

        if (index >= 0) {
            this.tagArray.splice(index, 1);
        }
        this.newtags(this.tagArray);
    }

    newtags(value: string[]) {
        this.newtagArray.emit(value);
    }

    onblur(event: any) {
        if (
            event.relatedTarget?.classList.contains("comentario-tags") ||
      event.relatedTarget?.classList.contains("editor")
        ) {
            return;
        } else {
            this.onblurEmitte.emit(event);
        }
    }
}
