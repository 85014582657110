import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../helpers/material.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AuthService } from "./services/auth.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthGuard } from "./guards/auth.guard";
import { AssinaturaService } from "./services/assinatura.service";
import { BandeirasCartaoComponent } from "./components/bandeiras-cartao/bandeiras-cartao.component";
import { IndicadorTrialComponent } from "./components/indicador-trial/indicador-trial.component";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
    declarations: [
        BandeirasCartaoComponent,
        IndicadorTrialComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MaterialModule,
        FontAwesomeModule
        ,   NgxMaskModule.forChild()
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FontAwesomeModule,
        BandeirasCartaoComponent,
        IndicadorTrialComponent,
    ],
    providers: [
        AuthService,
        AssinaturaService,
        AuthGuard
    ]
})
export class SharedModule { }
