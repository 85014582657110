export class AlterarSenhaModel {
    public senhaAntiga: string;
    public senhaNova: string;
    public confereSenha: string;

    public get valido(): boolean {
        return (
            (this.senhaAntiga && this.senhaAntiga.length > 0) &&
    (this.senhaNova && this.senhaNova.length > 0) &&
    (this.senhaNova === this.confereSenha)
        ) ? true : false;
    }
}
