import { Tema, TipoFundo } from "../models/tema";

export const TemasApp: Array<Tema> = [
    { nome: "azul", background: "#B1D5EF", thumbnail: "", foreground: "#000", tipoFundo: TipoFundo.Cor },
    { nome: "azul", background: "#F5F5F5", thumbnail: "", foreground: "#000", tipoFundo: TipoFundo.Cor },
    { nome: "azul", background: "#CAB5ED", thumbnail: "", foreground: "#000", tipoFundo: TipoFundo.Cor },
    { nome: "azul", background: "#F6BFD0", thumbnail: "", foreground: "#000", tipoFundo: TipoFundo.Cor },
    { nome: "azul", background: "#F7DD6E", thumbnail: "", foreground: "#000", tipoFundo: TipoFundo.Cor },
    { nome: "azul", background: "#D8D8D8", thumbnail: "", foreground: "#000", tipoFundo: TipoFundo.Cor },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg01.jpg)", thumbnail: "url(assets/ui/backgrounds/t01.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg02.jpg)", thumbnail: "url(assets/ui/backgrounds/t02.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg03.jpg)", thumbnail: "url(assets/ui/backgrounds/t03.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg04.jpg)", thumbnail: "url(assets/ui/backgrounds/t04.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg05.jpg)", thumbnail: "url(assets/ui/backgrounds/t05.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg06.jpg)", thumbnail: "url(assets/ui/backgrounds/t06.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg07.jpg)", thumbnail: "url(assets/ui/backgrounds/t07.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg08.jpg)", thumbnail: "url(assets/ui/backgrounds/t08.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg09.jpg)", thumbnail: "url(assets/ui/backgrounds/t09.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg10.jpg)", thumbnail: "url(assets/ui/backgrounds/t10.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg11.jpg)", thumbnail: "url(assets/ui/backgrounds/t11.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg12.jpg)", thumbnail: "url(assets/ui/backgrounds/t12.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg13.jpg)", thumbnail: "url(assets/ui/backgrounds/t13.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg14.jpg)", thumbnail: "url(assets/ui/backgrounds/t14.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg15.jpg)", thumbnail: "url(assets/ui/backgrounds/t15.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg16.jpg)", thumbnail: "url(assets/ui/backgrounds/t16.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg17.jpg)", thumbnail: "url(assets/ui/backgrounds/t17.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg18.jpg)", thumbnail: "url(assets/ui/backgrounds/t18.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg19.jpg)", thumbnail: "url(assets/ui/backgrounds/t19.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg20.jpg)", thumbnail: "url(assets/ui/backgrounds/t20.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg21.jpg)", thumbnail: "url(assets/ui/backgrounds/t21.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg22.jpg)", thumbnail: "url(assets/ui/backgrounds/t22.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg23.jpg)", thumbnail: "url(assets/ui/backgrounds/t23.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg24.jpg)", thumbnail: "url(assets/ui/backgrounds/t24.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg25.jpg)", thumbnail: "url(assets/ui/backgrounds/t25.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg26.jpg)", thumbnail: "url(assets/ui/backgrounds/t26.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg27.jpg)", thumbnail: "url(assets/ui/backgrounds/t27.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg28.jpg)", thumbnail: "url(assets/ui/backgrounds/t28.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg29.jpg)", thumbnail: "url(assets/ui/backgrounds/t29.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem },
    { nome: "azul", background: "url(assets/ui/backgrounds/bg30.jpg)", thumbnail: "url(assets/ui/backgrounds/t30.jpg)", foreground: "#000", tipoFundo: TipoFundo.Imagem }
];
