export enum StatusIndicacao {
    pendente = 1,
    aprovado = 2,
    cancelado = 3
}

export class StatusIndicacaoModel {
    public static status: StatusIndicacaoModel[] = [
        { codigo: StatusIndicacao.pendente, descricao: "Pendente" },
        { codigo: StatusIndicacao.aprovado, descricao: "Aprovado" },
        { codigo: StatusIndicacao.cancelado, descricao: "Cancelado" }
    ];
    codigo: StatusIndicacao;
    descricao: string;
}

export class ContatoIndicacao {
    nome: string;
    email: string;
    dataEnvio: Date;
    dataCadastro: Date;
    status: StatusIndicacaoModel;
}


