
export class SelectionHelper {

}
export class SelectedElement {
    element: Node;
    partlySelected: boolean;

    constructor(element: Node, partlySelected: boolean) {
        this.element = element;
        this.partlySelected = partlySelected;
    }
}
