<div [matTooltip]="tooltipBusca">
  <label class="lbl-art" (click)="nmArt.focus()">{{ prefixoBusca }}</label>
  <input
    #nmArt
    [ngClass]="{ 'not-found': numeroArtigo && !(matchBuscaRapida$ | async) }"
    class="not-found"
    type="text"
    [(ngModel)]="numeroArtigo"
    (ngModelChange)="txtBuscaChanged()"
    (keypress)="validate($event)"
  />
</div>
