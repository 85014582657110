
export class Coluna {
    public texto: string;
    public width: string;
}
export class LinhaTabela {
    public colunas: Array<Coluna>;
    constructor() {
        this.colunas = new Array<Coluna>();
    }
}

export class Tabela {
    public linhas: Array<LinhaTabela>;
    constructor() {
        this.linhas = new Array<LinhaTabela>();
    }
}
