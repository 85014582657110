import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { LeiLookup } from "src/app/models/lei/lei.lookup";
import { EstatisticasLeitura } from "src/app/models/usuario/EstatisticasLeitura";
import { LeiRepositorio } from "src/app/repositorios/lei.repositorio";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";

@Injectable({
    providedIn: "root",
})
export class NavegacaoFavoritosService {
    private leisFavoritasLookup: LeiLookup[] = [];
    private leisFavoritasLookup$ = new BehaviorSubject<LeiLookup[]>(
        this.leisFavoritasLookup
    );

    private listIdFavoritos: string[] = [];
    private subscriptions: Subscription[] = [];
    constructor(
        private leiRepositorio: LeiRepositorio,
        private usuarioEstatisticasService: UsuarioEstatisticasService
    ) {
        this.subscriptions.push(
            this.usuarioEstatisticasService.getLeisFavoritas().subscribe((fav) => {
                this.listIdFavoritos = fav;
                // this.Leisfavotiras();
            })
        );
    }

    public async leisfavotiras() {
        const leiLookup: LeiLookup[] =
      await this.leiRepositorio.carregarLeisCabecalhoFavoritos();

        if (!leiLookup) {
            return;
        }

        this.leisFavoritasLookup = leiLookup;

        this.leisFavoritasLookup.forEach((lei) => {
            lei.favorita = this.listIdFavoritos.indexOf(lei.id) !== -1;
        });
        this.updateLeisFavoritasLookup();
    }

    updateLeisFavoritasLookup() {
        this.leisFavoritasLookup$.next(this.leisFavoritasLookup);
    }

    getLeisFavoritasLookup(): Observable<LeiLookup[]> {
        return this.leisFavoritasLookup$.asObservable();
    }
}
