import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { PopupType } from "src/app/models/PopupType";

@Injectable()
export class PopupsService {
    private s_prefs: Subscription;
    private prefs: ConfiguracoesUsuario;

    private popupType: PopupType = null;
    private popupType$ = new BehaviorSubject<PopupType>(this.popupType);

    private visible = false;
    private visible$ = new BehaviorSubject<boolean>(this.visible);

    private canClose = false;
    private canClose$ = new BehaviorSubject<boolean>(this.canClose);

    constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {
        this.s_prefs = this.usuarioPreferenciasService
            .getConfiguracoes()
            .subscribe((c) => {
                this.prefs = c;
                this.update();
            });
    }


    public get aberto(): boolean {
        return this.visible;
    }


    public abrirPopupPerfil(): void {
        this.popupType = PopupType.Perfil;
        this.updatePopupType();
    }

    public abrirPopupPreferencias(): void {
        this.popupType = PopupType.Preferencias;
        this.updatePopupType();
    }

    public abrirPopupFeedback(): void {
        this.popupType = PopupType.Feedback;
        this.updatePopupType();
    }

    public fecharPopups(): void {
        if (this.canClose) {
            this.popupType = null;
            this.updatePopupType();
        }
    }

    updatePopupType() {
        const v = this.popupType ? true : false;

        this.visible = this.popupType ? true : false;
        this.updateVisible();
        if (v) {
            this.canClose = this.popupType !== PopupType.BoasVindas ? true : false;
            this.updateCanClose();
        }

        this.popupType$.next(this.popupType);
    }

    getPopupType(): Observable<PopupType> {
        return this.popupType$.asObservable();
    }

    updateVisible() {
        this.visible$.next(this.visible);
    }

    getVisible(): Observable<boolean> {
        return this.visible$.asObservable();
    }
    updateCanClose() {
        this.canClose$.next(this.canClose);
    }

    getCanClose(): Observable<boolean> {
        return this.canClose$.asObservable();
    }

    private update() {
        if (
            !this.prefs ||
    (this.prefs.palavrasMinuto && this.prefs.perfilUsuario)
        ) {
            this.popupType = null;
            this.updatePopupType();
        } else {
            this.popupType = PopupType.BoasVindas;
            this.updatePopupType();
        }
    }
    private popupType_subscribe(type: PopupType): void {
        const v = type ? true : false;

        this.visible$.next(v);
        if (v) {
            const c = type !== PopupType.BoasVindas ? true : false;
            this.canClose$.next(c);
        }
    }

}
