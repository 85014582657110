import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PaletaCoresModel } from "src/app/models/PaletaCoresModel";

@Component({
    selector: "app-color-picker",
    templateUrl: "./color-picker.component.html",
    styleUrls: ["./color-picker.component.scss"]
})
export class ColorPickerComponent implements OnInit {
    public corAtual: string;
    public corNova: string;

    constructor(
        public dialogRef: MatDialogRef<ColorPickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PaletaCoresModel
    ) { }

    ngOnInit() {
        this.corAtual = this.data.corAtual;
    }

    public cancelar(): void {
        this.dialogRef.close(this.corAtual);
    }

    public salvar(): void {
        this.dialogRef.close(this.corNova);
    }

    public pick(cor: string): void {
        this.corNova = cor;
    }
}

