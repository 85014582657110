import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-star-rating",
    templateUrl: "./star-rating.component.html",
    styleUrls: ["./star-rating.component.scss"],
})
export class StarRatingComponent  {
    @Output() ratingUpdated = new EventEmitter();
    @Input() rating = 70;
    stars: number[] = [70, 140, 210, 280, 350];
    myVariableColor = "#0a83ba";


    constructor() {}


    countStar(star: number) {
        star = star * 70;
        this.rating = star;
        this.ratingUpdated.emit(star);
    }
}
