import { Injectable } from "@angular/core";
import { StorageHelper } from "../../helpers/storage.helper";
import { ErrorHandlerService } from "../errorHandler.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { Apontamento } from "src/app/models/Apontamento";
import { SignalrService } from "../signalr.service";

@Injectable()
export class UsuarioApontamentosService {
    readonly databaseName = "userdata";
    readonly collectionName = "apontamentos";

    constructor(
        private signalrService: SignalrService,
        private errorHandlerService: ErrorHandlerService,
        private auth: AuthService,
        private httpClient: HttpClient
    ) {}

    public async carregarApontamentos(leiId: string): Promise<Apontamento[]> {
        const url = `${AppConfig.apiEndpoint}/DadosCadastroUsuario/Apontamentos`;
        let requestParams = new HttpParams();
        let apontamentos;
        const apontamentoslocal = await StorageHelper.list<Apontamento>(
            this.databaseName,
            this.collectionName,
            (c) => c.idLei === leiId
        );

        requestParams = requestParams.append("usuarioId", this.auth.userID);
        await this.httpClient
            .get(url, { params: requestParams })
            .toPromise()
            .then((result: Apontamento[]) => {
                if (result) {
                    this.fromNuvem(result).catch((err) => {
                        this.errorHandlerService.handleError(err);
                    });
                    apontamentos = result;
                } else {
                    if (apontamentoslocal) {
                        apontamentos = apontamentoslocal;
                    } else {
                        apontamentos = new Array<Apontamento>();
                    }
                }
            })
            .catch((err) => {
                this.errorHandlerService.handleError(err);
                if (apontamentoslocal) {
                    apontamentos = apontamentoslocal;
                } else {
                    apontamentos = new Array<Apontamento>();
                }
            });

        return apontamentos;
    }

    public async fromNuvem(apontamentos: Apontamento[]): Promise<void> {
        try {
            await StorageHelper.upsertMany(
                apontamentos,
                this.databaseName,
                this.collectionName,
                false
            );
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    public async atualizar(apontamento: Apontamento, sync = true): Promise<void> {
        try {
            const url = `${AppConfig.apiEndpoint}/DadosCadastroUsuario/Apontamentos`;

            await this.httpClient.post(url, apontamento).toPromise();

            await StorageHelper.upsert(
                apontamento,
                this.databaseName,
                this.collectionName
            );
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    public async buscar(idLei = null): Promise<Apontamento[]> {
        try {
            if (idLei) {
                return await StorageHelper.list<Apontamento>(
                    this.databaseName,
                    this.collectionName,
                    (a) => a.idLei === idLei
                );
            } else {
                return await StorageHelper.list<Apontamento>(
                    this.databaseName,
                    this.collectionName
                );
            }
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }
}
