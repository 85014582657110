
import { EntitiesHelper } from "../helpers/entities.helper";

import { MatchBuscaTexto } from "./MatchBuscaTexto";
import { BaseObj } from "./UserdataSync";
import { OpcaoToolbar } from "./opcaoToolbar";
import { ProvaDados } from "./ProvaDados";
import { BuscaPanelParameters } from "../components/leitor-content-panelbusca/busca-panel.parameters";
import { EstadoJanelaBusca } from "./estadoJanelaBusca";

export enum PerfilUsuario {
    Estudante = 1,
    Concurseiro = 2,
    Advogado = 3,
}

export enum TipoLogin {
    Senha = 0,
    Facebook = 1,
    Google = 2,
    Token = 3,
}

export class PreferenciasUsuario {
    larguraPapel: string;
    idTema: number;

    exibirItensRevogados: boolean;
    exibirApenasItensComentados: boolean;
    exibirMeusComentarios: boolean;
    exibirComentariosSvm: boolean;

    constructor(
        larguraPapel: string = "3-3",
        idTema: number = 2,
        exibirItensRevogados: boolean = true,
        exibirApenasItensComentados: boolean = false,
        exibirMeusComentarios: boolean = true,
        exibirComentariosSvm: boolean = true
    ) {
        this.larguraPapel = larguraPapel;
        this.idTema = idTema;
        this.exibirItensRevogados = exibirItensRevogados;
        this.exibirApenasItensComentados = exibirApenasItensComentados;
        this.exibirMeusComentarios = exibirMeusComentarios;
        this.exibirComentariosSvm = exibirComentariosSvm;
    }
}

export class ParametrosCaneta {
    public static corResultadoBuscaDestaque = "orange";
    public static corResultadoBuscaNormal = "yellow";
    public static corDestaqueHover = "lightgray";

    public descricaoCaneta1: string;
    public corCaneta1: string;
    public modoRealceCaneta1: string;
    public descricaoCaneta2: string;
    public corCaneta2: string;
    public modoRealceCaneta2: string;
    public descricaoCaneta3: string;
    public corCaneta3: string;
    public modoRealceCaneta3: string;
    public descricaoCaneta4: string;
    public corCaneta4: string;
    public modoRealceCaneta4: string;
    public descricaoCaneta5: string;
    public corCaneta5: string;
    public modoRealceCaneta5: string;

    public corTagProva: string;
    public posicionamentoProva: string;
    public corTagComentario: string;
    public posicionamentoComentario: string;
    public corTagMnemonico: string;
    public posicionamentoMnemonico: string;
    public realcarMarcacoesProva: boolean;
    public realcarComentarios: boolean;
    public realcarMnemonicos: boolean;

    public constructor() {}
}

export class ItemProvaDatasource extends BaseObj {
    descricao: string;

    constructor() {
        super();
        this.id = EntitiesHelper.generateGuid();
    }
}


export class ProvaDatasource {
    instituicoes: Array<ItemProvaDatasource>;
    bancas: Array<ItemProvaDatasource>;
    anos: Array<ItemProvaDatasource>;
    tipos: Array<ItemProvaDatasource>;
    cargos: Array<ItemProvaDatasource>;

    constructor() {
        this.instituicoes = new Array<ItemProvaDatasource>();
        this.bancas = new Array<ItemProvaDatasource>();
        this.anos = new Array<ItemProvaDatasource>();
        this.tipos = new Array<ItemProvaDatasource>();
        this.cargos = new Array<ItemProvaDatasource>();
    }
}


export class BuscaSalva extends BaseObj {
    textoBuscar: string;
    marcacoesCaneta: Array<OpcaoToolbar>;
    provaBuscar: ProvaDados;
    provascaiuEmProva: OpcaoToolbar;
    comentarioBuscar: string;
    comentariosComentado: OpcaoToolbar;
    mnemonicoBuscar: string;
    mnemonicosOpcao: OpcaoToolbar;
    buscarTodosDocumentos: boolean;
    estadoJanelaBusca: EstadoJanelaBusca;
    matchsResultadoBusca: Array<MatchBuscaTexto>;
    matchResultadoBuscaFoco: MatchBuscaTexto;
    guiaId: string;
    idPagina: number;

    constructor(params: BuscaPanelParameters = null) {
        super();

        if (!params) {
            this.marcacoesCaneta = new Array<OpcaoToolbar>();
            this.matchsResultadoBusca = new Array<MatchBuscaTexto>();
            return;
        }

        this.marcacoesCaneta = params.marcacoesCaneta.slice();
        this.comentariosComentado = params.comentariosComentado;
        this.comentarioBuscar = params.comentarioBuscar
            ? params.comentarioBuscar
            : "";
        this.mnemonicosOpcao = params.mnemonicosOpcao;
        this.mnemonicoBuscar = params.mnemonicoBuscar;
        this.buscarTodosDocumentos = params.buscarTodosDocumentos;
        this.idPagina = params.idPagina;
        this.provascaiuEmProva = params.provascaiuEmProva;
        this.textoBuscar = params.textoBuscar ? params.textoBuscar : "";
        this.provaBuscar = params.provaBuscar ? params.provaBuscar : null;
        this.estadoJanelaBusca = params.estadoJanelaBusca;
        this.matchsResultadoBusca = params.matchsResultadoBusca;
        this.matchResultadoBuscaFoco = params.matchResultadoBuscaFoco;
    }
}
