<div
  type="button"
  cdkOverlayOrigin
  #menuOverlay="cdkOverlayOrigin"
  #menuViewOverlay
  [ngClass]="{ opened: isOverlayOpen }"
  class="menu-navegacao-container"
>
  <ng-container *ngIf="!(breakpointMobileLg$ | async); else Mobile">
    <div
      class="menu-button"
      mat-button
      [ngClass]="{
        openedmenu: isOverlayOpen,
        openedbutton: ramos[0] == 'Direito Publico'
      }"
      (click)="abrirPrimeiroRamos('Direito Publico')"
    >
      <p>Direito Público</p>
      <mat-icon
        class="navegacao-menu-button-icon"
        [svgIcon]="ramos[0] == 'Direito Publico' ? 'caret-up' : 'caret-down'"
      ></mat-icon>
    </div>

    <mat-divider class="menu-divider" [vertical]="true"></mat-divider>

    <div
      class="menu-button"
      mat-button
      [ngClass]="{
        openedmenu: isOverlayOpen,
        openedbutton: ramos[0] == 'Direito Privado'
      }"
      (click)="abrirPrimeiroRamos('Direito Privado')"
    >
      <p>Direito Privado</p>
      <mat-icon
        class="navegacao-menu-button-icon"
        [svgIcon]="ramos[0] == 'Direito Privado' ? 'caret-up' : 'caret-down'"
      ></mat-icon>
    </div>

    <mat-divider class="menu-divider" [vertical]="true"></mat-divider>

    <div
      class="menu-button"
      mat-button
      [ngClass]="{
        openedmenu: isOverlayOpen,
        openedbutton: ramos[0] == 'Direito Criminal'
      }"
      (click)="abrirPrimeiroRamos('Direito Criminal')"
    >
      <p>Direito Criminal</p>
      <mat-icon
        class="navegacao-menu-button-icon"
        [svgIcon]="ramos[0] == 'Direito Criminal' ? 'caret-up' : 'caret-down'"
      ></mat-icon>
    </div>

    <mat-divider class="menu-divider" [vertical]="true"></mat-divider>

    <div
      class="menu-button"
      mat-button
      [ngClass]="{
        openedmenu: isOverlayOpen,
        openedbutton: ramos[0] == 'Direito Social'
      }"
      (click)="abrirPrimeiroRamos('Direito Social')"
    >
      <p>Direito Social</p>
      <mat-icon
        class="navegacao-menu-button-icon"
        [svgIcon]="ramos[0] == 'Direito Social' ? 'caret-up' : 'caret-down'"
      ></mat-icon>
    </div>

    <mat-divider class="menu-divider" [vertical]="true"></mat-divider>

    <div
      class="menu-button"
      mat-button
      [ngClass]="{
        openedmenu: isOverlayOpen,
        openedbutton: ramos[0] == 'Direito Internacional'
      }"
      (click)="abrirPrimeiroRamos('Direito Internacional')"
    >
      <p>Direito Internacional</p>
      <mat-icon
        class="navegacao-menu-button-icon"
        [svgIcon]="
          ramos[0] == 'Direito Internacional' ? 'caret-up' : 'caret-down'
        "
      ></mat-icon>
    </div>
  </ng-container>

  <ng-template #Mobile>
    <div class="menu-navegacao-container">
      <div
        class="menu-button menu-button-mobile"
        mat-button
        (click)="overlayOpen()"
      >
        <mat-icon
          class="navegacao-menu-button-icon navegacao-menu-button-icon-mobile"
          svgIcon="bars"
        ></mat-icon>
        <p>Ver todas as leis</p>
      </div>
    </div>
  </ng-template>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="menuOverlay"
  [cdkConnectedOverlayOpen]="isOverlayOpen"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPanelClass]="'navegacao-leis-ramos-menu-Overlay-custom'"
  (detach)="isOverlayOpen = false"
  (backdropClick)="overlayclose()"
>
  <div class="custon-menu-navegacao-ramos-container">
    <!-- INICIO MOBILE -->
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="
        (breakpointMobileLg$ | async) &&
        ramos[0] == null &&
        ramos[1] == null &&
        ramos[2] == null
      "
    >
      <div
        class="menu-button"
        mat-button
        [ngClass]="{ openedbuttoncolum: ramos[0] == 'Direito Publico' }"
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <p>Direito Público</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>

      <div
        class="menu-button"
        mat-button
        [ngClass]="{ openedbuttoncolum: ramos[0] == 'Direito Privado' }"
        (click)="abrirPrimeiroRamos('Direito Privado')"
      >
        <p>Direito Privado</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>

      <div
        class="menu-button"
        mat-button
        [ngClass]="{ openedbuttoncolum: ramos[0] == 'Direito Criminal' }"
        (click)="abrirPrimeiroRamos('Direito Criminal')"
      >
        <p>Direito Criminal</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        class="menu-button"
        mat-button
        [ngClass]="{ openedbuttoncolum: ramos[0] == 'Direito Social' }"
        (click)="abrirPrimeiroRamos('Direito Social')"
      >
        <p>Direito Social</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        class="menu-button"
        mat-button
        [ngClass]="{ openedbuttoncolum: ramos[0] == 'Direito Internacional' }"
        (click)="abrirPrimeiroRamos('Direito Internacional')"
      >
        <p>Direito Internacional</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>

    <!-- DIREITO PÚBLICO -->
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[0] == 'Direito Publico'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="fechatodosRamos()"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Público</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Constitucional' }"
        (click)="abrirSegundoRamos('Direito Constitucional')"
      >
        <p>Direito Constitucional</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Administrativo' }"
        (click)="abrirSegundoRamos('Direito Administrativo')"
      >
        <p>Direito Administrativo</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Tributario' }"
        (click)="abrirSegundoRamos('Tributario')"
      >
        <p>Tributário</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Eleitoral' }"
        (click)="abrirSegundoRamos('Direito Eleitoral')"
      >
        <p>Direito Eleitoral</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Ambiental' }"
        (click)="abrirSegundoRamos('Direito Ambiental')"
      >
        <p>Direito Ambiental</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum: ramos[1] == 'Direito Economico / Financeiro'
        }"
        (click)="abrirSegundoRamos('Direito Economico / Financeiro')"
      >
        <p>Direito Econômico / Financeiro</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>
    <mat-divider
      *ngIf="ramos[0] == 'Direito Publico' && !(breakpointMobileLg$ | async)"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Constitucional'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Constitucional</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum:
            ramos[2] == 'Leis do Controle de Constitucionalidade'
        }"
        (click)="abrirTerceiroRamos('Leis do Controle de Constitucionalidade')"
      >
        <p>Leis do Controle de Constitucionalidade</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum: ramos[2] == 'Remedios Constitucionais'
        }"
        (click)="abrirTerceiroRamos('Remedios Constitucionais')"
      >
        <p>Remédios Constitucionais</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Orgaos Constitucionais' }"
        (click)="abrirTerceiroRamos('Orgaos Constitucionais')"
      >
        <p>Órgãos Constitucionais</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Regimentos e Resolucoes' }"
        (click)="abrirTerceiroRamos('Regimentos e Resolucoes')"
      >
        <p>Regimentos e Resoluções</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Advocacia' }"
        (click)="abrirTerceiroRamos('Advocacia')"
      >
        <p>Advocacia</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>Constituição Federal</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div mat-button class="menu-button" (click)="selecionar()">
        Emendas Constitucionais
      </div>
      <div mat-button class="menu-button" (click)="selecionar()">ADCT</div> -->
    </div>

    <mat-divider
      *ngIf="
        ramos[0] == 'Direito Publico' && ramos[1] == 'Direito Constitucional'
      "
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Leis do Controle de Constitucionalidade'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Constitucional')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Leis do Controle de Constitucionalidade</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e98c93a1e09bc82984f',
            'Ação Declaratória de Constitucionalidade / Inconstitucionalidade - ADIN/ADC/ADO - Lei Ordinária nº 9.868, de 10.11.1999 - Lei nº 9.868/1999'
          )
        "
      >
        <p>
          Lei da Ação Direta de Inconstitucionalidade (ADI)" - Lei 9.868/1999
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e98c93a1e09bc82984f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e98c93a1e09bc82984f',
            'Ação Declaratória de Constitucionalidade / Inconstitucionalidade - ADIN/ADC/ADO - Lei Ordinária nº 9.868, de 10.11.1999 - Lei nº 9.868/1999'
          )
        "
      >
        <p>
          Lei da Ação Direta de Inconstitucionalidade por Omissão (ADO)" - Lei
          9.882/1999
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e98c93a1e09bc82984f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e94c93a1e09bc82984d',
            'Ação de Arguição de Descumprimento de Preceito Fundamental - ADPF - Lei Ordinária nº 9.882, de 03.12.1999 - Lei nº 9.882/1999'
          )
        "
      >
        <p>
          Lei do Arguição de Descumprimento de Preceito Fundamental (ADPF) - Lei
          9.882/1999
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e94c93a1e09bc82984d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e58c93a1e09bc829835',
            'Súmula Vinculante - Edição e Revisão - Lei Ordinária nº 11.417, de 19.12.2006 - Lei nº 11.417/2006'
          )
        "
      >
        <p>Lei de Súmulas Vinculantes - Lei 11.417/2006</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e58c93a1e09bc829835')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Remedios Constitucionais'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Constitucional')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Remédios Constitucionais</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e34c93a1e09bc829824',
            'Lei do Mandado de Segurança - Lei Ordinária nº 12.016, de 07.08.2009 - Lei nº 12.016/2009'
          )
        "
      >
        <p>Lei do Mandado de Segurança - Lei 12.016/2009</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e34c93a1e09bc829824')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f0bc93a1e09bc82987f',
            'Processo perante o STJ/STF - Lei Ordinária nº 8.038, de 28.05.1990 - Lei nº 8.038/1990'
          )
        "
      >
        <p>Lei do Habeas Corpus - Lei 8.038/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f0bc93a1e09bc82987f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642eafc93a1e09bc82985a',
            'Lei do Habeas Data - Lei Ordinária nº 9.507, de 12.11.1997 - Lei nº 9.507/1997'
          )
        "
      >
        <p>Lei do Habeas Data - Lei 9.507/1997</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642eafc93a1e09bc82985a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02129c93a1e1f24d89ae2',
            'Lei do Mandado de Injunção - Lei Ordinária nº 13.300, de 23.06.2016 - Lei nº 13.300/2016'
          )
        "
      >
        <p>Lei do Mandado de Injunção - Lei 13.300/2016</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02129c93a1e1f24d89ae2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f38c93a1e1d3c75dda1',
            'Lei da Ação Popular - Lei Ordinária nº 4.717, de 29.06.1965 - Lei nº 4.717/1965'
          )
        "
      >
        <p>Lei da Ação Popular - Lei 4.717/1965</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f38c93a1e1d3c75dda1')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Orgaos Constitucionais'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Constitucional')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Órgãos Constitucionais</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f0bc93a1e09bc82987f',
            'Processo perante o STJ/STF - Lei Ordinária nº 8.038, de 28.05.1990 - Lei nº 8.038/1990'
          )
        "
      >
        <p>Processos nos Tribunais Superiores - Lei 8.038/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f0bc93a1e09bc82987f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5ec7fd00e9d1ae1a9c63d621',
            'Estatuto da Magistratura - Lei Complementar nº 35, de 14.3.1979 - LOMAN - LC nº 35/1979'
          )
        "
      >
        <p>
          Lei Orgânica da Magistratura Nacional (LOMAN) - Lei Complementar
          35/1979
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5ec7fd00e9d1ae1a9c63d621')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '63aa128fe51a2c280c781982',
            'Concurso Público para Ingresso na Magistratura - Resolução CNJ n º75 de 12.05.2009 - RES 75/CNJ - Res. CNJ nº 75/2009'
          )
        "
      >
        <p>
          Normas para ingresso na Magistratura Nacional - Resolução 75 do
          Conselho Nacional de Justiça (CNJ)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('63aa128fe51a2c280c781982')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d9dd925c93a1e12e0262c47',
            'Lei complementar nº 80, de 12.1.1994 - LC nº 80/1994'
          )
        "
      >
        <p>Lei Orgânica da Defensoria Pública - LC 80/1994</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d9dd925c93a1e12e0262c47')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc017e9c93a1e1f24d89981',
            'Lei complementar nº 75, de 20.5.1993 - LC nº 75/1993'
          )
        "
      >
        <p>Lei Orgânica do Ministério Público da União - LC 75/1993</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc017e9c93a1e1f24d89981')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc017e1c93a1e1f24d89980',
            'Lei complementar nº 73, de 10.2.1993 - LC nº 73/1993'
          )
        "
      >
        <p>Lei Orgânica da Advocacia-Geral da União - LC 73/1993</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc017e1c93a1e1f24d89980')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Regimentos e Resolucoes'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Constitucional')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Regimentos e Resoluções</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f0bc93a1e09bc82987f',
            'Processo perante o STJ/STF - Lei Ordinária nº 8.038, de 28.05.1990 - Lei nº 8.038/1990'
          )
        "
      >
        <p>
          Regimento Interno do STF (Supremo Tribunal Federal) e do STJ (Superior
          Tribunal de Justiça)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f0bc93a1e09bc82987f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Regimentos da Câmara dos Deputados e do Senado Federal
      </div> -->
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Resoluções do CNJ (Conselho Nacional de Justiça) e CNMP (Conselho
        Nacional do Ministério Público)
      </div> -->
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Advocacia'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Constitucional')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Advocacia</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a522c93a1e229cb7610c',
            'Regulamento Geral do Estatuto da Advocacia e da OAB'
          )
        "
      >
        <p>
          Estatuto da Advocacia e a Ordem dos Advogados do Brasil (EOAB) - Lei
          8.906/1994
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a522c93a1e229cb7610c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar('5dc8209dc93a1e1d3c75dd8c', 'Código de Ética da OAB')
        "
      >
        <p>
          Código de Ética e Disciplina da OAB - Resolução nº 02/2015 do Conselho
          Federal da OAB
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc8209dc93a1e1d3c75dd8c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Regulamento Geral da OAB - Resolução nº 02/2015 do Conselho Federal da
        OAB
      </div> -->
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Administrativo'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Administrativo</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>
          Constituição Federal - Da Administração Pública - art. 37 e outros
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ea1c93a1e09bc829853',
            'Processo Administrativo Federal - Lei Ordinária nº 9.784, de 29.01.1999 - Lei nº 9.784/1999'
          )
        "
      >
        <p>Processo Administrativo Federal - Lei 9.784/1999</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ea1c93a1e09bc829853')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f87c93a1e09bc8298b6',
            'Prescrição Quinquenal das Obrigações da Fazenda Pública - Decreto nº 20.910, de 06.01.1932 - Dec. nº 20.910/1932'
          )
        "
      >
        <p>Prescrição Administrativa - Decreto 20.910/1932</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f87c93a1e09bc8298b6')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a566c93a1e229cb76113',
            'Regime Jurídico dos Servidores Públicos Civis da União - Lei Ordinária nº 8.112, de 11.12.1990 - Lei nº 8.112/1990'
          )
        "
      >
        <p>Servidores Públicos Federais - Lei 8.112/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a566c93a1e229cb76113')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ef4c93a1e09bc829876',
            'Lei de Improbidade Administrativa - Lei Ordinária nº 8.429, de 02.06.1992 - Lei nº 8.429/1992'
          )
        "
      >
        <p>Improbidade Administrativa - Lei 8.429/1992</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ef4c93a1e09bc829876')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a148c93a1e229cb760e2',
            'Lei Anticorrupção - Lei Ordinária nº 12.846, de 1º.8.2013  - Acordo de Leniência - Lei nº 12.846/2013'
          )
        "
      >
        <p>Lei Anticorrupção - Lei 12.846/2013</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a148c93a1e229cb760e2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '61290314e51a2c2d24709fe2',
            'Nova Lei de Licitações e Contratos - Lei nº 14.133, de 01.04.2021 - Nova lei de licitações - Lei nº 14.133/2021'
          )
        "
      >
        <p>Licitações e Contratos - Lei 14.133/2021</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('61290314e51a2c2d24709fe2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a342c93a1e229cb760fd',
            'Bens Imóveis de Domínio da União - Lei Ordinária nº 9.636, de 15.05.1998 - Lei nº 9.636/1998'
          )
        "
      >
        <p>Lei de Imóveis da União - Lei 9.636/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a342c93a1e229cb760fd')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a0ccc93a1e229cb760bd',
            'Lei da Desapropriação e Servidão Administrativa - Decreto-Lei nº 3.365, de 21.06.1941 - DL 3365/41 - Dec.-lei nº 3.365/1941'
          )
        "
      >
        <p>Desapropriação - Decreto 3.365/1941</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a0ccc93a1e229cb760bd')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '627454c2e51a2c4e506ee46b',
            'Proteção do patrimônio histórico e artístico nacional - Drecreto-Lei nº 25 de 30.11.1937 - Dec-Lei nº 25/1937'
          )
        "
      >
        <p>Decreto-Lei do Tombamento - Decreto-Lei 25/1937</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('627454c2e51a2c4e506ee46b')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ed3c93a1e09bc829869',
            'Concessão e Permissão de Prestação de Serviços Públicos - Lei Ordinária nº 8.987, de 13.02.1995 - Lei dos Serviços Públicos - Lei nº 8.987/1995'
          )
        "
      >
        <p>Serviços Públicos - Lei 8.987/1995</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ed3c93a1e09bc829869')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e6fc93a1e09bc82983c',
            'Parceria Público Privada - PPP - Lei Ordinária nº 11.079, de 30.12.2004 - Lei nº 11.079/2004'
          )
        "
      >
        <p>PPP (Parcerias Público-Privadas) - Lei 11.079/2004</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e6fc93a1e09bc82983c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e63c93a1e09bc829839',
            'Lei dos Consórcios Públicos - Lei Ordinária nº 11.107, de 06.04.2005 - Lei nº 11.107/2005'
          )
        "
      >
        <p>Consórcios Públicos - Lei 11.107/2005</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e63c93a1e09bc829839')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a0b6c93a1e229cb760b8',
            'Lei Complementar nº 151, de 5.8.2015 - LC nº 151/2015'
          )
        "
      >
        <p>
          Lei Complementar dos Depósitos Judiciais - Lei Complementar 151/2015
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a0b6c93a1e229cb760b8')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f03c93a1e09bc82987c',
            'Lei do Funcionamento dos Serviços de Saúde - Lei Ordinária nº 8.080, de 19.09.1990 - Lei nº 8.080/1990'
          )
        "
      >
        <p>Lei do SUS (Sistema Único de Saúde) - Lei 8.080/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f03c93a1e09bc82987c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02120c93a1e1f24d89ae0',
            'Estatuto das Empresas Estatais - Lei Ordinária nº 13.303, de 30.06.2016 - Lei nº 13.303/2016'
          )
        "
      >
        <p>Estatuto das Estatais - Lei 13.303/2016</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02120c93a1e1f24d89ae0')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95ee5c93a1e1d3c75dd94',
            'Lei das Sociedades por Ações - Lei Ordinária nº 6.404, de 15.12.1976 - Lei nº 6.404/1976'
          )
        "
      >
        <p>Lei Geral das S/A (Sociedades Anônimas) - Lei 6.404/1976</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95ee5c93a1e1d3c75dd94')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ea7c93a1e09bc829856',
            'Lei das Organizações Sociais - Lei Ordinária nº 9.637, de 15.05.1998 - Lei nº 9.637/1998'
          )
        "
      >
        <p>Lei das Organizações Sociais - Lei 9.637/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ea7c93a1e09bc829856')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e9fc93a1e09bc829852',
            'Lei das Organizações da Sociedade Civil de Interesse Público - OSCIP - Lei Ordinária nº 9.790, de 23.03.1999 - Lei nº 9.790/1999'
          )
        "
      >
        <p>
          Lei das OSCIPs (Organizações da Sociedade Civil de Interesse Público)
          - Lei 9.790/1999
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e9fc93a1e09bc829852')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a173c93a1e229cb760eb',
            'Lei de Defesa da Livre Concorrência - Lei Ordinária nº 12.529, de 30.11.2011 - CADE e Infrações contra Ordem Econômica - Lei nº 12.529/2011'
          )
        "
      >
        <p>
          CADE (Conselho Administrativo de Defesa Econômica) - Lei 12.529/2011
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a173c93a1e229cb760eb')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Tributario'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Tributário</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>
          Constituição Federal - Sistema Tributário Nacional (arts. 145 a 162)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc01721c93a1e1f24d89945',
            'Código Tributário Nacional - CTN - Lei nº 5.172/1966'
          )
        "
      >
        <p>Código Tributário Nacional (CTN) - Lei 5.172/1966</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc01721c93a1e1f24d89945')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f2ec93a1e09bc82988f',
            'Lei de Execução Fiscal - Lei Ordinária nº 6.830, de 22.09.1980 - Lei nº 6.830/1980'
          )
        "
      >
        <p>Lei de Execuções Fiscais - Lei 6.830/1980</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f2ec93a1e09bc82988f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ef6c93a1e09bc829877',
            'Medida Cautelar Fiscal - Lei Ordinária nº 8.397, de 06.01.1992 - Lei nº 8.397/1992'
          )
        "
      >
        <p>Medida Cautelar Fiscal - Lei 8.397/1992</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ef6c93a1e09bc829877')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '63d53098dd04599f21597f4f',
            'Imposto de Renda das Pessoas Físicas - IRPF - Lei nº 9.250, de 26.12.95 - Lei nº 9.250/95'
          )
        "
      >
        <p>Imposto de Renda Pessoa Física - Lei 9.250/1995</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('63d53098dd04599f21597f4f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc01803c93a1e1f24d89985',
            'Lei do ICMS - Lei Complementar nº 87, de 13.9.1996 - Lei Kandir - LC nº 87/1996'
          )
        "
      >
        <p>Lei Kandir (ICMS) - LC 87/1996</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc01803c93a1e1f24d89985')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a2f4c93a1e229cb760f5',
            'Lei do PIS/PASEP - Lei Ordinária nº 10.637, de 30.12.2002 - Lei nº 10.637/2002'
          )
        "
      >
        <p>PIS/PASEP - Lei 10.637/2002</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a2f4c93a1e229cb760f5')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc01b29c93a1e1f24d89997',
            'Lei do ISS - Lei Complementar nº 116, de 31.7.2003 - LC nº 116/2003'
          )
        "
      >
        <p>Lei ISS - LC 116/2003</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc01b29c93a1e1f24d89997')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a2f8c93a1e229cb760f6',
            'Cadastro Informativo dos Créditos Não Quitados de Órgãos e Entidades Federais - Lei Ordinária nº 10.522, de 19.07.2002 - CADIN - Lei nº 10.522/2002'
          )
        "
      >
        <p>Lei do Débito Tributário - Lei 10.522/2002</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a2f8c93a1e229cb760f6')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5ee3c2fce9d1ae10b8b0fc44',
            'Estatuto Nacional da Microempresa e da Empresa de Pequeno Porte - Lei Complementar nº 123, de 14.12.2006 - LC nº 123/2006'
          )
        "
      >
        <p>
          Estatuto da Micro Empresa - Institui o Simples Nacional - LC 123/2006
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5ee3c2fce9d1ae10b8b0fc44')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '613a7b30e51a2c86b42b9fd1',
            'Transação Tributária - Lei Ordinária nº 13.988, de 14.04.2020 - Transações fiscais - Lei nº 13.988/2020'
          )
        "
      >
        <p>Lei da Transação Tributária - Lei 13.988/2020</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('613a7b30e51a2c86b42b9fd1')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a55ec93a1e229cb76112',
            'Crimes Contra a Ordem Tributária e Consumo - Lei Ordinária nº 8.137, de 27.12.1990 - Lei nº 8.137/1990'
          )
        "
      >
        <p>
          Crimes contra a ordem tributária, econômica e contra as relações de
          consumo - Lei 8.137/1990
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a55ec93a1e229cb76112')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Eleitoral'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Eleitoral</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>
          Constituição - Direitos Políticos - art. 14 da CF (Constituição
          Federal)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f2fc93a1e1d3c75dd9f',
            'Código Eleitoral - Lei Ordinária nº 4.737, de 15.07.1965 - Lei nº 4.737/1965'
          )
        "
      >
        <p>Código Eleitoral - Lei 4.737/1965</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f2fc93a1e1d3c75dd9f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a362c93a1e229cb76102',
            'Lei Geral das Eleições - Lei Ordinária nº 9.504, de 30.09.1997 - Lei nº 9.504/1997'
          )
        "
      >
        <p>Lei Geral das Eleições - Lei 9.504/1997</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a362c93a1e229cb76102')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a50ac93a1e229cb76109',
            'Lei dos Partidos Políticos - Lei Ordinária nº 9.096, de 19.09.1995 - Lei nº 9.096/1995'
          )
        "
      >
        <p>Lei dos Partidos Políticos - Lei 9.096/1995</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a50ac93a1e229cb76109')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a078c93a1e229cb760a6',
            'Lei das Inelegibilidades - Lei Complementar nº 64, de 18.5.1990 - LC nº 64/1990'
          )
        "
      >
        <p>Lei das Inelegibilidades - LC (Lei Complementar) 64/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a078c93a1e229cb760a6')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f2fc93a1e1d3c75dd9f',
            'Código Eleitoral - Lei Ordinária nº 4.737, de 15.07.1965 - Lei nº 4.737/1965'
          )
        "
      >
        <p>Crimes Eleitorais - art. 283, CE (Código Eleitoral)</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f2fc93a1e1d3c75dd9f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Ambiental'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Ambiental</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Meio ambiente urbano' }"
        (click)="abrirTerceiroRamos('Meio ambiente urbano')"
      >
        <p>Meio ambiente urbano</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum: ramos[2] == 'Protecao Recursos Biologicos'
        }"
        (click)="abrirTerceiroRamos('Protecao Recursos Biologicos')"
      >
        <p>Proteção Recursos Biológicos</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Publico' && ramos[1] == 'Direito Ambiental'"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Meio ambiente urbano'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Ambiental')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Meio ambiente urbano</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>Direito Urbanístico - CF (Constituição Federal) art. 182</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e84c93a1e09bc829845',
            'Estatuto da Cidade - Lei Ordinária nº 10.257, de 10.07.2001 - Lei nº 10.257/2001'
          )
        "
      >
        <p>Estatuto das Cidades - Lei 10.257/2001</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e84c93a1e09bc829845')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Estatuto das Metrópoles - Lei 13.089/2015
      </div> -->
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '6501e5178a4d1d060bf039e1',
            'Marco Legal do Saneamento Básico - Lei nº 14.026, de 15.07.2020 - Lei nº 14.026/2020'
          )
        "
      >
        <p>
          Marco Legal do Saneamento Básico - Lei 14.026/2020 alterando outras
          leis
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('6501e5178a4d1d060bf039e1')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Estatuto do Saneamento Básico - Lei 9.984/2000
      </div> -->
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Protecao Recursos Biologicos'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Ambiental')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Proteção Recursos Biológicos</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e61c93a1e09bc829838',
            'Normas para Organismos Geneticamente Modificados - OGM - Lei Ordinária nº 11.105, de 24.03.2005 - Lei de Biossegurança - Lei nº 11.105/2005'
          )
        "
      >
        <p>Lei de Biossegurança - Lei 11.105/2005</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e61c93a1e09bc829838')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a375c93a1e229cb76104',
            'Lei Ordinária nº 9.434, de 04.02.1997 - Lei nº 9.434/1997'
          )
        "
      >
        <p>Lei de Transplantes - Lei 9.434/1997</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a375c93a1e229cb76104')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a353c93a1e229cb76100',
            'Crimes Ambientais - Lei Ordinária nº 9.605, de 12.02.1998 - Lei nº 9.605/1998'
          )
        "
      >
        <p>Crimes Ambientais e Infrações Administrativas - Lei 9.605/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a353c93a1e229cb76100')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Economico / Financeiro'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Publico')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Econômico / Financeiro</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>Ordem Econômica - CF (Constituição Federal) art. 170 a 181</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>Direito Financeiro - CF (Constituição Federal) art. 163 a 169</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a173c93a1e229cb760eb',
            'Lei de Defesa da Livre Concorrência - Lei Ordinária nº 12.529, de 30.11.2011 - CADE e Infrações contra Ordem Econômica - Lei nº 12.529/2011'
          )
        "
      >
        <p>
          CADE (Conselho Administrativo de Defesa Econômica) - Lei 12.529/2011
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a173c93a1e229cb760eb')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a148c93a1e229cb760e2',
            'Lei Anticorrupção - Lei Ordinária nº 12.846, de 1º.8.2013  - Acordo de Leniência - Lei nº 12.846/2013'
          )
        "
      >
        <p>Lei Anticorrupção - Lei 12.846/2013</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a148c93a1e229cb760e2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc0181bc93a1e1f24d8998c',
            'Lei de Responsabilidade Fiscal - LRF - Lei Complementar nº 101, de 4.5.2000 - LC nº 101/2000'
          )
        "
      >
        <p>Lei de Responsabilidade Fiscal - LC (Lei Complementar) 101/2000</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc0181bc93a1e1f24d8998c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f4ec93a1e1d3c75dda4',
            'Normas Gerais de Direito Financeiro - Lei Ordinária nº 4.320, de 17.03.1964 - Controle Orçamentário - Lei nº 4.320/1964'
          )
        "
      >
        <p>
          Lei de Normas Gerais sobre Orçamento e Finanças Públicas - Lei
          4.320/1964
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f4ec93a1e1d3c75dda4')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <!-- DIREITO PRIVADO -->
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[0] == 'Direito Privado'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="fechatodosRamos()"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Privado</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Civil' }"
        (click)="abrirSegundoRamos('Direito Civil')"
      >
        <p>Direito Civil</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito do Consumidor' }"
        (click)="abrirSegundoRamos('Direito do Consumidor')"
      >
        <p>Direito do Consumidor</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum: ramos[1] == 'Direito Processual Civil'
        }"
        (click)="abrirSegundoRamos('Direito Processual Civil')"
      >
        <p>Direito Processual Civil</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Empresarial' }"
        (click)="abrirSegundoRamos('Direito Empresarial')"
      >
        <p>Direito Empresarial</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Privado' && !(breakpointMobileLg$ | async)"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Civil'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Privado')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Civil</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Direito Digital' }"
        (click)="abrirTerceiroRamos('Direito Digital')"
      >
        <p>Direito Digital</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a0d8c93a1e229cb760bf',
            'Lei de Introdução às normas do Direito Brasileiro - LINDB - Decreto-Lei nº 4.657, de 04.09.1942 - Dec.-lei nº 4.657/1942'
          )
        "
      >
        <p>
          LINDB (Lei de Introdução às Normas do Direito Brasileiro) - Lei
          4.657/1942
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a0d8c93a1e229cb760bf')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779f6ec93a1e229cb7609a',
            'Código Civil - CC - Lei nº 10.406/2002'
          )
        "
      >
        <p>Código Civil - Lei 10.406/2002</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779f6ec93a1e229cb7609a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642dfdc93a1e09bc82980b',
            'Alienação Fiduciária de Bens Móveis (Busca e Apreensão) - Decreto-Lei nº 911, de 1º.10.1969 - 911/69 - Dec.-lei nº 911/1969'
          )
        "
      >
        <p>Busca e Apreensão - Decreto 911/1969</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642dfdc93a1e09bc82980b')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a359c93a1e229cb76101',
            'Sistema Financeiro Imobiliário - SFI - Lei Ordinária nº 9.514, de 20.11.1997 - Lei nº 9.514/1997'
          )
        "
      >
        <p>Sistema Financeiro Imobiliário - Lei 9.514/1997</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a359c93a1e229cb76101')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ef9c93a1e09bc829878',
            'Lei de Locação de Imóveis Urbanos - Lei Ordinária nº 8.245, de 18.10.1991 - Lei do Inquilinato - Lei nº 8.245/1991'
          )
        "
      >
        <p>Lei de Locações - Lei 8.245/1991</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ef9c93a1e09bc829878')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a34bc93a1e229cb760ff',
            'Lei dos Direitos Autorais - Lei Ordinária nº 9.610, de 19.02.1998 - Lei nº 9.610/1998'
          )
        "
      >
        <p>Direitos Autorais - Lei 9.610/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a34bc93a1e229cb760ff')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f01c93a1e1d3c75dd99',
            'Lei de Registros Públicos - Lei Ordinária nº 6.015, de 31.12.1973 - Lei nº 6.015/1973'
          )
        "
      >
        <p>Lei dos Registros Públicos - Lei 6.015/1973</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f01c93a1e1d3c75dd99')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f0dc93a1e09bc829880',
            'Impenhorabilidade do Bem de Família - Lei Ordinária nº 8.009, de 29.03.1990 - Lei nº 8.009/1990'
          )
        "
      >
        <p>Lei de Proteção do Bem de Família - Lei 8.009/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f0dc93a1e09bc829880')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e3bc93a1e09bc829827',
            'Alimentos Gravídicos - Lei Ordinária nº 11.804, de 05.11.2008 - Lei nº 11.804/2008'
          )
        "
      >
        <p>Lei dos Alimentos Gravídicos - Lei 11.804/2008</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e3bc93a1e09bc829827')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e20c93a1e09bc82981b',
            'Alienação Parental - Lei Ordinária nº 12.318, de 26.08.2010 - Lei nº 12.318/2010'
          )
        "
      >
        <p>Lei de Alienação Parental - Lei 12.318/2010</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e20c93a1e09bc82981b')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e37c93a1e09bc829825',
            'Lei Ordinária nº 12.010, de 03.08.2009 - Lei nº 12.010/2009'
          )
        "
      >
        <p>Lei de Adoção - Lei 12.010/2009</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e37c93a1e09bc829825')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ee8c93a1e09bc829871',
            'Lei Ordinária nº 8.560, de 29.12.1992 - Lei nº 8.560/1992'
          )
        "
      >
        <p>Lei de Investigação de Paternidade - Lei 8.560/1992</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ee8c93a1e09bc829871')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Privado' && ramos[1] == 'Direito Civil'"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito do Consumidor'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Privado')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito do Consumidor</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f06c93a1e09bc82987d',
            'Lei Ordinária nº 8.078, de 11.09.1990 - Lei nº 8.078/1990'
          )
        "
      >
        <p>Código de Defesa do Consumidor - Lei 8.078/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f06c93a1e09bc82987d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a336c93a1e229cb760fc',
            'Lei Ordinária nº 9.656, de 03.06.1998 - Lei nº 9.656/1998'
          )
        "
      >
        <p>Lei dos Planos de Saúde - Lei 9.656/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a336c93a1e229cb760fc')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f03c93a1e09bc82987c',
            'Lei do Funcionamento dos Serviços de Saúde - Lei Ordinária nº 8.080, de 19.09.1990 - Lei nº 8.080/1990'
          )
        "
      >
        <p>Lei do SUS - Lei 8.080/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f03c93a1e09bc82987c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02b10c93a1e1f24d89bce',
            'Marco Civil da Internet - Lei Ordinária nº 12.965, de 23.4.2014 - Lei nº 12.965/2014'
          )
        "
      >
        <p>Marco Civil da Internet - Lei 12.965/2014</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02b10c93a1e1f24d89bce')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5febbda7e51a2c41c0cadf07',
            'Lei Geral de Proteção de Dados - LGPD - Lei nº 13.709, de 14.08.2018 - Lei nº 13.709/2018'
          )
        "
      >
        <p>LGPD (Lei Geral de Proteção de Dados) - Lei 13.709/2018</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5febbda7e51a2c41c0cadf07')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ecdc93a1e09bc829866',
            'Lei dos Juizados Especiais - Lei Ordinária nº 9.099, de 26.09.1995 - Lei nº 9.099/1995'
          )
        "
      >
        <p>Juizado Especial Cível - Lei 9.099/1995</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ecdc93a1e09bc829866')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e28c93a1e09bc82981f',
            'Lei dos Juizados Especiais da Fazenda Pública - Lei Ordinária nº 12.153, de 22.12.2009 - Lei nº 12.153/2009'
          )
        "
      >
        <p>Juizado de Fazenda Pública - Lei 12.153/2009</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e28c93a1e09bc82981f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e81c93a1e09bc829844',
            'Lei dos Juizados Especiais Federais - Lei Ordinária nº 10.259, de 12.07.2001 - Lei nº 10.259/2001'
          )
        "
      >
        <p>Juizado Especial Federal - Lei 10.259/2001</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e81c93a1e09bc829844')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Processual Civil'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Privado')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Processual Civil</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fb5c93a1e229cb7609c',
            'Código de Processo Civil de 2015 - NCPC - Lei nº 13.105/2015'
          )
        "
      >
        <p>Código de Processo Civil (CPC) - Lei 13.105/2015</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fb5c93a1e229cb7609c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f24c93a1e09bc82988a',
            'Lei da Ação Civil Pública - ACP - Lei Ordinária nº 7.347, de 24.07.1985 - Lei nº 7.347/1985'
          )
        "
      >
        <p>Lei da Ação Civil Pública - Lei 7.347/1985</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f24c93a1e09bc82988a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f06c93a1e09bc82987d',
            'Lei Ordinária nº 8.078, de 11.09.1990 - Lei nº 8.078/1990'
          )
        "
      >
        <p>Código de Defesa do Consumidor - arts. 81 e seguintes</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f06c93a1e09bc82987d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e34c93a1e09bc829824',
            'Lei do Mandado de Segurança - Lei Ordinária nº 12.016, de 07.08.2009 - Lei nº 12.016/2009'
          )
        "
      >
        <p>Mandado de Segurança Coletivo - Lei 12.016/2009 - art. 21</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e34c93a1e09bc829824')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a386c93a1e229cb76106',
            'Lei da Arbitragem - Lei Ordinária nº 9.307, de 23.09.1996 - Juízo Arbitral - Lei nº 9.307/1996'
          )
        "
      >
        <p>Lei de Arbitragem - Lei 9.307/1996</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a386c93a1e229cb76106')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc0249fc93a1e1f24d89b4f',
            'Lei Ordinária nº 13.129, de 26.05.2015 - Lei nº 13.129/2015'
          )
        "
      >
        <p>Arbitragem na Fazenda Pública - Lei 13.129/2015</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc0249fc93a1e1f24d89b4f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642eb1c93a1e09bc82985b',
            'Tutela Antecipada contra a Fazenda Pública - Lei Ordinária nº 9.494, de 10.09.1997 - Lei nº 9.494/1997'
          )
        "
      >
        <p>
          Lei de Tutela Antecipada contra a Fazenda Pública - Lei 9.494/1997
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642eb1c93a1e09bc82985b')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e56c93a1e09bc829834',
            'Lei Ordinária nº 11.419, de 19.12.2006 - Lei nº 11.419/2006'
          )
        "
      >
        <p>Lei do Processo Eletrônico - Lei 11.419/2006</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e56c93a1e09bc829834')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Empresarial'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Privado')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Empresarial</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Estruturas empresariais' }"
        (click)="abrirTerceiroRamos('Estruturas empresariais')"
      >
        <p>Estruturas empresariais</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Contratos Diferenciados' }"
        (click)="abrirTerceiroRamos('Contratos Diferenciados')"
      >
        <p>Contratos Diferenciados</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum: ramos[2] == 'Lei dos titulos de creditos'
        }"
        (click)="abrirTerceiroRamos('Lei dos titulos de creditos')"
      >
        <p>Lei dos títulos de créditos</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779f6ec93a1e229cb7609a',
            'Código Civil - CC - Lei nº 10.406/2002'
          )
        "
      >
        <p>
          Código Civil - Direito Empresarial - Lei 10.406/2002, artigos 966 a
          1.195
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779f6ec93a1e229cb7609a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e6cc93a1e09bc82983b',
            'Lei de Falência e Recuperação Judicial - Lei Ordinária nº 11.101, de 09.02.2005 - Lei nº 11.101/2005'
          )
        "
      >
        <p>
          Lei de Falências e Recuperação Judicial - Lei 11.101/2005 (reformada
          pela Lei 14.112/2021)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e6cc93a1e09bc82983b')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ec7c93a1e09bc829863',
            'Lei de Propriedade Industrial - LPI - Lei Ordinária nº 9.279, de 14.05.1996 - Lei nº 9.279/1996'
          )
        "
      >
        <p>Lei de Propriedade Industrial - Lei 9.279/1996</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ec7c93a1e09bc829863')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Privado' && ramos[1] == 'Direito Empresarial'"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Direito Digital'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Civil')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Digital</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02b10c93a1e1f24d89bce',
            'Marco Civil da Internet - Lei Ordinária nº 12.965, de 23.4.2014 - Lei nº 12.965/2014'
          )
        "
      >
        <p>Marco Civil da Internet - Lei 12.965/2014</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02b10c93a1e1f24d89bce')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5febbda7e51a2c41c0cadf07',
            'Lei Geral de Proteção de Dados - LGPD - Lei nº 13.709, de 14.08.2018 - Lei nº 13.709/2018'
          )
        "
      >
        <p>Lei Geral de Proteção de Dados (LGPD) - Lei 13.709/2018</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5febbda7e51a2c41c0cadf07')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Estruturas empresariais'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Empresarial')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Estruturas empresariais</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5ee3c2fce9d1ae10b8b0fc44',
            'Estatuto Nacional da Microempresa e da Empresa de Pequeno Porte - Lei Complementar nº 123, de 14.12.2006 - LC nº 123/2006'
          )
        "
      >
        <p>
          Lei do Microempreendedor Individual (MEI) - Lei Complementar 123/2006
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5ee3c2fce9d1ae10b8b0fc44')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f40c93a1e09bc829896',
            'Lei das Cooperativas - Lei Ordinária nº 5.764, de 16.12.1971 - Lei nº 5.764/1971'
          )
        "
      >
        <p>Lei das Cooperativas - Lei 5.764/1971</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f40c93a1e09bc829896')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02120c93a1e1f24d89ae0',
            'Estatuto das Empresas Estatais - Lei Ordinária nº 13.303, de 30.06.2016 - Lei nº 13.303/2016'
          )
        "
      >
        <p>Estatuto das Empresas Estatais - Lei 13.303/2016</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02120c93a1e1f24d89ae0')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95ee5c93a1e1d3c75dd94',
            'Lei das Sociedades por Ações - Lei Ordinária nº 6.404, de 15.12.1976 - Lei nº 6.404/1976'
          )
        "
      >
        <p>Lei das Sociedades por Ações - Lei 6.404/1976</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95ee5c93a1e1d3c75dd94')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Contratos Diferenciados'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Empresarial')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Contratos Diferenciados</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '613fb178e51a2c58b84b79dd',
            'Lei das Franquias Empresariais (Franchising) - Lei Ordniária nº 13.966, de 26.12.2019 - Lei das franquias - Lei nº 13.966/2019'
          )
        "
      >
        <p>Lei de Franquia Empresarial - Lei 13.966/2019</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('613fb178e51a2c58b84b79dd')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f40c93a1e1d3c75dda2',
            'Lei Ordinária nº 4.591, de 16.12.1964 - Lei nº 4.591/1964'
          )
        "
      >
        <p>Lei de Incorporações Imobiliárias - Lei 4.591/1964</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f40c93a1e1d3c75dda2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95ef3c93a1e1d3c75dd98',
            'Intervenção e Liquidação Extrajudicial de Instituições Financeiras - Lei Ordinária nº 6.024, de 13.03.1974 - Lei nº 6.024.1974'
          )
        "
      >
        <p>Lei de Liquidação Extrajudicial - Lei 6.024/1974</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95ef3c93a1e1d3c75dd98')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95ee9c93a1e1d3c75dd95',
            'Lei do Mercado Mobiliário - Ações - CMV - Lei Ordinária nº 6.385, de 07.12.1976 - Lei nº 6.385/1976'
          )
        "
      >
        <p>Lei do Mercado de Valores Mobiliários - Lei 6.385/1976</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95ee9c93a1e1d3c75dd95')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Lei dos titulos de creditos'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Empresarial')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Lei dos títulos de créditos</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779f6ec93a1e229cb7609a',
            'Código Civil - CC - Lei nº 10.406/2002'
          )
        "
      >
        <p>
          Código Civil Brasileiro - Lei 10.406/2002 (Parte Especial, Livro I,
          Título VIII, Capítulo III)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779f6ec93a1e229cb7609a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95fd5c93a1e1d3c75ddb2',
            'Lei Uniforme de Genebra - LUG - Títulos de Crédito - Decreto nº 57.663, de 24.01.1966 - Dec. nº 57.663/1966'
          )
        "
      >
        <p>
          Lei Uniforme de Genebra sobre Letras de Câmbio e Notas Promissórias -
          Decreto 57.663/1966
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95fd5c93a1e1d3c75ddb2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95fddc93a1e1d3c75ddb3',
            'Decreto nº 57.595, de 07.01.1966 - Dec. nº 57.595/1966'
          )
        "
      >
        <p>Lei Uniforme sobre Cheques - Decreto 57.595/1966</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95fddc93a1e1d3c75ddb3')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f15c93a1e1d3c75dd9c',
            'Lei da Duplicata - Títulos de Crédito - Lei Ordinária nº 5.474, de 18.07.1968 - Lei nº 5.474/1968'
          )
        "
      >
        <p>Lei do Duplicata Mercantil - Lei 5.474/1968</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f15c93a1e1d3c75dd9c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642eb3c93a1e09bc82985c',
            'Protesto - Títulos de Crédito - Lei Ordinária nº 9.492, de 10.09.1997 - Lei nº 9.492/1997'
          )
        "
      >
        <p>Lei do Protesto de Títulos - Lei 9.492/1997</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642eb3c93a1e09bc82985c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <!-- DIREITO CRIMINAL -->
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[0] == 'Direito Criminal'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="fechatodosRamos()"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Criminal</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Penal' }"
        (click)="abrirSegundoRamos('Direito Penal')"
      >
        <p>Direito Penal</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum: ramos[1] == 'Direito Processual Penal'
        }"
        (click)="abrirSegundoRamos('Direito Processual Penal')"
      >
        <p>Direito Processual Penal</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Militar' }"
        (click)="abrirSegundoRamos('Direito Militar')"
      >
        <p>Direito Militar</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Criminal' && !(breakpointMobileLg$ | async)"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Penal'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Criminal')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Penal</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Penal extravagante' }"
        (click)="abrirTerceiroRamos('Penal extravagante')"
      >
        <p>Penal extravagante</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum:
            ramos[2] == 'NOVOS CRIMES INSERIDOS NO CODIGO PENAL'
        }"
        (click)="abrirTerceiroRamos('NOVOS CRIMES INSERIDOS NO CODIGO PENAL')"
      >
        <p>NOVOS CRIMES INSERIDOS NO CÓDIGO PENAL</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fd6c93a1e229cb7609d',
            'Código Penal - CP - Decreto-Lei nº 2.848/1940'
          )
        "
      >
        <p>Código Penal - Decreto-Lei 2.848/1940</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fd6c93a1e229cb7609d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f22c93a1e09bc829889',
            'Lei de Execução Penal - LEP - Lei Ordinária nº 7.210, de 11.07.1984 - Lei nº 7.210/1984'
          )
        "
      >
        <p>Lei de Execuções Penais - Lei 7.210/1984</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f22c93a1e09bc829889')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f08c93a1e09bc82987e',
            'Lei dos Crimes Hediondos - Lei Ordinária nº 8.072, de 25.07.1990 - Lei nº 8.072/1990'
          )
        "
      >
        <p>Lei dos Crimes Hediondos - Lei 8.072/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f08c93a1e09bc82987e')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Criminal' && ramos[1] == 'Direito Penal'"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Processual Penal'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Criminal')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Processual Penal</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779ffdc93a1e229cb7609f',
            'Código de Processo Penal - CPP - Decreto-Lei nº 3.689/1941'
          )
        "
      >
        <p>Código de Processo Penal (CPP) - Decreto-Lei 3.689/1941</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779ffdc93a1e229cb7609f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f22c93a1e09bc829889',
            'Lei de Execução Penal - LEP - Lei Ordinária nº 7.210, de 11.07.1984 - Lei nº 7.210/1984'
          )
        "
      >
        <p>Lei de Execução Penal (LEP) - Lei 7.210/1984</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f22c93a1e09bc829889')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ecdc93a1e09bc829866',
            'Lei dos Juizados Especiais - Lei Ordinária nº 9.099, de 26.09.1995 - Lei nº 9.099/1995'
          )
        "
      >
        <p>
          Lei dos Juizados Especiais Criminais (Lei dos JECRIM) - Lei 9.099/1995
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ecdc93a1e09bc829866')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ec2c93a1e09bc829862',
            'Interceptação de Comunicações Telefônicas - Lei Ordinária nº 9.296, de 24.07.1996 - Grampo e Escuta de Telefonia - Lei nº 9.296/1996'
          )
        "
      >
        <p>Lei de Interceptação Telefônica - Lei 9.296/1996</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ec2c93a1e09bc829862')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642eb7c93a1e09bc82985e',
            'Lei dos Crimes de Tortura - Lei Ordinária nº 9.455, de 07.04.1997 - Lei nº 9.455/1997'
          )
        "
      >
        <p>Lei de Tortura - Lei 9.455/1997</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642eb7c93a1e09bc82985e')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f08c93a1e09bc82987e',
            'Lei dos Crimes Hediondos - Lei Ordinária nº 8.072, de 25.07.1990 - Lei nº 8.072/1990'
          )
        "
      >
        <p>Lei de Crimes Hediondos - Lei 8.072/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f08c93a1e09bc82987e')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a347c93a1e229cb760fe',
            'Crimes de Lavagem de Dinheiro - Lei Ordinária nº 9.613, de 03.03.1998 - Lavagem de Capitais - Lei nº 9.613/1998'
          )
        "
      >
        <p>Lei de Lavagem de Dinheiro - Lei 9.613/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a347c93a1e229cb760fe')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d6400e1c93a1e1e2065b51a',
            'Lei das Organizações Criminosas - Lei Ordinária nº 12.850, de 2.8.2013  - Delação Premiada - Lei nº 12.850/2013'
          )
        "
      >
        <p>Lei de Organizações Criminosas - Lei 12.850/2013</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d6400e1c93a1e1e2065b51a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e9bc93a1e09bc829850',
            'Programa de Proteção às Vítimas e Testemunhas - Lei Ordinária nº 9.807, de 13.07.1999 - Lei nº 9.807/1999'
          )
        "
      >
        <p>Lei de Proteção a Vítimas e Testemunhas - Lei 9.807/1999</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e9bc93a1e09bc829850')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '626002efe51a2c1544619a14',
            'Crimes de Abuso de Autoridade - Lei nº 13.869, de 05.09.2019 - Lei nº 13.869/2019'
          )
        "
      >
        <p>Lei dos Crimes de Abuso de Autoridade - Lei 13.869/2019</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('626002efe51a2c1544619a14')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a584c93a1e229cb76117',
            'Prisão Temporária - Lei Ordinária nº 7.960, de 21.12.1989 - Lei nº 7.960/1989'
          )
        "
      >
        <p>Prisão Temporária - Lei 7.960/1989</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a584c93a1e229cb76117')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Militar'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Criminal')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Militar</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fe9c93a1e229cb7609e',
            'Código Penal Militar - CPM - Decreto-Lei nº 1.001/1969'
          )
        "
      >
        <p>Código Penal Militar (CPM) - Lei 1.001/1969</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fe9c93a1e229cb7609e')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a016c93a1e229cb760a0',
            'Código de Processo Penal Militar - CPPM - Decreto-Lei nº 1.002/1969'
          )
        "
      >
        <p>Código de Processo Penal Militar (CPPM) - Decreto-Lei 1.002/1969</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a016c93a1e229cb760a0')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95ecfc93a1e1d3c75dd92',
            'Estatuto dos Militares - Lei Ordinária nº 6.880, de 09.12.1980 - Lei nº 6.880/1980'
          )
        "
      >
        <p>
          Lei de Remuneração dos Militares das Forças Armadas - Lei 6.880/1980
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95ecfc93a1e1d3c75dd92')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95ecfc93a1e1d3c75dd92',
            'Estatuto dos Militares - Lei Ordinária nº 6.880, de 09.12.1980 - Lei nº 6.880/1980'
          )
        "
      >
        <p>Estatuto dos Militares - Lei 6.880/1980</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95ecfc93a1e1d3c75dd92')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f55c93a1e09bc82989f',
            'Lei do Serviço Militar - Lei Ordinária nº 4.375, de 17.08.1964 - Lei nº 4.375/1964'
          )
        "
      >
        <p>Lei do Serviço Militar - Lei 4.375/1964</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f55c93a1e09bc82989f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ef0c93a1e09bc829874',
            'Lei Ordinária nº 8.457, de 04.09.1992 - Lei nº 8.457/1992'
          )
        "
      >
        <p>Código de Justiça Militar (CJM) - Lei 8.457/1992</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ef0c93a1e09bc829874')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Penal extravagante'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Penal')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Penal extravagante</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '626002efe51a2c1544619a14',
            'Crimes de Abuso de Autoridade - Lei nº 13.869, de 05.09.2019 - Lei nº 13.869/2019'
          )
        "
      >
        <p>Abuso de Autoridade - Lei 13.869/2019</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('626002efe51a2c1544619a14')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d6400e1c93a1e1e2065b51a',
            'Lei das Organizações Criminosas - Lei Ordinária nº 12.850, de 2.8.2013  - Delação Premiada - Lei nº 12.850/2013'
          )
        "
      >
        <p>Organização Criminosa - Lei 12.850/2013</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d6400e1c93a1e1e2065b51a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '627d8bede51a2c5080dbd821',
            'Lei dos Crimes de Terrorismo - Lei Ordinária nº 13.260, de 16.03.2016 - Lei nº 13.260/2016'
          )
        "
      >
        <p>Terrorismo e Tortura - Lei 13.260/2016</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('627d8bede51a2c5080dbd821')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642decc93a1e09bc829804',
            'Lei das Contravenções Penais - LCP - Decreto-Lei nº 3.688, de 03.10.1941 - Dec.-lei nº 3.688/1941'
          )
        "
      >
        <p>Contravenções Penais - Decreto-Lei 3.688/1941</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642decc93a1e09bc829804')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a2e6c93a1e229cb760f4',
            'Estatuto do Desarmamento - Lei Ordinária nº 10.826, de 22.12.2003 - Lei nº 10.826/2003'
          )
        "
      >
        <p>Estatuto do Desarmamento - Lei 10.826/2003</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a2e6c93a1e229cb760f4')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e5cc93a1e09bc829836',
            'Lei de Drogas - Lei Ordinária nº 11.343, de 23.08.2006 - Lei nº 11.343/2006'
          )
        "
      >
        <p>Lei de Drogas - Lei 11.343/2006</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e5cc93a1e09bc829836')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e5ec93a1e09bc829837',
            'Lei de Crimes de Violência Doméstica - Lei Ordinária nº 11.340, de 07.08.2006 - Lei Maria da Penha - Lei nº 11.340/2006'
          )
        "
      >
        <p>Lei Maria da Penha - Lei 11.340/2006</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e5ec93a1e09bc829837')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642ecdc93a1e09bc829866',
            'Lei dos Juizados Especiais - Lei Ordinária nº 9.099, de 26.09.1995 - Lei nº 9.099/1995'
          )
        "
      >
        <p>Juizados Especiais - Lei 9.099/1995</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642ecdc93a1e09bc829866')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f1dc93a1e09bc829888',
            'Crimes Contra o Sistema Financeiro - Lei Ordinária nº 7.492, de 16.06.1986 - Crimes de Colarinho Branco - Lei nº 7.492/1986'
          )
        "
      >
        <p>Sistema Financeiro - Crimes de Colarinho Branco - Lei 7.492/1986</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f1dc93a1e09bc829888')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a025c93a1e229cb760a1',
            'Código de Trânsito Brasileiro - CTB - Lei nº 9.503/1997'
          )
        "
      >
        <p>
          Crime de Trânsito - CTB (Código de Trânsito Brasileiro) - Lei
          9.503/1997
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a025c93a1e229cb760a1')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642df4c93a1e09bc829808',
            'Crimes de Responsabilidade de Prefeitos e Vereadores - Decreto-Lei nº 201, de 27.02.1967 - Dec.-lei nº 201/1967'
          )
        "
      >
        <p>Crime de Responsabilidade - Decreto-Lei 201/1967</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642df4c93a1e09bc829808')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e7dc93a1e09bc829842',
            'Estatuto do Torcedor (Revogado pela Lei 14.597/23) - Lei Ordinária nº 10.671, de 15.05.2003 - Lei nº 10.671/2003'
          )
        "
      >
        <p>
          Lei Geral do Esporte e Estatuto do Torcedor - Lei 14.597/1923
          (substituiu o antigo Estatuto do Torcedor)
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e7dc93a1e09bc829842')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e2dc93a1e09bc829821',
            'Identificação Criminal do Civilmente Identificado - Lei Ordinária nº 12.037, de 1º.10.2009 - Lei nº 12.037/2009'
          )
        "
      >
        <p>Identificação Criminal - Lei 12.037/2009</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e2dc93a1e09bc829821')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a347c93a1e229cb760fe',
            'Crimes de Lavagem de Dinheiro - Lei Ordinária nº 9.613, de 03.03.1998 - Lavagem de Capitais - Lei nº 9.613/1998'
          )
        "
      >
        <p>Lavagem de Capitais - Lei 9.613/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a347c93a1e229cb760fe')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fe9c93a1e229cb7609e',
            'Código Penal Militar - CPM - Decreto-Lei nº 1.001/1969'
          )
        "
      >
        <p>Justiça Militar - Decreto-Lei 1.001/1969</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fe9c93a1e229cb7609e')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a55ec93a1e229cb76112',
            'Crimes Contra a Ordem Tributária e Consumo - Lei Ordinária nº 8.137, de 27.12.1990 - Lei nº 8.137/1990'
          )
        "
      >
        <p>Crimes contra a Ordem Tributária - Lei 8.137/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a55ec93a1e229cb76112')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '645bef43ae0b7d7df24e62af',
            'Violência Doméstica contra Criança e Adolescente - Lei nº 14.344, de 24.05.2022 - Lei Henry Borel - Lei nº 14.344/2022'
          )
        "
      >
        <p>
          Violência Doméstica contra Criança e Adolescente - Lei 14.344/2022
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('645bef43ae0b7d7df24e62af')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'NOVOS CRIMES INSERIDOS NO CODIGO PENAL'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Penal')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>NOVOS CRIMES INSERIDOS NO CODIGO PENAL</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fd6c93a1e229cb7609d',
            'Código Penal - CP - Decreto-Lei nº 2.848/1940'
          )
        "
      >
        <p>Crimes contra a Seguridade Social - art. 168-A e 137-A</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fd6c93a1e229cb7609d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fd6c93a1e229cb7609d',
            'Código Penal - CP - Decreto-Lei nº 2.848/1940'
          )
        "
      >
        <p>
          Crimes em Licitação e Contratos Administrativos - art. 337-E e
          seguintes
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fd6c93a1e229cb7609d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d779fd6c93a1e229cb7609d',
            'Código Penal - CP - Decreto-Lei nº 2.848/1940'
          )
        "
      >
        <p>
          Crimes contra o Estado Democrático de Direito - art. 359-I e seguintes
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d779fd6c93a1e229cb7609d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <!-- DIREITO SOCIAL -->
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[0] == 'Direito Social'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="fechatodosRamos()"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Social</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Previdenciario' }"
        (click)="abrirSegundoRamos('Direito Previdenciario')"
      >
        <p>Direito Previdenciário</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Agrario' }"
        (click)="abrirSegundoRamos('Direito Agrario')"
      >
        <p>Direito Agrário</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito do Trabalho' }"
        (click)="abrirSegundoRamos('Direito do Trabalho')"
      >
        <p>Direito do Trabalho</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="ramos[0] == 'Direito Social' && !(breakpointMobileLg$ | async)"
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Previdenciario'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Social')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Previdenciário</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>
          Constituição Federal de 1988 - Capítulo da Seguridade Social (arts.
          194 a 204) e disposições sobre previdência social
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a54fc93a1e229cb76110',
            'Planos de Benefícios da Previdência Social - Lei Ordinária nº 8.213, de 24.07.1991 - Lei nº 8.213/1991'
          )
        "
      >
        <p>Planos de Benefícios da Previdência Social - Lei 8.213/1991</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a54fc93a1e229cb76110')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a557c93a1e229cb76111',
            'Lei da Seguridade Social - Lei Ordinária nº 8.212, de 24.07.1991 - Lei nº 8.212/1991'
          )
        "
      >
        <p>Custeio da Seguridade Social - Lei 8.212/1991</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a557c93a1e229cb76111')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a527c93a1e229cb7610e',
            'Lei da Assistência Social - LOAS - Lei Ordinária nº 8.742, de 07.12.1993 - BPC - Lei nº 8.742/1993'
          )
        "
      >
        <p>Organização da Assistência Social (LOAS) - Lei 8.742/1993</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a527c93a1e229cb7610e')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc0182ac93a1e1f24d89991',
            'Regime de Previdência Complementar Pública - Lei Complementar nº 108, de 29.5.2001 - LC nº 108/2001'
          )
        "
      >
        <p>Entidades Fechadas de Previdência Complementar - LC 108/2001</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc0182ac93a1e1f24d89991')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642de8c93a1e09bc829802',
            'Regime de Previdência Complementar Privada - Lei Complementar nº 109, de 29.5.2001 - LC nº 109/2001'
          )
        "
      >
        <p>Regime de Previdência Complementar - LC 109/2001</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642de8c93a1e09bc829802')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a57bc93a1e229cb76115',
            'Lei do FGTS - Lei Ordinária nº 8.036, de 11.05.1990 - Lei nº 8.036/1990'
          )
        "
      >
        <p>Lei do FGTS - Lei 8.036/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a57bc93a1e229cb76115')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Agrario'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Social')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Agrário</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f46c93a1e1d3c75dda3',
            'Estatuto da Terra - Lei Ordinária nº 4.504, de 30.11.1964 - Lei nº 4.504/1964'
          )
        "
      >
        <p>Estatuto da Terra - Lei 4.504/1964</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f46c93a1e1d3c75dda3')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Código Agrário - Lei 4.947/1966
      </div> -->
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a531c93a1e229cb7610f',
            'Reforma Agrária - Lei Ordinária nº 8.629, de 25.02.1993 - Lei nº 8.629/1993'
          )
        "
      >
        <p>Lei da Reforma Agrária - Lei 8.629/1993</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a531c93a1e229cb7610f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642efec93a1e09bc82987a',
            'Lei Ordinária nº 8.171, de 17.01.1991 - Lei nº 8.171/1991'
          )
        "
      >
        <p>Lei da Política Agrícola - Lei 8.171/1991</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642efec93a1e09bc82987a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '63724ed9e51a2c39f8a2cf5f',
            'Aquisição de imóvel Rural por Estrangeiro - Lei nº 5.709, de 07.10.1971 - Lei nº 5.709/71'
          )
        "
      >
        <p>
          Lei de Aquisição de Imóvel Rural por Estrangeiros - Lei 5.709/1971
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('63724ed9e51a2c39f8a2cf5f')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f3ac93a1e09bc829894',
            'Lei Ordinária nº 5.889, de 08.06.1973 - Lei nº 5.889/1973'
          )
        "
      >
        <p>Lei do Trabalho Rural - Lei 5.889/1973</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f3ac93a1e09bc829894')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito do Trabalho'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Social')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito do Trabalho</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a056c93a1e229cb760a2',
            'Consolidação das Leis do Trabalho - CLT - Decreto-Lei nº 5.452/1943'
          )
        "
      >
        <p>Consolidação das Leis do Trabalho (CLT) - Decreto-Lei 5.452/1943</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a056c93a1e229cb760a2')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f15c93a1e09bc829884',
            'Lei do Direito de Greve - Lei Ordinária nº 7.783, de 28.06.1989 - Lei nº 7.783/1989'
          )
        "
      >
        <p>Lei de Greve - Lei 7.783/1989</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f15c93a1e09bc829884')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f38c93a1e09bc829893',
            'Lei Ordinária nº 6.019, de 03.01.1974 - Lei nº 6.019/1974'
          )
        "
      >
        <p>Lei do Trabalho Temporário - Lei 6.019/1974</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f38c93a1e09bc829893')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642eadc93a1e09bc829859',
            'Lei Ordinária nº 9.601, de 21.01.1998 - Lei nº 9.601/1998'
          )
        "
      >
        <p>Lei do Contrato de Trabalho a Prazo Determinado - Lei 9.601/1998</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642eadc93a1e09bc829859')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Lei do Trabalho Doméstico - Lei Complementar 150/2015
      </div> -->
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e46c93a1e09bc82982c',
            'Lei Ordinária nº 11.788, de 25.09.2008 - Lei nº 11.788/2008'
          )
        "
      >
        <p>Lei do Estágio - Lei 11.788/2008</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e46c93a1e09bc82982c')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a57bc93a1e229cb76115',
            'Lei do FGTS - Lei Ordinária nº 8.036, de 11.05.1990 - Lei nº 8.036/1990'
          )
        "
      >
        <p>
          Lei do FGTS (Fundo de Garantia do Tempo de Serviço) - Lei 8.036/1990
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a57bc93a1e229cb76115')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a581c93a1e229cb76116',
            'Lei Ordinária nº 7.998, de 11.01.1990 - Lei nº 7.998/1990'
          )
        "
      >
        <p>Lei do Seguro-Desemprego - Lei 7.998/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a581c93a1e229cb76116')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Lei do PIS (Programa de Integração Social) - Lei 7.998/1990
      </div> -->
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e16c93a1e09bc829817',
            'Lei Ordinária nº 12.506, de 11.10.2011 - Lei nº 12.506/2011'
          )
        "
      >
        <p>Lei do Aviso Prévio - Lei 12.506/2011</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e16c93a1e09bc829817')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e41c93a1e09bc82982a',
            'Lei Ordinária nº 11.770, de 09.09.2008 - Lei nº 11.770/2008'
          )
        "
      >
        <p>
          Lei da Licença-Maternidade para Mães de Prematuros - Lei 11.770/2008
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e41c93a1e09bc82982a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02158c93a1e1f24d89af7',
            'Lei Ordinária nº 13.271, de 15.04.2016 - Lei nº 13.271/2016'
          )
        "
      >
        <p>Lei que Proíbe Revista Íntima de Empregados - Lei 13.271/2016</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02158c93a1e1f24d89af7')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642f3ac93a1e09bc829894',
            'Lei Ordinária nº 5.889, de 08.06.1973 - Lei nº 5.889/1973'
          )
        "
      >
        <p>Lei do Trabalho Rural - Lei 5.889/1973</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642f3ac93a1e09bc829894')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <!-- DIREITO INTERNACIONAL -->
    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[0] == 'Direito Internacional'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="fechatodosRamos()"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Internacional</p>
      </div>

      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[1] == 'Direito Internacional2' }"
        (click)="abrirSegundoRamos('Direito Internacional2')"
      >
        <p>Direito Internacional</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        [ngClass]="{
          openedbuttoncolum:
            ramos[1] == 'Direitos Humanos - Protecao Vulneraveis'
        }"
        (click)="abrirSegundoRamos('Direitos Humanos - Protecao Vulneraveis')"
      >
        <p>Direitos Humanos - Proteção Vulneráveis</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="
        ramos[0] == 'Direito Internacional' && !(breakpointMobileLg$ | async)
      "
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direito Internacional2'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirPrimeiroRamos('Direito Internacional')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direito Internacional</p>
      </div>
      <!--  -->
      <div
        mat-button
        class="menu-button"
        [ngClass]="{ openedbuttoncolum: ramos[2] == 'Estrangeiro' }"
        (click)="abrirTerceiroRamos('Estrangeiro')"
      >
        <p>Estrangeiro</p>
        <mat-icon
          class="navegacao-menu-ramos-button-icon"
          svgIcon="caret-right"
        ></mat-icon>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95fe7c93a1e1d3c75ddb4',
            'Carta das Nações Unidas - Carta da ONU - CNU'
          )
        "
      >
        <p>Carta das Nações Unidas - Incorporada pelo Decreto 19.841/1945</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95fe7c93a1e1d3c75ddb4')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc96d1fc93a1e1d3c75ddbc',
            'Convenção de Viena sobre o Direito dos Tratados'
          )
        "
      >
        <p>Convenção de Viena sobre Tratados</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc96d1fc93a1e1d3c75ddbc')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <!-- <div
        mat-button

        class="menu-button"
        (click)="selecionar()"
      >
        Carta da ONU - Incorporada pelo Decreto 26.059/1947
      </div> -->
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '63e2e19cd2068ce5b8b2d6e6',
            'Estatuto de Roma do Tribunal Penal Internacional - TPI - Decreto nº 4.388, de 25.09.2002 - Lei nº 4.388/2002'
          )
        "
      >
        <p>TPI (Tribunal Penal Internacional)</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('63e2e19cd2068ce5b8b2d6e6')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a0d8c93a1e229cb760bf',
            'Lei de Introdução às normas do Direito Brasileiro - LINDB - Decreto-Lei nº 4.657, de 04.09.1942 - Dec.-lei nº 4.657/1942'
          )
        "
      >
        <p>LINDB (Lei de Introdução às Normas do Direito Brasileiro)</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a0d8c93a1e229cb760bf')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5da71c97c93a1e11400fc9fd',
            'Convenção Americana sobre Direitos Humanos - Pacto de São José da Costa Rica - D 678/92'
          )
        "
      >
        <p>
          Pacto de São José da Costa Rica - Incorporado pelo Decreto 678/1992
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5da71c97c93a1e11400fc9fd')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <mat-divider
      *ngIf="
        ramos[0] == 'Direito Internacional' &&
        ramos[1] == 'Direito Internacional2'
      "
      class="menu-divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[2] == 'Estrangeiro'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Internacional2')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Estrangeiro</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d75ce58c93a1e28b4316dee',
            'Constituição Federal - Constituição da República Federativa do Brasil de 1988 e Ato das Disposições Constitucionais e Transitórias - CF e ADCT'
          )
        "
      >
        <p>Constituição - Da Nacionalidade - art. 12</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d75ce58c93a1e28b4316dee')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc01e2ec93a1e1f24d89a37',
            'Lei de Migração - Lei Ordinária nº 13.445, de 24.05.2017 - Novo Estatuto do Estrangeiro - Lei nº 13.445/2017'
          )
        "
      >
        <p>Lei de Migração - Lei 13.445/2017</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc01e2ec93a1e1f24d89a37')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>

    <div
      class="custon-menu-navegacao-ramos-colum"
      *ngIf="ramos[1] == 'Direitos Humanos - Protecao Vulneraveis'"
    >
      <div
        class="menu-button button-mobile"
        *ngIf="breakpointMobileLg$ | async"
        mat-button
        (click)="abrirSegundoRamos('Direito Internacional2')"
      >
        <mat-icon
          class="navegacao-menu-ramos-button-icon icon-mobile"
          svgIcon="chevron-left"
        ></mat-icon>
        <p>Direitos Humanos - Proteção Vulneráveis</p>
      </div>

      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95fe7c93a1e1d3c75ddb4',
            'Carta das Nações Unidas - Carta da ONU - CNU'
          )
        "
      >
        <p>Carta da ONU - Incorporada pelo Decreto 26.059/1947</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95fe7c93a1e1d3c75ddb4')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5da71c97c93a1e11400fc9fd',
            'Convenção Americana sobre Direitos Humanos - Pacto de São José da Costa Rica - D 678/92'
          )
        "
      >
        <p>
          Pacto de São José da Costa Rica - Incorporado pelo Decreto 678/1992
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5da71c97c93a1e11400fc9fd')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d77a575c93a1e229cb76114',
            'Estatuto da Criança e do Adolescente - ECA - Lei Ordinária nº 8.069, de 13.07.1990 - Lei nº 8.069/1990'
          )
        "
      >
        <p>Estatuto da Criança e do Adolescente (ECA) - Lei 8.069/1990</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d77a575c93a1e229cb76114')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e7ac93a1e09bc829841',
            'Estatuto da Pessoa Idosa - Lei Ordinária nº 10.741, de 1º.10.2003 - Estatuto do Idoso - Lei nº 10.741/2003'
          )
        "
      >
        <p>Estatuto do Idoso - Lei 10.741/2003</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e7ac93a1e09bc829841')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '612f8af6e51a2c5064218107',
            'Estatuto da Pessoa com Deficiência - Lei Ordinária nº 13.146, de 06.07.2015 - Lei nº 13.146/2015'
          )
        "
      >
        <p>
          Estatuto da Pessoa com Deficiência (Lei Brasileira de Inclusão) - Lei
          13.146/2015
        </p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('612f8af6e51a2c5064218107')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5d642e24c93a1e09bc82981d',
            'Estatuto da Igualdade Racial - Lei Ordinária nº 12.288, de 20.07.2010 - Lei nº 12.288/2010'
          )
        "
      >
        <p>Estatuto da Igualdade Racial - Lei 12.288/2010</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5d642e24c93a1e09bc82981d')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc95f04c93a1e1d3c75dd9a',
            'Estatuto do Índio - Lei Ordinária nº 6.001, de 19.12.1973 - Lei nº 6.001/1973'
          )
        "
      >
        <p>Estatuto do Índio - Lei 6.001/1973</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc95f04c93a1e1d3c75dd9a')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
      <div
        mat-button
        class="menu-button"
        (click)="
          selecionar(
            '5dc02b9dc93a1e1f24d89c0b',
            'Estatuto da Juventude - Lei Ordinária nº 12.852, de 5.8.2013  - Lei nº 12.852/2013'
          )
        "
      >
        <p class="titulo-button">Estatuto da Juventude - Lei 12.852/2013</p>
        <mat-icon
          *ngIf="(listaFavoritos$ | async).includes('5dc02b9dc93a1e1f24d89c0b')"
          class="icone-favorito"
          svgIcon="star"
        ></mat-icon>
      </div>
    </div>
  </div>
</ng-template>
