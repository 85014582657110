import { BuscaPanelParameters } from "./../../leitor-content-panelbusca/busca-panel.parameters";
import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChildren,
    QueryList,
    ChangeDetectorRef,
    AfterViewChecked,
    ChangeDetectionStrategy,
} from "@angular/core";
import { Datasource } from "ngx-ui-scroll";
import {
    BehaviorSubject,
    Subscription,
    Observable,
    fromEvent,
    merge,
} from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { Conteudo } from "../../../models/pagina/conteudo";

import { SelectedElement } from "../../../helpers/selection.helper";

import { Comentario } from "../../../models/Comentario";
import { Grifo } from "../../../models/Grifo";
import { ConteudoService } from "../../../services/conteudo.service";
import { MatchBuscaTexto } from "../../../models/MatchBuscaTexto";

import { ShortcutsService } from "../../../services/shortcuts.service";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { UsuarioGrifosService } from "../../../services/data-services/usuario.grifos.service";
import { UsuarioEstatisticasService } from "../../../services/data-services/usuario.estatisticas.service";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { ParametrosCaneta, ProvaDatasource } from "../../../models/UserData";
import { EstatisticasLeitura } from "../../../models/usuario/EstatisticasLeitura";
import { TagConteudo } from "../../../interfaces/TagConteudo";
import { HoverService } from "../../../services/hover.service";
import { UsuarioComentariosService } from "../../../services/data-services/usuario.comentarios.service";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import { BuscaRapidaService } from "../../../controls/busca/form-busca-artigo-documento-atual/busca.rapida.service";

import { Referencia } from "src/app/models/Referencia";
import { UsuarioReferenciaService } from "src/app/services/data-services/usuario.referencia.service";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";
import { BuscaService } from "src/app/services/busca.service";
import { PaginaModel } from "src/app/models/PaginaModel";
import { PopoverService } from "../../popover/popover.service";
import { ItemVersaoRangeSelecao } from "src/app/models/ItemVersaoRangeSelecao";
import { UiService } from "src/app/services/ui.service";
import { SelectionTexto } from "src/app/models/SelectionTexto";
import { ModoSelecao } from "src/app/models/ModoSelecao";
import { TextoPagina } from "src/app/models/pagina/TextoPagina";
import { Selector } from "src/app/models/Selector";
import { TextoPaginaFunctions } from "src/app/models/TextoPaginaFunctions";
import { TipoSelecao } from "src/app/models/TipoSelecao";
import { SelectionService } from "src/app/services/selection.service";
import { FuncoesProva } from "src/app/models/FuncoesProva";
import { Marcacao } from "src/app/models/Marcacao";
import { PopoverDirective } from "src/app/directives/popover.directive";

@Component({
    selector: "app-pagina",
    templateUrl: "./pagina.component.html",
    styleUrls: ["./pagina.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginaComponent
implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

    @ViewChild("Pagina", { static: false }) areaSelecao: ElementRef;
    // @ViewChild("revogados") exibirRevogadosToggle: MatSlideToggle;

    // Pop Over Marcações
    @ViewChildren(PopoverDirective)
    triggersPopOver: QueryList<PopoverDirective>;

    carregando: boolean;
    estatisticas: EstatisticasLeitura;

    public exibirApenasItensComentados: boolean;
    public carregandoConteudo$: Observable<boolean>;

    public model = new PaginaModel();

    public selection: SelectionTexto;

    public conteudo: Conteudo;

    public indexPrimeiroItem: number;
    public utlimoItemFocoId: string;
    public ultimoTitulo: string;
    public busca: BuscaPanelParameters;

    public preferenciasUsuario: ConfiguracoesUsuario;
    public prefixoBuscaRapida: string;

    public exibirRevogados: boolean;

    // Parâmetros Pop Over
    public popOverAberta: boolean;
    public comentarioComentando: Comentario;

    public criarReferencia: Referencia;

    public textoComentarioComentando: string;
    public mnemonicoComentando: boolean;
    public provaMarcando: Marcacao;
    public carregandoOpcoesProva = true;
    public opcoesProva: ProvaDatasource;
    public provaNova = false;
    public funcoesProva = FuncoesProva;
    public marginLeftPopOver: number;
    public marginLeftContentPopOver: number;
    public isAdm = false;
    public isPrintAdm = false;

    public classlarguraPapel: string;

    public printing = false;

    public cursor: SafeStyle;
    opcoesCoresPonteiros: ParametrosCaneta;

    public quantidadeItensBuffer = 35;
    public linhasPaginaDataSource = new Datasource({
        get: (index, count) => {
            this.carregando = true;
            const data = new BehaviorSubject<TextoPagina[]>(null);

            if (!this.conteudo || this.conteudo.linhas.length === 0) {
                data.next(new Array<TextoPagina>());
                this.carregando = false;
                return data.asObservable();
            }

            const itens = new Array<TextoPagina>();
            // Inserir itens, ajustando apenas a primeira e ultima linha
            for (let i = index; i <= index + count - 1; i++) {
                if (i > 0 && i < this.conteudo.linhas.length - 1) {
                    itens.push(this.conteudo.linhas[i]);
                } else if (i === 0 || i === this.conteudo.linhas.length - 1) {
                    const linhaInicialJaInclusa =
            itens.findIndex(
                (linha) => linha.id === this.conteudo.linhas[i].id
            ) !== -1;
                    if (!linhaInicialJaInclusa) {
                        itens.push(this.conteudo.linhas[i]);
                    }
                }
            }

            // Enviar dados
            data.next(itens);

            this.carregando = false;
            return data.asObservable();
        },
        settings: {
            startIndex: 0,
            bufferSize: this.quantidadeItensBuffer,
        },
    });
    private subscriptions: Subscription[] = [];
    private titulosDic = {};

    private selectionChange$: Subscription = Subscription.EMPTY;

    private _bufferTextAreaComentario: HTMLTextAreaElement;
    private _bufferMnemonicoInput: HTMLSpanElement;

    constructor(
        private sanitizer: DomSanitizer,
        private conteudoService: ConteudoService,
        private selectionService: SelectionService,
        private shortcutsService: ShortcutsService,
        private hoverService: HoverService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private usuarioGrifosService: UsuarioGrifosService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioEstatisticasService: UsuarioEstatisticasService,
        private usuarioReferenciaService: UsuarioReferenciaService,
        private changeDetector: ChangeDetectorRef,
        private buscaRapidaService: BuscaRapidaService,
        private featureFlagService: FeatureFlagService,
        private buscaService: BuscaService,
        private popoverService: PopoverService,
        public uiService: UiService
    ) { }

    // Evento antes de recarregar pagina
    @HostListener("window:keypress", ["$event"])
    keyPressListener(event: KeyboardEvent) {
        if (this.shortcutsService.listenToHotkeys && event && event.keyCode) {
            switch (event.keyCode) {
                case 49:
                    console.log("toggle marcação prova");
                    break;
                case 50:
                    console.log("toggle comentário");
                    break;
                case 51:
                    console.log("toggle caneta 1");
                    break;
                case 52:
                    console.log("toggle caneta 2");
                    break;
                case 53:
                    console.log("toggle caneta 3");
                    break;
                case 54:
                    console.log("toggle caneta 4");
                    break;
                case 55:
                    console.log("toggle caneta 5");
                    break;
                case 56:
                    console.log("toggle borracha");
                    break;
                default:
                    console.log(`Atalho não utilizado: ${event.keyCode}`);
                    break;
            }
        }
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.usuarioPreferenciasService
                .getConfiguracoes()
                .subscribe((p) => this.preferenciasChanged(p))
        );
        this.subscriptions.push(
            this.conteudoService
                .getConteudo()
                .subscribe((cont) => this.conteudoSubscribe(cont))
        );

        this.subscriptions.push(
            this.conteudoService
                .getIndexFoco()
                .subscribe((i) => this.carregarConteudo(i))
        );

        this.subscriptions.push(
            this.buscaService.getBusca().subscribe((c) => this.buscaChanged(c))
        );
        this.subscriptions.push(
            this.selectionService
                .getSelection()
                .subscribe(async (sel) => this.selectionSubscribe(sel))
        );

        this.subscriptions.push(
            this.buscaRapidaService.getMatchBuscaRapida().subscribe((m) => {
                if (!m) {
                    return;
                }

                let indexFocar = this.conteudo.linhas.findIndex(
                    (linha) => linha.id === m.idOrigem
                );
                indexFocar = indexFocar - 2 > 0 ? indexFocar - 2 : 0;

                this.carregarConteudo(indexFocar);
            })
        );

        this.subscriptions.push(
            this.linhasPaginaDataSource.adapter.isLoading$
                .pipe(debounceTime(500))
                .subscribe(() => this.atualizarCabecalho())
        );

        this.subscriptions.push(
            this.linhasPaginaDataSource.adapter.firstVisible$
                .pipe(debounceTime(500))
                .subscribe((item) => this.gravarUtlimoItemFocoId(item))
        );

        this.subscriptions.push(
            this.linhasPaginaDataSource.adapter.init$
                .pipe(debounceTime(50))
                .subscribe(() => this.carregarConteudoIdentificandoFocoInicial())
        );

        this.subscriptions.push(
            this.popoverService.getState().subscribe((resp) => {
                this.popOverAberta = resp;
            })
        );

        this.carregandoConteudo$ = this.conteudoService.getCarregando();
    }

    ngAfterViewInit(): void {
        this.selectionChange$ = merge(
            fromEvent(this.areaSelecao.nativeElement, "mouseup"),
            fromEvent(this.areaSelecao.nativeElement, "touchend")
        )
            .pipe(map((select) => [select, this.areaSelecao.nativeElement]))
            .subscribe((select) => {
                this.getItemsSelecionados(select);
            });
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    preferenciasChanged(p: ConfiguracoesUsuario): void {
        if (p) {
            this.classlarguraPapel =
        "documento-conteudo" + p.preferenciasUsuario.larguraPapel;
            this.exibirRevogados = p.preferenciasUsuario.exibirItensRevogados;

            this.exibirApenasItensComentados =
        p.preferenciasUsuario.exibirApenasItensComentados;
            this.opcoesCoresPonteiros = p.parametrosCaneta;
            this.preferenciasUsuario = p;
            this.isAdm = this.featureFlagService.IsAdm(p.email);
            this.isPrintAdm = this.featureFlagService.IsPrintAdm(p.email);
        }
    }

    fecharPopOvers(commit = true) {
        this.triggersPopOver
            .filter((x) => x.popoverOpen)
            .forEach((p) => p.closePopover());
        this.popOverAberta = false;

        if (commit) {
            if (this.selection && (this.comentarioComentando || this.provaMarcando)) {
                this.salvarMarcacao();
            }

            this.comentarioComentando = null;
            this.provaMarcando = null;
            this.textoComentarioComentando = "";
            this.provaNova = false;

            this.selectionService.resetaSelector();
            this.hoverService.destacar(null);
            window.getSelection().removeAllRanges();
        }
        this.popoverService.setState(false);
    }

    public salvarMarcacao() {
        // Comentarios
        if (this.comentarioComentando) {
            if (this.comentarioComentando.texto.length > 0) {
                const reg = /\n/g;
                if (this.comentarioComentando.texto.match(reg)) {
                    const linhas = this.comentarioComentando.texto.split(reg);
                    let maiorLinha = "";
                    linhas.forEach((l) => {
                        if (l.length > maiorLinha.length) {
                            maiorLinha = l;
                        }
                    });
                    this.comentarioComentando.larguraExibicao =
            this.getWidthOfText(maiorLinha);
                } else if (this.comentarioComentando.texto.length <= 35) {
                    this.comentarioComentando.larguraExibicao = this.getWidthOfText(
                        this.comentarioComentando.texto
                    );
                }
                this.usuarioComentariosService.atualizar(this.comentarioComentando);
            }
        }
    }
    public async atualizarCabecalho() {
        if (
            this.titulosDic &&
      this.linhasPaginaDataSource &&
      this.linhasPaginaDataSource.adapter &&
      this.linhasPaginaDataSource.adapter.firstVisible
        ) {
            this.ultimoTitulo =
        this.titulosDic[
            this.linhasPaginaDataSource.adapter.firstVisible.$index
        ];
        }
    }

    editandoComentario(idLinha: string) {
        if (this.comentarioComentando) {
            const posicionamento = !this.comentarioComentando.mnemonico
                ? this.preferenciasUsuario.parametrosCaneta.posicionamentoComentario
                : this.preferenciasUsuario.parametrosCaneta.posicionamentoMnemonico;
            const index =
        posicionamento === "Acima"
            ? 0
            : this.comentarioComentando.range.idItens.length - 1;
            const idVersao = this.comentarioComentando.range.idItens[index];

            return idVersao.idItem === idLinha;
        }

        return false;
    }

    cancelarComentario() {
        this.fecharPopOvers();
    }

    salvarComentario(comentario: Comentario) {
        this.comentarioComentando = comentario;
        this.fecharPopOvers();
    }

    processarSelecaoMarcacaoComentario(mnem: boolean) {
        const item = this.conteudo.linhas.find(
            (i) => i.id === this.selection.selector.selected[0].idItem
        );
        const comentario = new Comentario();
        comentario.range.idItens = this.selection.selector.selected;
        comentario.range.inicio = this.selection.selector.start;
        comentario.range.termino = this.selection.selector.end;
        comentario.idLei = item.idLei;
        comentario.mnemonico = mnem;

        comentario.texto = "";
        this.comentarioComentando = comentario;

        // Realçar comentario
        const col = new Array<TagConteudo>();
        const tag = new TagConteudo(null, comentario);
        col.push(tag);
        this.hoverService.destacar(col);

        this.fecharPopOvers(false);
    }

    public processarSelecaoMarcaTexto() {
        const grifos = new Array<Grifo>();
        this.selection.selector.selected.forEach((id) => {
            const item = this.conteudo.linhas.find((i) => i.id === id.idItem);
            const grifo = new Grifo();

            grifo.idItem = item.id;
            grifo.idLei = item.idLei;

            switch (this.selection.tipo) {
                case TipoSelecao.Caneta1:
                    grifo.tipo = TipoSelecao.Caneta1;
                    break;
                case TipoSelecao.Caneta2:
                    grifo.tipo = TipoSelecao.Caneta2;
                    break;
                case TipoSelecao.Caneta3:
                    grifo.tipo = TipoSelecao.Caneta3;
                    break;
                case TipoSelecao.Caneta4:
                    grifo.tipo = TipoSelecao.Caneta4;
                    break;
                case TipoSelecao.Caneta5:
                    grifo.tipo = TipoSelecao.Caneta5;
                    break;
            }

            grifo.inicio =
        this.selection.selector.selected.indexOf(id) > 0
            ? -1
            : this.selection.selector.start;
            grifo.termino =
        this.selection.selector.selected.indexOf(id) <
          this.selection.selector.selected.length - 1
            ? -1
            : this.selection.selector.end;
            grifo.idImportacao = id.idImportacao;

            grifos.push(grifo);
        });

        this.usuarioGrifosService.atualizarVarios(grifos);
    }

    public processarSelecaoBorracha() {
        return new Promise(() => {
            const grifos = new Array<Grifo>();
            const ids = this.selection.selector.selected;

            const tasks = new Array<Promise<any>>();

            ids.forEach((id) => {
                tasks.push(
                    this.usuarioGrifosService
                        .buscarLinha(id.idItem, id.idImportacao)
                        .then((check) => {
                            const inicioSelecao =
                this.selection.selector.selected.indexOf(id) > 0
                    ? -1
                    : this.selection.selector.start;
                            const terminoSelecao =
                this.selection.selector.selected.indexOf(id) <
                  this.selection.selector.selected.length - 1
                    ? -1
                    : this.selection.selector.end;

                            check.forEach((grifo) => {
                                if (
                                    (inicioSelecao < grifo.inicio &&
                    terminoSelecao > grifo.inicio) ||
                  (inicioSelecao < grifo.termino &&
                    terminoSelecao > grifo.termino) ||
                  (grifo.inicio === -1 && grifo.termino === -1) ||
                  (inicioSelecao === -1 && terminoSelecao === -1)
                                ) {
                                    grifos.push(grifo);
                                }
                            });
                        })
                );
            });

            Promise.all(tasks).then(() => {
                if (grifos.length > 0) {
                    this.usuarioGrifosService.removerVarios(grifos);
                }
            });
        });
    }

    public alterarCursor(cor: string, caracter?: string) {
        const canvas = document.createElement("canvas");
        canvas.width = 60;
        canvas.height = 50;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = cor;
        ctx.arc(22, 13, 12, 0, Math.PI * 2, true);
        ctx.fill();

        ctx.fillStyle = "#000000";
        ctx.font = "15px svm-ui";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        // Verificar se é borracha ou não
        if (caracter === "f") {
            ctx.fillText("D", 4, 8);
        } else {
            ctx.fillText("A", 4, 8);
        }

        ctx.fillStyle = "#FFFFFF";
        // ctx.fillStyle = cor;
        ctx.font = "15px svm-ui";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const dataURL = canvas.toDataURL("image/png");
        this.cursor = this.sanitizer.bypassSecurityTrustStyle(
            "url(" + dataURL + "), auto"
        );
    }

    public carregarConteudoIdentificandoFocoInicial() {
        if (!this.conteudo && !this.linhasPaginaDataSource.adapter.init) {
            return;
        }

        if (
            !this.conteudo ||
      !this.conteudo?.linhas ||
      this.conteudo?.linhas?.length <= 0
        ) {
            this.carregarConteudo(0);
            return;
        }

        let itemFoco = null;

        if (
            this.conteudo.estatisticas &&
      this.conteudo.estatisticas.idUltimoItemLido
        ) {
            itemFoco = this.conteudo.linhas.findIndex(
                (l) => l.id === this.conteudo.estatisticas.idUltimoItemLido
            );
            const indexFoco = itemFoco ? itemFoco : 0;
            this.carregarConteudo(indexFoco);
        }
    }

    public carregarConteudo(index: number) {
        index = index === -1 ? 0 : index;

        this.atualizarEstatisticas();
        if (this.linhasPaginaDataSource.adapter.init) {
            this.linhasPaginaDataSource.adapter.reload(index);
        }
    }

    public gravarUtlimoItemFocoId(item): void {
        if (item && (<TextoPagina>item?.data)?.id) {
            this.indexPrimeiroItem = item.$index;
            this.utlimoItemFocoId = (<TextoPagina>item.data).id;
            if (this.indexPrimeiroItem !== 0) {
                this.gravarIdItemLeitura();
            }
            this.changeDetector.markForCheck();
        }
    }

    public gravarIdItemLeitura(): void {
        if (!this.conteudo?.idLei || !this.utlimoItemFocoId) {
            return;
        }
        this.usuarioEstatisticasService.alterarPosicaoLeitura(
            this.conteudo?.idLei,
            this.utlimoItemFocoId
        );
    }

    public toggleHoverPopOverButton(button) {
        const corFundo = button.style.color;
        const corTexto = button.style.backgroundColor;

        button.style.backgroundColor =
      corFundo === "rgb(14, 72, 117)"
          ? this.opcoesCoresPonteiros.corTagComentario
          : corFundo;

        button.style.color =
      corTexto === "rgb(233, 235, 245)" ? "#0E4875" : corTexto;
    }

    public resizeTxtAreaComentarios(targ: HTMLTextAreaElement) {
        // targ.style.cssText = 'height:auto; padding:0';
        const div = document.getElementById("div-" + targ.id);

        if (!this._bufferTextAreaComentario) {
            this._bufferTextAreaComentario = document.createElement("textarea");
            this._bufferTextAreaComentario.style.border = "none";
            this._bufferTextAreaComentario.style.height = "0";
            this._bufferTextAreaComentario.style.overflow = "hidden";
            this._bufferTextAreaComentario.style.padding = "0";
            this._bufferTextAreaComentario.style.position = "absolute";
            this._bufferTextAreaComentario.style.left = "0";
            this._bufferTextAreaComentario.style.top = "0";
            this._bufferTextAreaComentario.style.zIndex = "-1";
            document.body.appendChild(this._bufferTextAreaComentario);
        }

        const cs = window.getComputedStyle(targ);
        const pl = parseInt(cs.paddingLeft, 10);
        const pr = parseInt(cs.paddingRight, 10);
        let lh = parseInt(cs.lineHeight, 10);

        // [cs.lineHeight] may return 'normal', which means line height = font size.
        if (isNaN(lh)) {
            lh = parseInt(cs.fontSize, 10);
        }

        // Copy content width.
        this._bufferTextAreaComentario.style.width =
      targ.clientWidth - pl - pr + "px";

        // Copy text properties.
        this._bufferTextAreaComentario.style.font = cs.font;
        this._bufferTextAreaComentario.style.letterSpacing = cs.letterSpacing;
        this._bufferTextAreaComentario.style.whiteSpace = cs.whiteSpace;
        this._bufferTextAreaComentario.style.wordBreak = cs.wordBreak;
        this._bufferTextAreaComentario.style.wordSpacing = cs.wordSpacing;
        this._bufferTextAreaComentario.style.wordWrap = cs.wordWrap;

        // Copy value.
        this._bufferTextAreaComentario.value = targ.value;

        let result = Math.ceil(this._bufferTextAreaComentario.scrollHeight / lh);
        if (result === 0) {
            result = 1;
        }

        // limitar numero maximo de linhas
        if (result > 15) {
            targ.value = targ.value.substring(0, targ.value.length - 1);
            result = 15;
        }

        // Ajustar height
        if (result === 1) {
            div.style.height = 44 + "px";
        } else {
            div.style.height = 20 + 15 * result + "px";
        }
        targ.style.height = "100%";
    }

    public getWidthOfText(texto: string) {
        {
            // position: absolute !important; font-size: 10pt;height: 0 !important; overflow: hidden !important;
            this._bufferMnemonicoInput = document.createElement("span");
            this._bufferMnemonicoInput.style.border = "none";
            this._bufferMnemonicoInput.style.height = "0";
            this._bufferMnemonicoInput.style.overflow = "hidden";
            this._bufferMnemonicoInput.style.padding = "0";
            this._bufferMnemonicoInput.style.position = "absolute";
            this._bufferMnemonicoInput.style.left = "0";
            this._bufferMnemonicoInput.style.top = "0";
            this._bufferMnemonicoInput.style.zIndex = "-1";
            this._bufferMnemonicoInput.style.fontSize = "10pt";
            document.body.appendChild(this._bufferMnemonicoInput);
        }

        this._bufferMnemonicoInput.textContent = texto;

        const width = this._bufferMnemonicoInput.offsetWidth + 40;
        return width;
        // this.el.nativeElement.style.width = width.toString() + 'px';
    }

    public print(): void {
        this.printing = true;

        const originalContents = document.body.innerHTML;
        const printReport = document.getElementById("print-section").innerHTML;
        document.body.innerHTML = printReport;
        window.print();
        document.body.innerHTML = originalContents;
        this.printing = false;
    }

    public referencia() {
        const item = this.conteudo.linhas.find(
            (i) => i.id === this.selection.selector.selected[0].idItem
        );
        const referencia = new Referencia();

        referencia.links = [
            {
                idLei: item.idLei,
                idItem: item.id,
            },
        ];

        this.criarReferencia = referencia;
    }

    public salvarReferencia(referencia: Referencia) {
        this.usuarioReferenciaService.atualizar(referencia);
        this.criarReferencia = null;
        this.changeDetector.markForCheck();
    }

    public editandoReferencia(idLinha: string) {
        if (this.criarReferencia) {
            return (
                this.criarReferencia.links?.find((e) => e.idItem === idLinha)
                    ?.idItem === idLinha
            );
        }

        return false;
    }

    public copyText() {
        navigator.clipboard.writeText(window.getSelection().toString());
        this.fecharPopOvers(false);
    }

    public getItemsSelecionados(select) {
        const selection = document?.getSelection();

        if (selection && selection.toString().trim().length <= 0) {
            return;
        }

        const range =
      selection && !selection?.isCollapsed ? selection?.getRangeAt(0) : null;



        if (!range) {

        } else {
            let de = range.startOffset;
            let ate = range.endOffset;
            let ids = new Array<ItemVersaoRangeSelecao>();

            const elems = new Array<SelectedElement>();
            const allWithinRangeParent =
            document.getElementsByClassName("coluna-texto");

            for (let i = 0, el; (el = allWithinRangeParent[i]); i++) {
                if (
                    selection.containsNode(el, false) &&
              !(<HTMLElement>el).parentElement.classList.contains(
                  "texto-introducao-busca"
              )
                ) {
                    elems.push(new SelectedElement(el, false));
                } else if (
                    selection.containsNode(el, true) &&
              !(<HTMLElement>el).parentElement.classList.contains(
                  "texto-introducao-busca"
              )
                ) {
                    elems.push(new SelectedElement(el, true));
                }

                if (
                    (<HTMLElement>el).parentElement.classList.contains(
                        "texto-introducao-busca"
                    )
                ) {
                    if (i === 0) {
                        de = -1;
                    } else if (i === allWithinRangeParent.length - 1) {
                        ate = -1;
                    }
                }
            }

            ids = elems.map(function (s) {
                return {
                    idItem: (s.element as HTMLElement).id,
                    marcacaoDesatualizada: null,
                    idImportacao: Number(
                        (s.element as HTMLElement).getAttribute("data-indexVersao")
                    ),
                };
            });

            const trimLeft = (txt: string): string => {
                let txtCopy = "";
                let pular = false;
                const spaces = [10, 32];

                for (let i = 0; i < txt.length; i++) {
                    const te = txt[i];
                    if (!pular && spaces.indexOf(txt.charCodeAt(i)) === -1) {
                        pular = true;
                    }

                    if (pular) {
                        txtCopy += txt[i];
                    }
                }

                return txtCopy;
            };

            const trimRight = (txt: string): string => {
                const spaces = [10, 32];
                let retorno = "";

                let pular = false;
                for (
                    let i = txt.length - 1, c = txt.charCodeAt(i);
                    c;
                    i--, c = txt.charCodeAt(i)
                ) {
                    if (pular) {
                        retorno = txt.charAt(i) + retorno;
                    } else {
                        if (spaces.indexOf(c) === -1) {
                            retorno = txt.charAt(i) + retorno;
                            pular = true;
                        }
                    }
                }

                return retorno;
            };

            const trimWithin = (txt: string): string => {
                let retorno = txt;

                const lb = String.fromCharCode(10);
                retorno = retorno.replace(new RegExp(lb, "g"), "");

                const es = String.fromCharCode(32);
                while (retorno.indexOf(`${es}${es}`) !== -1) {
                    retorno = retorno.replace(`${es}${es}`, es);
                }

                return retorno;
            };

            const calcularStartSelecao = (element: Node) => {
                const startParentSpan = <HTMLSpanElement>(
              range.startContainer.parentNode.parentNode.parentNode
            );
                const tipoSpan = startParentSpan.className;
                let offset = range.startOffset;

                let currentAppTrechoTexto =
              range.startContainer.parentNode.parentNode;
                while (
                    currentAppTrechoTexto.previousSibling &&
              currentAppTrechoTexto.previousSibling.nodeName ===
                "APP-TRECHO-TEXTO"
                ) {
                    currentAppTrechoTexto = <Node & ParentNode>(
                (<unknown>currentAppTrechoTexto.previousSibling)
              );

                    let span = <HTMLSpanElement>currentAppTrechoTexto.firstChild;
                    if (!span.innerText) {
                        span = <HTMLSpanElement>span.nextSibling;
                    }

                    const spanLength = span.innerText.length;
                    offset += spanLength;
                }

                de = offset;
                if (tipoSpan === "texto" && startParentSpan.previousElementSibling) {
                    de += (<HTMLSpanElement>startParentSpan.previousElementSibling)
                        .innerText.length;
                }
            };

            const calcularEndSelecao = (element: Node) => {
                const endParentSpan = <HTMLSpanElement>(
              range.endContainer.parentNode.parentNode.parentNode
            );
                const tipoSpan = endParentSpan.className;
                let offset = range.endOffset - 1;

                let currentAppTrechoTexto = range.endContainer.parentNode.parentNode;
                while (
                    currentAppTrechoTexto.previousSibling &&
              currentAppTrechoTexto.previousSibling.nodeName ===
                "APP-TRECHO-TEXTO"
                ) {
                    currentAppTrechoTexto = <Node & ParentNode>(
                (<unknown>currentAppTrechoTexto.previousSibling)
              );
                    let span = <HTMLSpanElement>currentAppTrechoTexto.firstChild;

                    if (!span.innerText) {
                        span = <HTMLSpanElement>span.nextSibling;
                    }

                    const spanLength = span.innerText.length;
                    offset += spanLength;
                }

                ate = offset;
                if (tipoSpan === "texto" && endParentSpan.previousElementSibling) {
                    let span = <HTMLSpanElement>endParentSpan.previousElementSibling;

                    if (!span.innerText) {
                        span = <HTMLSpanElement>span.nextSibling;
                    }

                    ate += span.innerText.length;
                }
            };

            if (elems.length === 0) {

            } else {
                if (elems.length === 1) {
                    calcularStartSelecao(elems[0].element);
                    calcularEndSelecao(elems[0].element);
                } else {
                    elems
                        .filter((e) => e.partlySelected)
                        .forEach((selected) => {
                            const index = elems.indexOf(selected);
                            if (index === 0) {
                                calcularStartSelecao(elems[index].element);
                            } else if (index === elems.length - 1) {
                                calcularEndSelecao(elems[index].element);
                            }
                        });
                }

                const sel = new Selector();
                sel.start = de;
                sel.end = ate;
                sel.selected = ids;
                sel.selectedSourceId = ids[ids.length - 1].idItem;
                sel.clientX = select[0]?.clientX;

                this.selection.selector=sel;

                this.selectionService.atualizar(this.selection);
            }
        }
    }

    private setMarginLeftContentPopOver() {
        const popOverPai = document.querySelector(".texto");
        let m = popOverPai
            ? this.marginLeftPopOver - popOverPai.getBoundingClientRect().left - 60
            : this.marginLeftPopOver;
        m = m * -1;
        this.marginLeftContentPopOver = m;
    }
    private getMarginLeftDefaultText(): number {
        const popOverPai = document.querySelector(".texto");
        const ret = popOverPai.getBoundingClientRect().left;
        return ret;
    }

    private processarSelecao(selector: Selector): void {
        // Finalizar popovers anteriores
        this.fecharPopOvers(false);
        if (!selector) {
            return;
        }

        this.comentarioComentando = null;
        this.provaMarcando = null;

        this.selection.selector = selector;
        switch (this.selection.modo) {
            case ModoSelecao.Padrao: {
                if (this.uiService.isBreakpointMobileLg) {
                    return;
                }
                // document.execCommand('Copy');
                const trigAtual = this.triggersPopOver.find(
                    (x) =>
                        x.elementRef.nativeElement.id.indexOf(selector.selectedSourceId) !==
            -1
                );
                if (trigAtual) {
                    const def = this.getMarginLeftDefaultText();
                    this.marginLeftPopOver = selector.clientX - def;
                    this.setMarginLeftContentPopOver();
                    trigAtual.togglePopover();
                    this.popOverAberta = true;
                }
                break;
            }
            case ModoSelecao.Marcacao: {
                if (this.selection.tipo === TipoSelecao.Comentario) {
                    this.processarSelecaoMarcacaoComentario(false);
                } else {
                    this.processarSelecaoMarcacaoComentario(true);
                }
                this.selectionService.resetaSelector();
                window.getSelection().removeAllRanges();

                break;
            }
            case ModoSelecao.MarcaTexto: {
                this.processarSelecaoMarcaTexto();
                this.selectionService.resetaSelector();
                window.getSelection().removeAllRanges();

                break;
            }
        }

    }

    private selectionSubscribe(selection: SelectionTexto): void {
        if (selection) {
            this.selection = selection;

            switch (selection.tipo) {
                case TipoSelecao.Comentario:
                    this.alterarCursor(this.opcoesCoresPonteiros.corTagComentario);
                    break;
                case TipoSelecao.Mnemonico:
                    this.alterarCursor(this.opcoesCoresPonteiros.corTagMnemonico);
                    break;
                case TipoSelecao.Caneta1:
                    this.alterarCursor(this.opcoesCoresPonteiros.corCaneta1);

                    break;
                case TipoSelecao.Caneta2:
                    this.alterarCursor(this.opcoesCoresPonteiros.corCaneta2);

                    break;
                case TipoSelecao.Caneta3:
                    this.alterarCursor(this.opcoesCoresPonteiros.corCaneta3);

                    break;
                case TipoSelecao.Caneta4:
                    this.alterarCursor(this.opcoesCoresPonteiros.corCaneta4);

                    break;
                case TipoSelecao.Caneta5:
                    this.alterarCursor(this.opcoesCoresPonteiros.corCaneta5);

                    break;
                default: {
                    if (selection.modo === ModoSelecao.Borracha) {
                        this.alterarCursor("#919191", "f");
                    } else {
                        this.cursor = null;
                    }
                    break;
                }
            }
        }
        this.processarSelecao(this.selection.selector);
    }

    private conteudoSubscribe(cont: Conteudo) {
        if (!cont) {
            return;
        }

        this.conteudo = cont;

        let _ultimoTitulo;
        this.titulosDic = {};
        for (let index = 0; index < cont.linhas.length; index++) {
            if (
                cont.linhas[index].tipoTexto === "texto-tituloprincipal" ||
        cont.linhas[index].tipoTexto === "texto-parte" ||
        cont.linhas[index].tipoTexto === "texto-livro" ||
        cont.linhas[index].tipoTexto === "texto-titulo" ||
        cont.linhas[index].tipoTexto === "texto-capitulo" ||
        cont.linhas[index].tipoTexto === "texto-secao" ||
        cont.linhas[index].tipoTexto === "texto-subsecao"
            ) {
                _ultimoTitulo = TextoPaginaFunctions.getVersao(
                    cont.linhas[index]
                ).texto;
            }

            this.titulosDic[index] = _ultimoTitulo;
        }
        this.atualizarCabecalho();
        if (cont.estatisticas) {
            this.estatisticas = cont.estatisticas;
            this.atualizarEstatisticas();
        }
    }

    private buscaChanged(cont: BuscaPanelParameters) {
        // console.log('4 - Verificar se é uma busca e foi alterada')
        if (cont && !(cont === this.busca) && cont.matchResultadoBuscaFoco) {
            // console.log('4.1 - Atualizar busca e navegar para o resultado focado')
            this.busca = cont;
            this.navegarScrollMatchAtualBusca(cont.matchResultadoBuscaFoco);
        }
    }

    private atualizarEstatisticas() {
        if (this.estatisticas && this.conteudo) {
            this.conteudo.linhas.forEach((linha) => {
                linha.lida = this.estatisticas.linhasLidas.find((l) => l === linha.id)
                    ? true
                    : false;
            });
        }
    }

    private navegarScrollMatchAtualBusca(m: MatchBuscaTexto) {
        if (this.conteudo?.linhas) {
            return;
        }
        let indexFocar = this.conteudo.linhas.findIndex(
            (linha) => linha.id === m.textoItemId
        );
        indexFocar = indexFocar - 2 > 0 ? indexFocar - 2 : 0;

        this.carregarConteudo(indexFocar);
    }
}
