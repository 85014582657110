import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from "@angular/common/http";
import { LeiStorage } from "../models/lei/lei.storage";
import { CabecalhoLeiResponse } from "../arguments/lei/cabecalho.lei.response";
import { AppConfig } from "../app.config";
import {
    CompressedResult,
    CompressionHelper,
} from "../helpers/compression.helper";
import { LeiInfo } from "../models/lei/lei.info";
import { LeiConteudo } from "../models/lei/lei.conteudo";
import { LeiLookup } from "../models/lei/lei.lookup";
import { Lei } from "../models/Lei";
import { StatusService } from "../services/status.service";
import { DataControleRepositorio } from "./dataControle.repositorio";
import { EnumTipoDataControle } from "../models/dataControle";
import { IdDataLei } from "../models/lei/id.data.lei";
import { ErrorHandlerService } from "../services/errorHandler.service";
import { ErrorLogParameters } from "../models/error/errorLogParameters";
import { AuthService } from "../modules/shared/services/auth.service";

const LEI_INFO_STORE = "lei-info";
const LEI_CONTEUDO_STORE = "lei-conteudo";
const LEI_TEMP_STORE = "lei-download";

@Injectable()
export class LeiRepositorio {
    constructor(
        private dbService: NgxIndexedDBService,
        private dataControleRepositorio: DataControleRepositorio,
        private statusService: StatusService,
        private httpClient: HttpClient,
        private errorHandlerService: ErrorHandlerService,
        private auth: AuthService
    ) {}



    async atualizarLista(): Promise<void> {
        const leisOffline = await this.listarOffline();
        let leisOnline = new Array<LeiStorage>();
        if (this.statusService.isAppOnline) {
            const idLeisOfflineMaiorData = new IdDataLei();
            idLeisOfflineMaiorData.ids = leisOffline.map((lei) => lei.id);
            leisOffline.forEach((l) => {
                const dataLei = l.dataHoraUltimaModificacaoTextoLei
                    ? new Date(l.dataHoraUltimaModificacaoTextoLei)
                    : null;
                idLeisOfflineMaiorData.MaiorDataAlteracaoApp =
          dataLei && dataLei > idLeisOfflineMaiorData.MaiorDataAlteracaoApp
              ? dataLei
              : idLeisOfflineMaiorData.MaiorDataAlteracaoApp;
            });

            leisOnline = await this.listarOnline(idLeisOfflineMaiorData);
        }

        for (const lei of leisOnline) {
            const leiAtualizar = leisOffline.find((l) => l.id === lei.id);

            if (leiAtualizar) {
                if (
                    !leiAtualizar.dataHoraModificacao ||
          lei.dataHoraModificacao > leiAtualizar.dataHoraModificacao
                ) {
                    await this.salvar(lei);
                }
            } else {
                await this.salvar(lei);
            }
        }

        await this.dataControleRepositorio.salvar({
            data: new Date(),
            tipo: EnumTipoDataControle.DataVerificacaoListaLeis,
        });
    }

    async carregarLookup(): Promise<LeiLookup[]> {
        try {
            if (await this.canCheckLeis()) {
                await this.atualizarLista();
            }

            const leiStorageCollection = await this.listarOffline();
            const leis = leiStorageCollection.map((lei) =>
                LeiLookup.fromLeiStorage(lei)
            );

            return leis;
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    async carregarLeisPaginada(pagina: number): Promise<LeiLookup[]> {
        try {
            const url = `${AppConfig.apiEndpoint}/Leis/LeisCabecalhosPaginado`;
            let requestParams = new HttpParams();

            requestParams = requestParams.append("pagina", pagina.toString());
            requestParams = requestParams.append(
                "tamanhoPagina",
                AppConfig.tamanhoPagina.toString()
            );

            return await this.httpClient
                .get(url, { params: requestParams })
                .toPromise()
                .then((leisCabecalhos: LeiLookup[]) => leisCabecalhos);
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    async carregarLeisPesquisaPaginada(
        pagina: number,
        texto: string
    ): Promise<LeiLookup[]> {
        try {
            const url = `${AppConfig.apiEndpoint}/Leis/LeisCabecalhosPesquisaPaginado`;
            let requestParams = new HttpParams();

            requestParams = requestParams.append("texto", texto);
            requestParams = requestParams.append("pagina", pagina.toString());
            requestParams = requestParams.append(
                "tamanhoPagina",
                AppConfig.tamanhoPagina.toString()
            );

            return await this.httpClient
                .get(url, { params: requestParams })
                .toPromise()
                .then((leisCabecalhos: LeiLookup[]) => leisCabecalhos);
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    async carregarLeisCabecalhoFavoritos(): Promise<LeiLookup[]> {
        try {
            const url = `${AppConfig.apiEndpoint}/Leis/LeisCabecalhosFavoritos`;
            let requestParams = new HttpParams();

            requestParams = requestParams.append("usuarioId", this.auth.userID);

            return await this.httpClient
                .get(url, { params: requestParams })
                .toPromise()
                .then((leisCabecalhos: LeiLookup[]) => leisCabecalhos);
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    async carregarLei(id: string): Promise<Lei> {
        try {
            const leiStorage = await this.buscarLocal(id);
            const lei = LeiStorage.ToLei(leiStorage);

            return lei;
        } catch (err) {
            this.errorHandlerService.handleError(err);
        }
    }

    async carregarItemLookup(id: string): Promise<LeiLookup> {
        const leiStorage = await this.buscarLocal(id);

        const itemLookup = LeiLookup.fromLeiStorage(leiStorage);
        itemLookup.baixada = leiStorage.dados ? true : false;

        return itemLookup;
    }

    async baixarLei(id: string): Promise<LeiStorage> {
        try {
            const leiStorage = await this.buscarOnline(id);
            await this.salvar(leiStorage);

            return leiStorage;
        } catch (error) {
            if (
                error instanceof HttpErrorResponse &&
        (<HttpErrorResponse>error).status === 404
            ) {
                await this.removerLei(id);
                return null;
            }
            this.errorHandlerService.handleError(error);
        }
    }

    async removerLei(id: string) {
        await this.dbService.delete(LEI_INFO_STORE, id).toPromise();
        await this.dbService.delete(LEI_CONTEUDO_STORE, id).toPromise();
    }

    public async clearRepository() {
        try {


            this.dbService.clear( LEI_CONTEUDO_STORE );
            this.dbService.clear( LEI_TEMP_STORE);
            this.dbService.clear(LEI_INFO_STORE);
        } catch (err) {
            this.errorHandlerService.handleError(err);
        }
    }

    private async listarOffline(): Promise<LeiStorage[]> {
        const leis = (
            await this.dbService
                .getAll(LEI_INFO_STORE)
                .toPromise()
                .then((lei: LeiInfo[]) => lei)
        ).map((info) => LeiStorage.Load(info, null));
        const temp = await this.listarTemp();

        temp.forEach(async (t) => {
            const iLei = leis.map((l) => l.id).indexOf(t.id);
            if (iLei === -1) {
                leis.push(t);
            } else {
                leis[iLei].disponivel = true;
            }
        });
        return leis;
    }

    private async listarTemp(): Promise<LeiStorage[]> {
        let leis: LeiStorage[] = [];

        const json = localStorage.getItem(LEI_TEMP_STORE);
        if (json) {
            leis = JSON.parse(json);
        }

        return leis.map((l) => LeiStorage.Load(l, null));
    }

    private async listarOnline(idsLeisOffline: IdDataLei): Promise<LeiStorage[]> {
        const url = `${AppConfig.apiEndpoint}/leis/cabecalhos`;

        try {
            idsLeisOffline = !idsLeisOffline ? new IdDataLei() : idsLeisOffline;

            const result = <CompressedResult>(
      await this.httpClient.post(url, idsLeisOffline).toPromise()
    );

            const json = CompressionHelper.unzip(result);
            const leis = (<CabecalhoLeiResponse[]>JSON.parse(json)).map((l) =>
                CabecalhoLeiResponse.toLeiStorage(l)
            );
            return leis;
        } catch (error) {
            this.errorHandlerService.handleError(error);
        }
    }

    private async salvar(lei: LeiStorage): Promise<void> {
        try {
            await this.dbService
                .update(LEI_INFO_STORE, LeiInfo.fromStorage(lei))
                .toPromise();

            if (lei.dados) {
                await this.dbService
                    .update(LEI_CONTEUDO_STORE, LeiConteudo.fromStorage(lei))
                    .toPromise();
            }
        } catch (err) {
            this.errorHandlerService.handleError(err);
        }
    }

    private async buscarLocal(id: string): Promise<LeiStorage> {
        const leiInfo = await this.dbService
            .getByKey(LEI_INFO_STORE, id)
            .toPromise()
            .then((lei: LeiInfo) => lei);
        const leiConteudo = await this.dbService
            .getByKey(LEI_CONTEUDO_STORE, id)
            .toPromise()
            .then((lei: LeiConteudo) => lei);
        if (
            leiInfo &&
    leiConteudo &&
    leiInfo.dataHoraUltimaModificacaoTextoLei ===
      leiConteudo.dataHoraUltimaModificacaoTextoLei
        ) {
            return LeiStorage.Load(leiInfo, leiConteudo);
        } else if (this.statusService.isAppOnline) {
            const lei = await this.baixarLei(id);
            if (lei) {
                return lei;
            } else {
                return null;
            }
        }
    }

    private async buscarOnline(id: string): Promise<LeiStorage> {
        const url = `${AppConfig.apiEndpoint}/leis/conteudo/${id}`;

        try {
            const result = <CompressedResult>(
      await this.httpClient.get(url).toPromise()
    );
            const lei = <Lei>JSON.parse(CompressionHelper.unzip(result));
            const leiStorage = LeiStorage.FromLei(lei);

            return leiStorage;
        } catch (err) {
            const params = new Array<ErrorLogParameters>();
            params.push({ name: "urlEndpoint", value: url });
            this.errorHandlerService.handleError(err);
        }
    }

    private async canCheckLeis(): Promise<boolean> {
        if (!this.statusService.isAppOnline) {
            return false;
        }

        const today = new Date();
        const lastDocumentsListCheck = await this.dataControleRepositorio.buscar(
            EnumTipoDataControle.DataVerificacaoListaLeis
        );
        const outdated =
    lastDocumentsListCheck &&
    lastDocumentsListCheck.data.getDay() === today.getDay() &&
    lastDocumentsListCheck.data.getMonth() === today.getMonth() &&
    lastDocumentsListCheck.data.getFullYear() === today.getFullYear()
        ? true
        : false;

        const localDbIsEmpty =
    (await this.dbService.count(LEI_INFO_STORE).toPromise()) == 0;

        return (outdated || localDbIsEmpty) && this.statusService.isAppOnline;
    }
}
