import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    HostListener,
} from "@angular/core";
import {
    Overlay,
    OverlayRef,
} from "@angular/cdk/overlay";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { TemplatePortal } from "@angular/cdk/portal";
import { SelectionService } from "src/app/services/selection.service";
import { PopoverService } from "../components/popover/popover.service";

@Directive({
    selector: "[appPopoverTrigger]",
})
export class PopoverDirective implements OnDestroy, OnInit {
    @Input() appPopoverTrigger!: TemplateRef<object>;

    @Input() closeOnClickOutside = "false";



    private unsubscribe$ = new Subject();
    private overlayRef!: OverlayRef;
    private _popoverOpen = false;

    constructor(
        public elementRef: ElementRef,
        private overlay: Overlay,
        private vcr: ViewContainerRef,
        private popoverService: PopoverService,
        private selectionService: SelectionService,
    ) {}
    get popoverOpen(): boolean {
        return this._popoverOpen;
    }

    @HostListener("click") clickou() {
        this.togglePopover();
    }


    ngOnInit(): void {
        this.createOverlay();
        this.popoverService.getState().subscribe((resp) => {
            if (resp) {
                this.closePopover();
            }
        });
    }

    ngOnDestroy(): void {
        this.closePopover();
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }


    public closePopover(): void {
        this._popoverOpen = false;
        this.popoverService.setState(false);

        this.selectionService.resetaSelector();

        this.focusOut();
        this.detachOverlay();
    }

    public togglePopover(): void {
        return this._popoverOpen ? this.closePopover() : this.openPopover();
    }

    public  openPopover(): void {
        if (!this._popoverOpen) {
            this._popoverOpen = true;
            this.focus();
            this.attachOverlay();
        }
    }

    public focus() {
        this.elementRef.nativeElement.focus();
    }

    public  focusOut() {
        this.elementRef.nativeElement.blur();
    }

    private createOverlay(): void {
        const scrollStrategy = this.overlay.scrollStrategies.block();

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX: "start",
                    originY: "top",
                    overlayX: "start",
                    overlayY: "top",
                },
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: true,
            backdropClass: "",
        });

        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (this.closeOnClickOutside) {
                    this.closePopover();
                }
            });
    }

    private attachOverlay(): void {
        if (!this.overlayRef.hasAttached()) {
            const periodSelectorPortal = new TemplatePortal(
                this.appPopoverTrigger,
                this.vcr
            );

            this.overlayRef.attach(periodSelectorPortal);
        }
    }

    private detachOverlay(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }

}
