import { Injectable } from "@angular/core";
import { Marcacao } from "../../../models/Marcacao";
import { Observable, BehaviorSubject } from "rxjs";
import { HoverService } from "../../../services/hover.service";
import { PainelComentariosParameters } from "src/app/models/PainelComentariosParameters";
import { PainelMarcacoesProvaParameters } from "src/app/models/PainelMarcacoesProvaParameters";

@Injectable()
export class PlaceholderPaineisService {
    private painelMarcacoesProvaParameters: PainelMarcacoesProvaParameters = null;
    private painelMarcacoesProvaParameters$ =
    new BehaviorSubject<PainelMarcacoesProvaParameters>(
        this.painelMarcacoesProvaParameters
    );

    private painelComentariosParameters: PainelComentariosParameters = null;
    private painelComentariosParameters$ =
    new BehaviorSubject<PainelComentariosParameters>(
        this.painelComentariosParameters
    );

    public constructor(private hoverService: HoverService) {}

    public get visible(): boolean {
        return (
            this.painelMarcacoesProvaParameters !== null ||
      this.painelComentariosParameters !== null
        );
    }

    public abrirDetalhePainelMarcacoesProva(marcacao: Marcacao): void {
        const current = this.painelMarcacoesProvaParameters;
        current.marcacaoAtiva = marcacao;

        this.painelMarcacoesProvaParameters$.next(current);
    }

    public fecharPainelMarcacoesProva(): void {
        this.painelMarcacoesProvaParameters$.next(null);
        this.hoverService.destacar(null);
    }

    public fecharDetalhePainelMarcacoesProva(): void {
        const current = this.painelMarcacoesProvaParameters;
        current.marcacaoAtiva = null;

        this.painelMarcacoesProvaParameters$.next(current);
    }

    public fecharPainelComentarios(): void {
        this.painelComentariosParameters$.next(null);
        this.hoverService.destacar(null);
    }

    updatePainelMarcacoesProvaParameters() {
        this.painelMarcacoesProvaParameters$.next(
            this.painelMarcacoesProvaParameters
        );
    }

    getPainelMarcacoesProvaParameters(): Observable<PainelMarcacoesProvaParameters> {
        return this.painelMarcacoesProvaParameters$.asObservable();
    }
    updatePainelComentariosParameters() {
        this.painelComentariosParameters$.next(this.painelComentariosParameters);
    }

    getPainelComentariosParameters(): Observable<PainelComentariosParameters> {
        return this.painelComentariosParameters$.asObservable();
    }
}
