import { Injectable } from "@angular/core";
import { UsuarioMarcacoesService } from "./usuario.marcacoes.service";
import { UsuarioComentariosService } from "./usuario.comentarios.service";
import { UsuarioGrifosService } from "./usuario.grifos.service";
import {
    MarcacaoServiceCarregarMarcacaoResult,
    MarcacaoServiceCarregarAnotacaoResult,
} from "../../models/MarcacaoServiceCarregarMarcacaoResult";
import { UsuarioApontamentosService } from "./usuario.apontamentos.service";

@Injectable()
export class UsuarioAnotacoesService {
    constructor(
        private usuarioMarcacoesService: UsuarioMarcacoesService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioGrifosService: UsuarioGrifosService,
        private usuarioApontamentosService: UsuarioApontamentosService
    ) {}

    public BuscarAnotacoes(
        idItem: string = null,
        versao: number = null
    ): Promise<MarcacaoServiceCarregarMarcacaoResult> {
        const result = new MarcacaoServiceCarregarMarcacaoResult();
        result.idItem = idItem;

        return new Promise((onsuccess) => {
            const promises = new Array<Promise<any>>();

            promises.push(
                this.usuarioMarcacoesService
                    .buscarLinhaVersao(idItem, versao)
                    .then((marcacoes) => {
                        result.marcacoes = marcacoes;
                    })
            );

            promises.push(
                this.usuarioComentariosService
                    .buscarLinhaVersao(idItem, versao)
                    .then((comentarios) => {
                        result.comentarios = comentarios;
                    })
            );

            promises.push(
                this.usuarioGrifosService.buscarLinha(idItem, versao).then((grifos) => {
                    result.grifos = grifos;
                })
            );

            Promise.all(promises).then(() => {
                onsuccess(result);
            });
        });
    }

    public BuscarAnotacoesPorLei(
        idLei: string
    ): Promise<MarcacaoServiceCarregarMarcacaoResult> {
        const result = new MarcacaoServiceCarregarMarcacaoResult();
        result.idLei = idLei;

        return new Promise((onsuccess) => {
            const promises = new Array<Promise<any>>();

            promises.push(
                this.usuarioMarcacoesService
                    .buscarLei(idLei)
                    .then((marcacoes) => (result.marcacoes = marcacoes))
            );
            promises.push(
                this.usuarioComentariosService
                    .buscarLei(idLei)
                    .then((comentarios) => (result.comentarios = comentarios))
            );
            promises.push(
                this.usuarioGrifosService
                    .buscarLei(idLei)
                    .then((grifos) => (result.grifos = grifos))
            );

            Promise.all(promises).then(() => onsuccess(result));
        });
    }

    public BuscarAnotacoesApontamentosPorLei(
        idLei: string
    ): Promise<MarcacaoServiceCarregarAnotacaoResult> {
        const result = new MarcacaoServiceCarregarAnotacaoResult();
        result.idLei = idLei;

        return new Promise((onsuccess) => {
            const promises = new Array<Promise<any>>();

            promises.push(
                this.usuarioMarcacoesService
                    .buscarLei(idLei)
                    .then((marcacoes) => (result.marcacoes = marcacoes))
            );
            promises.push(
                this.usuarioComentariosService
                    .buscarLei(idLei)
                    .then((comentarios) => (result.comentarios = comentarios))
            );
            promises.push(
                this.usuarioGrifosService
                    .buscarLei(idLei)
                    .then((grifos) => (result.grifos = grifos))
            );
            promises.push(
                this.usuarioApontamentosService
                    .buscar(idLei)
                    .then((apontamentos) => (result.apontamentos = apontamentos))
            );

            Promise.all(promises).then(() => onsuccess(result));
        });
    }
}
