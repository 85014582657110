export class ErroPopupDataModel {
    public titulo: string;
    public mensagem: string;

    constructor() {
        this.titulo = "Erro";
        this.mensagem =
      "A operação não ocorreu como esperado. Inicie a operação novamente ou entre em contato com a equipe de suporte do Super Vade Mecum";
    }
}
