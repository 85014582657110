import { Component, OnInit, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";
import { PainelComentariosParameters } from "src/app/models/PainelComentariosParameters";
import { PainelMarcacoesProvaParameters } from "src/app/models/PainelMarcacoesProvaParameters";
import { PlaceholderPaineisService } from "./placeholder-paineis.service";

@Component({
    selector: "app-placeholder-paineis",
    templateUrl: "./placeholder-paineis.component.html",
    styleUrls: ["./placeholder-paineis.component.scss"],
})
export class PlaceholderPaineisComponent implements OnInit, OnDestroy {

    public painelMarcacoesProvaParameters: PainelMarcacoesProvaParameters;
    public painelComentariosParameters: PainelComentariosParameters;

    private s_painelMarcacoesProvaParametersObservable: Subscription;
    private s_painelComentariosParametersObservable: Subscription;

    public constructor(
        private placeholderPaineisService: PlaceholderPaineisService
    ) {}
    get visible(): boolean {
        return this.placeholderPaineisService.visible;
    }
    public ngOnInit(): void {
        this.s_painelMarcacoesProvaParametersObservable =
      this.placeholderPaineisService
          .getPainelMarcacoesProvaParameters()
          .subscribe((params) => {
              this.painelMarcacoesProvaParameters = params;
          });
        this.s_painelComentariosParametersObservable =
      this.placeholderPaineisService
          .getPainelComentariosParameters()
          .subscribe((params) => {
              this.painelComentariosParameters = params;
          });
    }

    public ngOnDestroy(): void {
        this.s_painelComentariosParametersObservable.unsubscribe();
        this.s_painelMarcacoesProvaParametersObservable.unsubscribe();
    }

    public fecharPaineis(e: Event): void {
        if (
            ((<HTMLDivElement>e.target).parentNode &&
        (<HTMLDivElement>e.target).parentNode.nodeName ===
          "APP-PLACEHOLDER-PAINEIS") ||
      ((<HTMLDivElement>e.target).parentElement &&
        (<HTMLDivElement>e.target).parentElement.id === "placeholder-paineis")
        ) {
            this.placeholderPaineisService.fecharPainelMarcacoesProva();
            this.placeholderPaineisService.fecharPainelComentarios();
        }
    }


}
