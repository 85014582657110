import { Component, Inject, OnInit } from "@angular/core";
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { ConfiguracoesUsuario } from "src/app/models/usuario/ConfiguracoesUsuario";
import { UsuarioPreferenciasService } from "src/app/services/data-services/usuario.preferencias.service";

@Component({
    selector: "app-config-dialog",
    templateUrl: "./config-dialog.component.html",
    styleUrls: ["./config-dialog.component.scss"],
})
export class ConfigDialogComponent implements OnInit {
    configuracoes$: Observable<ConfiguracoesUsuario> = of(
        new ConfiguracoesUsuario()
    );

    meusComentarios: boolean;
    itensComentados: boolean;
    comentariosSvm: boolean;
    itensRevogados: boolean;

    constructor(
        public dialogRefConfig: MatDialogRef<ConfigDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private usuarioPreferenciasService: UsuarioPreferenciasService
    ) {}
    ngOnInit(): void {
        this.configuracoes$ = this.usuarioPreferenciasService.getConfiguracoes();
    }

    onCancel(): void {
        this.dialogRefConfig.close();
    }
    onSave() {
        const config = this.usuarioPreferenciasService.getConfiguracoesvalue;

        config.preferenciasUsuario.exibirMeusComentarios =
      this.meusComentarios ?? config.preferenciasUsuario.exibirMeusComentarios;
        config.preferenciasUsuario.exibirApenasItensComentados =
      this.itensComentados ??
      config.preferenciasUsuario.exibirApenasItensComentados;
        config.preferenciasUsuario.exibirComentariosSvm =
      this.comentariosSvm ?? config.preferenciasUsuario.exibirComentariosSvm;
        config.preferenciasUsuario.exibirItensRevogados =
      this.itensRevogados ?? config.preferenciasUsuario.exibirItensRevogados;

        this.usuarioPreferenciasService.alterarConfiguracoes(config);

        this.dialogRefConfig.close();
    }
    AlteraMeusComentarios(valor: boolean) {
        this.meusComentarios = valor;
    }
    AlteraItensComentados(valor: boolean) {
        this.itensComentados = valor;
    }
    AlteraComentariosSvm(valor: boolean) {
        this.comentariosSvm = valor;
    }
    AlteraItensRevogados(valor: boolean) {
        this.itensRevogados = valor;
    }
}
