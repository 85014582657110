import { ReferenciaGerenciada } from "./ReferenciaGerenciada";
import { BaseObj } from "./UserdataSync";

export class ReferenciasGerenciadas extends BaseObj {
    idLei: string;
    dataUltimaAtualizacao: string;
    referencias: ReferenciaGerenciada[];
    constructor(referencia?: ReferenciasGerenciadas) {
        super();
        this.referencias = referencia?.referencias;
        this.dataUltimaAtualizacao = referencia?.dataUltimaAtualizacao;
        this.idLei = referencia?.idLei;
    }
}
