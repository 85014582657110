import { Routes, RouterModule } from "@angular/router";
import { LeitorComponent } from "../components/leitor/leitor.component";
import { AuthGuard, AuthGuardParams } from "../modules/shared/guards/auth.guard";
import { BrowserCompatibleGuard, BrowserNotCompatibleGuard } from "../modules/shared/guards/browser.compatibility.guards";
import { IncompatibleBrowserComponent } from "../pages/incompatible-browser/incompatible-browser.component";

const appRoutes: Routes = [
    {
        path: "auth",
        loadChildren: () => import("../modules/auth/auth.module").then(m => m.AuthModule),
        canActivate: [BrowserNotCompatibleGuard]
    },
    {
        path: "cantinho",
        redirectTo: "auth/cantinho"
    },
    {
        path: "amoodireito",
        redirectTo: "auth/amoodireito"
    },
    {
        path: "leitor", component: LeitorComponent,
        canActivate: [AuthGuard, BrowserNotCompatibleGuard],
        data: { "authGuardParams": AuthGuardParams.loggedInAndNotFirstAccess() }
    },

    {
        path: "compatibility-error",
        component: IncompatibleBrowserComponent,
        canActivate: [AuthGuard, BrowserCompatibleGuard]
    },
    {
        path: "**",
        redirectTo: "leitor"
    }
];

export const routing = RouterModule.forRoot(appRoutes);
