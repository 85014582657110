import { Injectable } from "@angular/core";
import {
    BehaviorSubject,
    fromEvent,
    Observable,
} from "rxjs";
import { map, mergeWith } from "rxjs/operators";
import { SelectionService } from "./selection.service";
import { ModoSelecao } from "../models/ModoSelecao";
import { ProgressMessageModel } from "../models/ProgressMessageModel";

@Injectable()
export class StatusService {
    private selectedTool: number = null;

    private msgStatus = "";
    private msgStatus$ = new BehaviorSubject<string>(this.msgStatus);

    private appIsOffline$ = new BehaviorSubject<boolean>(false);
    private appOffline = false;
    private appOffline$ = new BehaviorSubject<boolean>(this.appOffline);

    private message = "";
    private message$ = new BehaviorSubject<string>(this.message);

    private progressoOperacao = 0;
    private progressoOperacao$ = new BehaviorSubject<number>(
        this.progressoOperacao
    );

    private mensagensProgresso: ProgressMessageModel[] = [];
    private mensagensProgresso$ = new BehaviorSubject<ProgressMessageModel[]>(
        this.mensagensProgresso
    );

    constructor(private selectionService: SelectionService) {
        this.initialize();
        this.appIsOffline$
            .pipe(
                mergeWith(fromEvent(window, "online"), fromEvent(window, "offline"))
            )
            .pipe(map(() => navigator.onLine))
            .subscribe((status) => {
                this.appOffline = !status;
                this.updateAppOffline();
            });
    }

    public get primeiroDownloadConcluido(): boolean {
        return this.dataUltimaAtualizacao ? true : false;
    }

    public get isAppOnline(): boolean {
        return !this.appOffline;
    }

    public get dataUltimaAtualizacao(): Date {
        return <Date>JSON.parse(localStorage.getItem("DataUltimaAtualizacao"));
    }

    set dataUltimaAtualizacao(value: Date) {
        localStorage.setItem("DataUltimaAtualizacao", JSON.stringify(value));
    }

    public async testConnection(): Promise<void> {
        this.updateAppOffline();
    }



    public mostrarMensagemProgresso(mensagem: string, tarefa: string) {
        const message = new ProgressMessageModel();
        message.message = mensagem;
        message.task = tarefa;

        const pilha = this.mensagensProgresso;
        const iItemPilha = pilha.findIndex((p) => p.task === message.task);

        if (iItemPilha === -1) {
            pilha.push(message);
        } else {
            pilha[iItemPilha] = message;
        }

        this.mensagensProgresso = pilha;
        this.updateMensagensProgresso();
    }

    public ocultarMensagemProgresso(tarefa: string) {
        const pilha = this.mensagensProgresso.filter((i) => i.task !== tarefa);
        this.mensagensProgresso = pilha;
        this.updateMensagensProgresso();
    }

    public setProgressoOperacao(progresso: number = null) {
        this.progressoOperacao = progresso;
        this.updateProgressoOperacao();
    }

    public setMessage(message: string = null) {
        this.message = message;
        this.updateMessage();
    }


    updateAppOffline() {
        this.appOffline$.next(this.appOffline);
    }

    public getAppOffline(): Observable<boolean> {
        return this.appOffline$.asObservable();
    }
    updateMessage() {
        this.message$.next(this.message);
    }
    public getMessage(): Observable<string> {
        return this.message$.asObservable();
    }
    updateMsgStatus() {
        this.msgStatus$.next(this.msgStatus);
    }

    getMsgStatus(): Observable<string> {
        return this.msgStatus$.asObservable();
    }
    public updateProgressoOperacao() {
        this.progressoOperacao$.next(this.progressoOperacao);
    }

    public getProgressoOperacao(): Observable<number> {
        return this.progressoOperacao$.asObservable();
    }
    public updateMensagensProgresso() {
        this.mensagensProgresso$.next(this.mensagensProgresso);
    }

    public getMensagensProgresso(): Observable<ProgressMessageModel[]> {
        return this.mensagensProgresso$.asObservable();
    }

    public voidStatus() {
        this.msgStatus = this.getToolDescription(this.selectedTool);
        this.updateMsgStatus();
    }



    public setStatusMessage(message: string, timeout: boolean = false) {
        this.msgStatus = message;
        this.updateMsgStatus();

        if (timeout) {
            this.voidStatus();
        }
    }
    private getToolDescription(modo: ModoSelecao): string {
        switch (modo) {
            case ModoSelecao.Padrao:
                return "Modo de seleção";
            case ModoSelecao.Marcacao:
                return "Modo marcação";
            case ModoSelecao.MarcaTexto:
                return "Modo marca-texto";
            case ModoSelecao.Borracha:
                return "Modo borracha";
        }
    }

    private initialize() {
        this.selectionService.getSelection().subscribe((sel) => {
            if (sel) {
                this.selectedTool = sel.modo;
            }
            this.voidStatus();
        });
    }

}

