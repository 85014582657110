import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConteudoService } from "../../services/conteudo.service";
import { Conteudo } from "../../models/pagina/conteudo";
import { BuscaService } from "../../services/busca.service";
import { Observable, of, Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { BuscaPanelParameters } from "../leitor-content-panelbusca/busca-panel.parameters";
import { OpcaoToolbar } from "src/app/models/opcaoToolbar";
import { UiService } from "src/app/services/ui.service";
import { SelectionTexto } from "src/app/models/SelectionTexto";
import { SelectionService } from "src/app/services/selection.service";
import { ModoSelecao } from "src/app/models/ModoSelecao";

@Component({
    selector: "app-leitor-side-menu",
    templateUrl: "./leitor-side-menu.component.html",
    styleUrls: ["./leitor-side-menu.component.scss"],
})
export class LeitorSideMenuComponent implements OnInit, OnDestroy {
    public visivel = false;
    public opcoes: OpcaoToolbar[];
    public opcoesExibir: OpcaoToolbar[];
    public opcaoSelecionada: OpcaoToolbar;
    public selection: SelectionTexto;
    public painelBuscaAberto = false;
    public breakpointMobileLg$: Observable<boolean> = of(false);
    public modoSelecao: boolean;
    public ferramentaSelecao = new OpcaoToolbar();
    public ferramentaBorracha = new OpcaoToolbar();
    public buscaSelecao = new OpcaoToolbar();

    private subscriptions: Subscription[] = [];

    constructor(
        private conteudoService: ConteudoService,
        private buscaService: BuscaService,
        private selectionService: SelectionService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        public uiService: UiService
    ) {}

    ngOnInit() {

        this.buscaSelecao.descricao = "Busca";
        this.buscaSelecao.modo = ModoSelecao.Padrao;
        this.buscaSelecao.tipo = null;
        this.buscaSelecao.icone = "search";
        this.buscaSelecao.ativo = this.painelBuscaAberto;
        this.buscaSelecao.cor = "#525F7A";

        this.ferramentaSelecao.descricao = "Modo de seleção";
        this.ferramentaSelecao.modo = ModoSelecao.Padrao;
        this.ferramentaSelecao.tipo = null;
        this.ferramentaSelecao.icone = "arrow-pointer";
        this.ferramentaSelecao.ativo = false;
        this.ferramentaSelecao.cor = "#525F7A";

        this.ferramentaBorracha.descricao = "Borracha";
        this.ferramentaBorracha.modo = ModoSelecao.Borracha;
        this.ferramentaBorracha.tipo = null;
        this.ferramentaBorracha.icone = "eraser";
        this.ferramentaBorracha.cor = "#525F7A";

        this.subscriptions.push(
            this.conteudoService
                .getConteudo()
                .subscribe((c) => this.conteudoSubscribe(c))
        );

        this.subscriptions.push(
            this.usuarioPreferenciasService
                .getConfiguracoes()
                .subscribe((config) => this.configSubscribe(config))
        );

        this.subscriptions.push(
            this.selectionService.getSelection().subscribe((s) => {
                this.modoSelecao = s.modo === ModoSelecao.Padrao;
            })
        );

        this.subscriptions.push(
            this.buscaService.getBusca().subscribe((c) => this.buscaChanged(c))
        );
        this.breakpointMobileLg$ = this.uiService.getBreakpointMobileLg();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }


    public async btnBuscaClick() {
        this.opcaoSelecionada.ativo = false;
        this.opcaoSelecionada = this.ferramentaSelecao;

        this.selection.modo = this.opcaoSelecionada.modo;
        this.selection.tipo = this.opcaoSelecionada.tipo;
        this.selectionService.atualizar(this.selection);

        if (!this.painelBuscaAberto) {
            this.buscaService.abrirPainelBusca(null);
        } else {
            await this.buscaService.fecharPainelBusca(true);
        }
    }

    public toggle(opcao: OpcaoToolbar): void {
        this.opcaoSelecionada.ativo = false;
        if (opcao === this.opcaoSelecionada) {
            this.opcaoSelecionada = this.ferramentaSelecao;
        } else {
            this.opcaoSelecionada = opcao;
        }
        this.opcaoSelecionada.ativo = true;

        this.selection.modo = this.opcaoSelecionada.modo;
        this.selection.tipo = this.opcaoSelecionada.tipo;
        this.selectionService.atualizar(this.selection);
    }

    public toggleSelecao() {
        this.opcaoSelecionada = null;

        this.selection.modo = ModoSelecao.Padrao;
        this.selection.tipo = null;
        this.selectionService.atualizar(this.selection);
    }

    private buscaChanged(busca: BuscaPanelParameters): void {
        this.painelBuscaAberto = busca ? true : false;
    }

    private conteudoSubscribe(conteudo: Conteudo): void {
        if (conteudo) {
            this.visivel =
      conteudo.linhas &&
      conteudo.linhas.length > 0 &&
      (conteudo.idLei ? true : false);
        }
    }

    private configSubscribe(config: ConfiguracoesUsuario) {
        if (config) {
            this.opcoes = OpcaoToolbar.carregarItensMenu(config.parametrosCaneta);
            // .filter((o) => o.toolbar);
            // this.opcoesExibir = this.opcoes.filter(o => o.descricao !== 'Seleção');
            this.opcoesExibir = this.opcoes;

            if (!this.opcaoSelecionada) {
                this.opcaoSelecionada = this.ferramentaSelecao;
                this.opcaoSelecionada.ativo = true;

                this.selection = new SelectionTexto();
                this.selection.modo = this.opcaoSelecionada.modo;
                this.selection.tipo = this.opcaoSelecionada.tipo;

                this.selectionService.atualizar(this.selection);
            } else {
                const opcao = this.opcoes.find(
                    (o) =>
                        o.modo === this.opcaoSelecionada.modo &&
          o.tipo === this.opcaoSelecionada.tipo
                );

                this.opcaoSelecionada = opcao ?? this.ferramentaSelecao;
            }
        }
    }

}
