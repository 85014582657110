import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ProgressMessageModel } from "src/app/models/ProgressMessageModel";
import { StatusService } from "src/app/services/status.service";

@Component({
    selector: "app-popup-carregamento",
    templateUrl: "./popup-carregamento.component.html",
    styleUrls: ["./popup-carregamento.component.scss"]
})
export class PopupCarregamentoComponent implements OnInit, OnDestroy {
    public status: string;
    public progresso: number;
    private pilha: ProgressMessageModel[];
    private message_subscription: Subscription;
    private progresso_subscription: Subscription;

    constructor(
        private statusService: StatusService) { }

    ngOnInit(): void {
        this.message_subscription = this.statusService.getMensagensProgresso().subscribe(_pilha => {
            this.pilha = _pilha;
            this.atualizar();
        });
        this.progresso_subscription = this.statusService.getProgressoOperacao().subscribe(progresso => this.progresso = progresso);
    }

    atualizar() {
        if (this.pilha && this.pilha.length > 0) {
            this.status = this.pilha[this.pilha.length - 1].message;
        } else {
            this.status = null;
        }
    }

    ngOnDestroy(): void {
        this.message_subscription.unsubscribe();
        this.progresso_subscription.unsubscribe();
    }
}
