import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/app.config";
import { ErrorHandlerService } from "../errorHandler.service";
import { ReferenciasGerenciadas } from "src/app/models/ReferenciasGerenciadas";
import { StorageHelper } from "src/app/helpers/storage.helper";

@Injectable()
export class UsuarioReferenciasGerenciadasService {
    readonly REFERENCIA_GERENCIADA_CONTEUDO_STORE =
    "referencias-gereciada-conteudo";
    readonly DATABASE_NAME = "svdm";

    constructor(
        private httpClient: HttpClient,
        private errorHandlerService: ErrorHandlerService
    ) {}

    public async carregarReferenciasGerenciadasPorLei(
        leiId: string
    ): Promise<ReferenciasGerenciadas> {
        const url = `${AppConfig.apiEndpoint}/Referencias/ReferenciasGerenciadas`;
        let requestParams = new HttpParams();
        let referencia;
        const referencialocal: ReferenciasGerenciadas =
      await StorageHelper.getByKey<ReferenciasGerenciadas>(
          leiId,
          this.DATABASE_NAME,
          this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
      ).then((referencia: ReferenciasGerenciadas) => referencia);
        requestParams = requestParams.append("IdLei", leiId);
        requestParams = requestParams.append(
            "DataUltimaAtualizacao",
            referencia ? referencia.dataUltimaAtualizacao : new Date(0).toISOString()
        );
        await this.httpClient
            .get(url, { params: requestParams })
            .toPromise()
            .then((result: ReferenciasGerenciadas) => {
                if (result) {
                    referencia = new ReferenciasGerenciadas(result);
                    this.salvarReferenciasGerenciadasLocal(referencia).catch((err) => {
                        onerror(err);
                    });
                    return referencia;
                }
            })
            .catch((err) => {
                this.errorHandlerService.handleError(err);
                if (referencialocal) {
                    return referencialocal;
                }
            });

        return new ReferenciasGerenciadas({
            id: "",
            dataHoraModificacao: null,
            removido: false,
            idLei: "",
            dataUltimaAtualizacao: "",
            referencias: [],
        });
    }

    public async salvarReferenciasGerenciadasLocal(
        referencia: ReferenciasGerenciadas
    ): Promise<void> {
        await StorageHelper.upsert<ReferenciasGerenciadas>(
            referencia,
            this.DATABASE_NAME,
            this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
        ).catch((err) => onerror(err));
    }

    public async buscarReferenciasGerenciadasLocal(): Promise<
    ReferenciasGerenciadas[]
    > {
        const referencias = await StorageHelper.list<ReferenciasGerenciadas>(
            this.DATABASE_NAME,
            this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
        )
            .then((referencias: ReferenciasGerenciadas[]) => referencias)
            .catch((err) => {
                onerror(err);
            });
        if (!referencias || referencias.length <= 0) {
            return [];
        } else {
            return referencias;
        }
    }

    public async buscarReferenciasGerenciadasLocalById(
        leiId: string
    ): Promise<ReferenciasGerenciadas> {
        if (!leiId) {
            return new ReferenciasGerenciadas({
                id: "",
                dataHoraModificacao: null,
                removido: false,
                idLei: "",
                dataUltimaAtualizacao: "",
                referencias: [],
            });
        }

        const referencia = await StorageHelper.getByKey<ReferenciasGerenciadas>(
            leiId,
            this.DATABASE_NAME,
            this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
        )
            .then((referencia: ReferenciasGerenciadas) => referencia)
            .catch((err) => {
                onerror(err);
            });
        if (!referencia) {
            return new ReferenciasGerenciadas({
                id: "",
                dataHoraModificacao: null,
                removido: false,
                idLei: "",
                dataUltimaAtualizacao: "",
                referencias: [],
            });
        } else {
            return referencia;
        }
    }
}
