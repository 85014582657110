import { ConfiguracoesUsuario } from "./usuario/ConfiguracoesUsuario";

export class IdentificacaoModel {
    public nome: string;
    public sobrenome: string;
    public foto: string;
    private nomeOriginal: string;
    private sobrenomeOriginal: string;
    private fotoOriginal: string;

    constructor(user: ConfiguracoesUsuario) {
        this.nomeOriginal = user.nome;
        this.nome = user.nome;

        this.sobrenomeOriginal = user.sobrenome;
        this.sobrenome = user.sobrenome;

        this.fotoOriginal = user.foto;
        this.foto = user.foto;
    }

    public get alterado(): boolean {
        return this.nome !== this.nomeOriginal ||
    this.sobrenome !== this.sobrenomeOriginal ||
    this.foto !== this.fotoOriginal
            ? true
            : false;
    }

    public get valido(): boolean {
        return this.nome &&
    this.nome.length > 0 &&
    this.sobrenome &&
    this.sobrenome.length > 0
            ? true
            : false;
    }
}
