import { Component, OnInit, OnDestroy } from "@angular/core";
import { LeitorParameters } from "../../models/leitor.parameters";
import { Observable, of, Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { UiService } from "src/app/services/ui.service";
import { Tema, TipoFundo } from "src/app/models/tema";

@Component({
    selector: "app-leitor",
    templateUrl: "./leitor.component.html",
    styleUrls: ["./leitor.component.scss"],
})
export class LeitorComponent implements OnInit, OnDestroy {

    public params: LeitorParameters;
    public configuracoes: ConfiguracoesUsuario;

    public backgroundImage: string;
    public backgroundColor: string;
    public foreground: string;
    public breakpointMobileLg$: Observable<boolean> = of(false);
    private subscriptions: Subscription[] = [];

    constructor(
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private uiService: UiService
    ) {
        this.params = new LeitorParameters();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.usuarioPreferenciasService
                .getConfiguracoes()
                .subscribe((c) => (this.configuracoes = c))
        );
        this.subscriptions.push(
            this.uiService.getTema().subscribe((t) => this.temaChanged(t))
        );

        this.breakpointMobileLg$ = this.uiService.getBreakpointMobileLg();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    private temaChanged(t: Tema): void {
        this.backgroundImage =
      t && t.tipoFundo === TipoFundo.Imagem ? t.background : "";
        this.backgroundColor =
      t && t.tipoFundo === TipoFundo.Cor ? t.background : "";
        this.foreground = t ? t.foreground : "";
    }
}
