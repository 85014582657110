import { Conteudo } from "../models/pagina/conteudo";

export class EntitiesHelper {
    static GenerateToken(): number {
        return new Date().getTime();
    }

    static HashOf(obj: any): string {
        const json = JSON.stringify(obj);
        return btoa(json);
    }

    static FromHash<T>(encoded: string): T {
        const json = atob(encoded);
        return JSON.parse(json) as T;
    }

    static Copy<T>(obj: T): T {
        const json = JSON.stringify(obj);
        return <T>JSON.parse(json);
    }

    static generateGuid(): string {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }

    static equals(a: any, b: any): boolean {
        if (!a && !b) {
            return true;
        }

        return JSON.stringify(a) === JSON.stringify(b);
    }
}
