import { Component } from "@angular/core";
import { TextFormatingService } from "../../../services/ui/textFormating.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-controle-fonte",
    templateUrl: "./controle-fonte.component.html",
    styleUrls: ["./controle-fonte.component.scss"],
})
export class ControleFonteComponent  {
    podeAumentar: Observable<boolean>;
    podeDiminuir: Observable<boolean>;

    constructor(private textFormatingService: TextFormatingService) {
        this.podeAumentar = textFormatingService.getPodeAumentar();
        this.podeDiminuir = textFormatingService.getPodeDiminuir();
    }

    diminuirFonte() {
        this.textFormatingService.decreaseFontSize();
    }

    aumentarFonte() {
        this.textFormatingService.increaseFontSize();
    }
}
