import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { UserdataDTO } from "../../models/UserdataSync";
import {
    SignalrService,
    OperacaoSignalR,
    EnumTipoObjeto,
} from "../signalr.service";
import { AppConfig } from "src/app/app.config";
import { HttpParams, HttpClient } from "@angular/common/http";
import { ErrorHandlerService } from "../errorHandler.service";

@Injectable()
export class UsuarioPreferenciasService {
    private readonly key = "config";

    private configuracoes: ConfiguracoesUsuario = this.carregarConfiguracoes();
    private configuracoes$ = new BehaviorSubject<ConfiguracoesUsuario>(
        this.configuracoes
    );

    constructor(
        private signalrService: SignalrService,
        private errorHandlerService: ErrorHandlerService,
        private httpClient: HttpClient
    ) {
        signalrService
            .getMensagem()
            .subscribe((msg) => this.onMessageReceived(msg));
    }



    public get getConfiguracoesvalue(): ConfiguracoesUsuario {
        return this.configuracoes;
    }


    public alterarConfiguracoes(
        config: ConfiguracoesUsuario,
        audit = true
    ): void {
        if (audit) {
            config.dataUltimaAlteracao = new Date();
        }

        this.setConfiguracoes(config, true);
    }

    public fromNuvem(userdata: UserdataDTO) {

        const model = ConfiguracoesUsuario.fromModel(userdata);
        this.setConfiguracoes(model, false);
    }

    public resetarPreferenciasUsuario(){

        const url = `${AppConfig.apiEndpoint}/DadosCadastroUsuario/ResetarConfiguracoesUsuario`;
        let requestParams = new HttpParams();

        requestParams = requestParams.append("usuarioId", this.configuracoes.id);
        this.httpClient
            .get(url, { params: requestParams })
            .toPromise()
            .then((result: ConfiguracoesUsuario) => {
                if (result) {
                    const prefe = Object.assign(this.configuracoes, result);
                    this.setConfiguracoes(prefe, false);
                    return(prefe);
                }
            })
            .catch((error) => this.errorHandlerService.handleError(error));

    }

    public updateConfiguracoes() {
        this.configuracoes$.next(this.configuracoes);
    }

    public getConfiguracoes(): Observable<ConfiguracoesUsuario> {
        return this.configuracoes$.asObservable();
    }
    private onMessageReceived(msg: OperacaoSignalR): void {
        if (msg && msg.tipoObjeto === EnumTipoObjeto.Preferencias) {
            this.setConfiguracoes(msg.dados, false);
        }
    }

    private carregarConfiguracoes(): ConfiguracoesUsuario {
        let retorno = null;

        const json = localStorage.getItem(this.key);
        if (json) {
            retorno = JSON.parse(json);
        }

        return retorno;
    }

    private setConfiguracoes(value: ConfiguracoesUsuario, sync = true) {
        const json = JSON.stringify(value);
        localStorage.setItem(this.key, json);

        this.configuracoes = value;
        this.updateConfiguracoes();

        if (sync) {
            const mensagem = new OperacaoSignalR();
            mensagem.dados = value;
            this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Preferencias);
        }
    }

}
