<mat-toolbar class="toolbar-component">
  <mat-toolbar-row class="toolbar-component-row">
    <div
      class="toolbar-logo-container"
      *ngIf="!(exibirNavegacaoPesquisaMobile$ | async)"
    >
      <mat-icon
        class="logo"
        [svgIcon]="(larguraTela$ | async) > 744 ? 'logo-lg' : 'logo-md'"
      ></mat-icon>
    </div>
    <div class="toolbar-spacer"></div>

    <app-navegacao-pesquisa-leis
      *ngIf="!(mobile$ | async) || (exibirNavegacaoPesquisaMobile$ | async)"
      class="app-navegacao-container"
    ></app-navegacao-pesquisa-leis>

    <div class="toolbar-spacer"></div>

    <div
      class="toolbar-icons-container"
      *ngIf="!(exibirNavegacaoPesquisaMobile$ | async)"
    >
      <button
        mat-icon-button
        *ngIf="(mobile$ | async) && !(exibirNavegacaoPesquisaMobile$ | async)"
        (click)="exibirPesquisaMobileClick()"
        class="navbar-button-icon navbar-icon-pesquisa"
      >
        <mat-icon matTooltip="Pesquise em todas as leis"> search </mat-icon>
      </button>

      <app-indicador-trial class="flex-row center-center"></app-indicador-trial>

      <button
        mat-icon-button
        matTooltip="Uma nova versão está disponível, clique aqui para atualizar"
        class="navbar-button-icon navbar-icon-atualizar"
        (click)="atualizarApp()"
        *ngIf="updateAvailable$ | async"
      >
        <mat-icon svgIcon="clock-rotate-left"></mat-icon>
      </button>

      <button
        mat-icon-button
        (click)="btnFeedback_click()"
        class="navbar-button-icon navbar-icon-helper"
        *ngIf="!(appOffline$ | async)"
      >
        <mat-icon
          svgIcon="question-circle"
          matTooltip="Dê seu feedback e nos ajude a melhorar"
        >
        </mat-icon>
      </button>

      <app-historico-atualizacoes
        matTooltip="Histórico atualizações"
        class="flex-row center-center"
      ></app-historico-atualizacoes>

      <div class="user-menu flex-row center-center">
        <a
          matTooltip="Meu perfil"
          class="flex-row center-center"
          [matMenuTriggerFor]="menu"
        >
          <ng-container *ngIf="false; else Empty">
            <img src="{{ model.user_foto }}" class="profile-photo" />
          </ng-container>
        </a>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="toolbar-component-second">
  <mat-toolbar-row class="toolbar-component-row-second">
    <app-navegacao-leis-ramos-direito class="navegacao-leis-ramos">
    </app-navegacao-leis-ramos-direito>
    <app-navegacao-favoritos class="navegacao-leis-favorito">
    </app-navegacao-favoritos>
  </mat-toolbar-row>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="lnkPerfil_click($event)"
      *ngIf="!(appOffline$ | async)"
    >
      <span>Meu perfil</span>
      <mat-icon>person</mat-icon>
    </button>
    <button mat-menu-item (click)="lnkPreferencias_click($event)">
      <span>Preferências</span>
      <mat-icon>settings</mat-icon>
    </button>
    <button *ngIf="exibirInstalacao" mat-menu-item (click)="addToHomeScreen()">
      <span>Instalar no Desktop</span>
      <mat-icon>system_update_alt</mat-icon>
    </button>
    <button mat-menu-item (click)="logoff()" *ngIf="!(appOffline$ | async)">
      <span>Sair</span>
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </mat-menu>
</mat-toolbar>

<ng-template #Empty>
  <mat-icon
    svgIcon="user-empty"
    class="material-symbols-outlined profile-photo"
  ></mat-icon>
</ng-template>
