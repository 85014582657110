<div *ngIf="editandoTag; else readonly">
  <mat-form-field
    appearance="standard"
    class="chip-list comentario-tags comentario-tags-bottom"
  >
    <mat-label class="comentario-tags">Tags do comentário</mat-label>
    <mat-chip-list #chipList aria-label="tag selection" class="comentario-tags">
      <mat-chip
        *ngFor="let tag of tagArray"
        [removable]="removable"
        (removed)="remove(tag)"
        class="chip-tags comentario-tags"
        backgroundContrast
        [backgroundColor]="color"
        (blur)="onblur($event)"
      >
        {{ tag }}
        <mat-icon
          class="chip-icon-tags comentario-tags"
          backgroundContrast
          [backgroundColor]="color"
          matChipRemove
          *ngIf="removable"
          >cancel</mat-icon
        >
      </mat-chip>
      <input
        maxlength="50"
        placeholder="Insira a Tag"
        #tagInput
        class="comentario-tags"
        (blur)="onblur($event)"
        [formControl]="tagsCtrl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
  </mat-form-field>
</div>
<ng-template #readonly>
  <mat-form-field class="chip-list comentario-tags-bottom">
    <mat-label>Tags do comentário</mat-label>
    <mat-chip-list #chipList aria-label="tag selection">
      <mat-chip
        class="chip-tags"
        *ngFor="let tag of tagArray"
        backgroundContrast
        [backgroundColor]="color"
      >
        {{ tag }}
      </mat-chip>
    </mat-chip-list>
  </mat-form-field>
</ng-template>
