import { RangeSelecao } from "./RangeSelecao";
import { EntitiesHelper } from "../helpers/entities.helper";
import { BaseObj } from "./UserdataSync";
import { TipoSelecao } from "./TipoSelecao";

export class Comentario extends BaseObj {
    id: string;
    idConjunto: string;
    idLei: string;
    texto: string;
    range: RangeSelecao;
    mnemonico = false;
    tipo: TipoSelecao;
    alturaExibicao: number;
    larguraExibicao: number;
    tags: string[] = [];
    constructor() {
        super();

        this.id = EntitiesHelper.generateGuid();
        this.range = new RangeSelecao();
        this.tipo = TipoSelecao.Comentario;
    }
}
