import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { Tempo } from "../../../models/usuario/EstatisticasLeitura";

@Component({
    selector: "app-indicador-leitura",
    templateUrl: "./indicador-leitura.component.html",
    styleUrls: ["./indicador-leitura.component.scss"],
})
export class IndicadorLeituraComponent  {
    @Input() progresso: number;
    @Input() tempoRestante: Tempo;

    public math = Math;

    public constructor() {}

    public get horas(): number {
        return this.tempoRestante.horas || 0;
    }

    public get minutos(): number {
        return this.tempoRestante.minutos || 0;
    }

    public get segundos(): number {
        return this.tempoRestante.segundos || 0;
    }



}
