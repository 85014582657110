import {
    Observable,
    BehaviorSubject,
} from "rxjs";

import { switchMap, take, filter, catchError } from "rxjs/operators";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UsuarioPreferenciasService } from "../services/data-services/usuario.preferencias.service";
import { AuthService } from "../modules/shared/services/auth.service";
import { ErrorHandlerService } from "../services/errorHandler.service";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshToken = null;
    private refreshToken$ = new BehaviorSubject<any>(this.refreshToken);

    constructor(
        public auth: AuthService,
        public userDataService: UsuarioPreferenciasService,
        private errorHandlerService: ErrorHandlerService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            request.url.indexOf("svm-log-api/api") > 0 ||
      (request.url.indexOf("api.supervademecum.com.br") === -1 &&
        request.url.indexOf("localhost") === -1)
        ) {
            return next.handle(request);
        }

        return next.handle(this.addAuthenticationToken(request)).pipe(
            catchError((error) => {
                if (
                    request.url.includes("refresh-token") ||
          request.url.includes("login")
                ) {
                    if (request.url.includes("refresh-token")) {
                        this.auth.logoff().then().catch((erro)=>this.errorHandlerService.handleError(erro));
                    }

                    this.errorHandlerService.handleError(error);
                }

                if (error.status !== 401) {
                    this.errorHandlerService.handleError(error);
                }

                if (this.refreshTokenInProgress) {
                    return this.refreshToken$.pipe(
                        filter((r) => r != null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                } else {
                    this.refreshTokenInProgress = true;
                    this.refreshToken = null;
                    this.updateRefreshToken();

                    const data = this.userDataService.getConfiguracoesvalue;
                    return this.auth.refreshToken(data.email, data.authToken).pipe(
                        switchMap((token: string) => {
                            this.refreshTokenInProgress = false;
                            this.refreshToken = token;
                            this.updateRefreshToken();

                            return next.handle(this.addAuthenticationToken(request));
                        }),
                        catchError((error: any) => {
                            this.refreshTokenInProgress = false;
                            this.errorHandlerService.handleError(error);
                            throw new Error(error?.message);
                        })
                    );
                }
            })
        );
    }

    addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = this.auth.accessToken();

        if (!token) {
            return request;
        }

        const ret = request.clone({
            setHeaders: { Authorization: "bearer " + token.token },
        });
        return ret;
    }
    updateRefreshToken() {
        this.refreshToken$.next(this.refreshToken);
    }

    getRefreshToken(): Observable<boolean> {
        return this.refreshToken$.asObservable();
    }
}
