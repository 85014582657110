export const versoes = [
    {
        numero: "1.15.4",
        alteracoes: ["Correções do Feedback"],
    },
    {
        numero: "1.15.3",
        alteracoes: [" Melhorias de performance em marcações "],
    },
    {
        numero: "1.15.2",
        alteracoes: ["Melhorias em cadastro de referências"],
    },
    {
        numero: "1.15.1",
        alteracoes: ["Correção em alteração de meio de pagamento"],
    },
    {
        numero: "1.15.0",
        alteracoes: ["Melhorias de performance"],
    },
    {
        numero: "1.14.3",
        alteracoes: ["Correções em histórico de atualizações"],
    },
    {
        numero: "1.14.2",
        alteracoes: ["Correções e melhorias em referências"],
    },
    {
        numero: "1.14",
        alteracoes: ["Adicionado painel de novidades"],
    },
    {
        numero: "1.13",
        alteracoes: [
            "Melhorias em referencias",
            "Melhorias em logs",
            "Correções em login",
        ],
    },
    {
        numero: "1.12",
        alteracoes: ["Adicionado cadastro por link de parceiros"],
    },
    {
        numero: "1.11",
        alteracoes: [
            "Recurso de mnemônicos convertido em títulos",
            "Recuros de provas desativado",
        ],
    },
    {
        numero: "1.10.1.1",
        alteracoes: ["Correção em exibição de dicas de estudo"],
    },
    {
        numero: "1.10.1",
        alteracoes: ["Adicionada funcionalidade de reset de configurações"],
    },
    {
        numero: "1.10",
        alteracoes: ["Adicionadas referencias gerenciadas SVM"],
    },
    {
        numero: "1.9.2",
        alteracoes: ["Adicionado funcionalidade de copiar texto"],
    },
    {
        numero: "1.9.1",
        alteracoes: ["Correções na funcionalidade de busca"],
    },
    {
        numero: "1.9",
        alteracoes: ["Adicionada funcionalidade de Referências"],
    },
    {
        numero: "1.8",
        alteracoes: ["Adicionados comentários SVM em constituição federal"],
    },
    {
        numero: "1.7.5",
        alteracoes: ["Adicionada funcionalidade de busca por proximidade"],
    },
    {
        numero: "1.7.4",
        alteracoes: ["Melhorias de usabilidade em busca"],
    },
    {
        numero: "1.7.3.1",
        alteracoes: ["Tags de comentário com cores personalizáveis"],
    },
    {
        numero: "1.7.3",
        alteracoes: ["Adicionada funcionalidade de tags em comentários"],
    },
    {
        numero: "1.7.2.5",
        alteracoes: [
            "Melhorias em métricas de leitura indicado (documento em processo de atualização)",
        ],
    },
    {
        numero: "1.7.2.4",
        alteracoes: [
            "Melhorias em métricas de leitura (Disponível em \"Menu\" >\"Preferências\" > \"Avançado\")",
        ],
    },
    {
        numero: "1.7.2.3",
        alteracoes: ["Dicas de formatação de texto em comentários/mnemônicos"],
    },
    {
        numero: "1.7.2.2",
        alteracoes: ["Atualizações de performance e segurança"],
    },
    {
        numero: "1.7.2.1",
        alteracoes: ["Melhorias em busca por atualização de leis"],
    },
    {
        numero: "1.7.1",
        alteracoes: ["Melhorias em responsividade para telas pequenas"],
    },
    {
        numero: "1.7",
        alteracoes: [
            "Adicionada ajuste de largura de texto em Preferências > Avançado",
        ],
    },
    {
        numero: "1.6.5",
        alteracoes: [
            "Adicionada compatibilidade para dispositivos móveis em modo de leitura",
            "Dispositivos revogados são exibidos por padrão",
        ],
    },
    {
        numero: "1.6.4",
        alteracoes: [
            "Comentários e provas exibidos sempre independente da versão do dispositivo",
        ],
    },
    {
        numero: "1.6.3",
        alteracoes: [
            "Correção alteração de cartão de crédito cadastrado",
            "Correção em exibição de dispositivo realçado ao criar comentários",
        ],
    },
    {
        numero: "1.6.2.1",
        alteracoes: [
            "Correção em exibição ao utilizar 'marcar até aqui' para ler multiplas linhas",
        ],
    },
    {
        numero: "1.6.2",
        alteracoes: [
            "Melhorias de performance em navegação entre documentos abertos",
        ],
    },
    {
        numero: "1.6.1",
        alteracoes: ["Ajustes nos formulários de cadastro"],
    },
    {
        numero: "1.6.0",
        alteracoes: ["Nova interface de cadastro, login e interação com o usuário"],
    },
    {
        numero: "1.5.6",
        alteracoes: ["Correção de bugs do editor de comentários"],
    },
    {
        numero: "1.5.5",
        alteracoes: ["Correção de bugs do editor de comentários"],
    },
    {
        numero: "1.5.4",
        alteracoes: ["Apresentação do e-mail vinculado a conta em 'Meu Perfil'"],
    },
    {
        numero: "1.5.3",
        alteracoes: ["Criada função 'Autofix' na aba 'Avançado' em 'Preferências'"],
    },
    {
        numero: "1.5.1",
        alteracoes: ["Melhoria em apresentação de erros"],
    },
    {
        numero: "1.4.7",
        alteracoes: ["Melhoria em processo de logoff"],
    },
    {
        numero: "1.4.6",
        alteracoes: ["Melhoria em logs de erro"],
    },
    {
        numero: "1.4.5",
        alteracoes: ["Melhoria em disposição de itens no índice sistemático"],
    },
    {
        numero: "1.4.4",
        alteracoes: ["Ajustes em datas de modificação de leis"],
    },
    {
        numero: "1.4.3",
        alteracoes: ["Logs de sincronização de leis exibidos em console"],
    },
    {
        numero: "1.4.2",
        alteracoes: ["Melhoria em sincronização de leis"],
    },
    {
        numero: "1.4.1",
        alteracoes: ["Melhoria para Índice Sistemático"],
    },
    {
        numero: "1.4.0",
        alteracoes: [
            "Criado Índice Sistemático",
            "Criado novo layout para cabeçalho do leitor",
        ],
    },
    {
        numero: "1.3.22",
        alteracoes: [
            "Correção de bug que mantinha a toolbar aberta após fazer uma busca em uma guia sem lei carregada",
            "Melhorada busca da nova guia",
        ],
    },
    {
        numero: "1.3.21",
        alteracoes: [
            "Correção de bug que ocorria ao abrir uma nova guia quando utilizando a busca completa",
        ],
    },
    {
        numero: "1.3.20",
        alteracoes: ["Melhoria exibição de datas de atualização de leis"],
    },
    {
        numero: "1.3.19",
        alteracoes: ["Melhoria em atualização de datas de lei"],
    },
    {
        numero: "1.3.18",
        alteracoes: [
            "Correção da busca em todos os documentos",
            "Correção do componente que exibe o tempo restante de leitura",
        ],
    },
    {
        numero: "1.3.17",
        alteracoes: ["Melhorias em cadastro inicial"],
    },
    {
        numero: "1.3.16",
        alteracoes: ["Gerenciamento de cache"],
    },
    {
        numero: "1.3.15",
        alteracoes: ["Ajuste na busca"],
    },
    {
        numero: "1.3.12",
        alteracoes: ["Otimização de build"],
    },
    {
        numero: "1.3.11",
        alteracoes: [
            "Resolvido mais bugs causados pelo carregamento da busca avançada",
        ],
    },
    {
        numero: "1.3.10",
        alteracoes: [
            "Resolvido problema que carregava outro elemento na guia atual após fechar a busca",
            "Revisão do processo de download de leis no modo offline para não baixar leis que não precisam ser atualizadas",
            "Corrigido posicionamento do realce na busca rápida",
            "Resolvido problema no resumoda lei, permitindo a navegação via marcações",
        ],
    },
    {
        numero: "1.3.9",
        alteracoes: [
            "Melhorada a experiência de busca rápida (por nº artigo/súmula)",
        ],
    },
    {
        numero: "1.3.8",
        alteracoes: [
            "Corrigido problema que não reconectava o serviço de sincronização após perda de conexão com a internet",
            "Corrigido problema que mostrava leis duplicadas na tela 'nova guia'",
        ],
    },
    {
        numero: "1.3.7",
        alteracoes: ["Incluída busca rápida para súmulas"],
    },
    {
        numero: "1.3.6",
        alteracoes: ["Corrigidas informações de leitura em nova guia"],
    },
    {
        numero: "1.3.5",
        alteracoes: [
            "Corrigido mecanismo de sincronização de dados",
            "Melhorado mecanismo de verificação de conexão com o servidor",
            "Melhorado mecanismo de download do modo 100% offline",
            "Criados ícones para indicar atividade do aplicativo",
        ],
    },
    {
        numero: "1.3.4",
        alteracoes: [
            "Resolvido bug que mantinha o número do artigo na busca rápida ao trocar de aba",
            "Resolvido bug que exibia guias duplicadas ao realizar login",
            "Corrigido realce de texto de títulos nos resultados da busca",
        ],
    },
    {
        numero: "1.3.3",
        alteracoes: [
            "Correção em avaliação de token de reset de senha",
            "Melhor disposição de botões ao resetar senha",
            "Remoção de configuração para exibição de itens revogados em Preferencias",
            "Nova correção em bug de exibição de itens revogados",
        ],
    },
    {
        numero: "1.3.2",
        alteracoes: [
            "Correção em exibição de itens",
            "Corrção em máscara de CPF no cadastro inical",
            "Botão Sair não é mais exibido em modo offline para que o usuário não perca informações",
        ],
    },
    {
        numero: "1.3.1",
        alteracoes: [
            "Ajustado mecanismo de renderização de marcações (Bug causava travamento do app durante a busca)",
        ],
    },
    {
        numero: "1.3",
        alteracoes: [
            "Correções em funcionalidade 'Marcar como lido até aqui' em documentos",
        ],
    },
    {
        numero: "1.2.9",
        alteracoes: [
            "A configuração de utilização em 100% offline fica salva no navegador, mesmo após o login",
        ],
    },
    {
        numero: "1.2.8",
        alteracoes: ["Correções em marcações e cálculos de progresso de leitura"],
    },
    {
        numero: "1.2.7",
        alteracoes: [
            "A tela de download de leis para uso offline é finalizada automaticamente em falhas de rede",
        ],
    },
    {
        numero: "1.2.6",
        alteracoes: [
            "A nova guia foi alterada para ordenar por data de alteração por padrão",
            "A tela do atualizador de leis agora redireciona automaticamente após a verificação",
            "A busca por artigo foi alterada para permitir buscas com algarismos romanos",
        ],
    },
    {
        numero: "1.2.5",
        alteracoes: ["Resolvido problema de carregamento de leis"],
    },
    {
        numero: "1.2.4",
        alteracoes: [
            "Implementado o log de alterações",
            "Alterado controle de guias para resolver o problema de duas guias abertas ao mesmo tempo",
            "Corrigido o carregamento de lei através da tela de detalhes da lei",
            "Escondendo as opções 'Meu perfil' e 'Feedback' quando offline",
        ],
    },
];
