
export class OpcaoDialogo {
    descricao: string;
    cor: string;
    action: Function;
}

export class DialogoModel {
    titulo: string;
    mensagem: string;
    opcoes: OpcaoDialogo[];

    public incluirOpcao(descricao: string, cor: string, action: Function) {
        if (!this.opcoes) {
            this.opcoes = new Array<OpcaoDialogo>();
        }

        this.opcoes.push({ descricao: descricao, cor: cor, action: action });
    }
}
