import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from "@angular/core";
import { Guia } from "../../../../models/Guia";

@Component({
    selector: "app-aba-leitor",
    templateUrl: "./aba-leitor.component.html",
    styleUrls: ["./aba-leitor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbaLeitorComponent  {
    @Input()
    public guia: Guia;

    @Input()
    public guiaIndex = -1;

    @Input()
    public ativaIndex = -1;

    @Input()
    public hoverIndex = -1;

    @Input()
    public totalGuias = -1;

    @Input()
    public larguraAba = "";

    @Output()
    public aoFecharAba: EventEmitter<string> = new EventEmitter();

    constructor() {}



    public get isBeforeVisible() {
        if (this.guiaIndex === this.ativaIndex) {
            return true;
        }

        if (this.guiaIndex === this.ativaIndex + 1) {
            return false;
        }

        if (this.hoverIndex !== -1 && this.guiaIndex === this.hoverIndex + 1) {
            return false;
        }

        return true;
    }

    public get isAfterVisible() {
        if (this.guiaIndex === this.ativaIndex) {
            return true;
        }

        if (this.guiaIndex === this.ativaIndex - 1) {
            return false;
        }

        if (this.hoverIndex !== -1 && this.guiaIndex === this.hoverIndex - 1) {
            return false;
        }

        return true;
    }

    public get classes() {
        return {
            active: this.guia.ativa,
            // 'hover': this.guia.hover,
            "before-visible": this.isBeforeVisible,
            "after-visible": this.isAfterVisible,
            first: this.guiaIndex === 0,
            last: this.guiaIndex === this.totalGuias - 1,
            border: this.showBorder,
        };
    }

    public get showBorder() {
        if (this.guiaIndex === this.totalGuias - 1) {
            return false;
        }

        if (this.guiaIndex === this.ativaIndex - 1) {
            return false;
        }

        if (this.guiaIndex === this.ativaIndex) {
            return false;
        }

        if (this.guiaIndex === this.hoverIndex) {
            return false;
        }

        if (this.guiaIndex === this.hoverIndex - 1) {
            return false;
        }

        return true;
    }

    public fecharGuia(e: Event) {
        e.preventDefault();
        e.stopPropagation();

        this.aoFecharAba.emit(this.guia.id);
    }
}
