import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MaskHelper } from "../../../../helpers/mask.helper";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { WebserviceHelper } from "../../../../helpers/webservice.helper";
import { ListaUfs } from "src/app/constants/lista.ufs";
import { PagamentoModel, DadosPessoaisModel, BandeiraCartao, DadosCartaoCredito, DadosPessoaisServidorModel, SolicitacaoAlteracaoDados } from "src/app/models/PagamentoModel";
import { DadosPessoais } from "src/app/models/DadosPessoais";

@Component({
    selector: "app-form-pagamento",
    templateUrl: "./form-pagamento.component.html",
    styleUrls: ["./form-pagamento.component.scss"],
})
export class FormPagamentoComponent implements OnInit {

    @Input() public dadosServidor: DadosPessoaisServidorModel;

    @Output() public onsaving = new EventEmitter();
    @Output() public onsaved = new EventEmitter<string>();
    @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);



    public bandeiraCartao: string;
    public finalCartao: string;

    public alterar: boolean;
    public model: PagamentoModel;
    public modelDadosPessoais: DadosPessoaisModel;
    public bandeiras = BandeiraCartao.listarBandeiras();

    public maskMesAno = MaskHelper.mesAno;

    public listaUfs = ListaUfs;
    private headers = new HttpHeaders({
        "content-type": "application/json",
    });

    private s_user: Subscription;
    constructor(private httpClient: HttpClient) {}

    public ngOnInit(): void {
        this.carregarModel();
    }

    public abrir(): void {
        this.carregarModel();
    }

    public fechar(): void {}

    public cancelar(): void {
        this.carregarModel();
    }

    public salvar(): void {
        this.onsaving.emit();

        const dadosPessoais = DadosPessoais.fromDadosPessoaisModel(
            this.modelDadosPessoais
        );
        const dadosCartao = DadosCartaoCredito.fromPagamentoModel(
            this.model,
            this.modelDadosPessoais.cpf
        );
        const solicitacao = new SolicitacaoAlteracaoDados();
        solicitacao.dadosPessoais = dadosPessoais;
        solicitacao.dadosCartaoCredito = dadosCartao;
        solicitacao.planoContratadoId = this.modelDadosPessoais.planoContratadoId;

        this.httpClient
            .post(AppConfig.apiEndpoint + "/DadosCadastroUsuario", solicitacao, {
                headers: this.headers,
            })
            .toPromise()
            .then((response: any) => {
                this.onsaved.emit(
                    "Sua nova forma de pagamento foi salva com sucesso! Ela será validada na próxima renovação da sua assinatura"
                );

                this.bandeiraCartao = this.model.bandeira.descricao;
                this.finalCartao = this.model.numero;
                while (this.finalCartao.length > 4) {
                    this.finalCartao = this.finalCartao.substring(1);
                }

                this.carregarModel(false);
            })
            .catch((err) => {
                this.onsaved.emit(err.error);
                this.carregarModel();
            });
    }

    cep_blur() {
        WebserviceHelper.consultaCep(this.model.cep).then((result) => {
            if (result) {
                this.model.cep = result.cep;
                this.model.logradouroEndereco = result.logradouro;
                this.model.bairro = result.bairro;
                this.model.cidadeEndereco = result.cidade;
                this.model.uf = result.estado;
                this.model.siglaPais = "BR";
            }
        });
    }

    private carregarModel(carregarDadosCartao = true) {
        if (carregarDadosCartao) {
            this.bandeiraCartao = this.dadosServidor.resumoCartao.bandeiraCartao;
            this.finalCartao = this.dadosServidor.resumoCartao.finalCartao;
        }

        this.model = new PagamentoModel();
        this.modelDadosPessoais = new DadosPessoaisModel(
            this.dadosServidor.dadosPessoais
        );
        this.alterar = false;
    }

}
