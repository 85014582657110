<table
  class="linha-placeholder"
  [ngClass]="{ 'noselect version-browser-visible': painelVersoesVisivel }"
>
  <tr *ngIf="painelVersoesVisivel" class="noselect">
    <td class="navegador-versoes" colspan="3">
      <table>
        <tr>
          <td class="btn-placeholder">
            <div
              class="icon icon-left"
              *ngIf="linha.indexVersao - 1 >= 0"
              (click)="versaoAnterior()"
            ></div>
          </td>
          <td>
            <div
              *ngIf="linha.indexVersao + 1 !== linha.versoes.length"
              class="texto-versao"
            >
              Versão {{ linha.indexVersao + 1 }}
            </div>
            <div
              *ngIf="linha.indexVersao + 1 === linha.versoes.length"
              class="texto-versao"
            >
              Versão vigente
            </div>
          </td>
          <td class="btn-placeholder">
            <div
              class="icon icon-right"
              *ngIf="linha.indexVersao + 1 <= linha.versoes.length - 1"
              (click)="proximaVersao()"
            ></div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td class="marcadores noselect">
      <table class="marcadores-placeholder" *ngIf="paleta">
        <tr>
          <td>
            <div
              class="icon icon-versao"
              [style.color]="'#989898'"
              *ngIf="linha.versoes && linha.versoes.length > 1"
              title="Versoes ({{ linha.versoes.length }})"
              (click)="btnVersoesClick($event)"
            ></div>
          </td>
        </tr>
      </table>
    </td>
    <td class="texto">
      <app-editor-comentario
        *ngIf="
          comentarioCriar &&
          ((comentarioCriar.mnemonico && posicaoMnemonico == 'Acima') ||
            (!comentarioCriar.mnemonico && posicaoComentario == 'Acima'))
        "
        class="noselect"
        [comentarioCriar]="comentarioCriar"
        (cancel)="cancelarComentario()"
        (commit)="salvarComentario($event)"
      ></app-editor-comentario>

      <p
        class="coluna-texto {{ linha.tipoTexto }}"
        [style.fontSize]="getDefaultFontSize() + (fontIncrement | async) + 'pt'"
        id="{{ linha.id }}"
        [attr.data-indexVersao]="linha.indexVersao"
      >
        <span
          *ngIf="
            linha.versoes[linha.indexVersao].prefixo &&
            linha.versoes[linha.indexVersao].prefixo.length > 0
          "
          class="prefixo"
        >
          <app-trecho-texto
            *ngFor="let trecho of linha.trechosPrefixo"
            [trechoTexto]="trecho"
            [linha]="linha"
          ></app-trecho-texto>
        </span>
        <span class="texto">
          <app-trecho-texto
            [ngClass]="{ revogado: linha.versoes[linha.indexVersao].revogado }"
            *ngFor="let trecho of linha.trechosTexto"
            [trechoTexto]="trecho"
            [linha]="linha"
          ></app-trecho-texto>
          <span
            *ngIf="linha.versoes[linha.indexVersao].revogado"
            class="revogado-sulfixo noselect"
            >(REVOGADO)</span
          >
        </span>
      </p>
      <app-editor-comentario
        *ngIf="
          comentarioCriar &&
          ((comentarioCriar.mnemonico && posicaoMnemonico == 'Abaixo') ||
            (!comentarioCriar.mnemonico && posicaoComentario == 'Abaixo'))
        "
        class="noselect"
        [comentarioCriar]="comentarioCriar"
        (cancel)="cancelarComentario()"
        (commit)="salvarComentario($event)"
      ></app-editor-comentario>
    </td>
    <td class="controle-leitura noselect">
      <table
        *ngIf="!linha.versoes[linha.indexVersao].revogado"
        class="btn-leitura-placeholder"
      >
        <tr>
          <td>
            <div
              class="icon icon-check"
              [ngClass]="{ lido: linha.lida }"
              title="Marcar como lido"
              (click)="toggleLido()"
            ></div>
          </td>
          <td *ngIf="!resultadoBuscaTodosDocumentos">
            <div
              class="icon icon-double-check"
              title="Marcar como lido até aqui"
              (click)="marcarLidoAteAqui()"
            ></div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
