import { Marcacao } from "./Marcacao";
import { Comentario } from "./Comentario";
import { Grifo } from "./Grifo";
import { Apontamento } from "./Apontamento";

export class MarcacaoServiceCarregarMarcacaoResult {
    marcacoes: Array<Marcacao>;
    comentarios: Array<Comentario>;
    grifos: Array<Grifo>;
    idLei: string;
    idItem: string;

    constructor() {
        this.marcacoes = new Array<Marcacao>();
        this.comentarios = new Array<Comentario>();
        this.grifos = new Array<Grifo>();
    }
}

export class MarcacaoServiceCarregarAnotacaoResult extends MarcacaoServiceCarregarMarcacaoResult {
    apontamentos: Apontamento[];

    constructor() {
        super();

        this.apontamentos = new Array<Apontamento>();
    }
}
