<div *ngIf="editandoComentario && !comentarioGerenciadoReadOnly; else readonly">
  <div [style.backgroundColor]="corComentario" class="editor-wrapper">
    <div class="editor-container">
      <div
        class="icon icon-comentario"
        backgroundContrast
        [backgroundColor]="corComentario"
      ></div>
      <div
        #editor
        class="editor editor-comentario"
        [ngClass]="{ novo: novoComentario }"
        placeholder="Insira o comentário"
        contenteditable="true"
        [innerHTML]="comentario.texto"
        (keyup)="keyPressed($event)"
        (keyup.enter)="enterPressed($event)"
        (keyup.esc)="escPressed()"
        (paste)="onPaste($event)"
        (blur)="onBlur($event)"
      ></div>
    </div>
    <div class="editor-comentario-icon">
      <div
        class="char-count"
        backgroundContrast
        [backgroundColor]="corComentario"
      >
        {{  (!editorElement || !editorElement.innerHTML) ? 0 :editorElement?.innerText?.length}}/{{ isAdm
          ? limiteExtendidoCaracteresComentario
          : limiteCaracteresComentario }}
      </div>
      <mat-icon
        backgroundContrast
        [backgroundColor]="corComentario"
        class="material-icons-outlined editor-comentario-icons-info"
        matTooltip="Adicione textos em negrito pressionando as teclas Ctrl + B.
           Adicione textos em itálico pressionando as teclas Ctrl + I.
           Adicione textos com sublinhado pressionando as teclas Ctrl + U."
        [matTooltipClass]="'editor-comentario-mattooltip'"
        [matTooltipPosition]="'below'"
        style="padding-left: 8px; cursor: pointer; font-size: 10pt"
        >info</mat-icon
      >
    </div>
  </div>

  <app-editor-tags
    id="tags"
    *ngIf="!comentarioGerenciadoReadOnly"
    [editandoTag]="editandoComentario"
    [color]="corComentario"
    (newtagArray)="updatetags($event)"
    [setTagArray]="comentario.tags"
    (onblurEmitte)="onBlur($event)"
  ></app-editor-tags>
</div>

<ng-template #readonly>
  <table
    backgroundContrast
    [backgroundColor]="corComentario"
    class="readonly-wrapper"
    [class.readonly-wrapper-comentarios-svm]="comentarioGerenciadoReadOnly"
    (click)="mouseupEdicao()"
  >
    <tr>
      <td>
        <span
          class="texto"
          [class.texto-comentario]="!comentario.mnemonico"
          #viewer
          [innerHTML]="comentario.texto"
          >{{ comentario.texto }}</span
        >
      </td>
      <td *ngIf="!comentarioGerenciadoReadOnly">
        <mat-icon
          class="btn-excluir"
          [ngStyle]="{ color: editandoComentario ? 'color' : 'unset' }"
          svgIcon="close"
          matTooltip="Excluir"
          (click)="confirmarDelecaoComentario($event, comentario)"
        >
        </mat-icon>
      </td>
      <td *ngIf="comentarioGerenciadoReadOnly" class="icon-container">
        <mat-icon
          class="icon btn-image-ico logo-comentario"
          matTooltip="comentários gerenciados SVM"
          svgIcon="logo-sm"
        ></mat-icon>
        <mat-icon
          *ngIf="comentarioGerenciadoPlus"
          class="icon logo-comentario icon-plus"
          matTooltip="comentários plus SVM"
          svgIcon="crown"
        ></mat-icon>
      </td>
    </tr>
  </table>

  <app-editor-tags
    *ngIf="comentario.tags?.length > 0 && !comentarioGerenciadoReadOnly"
    [editandoTag]="editandoComentario"
    [setTagArray]="comentario.tags"
    [color]="corComentario"
  ></app-editor-tags>
</ng-template>
