import { versoes } from "../changelog";
import { TemasApp } from "../constants/lista.temas";
import { ListaOpcoesModoUso } from "./ListaOpcoesModoUso";
import { OpcaoModoUso } from "./OpcaoModoUso";
import { OpcaoToolbar } from "./opcaoToolbar";
import { PreferenciasMarcadorModel } from "./PreferenciasMarcadorModel";
import { Tema } from "./tema";
import { TipoSelecao } from "./TipoSelecao";
import { ConfiguracoesUsuario } from "./usuario/ConfiguracoesUsuario";

export class PreferenciasModel {
    public versaoApp = versoes.sort(function (a, b) {
        if (b.numero > a.numero) {
            return 1;
        } else {
            return 0;
        }
    })[0];

    public opcoesLarguraPapel: string[] = ["3-3", "2-3", "1-3"];
    public opcaoLarguraPapelOriginal: string;
    public opcaoLarguraPapel: string;

    public opcoesModoUso: OpcaoModoUso[] = ListaOpcoesModoUso;
    public temas: Tema[] = TemasApp;

    public temaOriginal: Tema;
    public temaSelecionado: Tema;

    public opcaoModoUsoOriginal: OpcaoModoUso = null;
    public opcaoModoUsoSelecionada: OpcaoModoUso = null;

    public palavrasPorMinutoOriginal: number;
    public palavrasPorMinuto: number;

    public marcadoresOriginal: PreferenciasMarcadorModel[];
    public marcadores: PreferenciasMarcadorModel[];

    public grifosOriginal: PreferenciasMarcadorModel[];
    public grifos: PreferenciasMarcadorModel[];

    constructor(prefs: ConfiguracoesUsuario) {
        this.opcaoLarguraPapelOriginal = prefs.preferenciasUsuario.larguraPapel;
        this.opcaoLarguraPapel = this.opcaoLarguraPapelOriginal;

        this.temaOriginal = this.temas[prefs.idTema];
        this.temaSelecionado = this.temaOriginal;

        this.opcaoModoUsoOriginal = this.opcoesModoUso.find(
            (o) => o.tipo === prefs.perfilUsuario
        );
        this.opcaoModoUsoSelecionada = this.opcaoModoUsoOriginal;

        this.palavrasPorMinutoOriginal = prefs.palavrasMinuto;
        this.palavrasPorMinuto = this.palavrasPorMinutoOriginal;

        this.marcadoresOriginal = OpcaoToolbar.carregarItensMenu(
            prefs.parametrosCaneta
        )
            .filter(
                (op) =>
                    op.tipo === TipoSelecao.Comentario ||
        op.tipo === TipoSelecao.Mnemonico
            )
            .map((m) => {
                let p: string;
                switch (m.tipo) {
                    case TipoSelecao.Comentario:
                        p = prefs.parametrosCaneta.posicionamentoComentario;
                        break;
                    case TipoSelecao.Mnemonico:
                        p = prefs.parametrosCaneta.posicionamentoMnemonico;
                        break;
                }
                if (!p) {
                    p = "Acima";
                }

                return {
                    icone: m.icone,
                    tipoMarcacao: m.tipo,
                    iconeColorPicker: "url(\"assets/ui/icons/ic-color-picker-normal.png\")",
                    cor: m.cor,
                    sublinhar: m.realcarMarcacao,
                    apelido: m.descricao,
                    posicionamento: p,
                    modoRealce: null,
                };
            });

        this.grifosOriginal = OpcaoToolbar.carregarItensMenu(prefs.parametrosCaneta)
            .filter(
                (op) =>
                    op.tipo === TipoSelecao.Caneta1 ||
        op.tipo === TipoSelecao.Caneta2 ||
        op.tipo === TipoSelecao.Caneta3 ||
        op.tipo === TipoSelecao.Caneta4 ||
        op.tipo === TipoSelecao.Caneta5
            )
            .map((g) => {
                let r: string;
                switch (g.tipo) {

                    case TipoSelecao.Caneta1:
                        r = prefs.parametrosCaneta.modoRealceCaneta1;
                        break;
                    case TipoSelecao.Caneta2:
                        r = prefs.parametrosCaneta.modoRealceCaneta2;
                        break;
                    case TipoSelecao.Caneta3:
                        r = prefs.parametrosCaneta.modoRealceCaneta3;
                        break;
                    case TipoSelecao.Caneta4:
                        r = prefs.parametrosCaneta.modoRealceCaneta4;
                        break;
                    case TipoSelecao.Caneta5:
                        r = prefs.parametrosCaneta.modoRealceCaneta5;
                        break;
                }
                if (!r) {
                    r = "Grifar";
                }

                return {
                    icone: g.icone,
                    tipoMarcacao: g.tipo,
                    iconeColorPicker: "url(\"assets/ui/icons/ic-color-picker-normal.png\")",
                    cor: g.cor,
                    sublinhar: false,
                    apelido: g.descricao,
                    posicionamento: null,
                    modoRealce: r,
                };
            });

        this.marcadores = this.marcadoresOriginal;
        this.grifos = this.grifosOriginal;
    }
}
