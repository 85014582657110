import { MaskHelper } from "../helpers/mask.helper";
import { DadosPessoais } from "./DadosPessoais";


export class BandeiraCartao {
    public id: number;
    public descricao: string;

    public static listarBandeiras(): BandeiraCartao[] {
        return [
            { id: 1, descricao: "VISA" },
            { id: 2, descricao: "AMEX" },
            { id: 3, descricao: "MASTERCARD" },
            { id: 4, descricao: "ELO" },
            { id: 5, descricao: "HIPERCARD" },
            { id: 6, descricao: "DINERS" },
        ];
    }
}

export class PagamentoModel {
    public bandeira: BandeiraCartao;
    public numero: string;
    public codigo: string;
    public vencimento: string;
    public nomeCartao: string;

    public cep: string;
    public logradouroEndereco: string;
    public numeroEndereco: string;
    public complemento: string;
    public bairro: string;
    public cidadeEndereco: string;
    public uf: string;
    public siglaPais: string;

    public get valido(): boolean {
        return this.bandeira &&
      this.numero &&
      this.numero.length > 0 &&
      this.codigo &&
      this.codigo.length > 0 &&
      this.vencimento &&
      this.vencimento.length > 0 &&
      this.nomeCartao &&
      this.nomeCartao.length > 0 &&
      this.cep &&
      this.cep.length > 0 &&
      this.logradouroEndereco &&
      this.logradouroEndereco.length > 0 &&
      this.numeroEndereco &&
      this.numeroEndereco.length > 0 &&
      this.bairro &&
      this.bairro.length > 0 &&
      this.cidadeEndereco &&
      this.cidadeEndereco.length > 0 &&
      this.uf &&
      this.uf.length > 0
            ? true
            : false;
    }
}

export class DadosPessoaisModel {
    public cpf: string;
    public telefone: string;
    public planoContratadoId: number;

    public maskCelular = MaskHelper.celular;
    public maskMesAno = MaskHelper.mesAno;
    private cpf_original: string;
    private telefone_original: string;

    constructor(dados: DadosPessoais) {
        this.cpf_original = dados.cpf;
        this.cpf = this.cpf_original;

        this.telefone_original = dados.telefone;
        this.telefone = this.telefone_original;

        this.planoContratadoId = dados.planoContratadoId;
    }

    public get alterado(): boolean {
        return this.cpf !== this.cpf_original ||
      this.telefone !== this.telefone_original
            ? true
            : false;
    }

    public get valido(): boolean {
        return this.cpf &&
      this.cpf.length > 0 &&
      this.telefone &&
      this.telefone.length > 0
            ? true
            : false;
    }
}




export class ResumoCartaoCredito {
    public bandeiraCartao: string;
    public finalCartao: string;
}

export class DadosPessoaisServidorModel {
    public trial: boolean;
    public resumoCartao: ResumoCartaoCredito;
    public dadosPessoais: DadosPessoais;
    constructor() {
        this.resumoCartao = new ResumoCartaoCredito();
        this.dadosPessoais = new DadosPessoais();
    }
}

export class DadosCartaoCredito {
    public cpfTitular: string;
    public nomeImpressoCartao: string;
    public numeroCartao: string;
    public codigoSeguranca: string;
    public dataVencimento: string;
    public cep: string;
    public logradouroEndereco: string;
    public numeroEndereco: string;
    public complemento: string;
    public bairro: string;
    public cidadeEndereco: string;
    public uf: string;
    public siglaPais: string;
    public meioPagamento: number;

    public static fromPagamentoModel(
        model: PagamentoModel,
        cpf: string
    ): DadosCartaoCredito {
        const retorno = new DadosCartaoCredito();

        retorno.cpfTitular = cpf;
        retorno.nomeImpressoCartao = model.nomeCartao;
        retorno.numeroCartao = model.numero;
        retorno.codigoSeguranca = model.codigo;
        retorno.dataVencimento = model.vencimento;
        retorno.cep = model.cep;
        retorno.logradouroEndereco = model.logradouroEndereco;
        retorno.numeroEndereco = model.numeroEndereco;
        retorno.complemento = model.complemento;
        retorno.bairro = model.bairro;
        retorno.cidadeEndereco = model.cidadeEndereco;
        retorno.uf = model.uf;
        retorno.siglaPais = "BR";
        retorno.meioPagamento = model.bandeira.id;

        return retorno;
    }
}



export class SolicitacaoAlteracaoDados {
    public dadosPessoais: DadosPessoais;
    public dadosCartaoCredito: DadosCartaoCredito;
    public planoContratadoId: number;
}



