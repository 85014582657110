<div class="timer-container">
  <div class="timer-player">
    <div class="timer-player-dispaly">
      <span class="timer-player-dispaly-number"
        >{{ hr }}:{{ min }}:{{ sec }}</span
      >
    </div>

    <div class="timer-player-dispaly-buttons">
      <button
        mat-icon-button
        (click)="reset()"
        class="timer-player-dispaly-button"
      >
        <mat-icon svgIcon="stop" class="icon-button-stop"> </mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="start()"
        class="timer-player-dispaly-button button-play"
      >
        <mat-icon
          [svgIcon]="running ? 'pause' : 'play'"
          class="icon-button-play"
        >
        </mat-icon>
      </button>
    </div>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div class="timer-player-total-container">
    <span class="timer-player-total"> Tempo total</span>

    <span class="timer-player-timer">{{
      apontamentosTempoTotal$ | async
    }}</span>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div class="timer-player-anotar-time">
    <input
      #txtAnotarTimer
      class="txt-anotar-timer"
      type="text"
      [placeholder]="textoAnotacao || 'Anote aqui...'"
      [(ngModel)]="textoAnotacao"
      name="txtTimerInput"
      id="txtTimerInput"
    />
    <span class="timer-player-timer">
      {{
        ((apontamento?.controleTempo[0]?.termino -
          apontamento?.controleTempo[0]?.inicio) /
          1000 | mathFloor) /
          60 /
          60
          | mathFloor
          | number : "2.0-0"
      }}:{{
        (((apontamento?.controleTempo[0]?.termino -
          apontamento?.controleTempo[0]?.inicio) /
          1000 | mathFloor) /
          60) %
          60
          | mathFloor
          | number : "2.0-0"
      }}:{{
        ((apontamento?.controleTempo[0]?.termino -
          apontamento?.controleTempo[0]?.inicio) /
          1000 | mathFloor) % 60
          | mathFloor
          | number : "2.0-0"
      }}
    </span>
  </div>
  <div class="timer-player-list">
    <div
      class="timer-player-lists-time"
      *ngFor="let items of apontamentosListaPorData$ | async"
    >
      <div
        *ngIf="items?.dataApontamento"
        class="timer-player-titulo-anotar-time"
      >
        <span>{{ items?.dataApontamento | date : "d 'de' MMMM 'de' y" }} </span>
      </div>

      <div
        *ngFor="let item of items?.apontamentos"
        class="timer-player-list-time-column"
      >
        <span class="txt-anotar-timer txt-anotar-timer-descricao">{{
          item?.descricaoConteudo
        }}</span>
        <div class="timer-player-list-time-row">
          <span class="txt-anotar-timer">{{ item?.anotacoes[0] }}</span>
          <span class="timer-player-timer">
            {{
              ((item?.controleTempo[0]?.termino -
                item?.controleTempo[0]?.inicio) /
                1000 | mathFloor) /
                60 /
                60
                | mathFloor
                | number : "2.0-0"
            }}:{{
              (((item?.controleTempo[0]?.termino -
                item?.controleTempo[0]?.inicio) /
                1000 | mathFloor) /
                60) %
                60
                | mathFloor
                | number : "2.0-0"
            }}:{{
              ((item?.controleTempo[0]?.termino -
                item?.controleTempo[0]?.inicio) /
                1000 | mathFloor) % 60
                | mathFloor
                | number : "2.0-0"
            }}
          </span>

          <mat-icon svgIcon="ellipsis" class="icon-button-ellipsis"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
