import { TrechoTexto } from "../../../../../models/pagina/TrechoTexto";
import { TextoPagina } from "../../../../../models/pagina/TextoPagina";
import { PlaceholderPaineisService } from "../../../placeholder-paineis/placeholder-paineis.service";
import { UsuarioGrifosService } from "../../../../../services/data-services/usuario.grifos.service";
import { Component, OnInit, Input } from "@angular/core";
import { ModoSelecao } from "src/app/models/ModoSelecao";
import { SelectionService } from "src/app/services/selection.service";
import { Tabela, LinhaTabela, Coluna } from "src/app/models/Tabela";

@Component({
    selector: "app-trecho-texto",
    templateUrl: "./trecho-texto.component.html",
    styleUrls: ["./trecho-texto.component.scss"],
})
export class TrechoTextoComponent implements OnInit {
    @Input()
    public trechoTexto: TrechoTexto;

    @Input()
    public linha: TextoPagina;

    public contemTabela: boolean;
    public tabela: Tabela;
    constructor(
        private placeholderPaineisService: PlaceholderPaineisService,
        private usuarioGrifosService: UsuarioGrifosService,
        private selectionService: SelectionService
    ) {}

    ngOnInit() {
        this.gerarLinhas();
    }

    processClick(e: Event) {
        e.stopPropagation();

        if (
            this.selectionService.selection.modo === ModoSelecao.Borracha &&
      this.trechoTexto.grifo
        ) {
            this.usuarioGrifosService.remover(this.trechoTexto.grifo);
        }

        if (!this.trechoTexto.marcacao && !this.trechoTexto.comentario) {
            return;
        }

        if (this.trechoTexto.marcacao) {
            document
                .getElementById("prov-sup-input-" + this.trechoTexto.marcacao.id)
                .focus();
        } else if (
            this.trechoTexto.comentario &&
      !this.trechoTexto.comentario.mnemonico
        ) {
            document
                .getElementById("coment-sup-input-" + this.trechoTexto.comentario.id)
                .getElementsByTagName("table")[0]
                .click();
        } else {
            document
                .getElementById("mne-sup-input-" + this.trechoTexto.comentario.id)
                .getElementsByTagName("table")[0]
                .click();
        }
    }

    public quebrarLinhasTabela(texto: string): Array<string> {
        const colunas = texto.split("⊞");
        return colunas;
    }

    public quebrarColunasLinhasTabela(texto: string): Array<string> {
        const linhas = texto.split("⠇");
        return linhas;
    }

    public gerarLinhas() {
        this.contemTabela =
      this.linha.tipoTexto.indexOf("texto-linhatabela") !== -1;

        if (!this.contemTabela) {
            return;
        }

        this.tabela = new Tabela();
        this.quebrarLinhasTabela(this.trechoTexto.texto).forEach((linha) => {
            const colunas = this.quebrarColunasLinhasTabela(linha);
            const linhaTabela = new LinhaTabela();
            colunas.forEach((c) => {
                const coluna = new Coluna();
                coluna.texto = c;
                coluna.width = (100 / colunas.length).toString() + "%";

                linhaTabela.colunas.push(coluna);
            });

            this.tabela.linhas.push(linhaTabela);
        });
    }
}


