import { Component, Inject, OnInit } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { Guia } from "src/app/models/Guia";
import { HistoricoAtualizacoes } from "src/app/models/HistoricoAtualizacoes";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";
import { UsuarioGuiasService } from "src/app/services/data-services/usuario.guias.service";
import { HistoricoAtualizacoesService } from "src/app/services/historico-atualizacoes.service";
import { SimNaoDialogComponent } from "../../dialogs/sim-nao-dialog/sim-nao-dialog.component";
import {
    DataControle,
    EnumTipoDataControle,
} from "src/app/models/dataControle";
import { DataControleRepositorio } from "src/app/repositorios/dataControle.repositorio";
import { Observable, of } from "rxjs";

@Component({
    selector: "app-historico-atualizacoes-dialog",
    templateUrl: "./historico-atualizacoes-dialog.component.html",
    styleUrls: ["./historico-atualizacoes-dialog.component.scss"],
})
export class HistoricoAtualizacoesDialogComponent implements OnInit {
    public historicoAtualizacoesReferencia$: Observable<HistoricoAtualizacoes[]> =
    of([]);
    public historicoAtualizacoesComentarios$: Observable<
    HistoricoAtualizacoes[]
    > = of([]);
    public historicoAtualizacoesLeis$: Observable<HistoricoAtualizacoes[]> = of(
        []
    );
    public historicoAtualizacoes$: Observable<HistoricoAtualizacoes[]> = of([]);

    constructor(
        public dialogRef: MatDialogRef<HistoricoAtualizacoesDialogComponent>,
        private usuarioEstatisticasService: UsuarioEstatisticasService,
        private usuarioGuiasService: UsuarioGuiasService,
        private historicoAtualizacoesService: HistoricoAtualizacoesService,
        public dialog: MatDialog,
        private dataControleRepositorio: DataControleRepositorio,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.historicoAtualizacoes$ =
      this.historicoAtualizacoesService.getHistoricoAtualizacoesAll();

        this.historicoAtualizacoesLeis$ =
      this.historicoAtualizacoesService.getHistoricoAtualizacoesLeis();

        this.historicoAtualizacoesReferencia$ =
      this.historicoAtualizacoesService.getHistoricoAtualizacoesReferencias();

        this.historicoAtualizacoesComentarios$ =
      this.historicoAtualizacoesService.getHistoricoAtualizacoesComentarios();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    async abrirEmNovaGuia(tituloLei: string, idLei: string, id: string) {
        const guia = new Guia();
        guia.idLei = idLei;
        guia.titulo = tituloLei;

        await this.usuarioEstatisticasService.alterarPosicaoLeitura(idLei, id);
        await this.usuarioGuiasService.novaGuia(guia);
    }

    public confirmarCloseHistorico() {
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja limpar notificações?",
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            const dataControle: DataControle = {
                tipo: EnumTipoDataControle.DataVisualizacaoNovidades,
                data: new Date(),
            };
            if (result) {
                await this.dataControleRepositorio.salvar(dataControle);
            }
            this.onCancel();
        });
    }
}
