import { Component, OnInit } from "@angular/core";
import { HistoricoAtualizacoes } from "src/app/models/HistoricoAtualizacoes";
import { HistoricoAtualizacoesService } from "src/app/services/historico-atualizacoes.service";
import { HistoricoAtualizacoesDialogComponent } from "../../popups/historico-atualizacoes-dialog/historico-atualizacoes-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";

@Component({
    selector: "app-historico-atualizacoes",
    templateUrl: "./historico-atualizacoes.component.html",
    styleUrls: ["./historico-atualizacoes.component.scss"],
})
export class HistoricoAtualizacoesComponent implements OnInit {
    public historicoAtualizacoes$: Observable<HistoricoAtualizacoes[]> = of([]);

    constructor(
        private historicoAtualizacoesService: HistoricoAtualizacoesService,

        public dialog: MatDialog
    ) {}

    async ngOnInit() {
        this.historicoAtualizacoes$ =
      this.historicoAtualizacoesService.getHistoricoAtualizacoesAll();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(HistoricoAtualizacoesDialogComponent, {
            width: "60vw",
            data: {},
            autoFocus: false,
            panelClass: "historico-dialog-class",
        });

        dialogRef.afterClosed().subscribe((result: any) => {});
    }
}
