import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../app.config";
import { DetalhesAssinaturaResponse } from "../arguments/assinatura/DetalhesAssinaturaResponse";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ItemLookupPlano } from "../../auth/models/item-lookup-plano.model";
import { CadastroUsuarioPlanoRequest } from "../../auth/models/CadastroUsuarioPlanoRequest.model";

@Injectable()
export class AssinaturaService {
    private trialExpirou = false;
    private trialExpirou$ = new BehaviorSubject<boolean>(this.trialExpirou);
    private amodireito = false;

    constructor(private httpClient: HttpClient, private router: Router) {
        this.amodireito = this.router.url.indexOf("amoodireito") >= 0;
    }

    async carregarPlanoAtual(): Promise<DetalhesAssinaturaResponse> {
        const url = `${AppConfig.apiEndpoint}/assinatura/meu-plano`;
        const plano = <DetalhesAssinaturaResponse>(
      (await this.httpClient.get(url).toPromise())[0]
    );

        if (plano.trialGratuito) {
            plano.diasTesteUtilizados =
        plano.tempoAvaliacaoGratuita - plano.diasRestantesAvaliacao;

            if (plano.tempoAvaliacaoGratuita <= 0) {
                this.trialExpirou = true;
                this.updateTrialExpirou();
            }
        }

        return plano;
    }

    async listarPlanos(apenasTrial: boolean): Promise<ItemLookupPlano[]> {
        const planos = [
            { id: 6, descricao: "Teste grátis", periodicidade: "trial" },
            {
                id: 7,
                descricao: this.amodireito
                    ? "Cobrança mensal - R$ 21,00 (15% off)"
                    : "Cobrança mensal - R$ 24,90",
                periodicidade: "mensal",
            },
            {
                id: 8,
                descricao: this.amodireito
                    ? "Cobrança anual - R$ 174,30 (30% off)"
                    : "Cobrança anual - R$ 249,00",
                periodicidade: "anual",
            },
        ];

        return planos.filter(
            (p) =>
                (apenasTrial && p.periodicidade == "trial") ||
        p.periodicidade != "trial"
        );
    }

    async assinarPlano(payload: CadastroUsuarioPlanoRequest) {
        const url = `${AppConfig.apiEndpoint}/assinatura/novo`;
        await this.httpClient.post(url, payload).toPromise();
    }

    async alterarPlano(payload: CadastroUsuarioPlanoRequest) {
        const url = `${AppConfig.apiEndpoint}/assinatura/alterar`;
        await this.httpClient.post(url, payload).toPromise();
    }

    updateTrialExpirou() {
        this.trialExpirou$.next(this.trialExpirou);
    }

    getTrialExpirou(): Observable<boolean> {
        return this.trialExpirou$.asObservable();
    }
}
