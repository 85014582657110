import { TipoAlteracao } from "./TipoAlteracao";

export class Versao {
    prefixo: string;
    texto: string;
    textoMarcado: string;
    textoGrifado: string;
    observacoes: string;
    revogado: boolean;
    tipoUltimaAlteracao: TipoAlteracao;
}

