import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-bandeiras-cartao",
    templateUrl: "./bandeiras-cartao.component.html",
    styleUrls: ["./bandeiras-cartao.component.scss"]
})
export class BandeirasCartaoComponent  {

    constructor() { }

}
