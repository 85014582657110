import { ItemLookupOrdenacaoNovaGuia, TipoOrdenacaoNovaGuia } from "../models/lei/item.lookup.ordenacao.nova.guia";

export const modosOrdenacao: ItemLookupOrdenacaoNovaGuia[] = [
    {
        label: "Ordem alfabética",
        ordenacao: TipoOrdenacaoNovaGuia.OrdemAlfabetica,
    },
    { label: "Mais lidos", ordenacao: TipoOrdenacaoNovaGuia.MaisLidos },
    { label: "Menos lidos", ordenacao: TipoOrdenacaoNovaGuia.MenosLidos },
    {
        label: "Data de alteração",
        ordenacao: TipoOrdenacaoNovaGuia.DataAlteracao,
    },
];
