import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConteudoService } from "../../services/conteudo.service";
import { Observable, of, Subscription } from "rxjs";
import { BuscaPanelParameters } from "../leitor-content-panelbusca/busca-panel.parameters";
import { BuscaService } from "src/app/services/busca.service";
import { UiService } from "src/app/services/ui.service";

@Component({
    selector: "app-leitor-content",
    templateUrl: "./leitor-content.component.html",
    styleUrls: ["./leitor-content.component.scss"],
})
export class LeitorContentComponent implements OnInit, OnDestroy {
    public mostrarNovaGuia = false;
    public mostrarPainelBusca = false;
    public mostrarResultadoBuscaAvancada = false;



    public breakpointMobileLg$: Observable<boolean> = of(false);

    private subscriptions: Subscription[] = [];

    constructor(
        private conteudoService: ConteudoService,
        private buscaService: BuscaService,
        public uiService: UiService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.conteudoService
                .getConteudo()
                .subscribe((conteudo) => this.conteudoChanged(conteudo?.novaGuia))
        );
        this.subscriptions.push(
            this.buscaService
                .getBusca()
                .subscribe((busca) => this.buscaChanged(busca))
        );

        this.breakpointMobileLg$ = this.uiService.getBreakpointMobileLg();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    public conteudoChanged(conteudo): void {
        if (conteudo) {
            this.mostrarNovaGuia = true;
        } else {
            this.mostrarNovaGuia = false;
        }
    }

    public buscaChanged(params: BuscaPanelParameters): void {
        this.mostrarPainelBusca = params ? true : false;

        if (params && !params?.buscaSemResultado && params?.buscarTodosDocumentos) {
            this.mostrarResultadoBuscaAvancada = true;
        } else {
            this.mostrarResultadoBuscaAvancada = false;
        }
    }


}
