import { Conteudo } from "./../../../../models/pagina/conteudo";
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { ConteudoService } from "../../../../services/conteudo.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfigDialogComponent } from "../../../popups/config-dialog/config-dialog.component";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";
import { TipoReferencia } from "src/app/models/Referencia";
import { UsuarioReferenciasGerenciadasService } from "src/app/services/data-services/usuario.referencias.gerenciadas.service";
import { ReferenciaGerenciada } from "src/app/models/ReferenciaGerenciada";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";

@Component({
    selector: "app-cabecalho",
    templateUrl: "./cabecalho.component.html",
    styleUrls: ["./cabecalho.component.scss"],
})
export class CabecalhoComponent implements OnInit, OnDestroy {
    @Input() indexLinhaAtual: number;
    @Input() tituloAtual: string;
    @Input() idLei: string;

    @Output() focoChange: EventEmitter<number> = new EventEmitter<number>();

    public exibirApenasItensComentados: boolean;
    public exibirMeusComentarios: boolean;
    public exibirComentariosSvm: boolean;
    public exibirItensRevogados: boolean;

    public dicasEstudosGerenciada: Array<ReferenciaGerenciada> = new Array<ReferenciaGerenciada>();
    public carregandoConteudo: boolean;
    public prefixoBusca: string;
    public tooltipBusca: string;
    public totalLinhas: number;
    public urlFonteLei: string;
    public isfavorito = false;
    private subscriptions = new Array<Subscription>();

    constructor(
        private conteudoService: ConteudoService,
        public dialog: MatDialog,
        public featureFlagService: FeatureFlagService,
        public usuarioReferenciasGerenciadasService: UsuarioReferenciasGerenciadasService,
        private usuarioEstatisticasService: UsuarioEstatisticasService    ) {}

    public get tituloAtualWrapped(): string {
        if (!this.tituloAtual) {
            return "";
        }

        return this.tituloAtual.length > 75
            ? this.tituloAtual.substring(0, 75) + "..."
            : this.tituloAtual;
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.conteudoService
                .getCarregando()
                .subscribe((c) => (this.carregandoConteudo = c ? true : false))
        );

        this.subscriptions.push(
            this.conteudoService
                .getConteudo()
                .subscribe((c) => this.conteudo_subscribe(c))
        );

        this.subscriptions.push(
            this.usuarioEstatisticasService
                .getLeisFavoritas()
                .subscribe((fav) => this.favoritos_changed(fav))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    favoritos_changed(fav: string[]): void {
        if (!fav) {
            return;
        }
        this.isfavorito = fav.indexOf(this.idLei) !== -1;
    }


    public async conteudo_subscribe(c: Conteudo) {
        if (!c) {
            return;
        }

        this.dicasEstudosGerenciada = [];

        const referenciasGerenciadas =
      await this.usuarioReferenciasGerenciadasService.buscarReferenciasGerenciadasLocalById(
          c.idLei
      );
        const aux =
        referenciasGerenciadas?.referencias.filter(
            (y) => y.tipo === TipoReferencia.DicasEstudo
        ) ?? [];
        this.dicasEstudosGerenciada.push(...aux);

        switch (c.tipoDocumento) {
            case 0:
                this.prefixoBusca = "Art. nº";
                this.tooltipBusca = "Buscar pelo número do artigo";
                break;
            case 1:
                this.prefixoBusca = "Súm. nº";
                this.tooltipBusca = "Buscar pelo número da súmula";
                break;
            case 2:
                this.prefixoBusca = "Art. nº";
                this.tooltipBusca = "Buscar pelo número do artigo";
                break;
            default:
                this.prefixoBusca = "";
                this.tooltipBusca = "";
                break;
        }

        this.totalLinhas = c.linhas.length;
        this.urlFonteLei = c.urlFonteLei;

        this.checkFavoritos();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ConfigDialogComponent, {
            width: "280px",
            data: {},
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(() => {});
    }

    public async favoritoAlterardo() {
        const estatisticas = await this.usuarioEstatisticasService.marcarFavorito(
            this.idLei
        );
        this.isfavorito = estatisticas.favorito;
    }

    private async checkFavoritos() {
        const estatistica = await this.usuarioEstatisticasService.buscar(
            this.idLei
        );
        this.isfavorito = estatistica.favorito ? true : false;
    }

}
