import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    EventEmitter,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core";

import { TrechoTexto } from "../../../../models/pagina/TrechoTexto";
import { ConteudoService } from "../../../../services/conteudo.service";
import { ParametrosCaneta } from "../../../../models/UserData";
import { HoverService } from "../../../../services/hover.service";
import { Subscription, Observable } from "rxjs";
import { UsuarioPreferenciasService } from "../../../../services/data-services/usuario.preferencias.service";
import { UsuarioMarcacoesService } from "../../../../services/data-services/usuario.marcacoes.service";
import { UsuarioComentariosService } from "../../../../services/data-services/usuario.comentarios.service";
import { UsuarioGrifosService } from "../../../../services/data-services/usuario.grifos.service";
import { Marcacao } from "../../../../models/Marcacao";
import { Comentario } from "../../../../models/Comentario";
import { Grifo } from "../../../../models/Grifo";
import {
    TextFormatingService,
    TextStyles,
} from "../../../../services/ui/textFormating.service";
import { Intervalo } from "../../../../models/Intervalo";
import { TipoIntervalo } from "../../../../enums/tipo.intervalo";
import { CharInfo } from "../../../../models/char.info";
import { BuscaRapidaService } from "../../../../controls/busca/form-busca-artigo-documento-atual/busca.rapida.service";

import { DialogoService } from "../../../popups/dialogo/dialogo.service";
import { UsuarioReferenciaService } from "src/app/services/data-services/usuario.referencia.service";
import { Referencia } from "src/app/models/Referencia";
import { BuscaService } from "src/app/services/busca.service";
import { BuscaPanelParameters } from "src/app/components/leitor-content-panelbusca/busca-panel.parameters";
import { ConfiguracoesUsuario } from "src/app/models/usuario/ConfiguracoesUsuario";
import { TextoPagina } from "src/app/models/pagina/TextoPagina";
import { TextoPaginaFunctions } from "src/app/models/TextoPaginaFunctions";
import { TipoSelecao } from "src/app/models/TipoSelecao";
import { DialogoModel } from "src/app/models/DialogoModel";
import { TipoTag } from "src/app/enums/TipoTag";
import { TagConteudo } from "src/app/interfaces/TagConteudo";

@Component({
    selector: "app-linha",
    templateUrl: "./linha.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./linha.component.scss",
        "./formatacao-texto.scss",
        "../../../../../styles/formatacao-texto.scss",
    ],
})
export class LinhaComponent implements OnInit, OnDestroy {


    @Output() on_cancelarComentario = new EventEmitter();

    @Output() on_salvarComentario = new EventEmitter<Comentario>();

    @Input() idItem: string;
    @Input() linha: TextoPagina;


    exibirApenasItensComentados = false;




    public paleta: ParametrosCaneta;

    public painelVersoesVisivel = false;
    resultadoBuscaTodosDocumentos = false;

    fontIncrement: Observable<number>;
    realceBuscaRapida: Intervalo;

    posicaoComentario: string;
    posicaoMnemonico: string;
    private _comentarioCriar: Comentario;
    private subscriptions: Subscription[] = [];
    private realcarMarcacoes = false;
    private realcarComentarios = false;
    private realcarMnemonicos = false;
    private ignoreMouseLeave = false;
    private destaques: TagConteudo[] = null;


    constructor(
        private changeDetector: ChangeDetectorRef,
        private conteudoService: ConteudoService,
        private hoverService: HoverService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private usuarioMarcacoesService: UsuarioMarcacoesService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioReferenciaService: UsuarioReferenciaService,
        private usuarioGrifosService: UsuarioGrifosService,
        private textFormatingService: TextFormatingService,
        private buscaRapidaService: BuscaRapidaService,
        private dialogoService: DialogoService,
        private buscaService: BuscaService
    ) {}

    get comentarioCriar() {
        return this._comentarioCriar;
    }
    @Input() set comentarioCriar(comentario: Comentario) {
        this._comentarioCriar = comentario;
    }
    cancelarComentario() {
        this._comentarioCriar = null;
        this.on_cancelarComentario.emit();
        this.changeDetector.markForCheck();
    }
    salvarComentario(e: Comentario) {
        this.on_salvarComentario.emit(e);
        this._comentarioCriar = null;
        this.changeDetector.markForCheck();
    }
    getDefaultFontSize() {
        let estilo = TextStyles.find(
            (t) => t.types.indexOf(this.linha.tipoTexto.replace("texto-", "")) !== -1
        );
        if (!estilo) {
            estilo = TextStyles.find((t) => t.default);
        }

        return estilo.fontsize;
    }

    public ngOnInit() {
        this.initialize();
        this.subscriptions.push(
            this.usuarioPreferenciasService
                .getConfiguracoes()
                .pipe()
                .subscribe((config) => this.preferencias_changed(config))
        );
        this.subscriptions.push(
            this.usuarioGrifosService
                .getModificadoGrifo()
                .subscribe((g) => this.grifos_changed(g))
        );
        this.subscriptions.push(
            this.usuarioComentariosService
                .getmodificadoComentario()
                .subscribe((c) => this.comentario_changed(c))
        );
        this.subscriptions.push(
            this.usuarioReferenciaService
                .getModificadoReferencia()
                .subscribe((r) => this.referencia_changed(r))
        );
        this.subscriptions.push(
            this.usuarioMarcacoesService
                .getModificadoMarcacao()
                .subscribe((m) => this.marcacao_changed(m))
        );
        this.subscriptions.push(
            this.hoverService
                .getItens()
                .subscribe((i) => this.itens_destacados_changed(i))
        );
        this.subscriptions.push(
            this.conteudoService
                .getMultipleReadedLinesChanged()
                .subscribe(() => this.carregarTexto())
        );
        this.subscriptions.push(
            this.buscaService.getBusca().subscribe((c) => this.conteudo_changed(c))
        );

        this.subscriptions.push(
            this.buscaRapidaService
                .getMatchBuscaRapida()
                .subscribe((matchBuscaRapida) => {
                    if (!matchBuscaRapida && this.realceBuscaRapida) {
                        this.realceBuscaRapida = null;
                        this.carregarTexto();
                    } else if (
                        matchBuscaRapida &&
            matchBuscaRapida.idOrigem === this.linha.id &&
            !(this.realceBuscaRapida, matchBuscaRapida)
                    ) {
                        this.realceBuscaRapida = matchBuscaRapida;
                        this.carregarTexto();
                    }
                })
        );

        this.fontIncrement = this.textFormatingService.getFontIncrement();
    }

    conteudo_changed(busca: BuscaPanelParameters): void {
        if (!busca) {
            this.resultadoBuscaTodosDocumentos = false;
            const recarregar =
        this.linha.resultadosBusca || this.linha.resultadosBuscaAtivo
            ? true
            : false;
            this.linha.resultadosBusca = null;
            this.linha.resultadosBuscaAtivo = null;

            if (recarregar) {
                this.carregarTexto();
            }
        } else {
            this.resultadoBuscaTodosDocumentos = busca.buscarTodosDocumentos;

            const resultados =
        busca && busca.matchsResultadoBusca
            ? busca.matchsResultadoBusca.filter(
                (m) => m.textoItemId === this.linha.id
            )
            : null;

            const resultadoDestacado =
        busca &&
        busca.matchResultadoBuscaFoco &&
        busca.matchResultadoBuscaFoco.textoItemId === this.linha.id
            ? busca.matchResultadoBuscaFoco
            : null;

            const processarAlteracoesBusca = () => {
                if (!(resultados === this.linha.resultadosBusca)) {
                    this.linha.resultadosBusca = resultados;
                }

                if (!(resultadoDestacado === this.linha.resultadosBuscaAtivo)) {
                    this.linha.resultadosBuscaAtivo = resultadoDestacado;
                }
            };

            if (
                !(resultados === this.linha.resultadosBusca) ||
        !(resultadoDestacado === this.linha.resultadosBuscaAtivo)
            ) {
                processarAlteracoesBusca();
            }

            this.carregarTexto();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    public mouseLeave(): void {
        if (this.ignoreMouseLeave === false) {
            this.hoverService.destacar(null);
        } else {
            this.ignoreMouseLeave = false;
        }
    }

    public async btnVersoesClick(e: Event) {
        e.stopPropagation();

        const mostrarNavegadorVersoes = () => {
            this.painelVersoesVisivel = true;
        };

        const ocultarNavegadorVersoes = async () => {
            this.painelVersoesVisivel = false;
            this.linha.indexVersao = this.linha.versoes.length - 1;
            this.carregarTexto();
        };

        if (this.painelVersoesVisivel) {
            ocultarNavegadorVersoes();
        } else {
            mostrarNavegadorVersoes();
        }
    }

    public async versaoAnterior() {
        this.linha.indexVersao--;
        this.carregarTexto();
        this.changeDetector.markForCheck();
    }

    public async proximaVersao() {
        this.linha.indexVersao++;
        this.carregarTexto();
    }

    public toggleLido(): void {
        this.conteudoService.marcarLido(this.linha.id);
        this.linha.lida = !this.linha.lida;
        this.changeDetector.markForCheck();
    }

    public marcarLidoAteAqui(): void {
        const model = new DialogoModel();

        model.titulo = "Marcar como lido até aqui";
        model.mensagem =
      "Todos os dispositivos anteriores à esse serão marcados como lidos neste documento. Deseja continuar?";

        model.incluirOpcao("Sim", "primary", async () => {
            this.conteudoService.marcarLidoAteAqui(this.linha.index);
            this.dialogoService.fecharDialogo();
        });
        model.incluirOpcao("Não", "warn", () => {
            this.dialogoService.fecharDialogo();
        });

        this.dialogoService.mostrarDialogo(model);
    }

    private initialize() {
        const prefs = this.usuarioPreferenciasService.getConfiguracoesvalue;

        if (prefs && prefs.parametrosCaneta) {
            this.paleta = prefs.parametrosCaneta;
            this.realcarMarcacoes = prefs.parametrosCaneta.realcarMarcacoesProva;
            this.realcarComentarios = prefs.parametrosCaneta.realcarComentarios;
            this.realcarMnemonicos = prefs.parametrosCaneta.realcarMnemonicos;
            this.posicaoComentario = prefs.parametrosCaneta.posicionamentoComentario;
            this.posicaoMnemonico = prefs.parametrosCaneta.posicionamentoMnemonico;
        }
    }

    private itens_destacados_changed(itens: TagConteudo[]) {
        if ((this.destaques, itens)) {
            return;
        }

        const itensProcessar = itens
            ? itens.filter((i) => i.ids.find((x) => x.idItem === this.linha.id))
            : new Array<TagConteudo>();
        itens = itensProcessar.length > 0 ? itensProcessar : null;

        if ((this.destaques, itens)) {
            return;
        }

        this.destaques = itens;
        this.carregarTexto();
    }

    private grifos_changed(g: Grifo) {
        if (!g || g.idItem.indexOf(this.linha.id) === -1) {
            return;
        }

        if (g.removido) {
            this.linha.grifos.splice(this.linha.grifos.indexOf(g), 1);
        } else if (this.linha.grifos.findIndex((gr) => gr.id === g.id) === -1) {
            this.linha.grifos.push(g);
        }

        this.carregarTexto();
    }

    private comentario_changed(c: Comentario) {
        if (
            !c ||
      c.range.idItens.map((i) => i.idItem).indexOf(this.linha.id) === -1
        ) {
            return;
        }

        if (c.removido) {
            this.linha.comentarios.splice(this.linha.comentarios.indexOf(c), 1);
        } else if (this.linha.comentarios.findIndex((m) => m.id === c.id) === -1) {
            this.linha.comentarios.push(c);
        }

        this.carregarTexto();
    }

    private referencia_changed(r: Referencia) {
        if (!r || r.links.findIndex((i) => i.idItem === this.linha.id) === -1) {
            return;
        }

        if (r.removido) {
            this.linha.referencias.splice(this.linha.referencias.indexOf(r), 1);
        } else if (this.linha.referencias.findIndex((m) => m.id === r.id) === -1) {
            this.linha.referencias.push(r);
        } else {
            this.linha.referencias.forEach((m) => {
                if (m.id === r.id) {
                    m.conteudo = r.conteudo;
                    m.links = r.links;
                    m.link = r.link;
                    m.ref = r.ref;
                    m.tipo = r.tipo;
                }
            });
        }

        this.carregarTexto();
    }

    private marcacao_changed(m: Marcacao) {
        if (
            !m ||
      m.range.idItens.map((i) => i.idItem).indexOf(this.linha.id) === -1
        ) {
            return;
        }

        if (m.removido) {
            this.linha.marcacoesProva.splice(this.linha.marcacoesProva.indexOf(m), 1);
        } else if (
            this.linha.marcacoesProva.findIndex((ma) => ma.id === m.id) === -1
        ) {
            this.linha.marcacoesProva.push(m);
        }

        this.carregarTexto();
    }

    private carregarTexto() {
        let prefixo = TextoPaginaFunctions.getVersao(this.linha).prefixo;
        if (prefixo) {
            prefixo = prefixo.replace("  ", " ");
        }

        const textoVersa = TextoPaginaFunctions.getVersao(this.linha).texto;
        const textocompleto = (prefixo ? prefixo : "") + (textoVersa ? textoVersa : "");

        const carregarApenasTexto = () => {
            const trechoPrefixo = new TrechoTexto();
            trechoPrefixo.texto = prefixo;
            this.linha.trechosPrefixo.push(trechoPrefixo);

            const trechoTexto = new TrechoTexto();
            trechoTexto.texto = textoVersa;
            this.linha.trechosTexto.push(trechoTexto);

            this.changeDetector.markForCheck();
        };

        const carregarComRealces = () => {
            const marcacoesDestacar: Array<TagConteudo> = this.destaques
                ? this.destaques.filter((d) => d.tipoTag === TipoTag.Marcacao)
                : new Array<TagConteudo>();
            const comentariosDestacar: Array<TagConteudo> = this.destaques
                ? this.destaques.filter((d) => d.tipoTag === TipoTag.Comentario)
                : new Array<TagConteudo>();
            const idBuscaAtual: string = this.linha.resultadosBuscaAtivo
                ? this.linha.resultadosBuscaAtivo.id
                : null;

            const intervalosMarcacao = new Array<Intervalo>();
            const intervalosMarcacaoDestacar = new Array<Intervalo>();
            const todasMarcacoes = new Set(
                this.linha.marcacoesProva.filter(
                    (x) =>
                        x.range.idItens.findIndex(
                            (id) => id.idImportacao === this.linha.indexVersao
                        ) !== -1
                )
            );
            marcacoesDestacar.forEach((m) => {
                todasMarcacoes.add(m.tag);
            });

            if (todasMarcacoes) {
                todasMarcacoes.forEach((marcacao) => {
                    const destacar =
            marcacoesDestacar.findIndex((x) => x.idTag === marcacao.id) !== -1;
                    const primeiraLinhaMarcacao =
            marcacao.range.idItens.findIndex(
                (i) => i.idItem === this.linha.id
            ) === 0;
                    const ultimaLinhaMarcacao =
            marcacao.range.idItens.findIndex(
                (i) => i.idItem === this.linha.id
            ) ===
            marcacao.range.idItens.length - 1;

                    const de = primeiraLinhaMarcacao ? marcacao.range.inicio : null;
                    const ate = ultimaLinhaMarcacao ? marcacao.range.termino : null;

                    const intervalo = new Intervalo(
                        marcacao.id,
                        TipoIntervalo.MarcacaoProva,
                        destacar,
                        de,
                        ate,
                        marcacao.dataHoraModificacao
                    );
                    (intervalo.destacar
                        ? intervalosMarcacaoDestacar
                        : intervalosMarcacao
                    ).push(intervalo);
                });
            }

            const intervalosComentario = new Array<Intervalo>();
            const intervalosComentarioDestacar = new Array<Intervalo>();
            const todosComentarios = new Set(
                this.linha.comentarios.filter(
                    (x) =>
                        x.range.idItens.findIndex(
                            (id) => id.idImportacao === this.linha.indexVersao
                        ) !== -1
                )
            );
            comentariosDestacar.forEach((c) => {
                todosComentarios.add(c.tag);
            });

            if (todosComentarios) {
                todosComentarios.forEach((comentario) => {
                    const destacar =
            comentariosDestacar.findIndex((x) => x.idTag === comentario.id) !==
            -1;

                    const primeiraLinhaComentario =
            comentario.range.idItens.findIndex(
                (i) => i.idItem === this.linha.id
            ) === 0;
                    const ultimaLinhaComentario =
            comentario.range.idItens.findIndex(
                (i) => i.idItem === this.linha.id
            ) ===
            comentario.range.idItens.length - 1;

                    const de = primeiraLinhaComentario ? comentario.range.inicio : null;
                    const ate = ultimaLinhaComentario ? comentario.range.termino : null;

                    const intervalo = new Intervalo(
                        comentario.id,
                        comentario.mnemonico
                            ? TipoIntervalo.Mnemonico
                            : TipoIntervalo.Comentario,
                        destacar,
                        de,
                        ate,
                        comentario.dataHoraModificacao
                    );
                    (intervalo.destacar
                        ? intervalosComentarioDestacar
                        : intervalosComentario
                    ).push(intervalo);
                });
            }

            const intervalosBusca = new Array<Intervalo>();
            const intervalosBuscaDestacar = new Array<Intervalo>();
            if (this.linha.resultadosBusca) {
                this.linha.resultadosBusca.forEach((resultado) => {
                    const destacar = idBuscaAtual === resultado.id;

                    const de = resultado.de === -1 ? 0 : resultado.de;
                    const ate =
            resultado.ate === -1 ? textocompleto.length : resultado.ate;

                    const intervalo = new Intervalo(
                        resultado.id,
                        TipoIntervalo.ResultadoBusca,
                        destacar,
                        de,
                        ate,
                        new Date()
                    );
                    (intervalo.destacar ? intervalosBuscaDestacar : intervalosBusca).push(
                        intervalo
                    );
                });
            }

            const intervalosMarcaTexto = new Array<Intervalo>();
            const grifosVersaoAtual = this.linha.grifos.filter(
                (x) => x.idImportacao === this.linha.indexVersao
            );
            if (grifosVersaoAtual) {
                grifosVersaoAtual.forEach((grifo) => {
                    const de = grifo.inicio === -1 ? 0 : grifo.inicio;
                    const ate =
            grifo.termino === -1 ? textocompleto.length : grifo.termino;

                    let tipo: any;
                    switch (grifo.tipo) {
                        case TipoSelecao.Caneta1:
                            tipo = TipoIntervalo.Caneta1;
                            break;
                        case TipoSelecao.Caneta2:
                            tipo = TipoIntervalo.Caneta2;
                            break;
                        case TipoSelecao.Caneta3:
                            tipo = TipoIntervalo.Caneta3;
                            break;
                        case TipoSelecao.Caneta4:
                            tipo = TipoIntervalo.Caneta4;
                            break;
                        case TipoSelecao.Caneta5:
                            tipo = TipoIntervalo.Caneta5;
                            break;
                    }
                    const intervalo = new Intervalo(
                        grifo.id,
                        tipo,
                        false,
                        de,
                        ate,
                        grifo.dataHoraModificacao,
                        grifo.tipo
                    );
                    intervalosMarcaTexto.push(intervalo);
                });
            }

            let intervalosSublinhar = new Array<Intervalo>();
            intervalosMarcacao.forEach((i) => intervalosSublinhar.push(i));
            intervalosComentario.forEach((i) => intervalosSublinhar.push(i));
            intervalosMarcaTexto
                .filter((x) => x.getModoRealce(this.paleta) === "Sublinhar")
                .forEach((i) => intervalosSublinhar.push(i));

            let intervalosPreencher = new Array<Intervalo>();
            intervalosMarcaTexto
                .filter(
                    (x) =>
                        !x.getModoRealce(this.paleta) ||
            x.getModoRealce(this.paleta) === "Grifar"
                )
                .forEach((i) => intervalosPreencher.push(i));
            intervalosBusca.forEach((i) => intervalosPreencher.push(i));
            intervalosBuscaDestacar.forEach((i) => intervalosPreencher.push(i));
            intervalosMarcacaoDestacar.forEach((i) => intervalosPreencher.push(i));
            intervalosComentarioDestacar.forEach((i) => intervalosPreencher.push(i));

            if (this.realceBuscaRapida) {
                intervalosPreencher.push(this.realceBuscaRapida);
            }



            const resolverIntervalosSobrepostos = (
                arrayIntervalos: Intervalo[]
            ): Intervalo[] => {
                arrayIntervalos
                    .filter((inter) => inter.ranges[0].de === null)
                    .forEach((element) => (element.ranges[0].de = 0));
                arrayIntervalos
                    .filter(
                        (inter) => inter.ranges[0].ate === null || inter.ranges[0].ate === 0
                    )
                    .forEach((element) => (element.ranges[0].ate = textocompleto.length));

                // Ordena os intervalos pelo início

                arrayIntervalos.sort((a, b) => a.ranges[0].de - b.ranges[0].ate);

                const iAjuste = new Array<Intervalo>();

                arrayIntervalos.forEach((intervalo) => {
                    intervalo.ranges
                        .filter((r) => !r.ignorar)
                        .forEach((range) => {
                            iAjuste.push(
                                new Intervalo(
                                    intervalo.idOrigem,
                                    intervalo.tipoIntervalo,
                                    intervalo.destacar,
                                    range.de,
                                    range.ate,
                                    intervalo.dataAlteracao,
                                    intervalo.tipoSelecao
                                )
                            );
                        });
                });

                return iAjuste;
            };

            intervalosSublinhar = resolverIntervalosSobrepostos(intervalosSublinhar);
            intervalosPreencher = resolverIntervalosSobrepostos(intervalosPreencher);

            const montarTrechosTexto = (
                texto: string,
                indexTextoCompleto: number,
                sublinhar: Intervalo[],
                preencher: Intervalo[]
            ): TrechoTexto[] => {
                let retorno = new Array<TrechoTexto>();
                if (!texto) {
                    return;
                }

                // Feito para que ajustes do prefixo não afetem o texto
                const sublinharParteAtual = sublinhar;
                const preencherParteAtual = preencher;

                const terminoIndex = indexTextoCompleto + texto.length;

                // Verificar se existem trechos para sublinhar ou preencher no trecho enviado;
                let preencherFiltrado = preencherParteAtual.filter(
                    (item) =>
                        (indexTextoCompleto <= item.ranges[0].de &&
              terminoIndex > item.ranges[0].de) ||
            (indexTextoCompleto >= item.ranges[0].de &&
              indexTextoCompleto <= item.ranges[0].ate)
                );

                let sublinharFiltrado = sublinharParteAtual.filter(
                    (item) =>
                        (indexTextoCompleto <= item.ranges[0].de &&
              terminoIndex > item.ranges[0].de) ||
            (indexTextoCompleto >= item.ranges[0].de &&
              indexTextoCompleto <= item.ranges[0].ate)
                );

                // Verificar se existem trechos para sublinhar ou preencher
                if (preencherFiltrado.length === 0 && sublinharFiltrado.length === 0) {
                    // Se não, criar um Trecho de texto sem preenchimento ou sublinhado, apenas com o texto completo;
                    const trechoSimples = new TrechoTexto();
                    trechoSimples.texto = texto;
                    retorno.push(trechoSimples);
                } else {
                    // Se sim, ajustar os intervalos de preenchimento de acordo com os de sublinhar e posicionar corretamente no texto
                    preencherFiltrado = preencherFiltrado.sort(
                        (a, b) => a.ranges[0].de - b.ranges[0].de
                    );
                    sublinharFiltrado = sublinharFiltrado.sort(
                        (a, b) => a.ranges[0].de - b.ranges[0].de
                    );

                    const charInfoList = new Array<CharInfo>();

                    preencherFiltrado.forEach((p) => {
                        p.ranges[0].de = p.ranges[0].de - indexTextoCompleto;
                        p.ranges[0].ate = p.ranges[0].ate - indexTextoCompleto + 1;
                    });

                    sublinharFiltrado.forEach((s) => {
                        s.ranges[0].de = s.ranges[0].de - indexTextoCompleto;
                        s.ranges[0].ate = s.ranges[0].ate - indexTextoCompleto + 1;
                    });

                    for (let i = 0; i < texto.length; i++) {
                        const info = new CharInfo();
                        info.index = i;
                        info.preencher = preencherFiltrado.find(
                            (p) => p.ranges[0].de <= i && p.ranges[0].ate > i
                        );
                        info.sublinhar = sublinharFiltrado.find(
                            (p) => p.ranges[0].de <= i && p.ranges[0].ate > i
                        );

                        charInfoList.push(info);
                    }

                    const trechos = new Array<TrechoTexto>();
                    let trecho = new TrechoTexto();

                    let idPreencherAtual = null;
                    let idSublinharAtual = null;

                    charInfoList.forEach((charInfo) => {
                        const idPreencher = charInfo.preencher
                            ? charInfo.preencher.idOrigem
                            : null;
                        const idSublinhar = charInfo.sublinhar
                            ? charInfo.sublinhar.idOrigem
                            : null;

                        if (
                            charInfo.index === 0 ||
              idPreencher !== idPreencherAtual ||
              idSublinhar !== idSublinharAtual
                        ) {
                            idPreencherAtual = idPreencher;
                            idSublinharAtual = idSublinhar;

                            if (charInfo.index > 0) {
                                trechos.push(trecho);
                                trecho = new TrechoTexto();
                            }

                            if (idSublinhar) {
                                trecho.textDecoration = sublinhar
                                    .find((x) => x.idOrigem === idSublinhar)
                                    .getTextDecoration(this.paleta);
                                // feito dessa forma pois objetos copiados perdem os métodos
                                // trecho.textDecoration = charInfo.sublinhar.getTextDecoration(this.paleta);
                                if (
                                    charInfo.sublinhar.tipoIntervalo ===
                  TipoIntervalo.MarcacaoProva
                                ) {
                                    trecho.marcacao = this.linha.marcacoesProva.find(
                                        (m) => m.id === charInfo.sublinhar.idOrigem
                                    );
                                } else if (
                                    charInfo.sublinhar.tipoIntervalo ===
                    TipoIntervalo.Comentario ||
                  charInfo.sublinhar.tipoIntervalo === TipoIntervalo.Mnemonico
                                ) {
                                    trecho.comentario = this.linha.comentarios.find(
                                        (m) => m.id === charInfo.sublinhar.idOrigem
                                    );
                                } else {
                                    trecho.grifo = this.linha.grifos.find(
                                        (x) => x.id === charInfo.sublinhar.idOrigem
                                    );
                                }
                            }

                            if (idPreencher) {
                                // feito dessa forma pois objetos copiados perdem os métodos
                                const grifo = this.linha.grifos
                                    ? this.linha.grifos.find(
                                        (g) => g.id === charInfo.preencher.idOrigem
                                    )
                                    : null;
                                if (grifo) {
                                    trecho.grifo = grifo;
                                }

                                const resultadoBusca = this.linha.resultadosBusca
                                    ? this.linha.resultadosBusca.find(
                                        (x) => x.id === charInfo.preencher.idOrigem
                                    )
                                    : null;
                                if (resultadoBusca) {
                                    trecho.realceBusca = true;
                                }

                                const resultadoBuscaAtivo =
                  this.linha.resultadosBuscaAtivo &&
                  this.linha.resultadosBuscaAtivo.id ===
                    charInfo.preencher.idOrigem
                      ? this.linha.resultadosBuscaAtivo
                      : null;
                                if (resultadoBuscaAtivo) {
                                    trecho.realceBusca = true;
                                }

                                trecho.backgroundColor = preencher
                                    .find((x) => x.idOrigem === idPreencher)
                                    .getBackgroundColor(this.paleta);
                                trecho.modoRealce = preencher
                                    .find((x) => x.idOrigem === idPreencher)
                                    .getModoRealce(this.paleta);
                            }
                        }

                        trecho.texto += texto[charInfo.index];
                    });

                    trechos.push(trecho);
                    retorno = trechos;
                }

                return retorno;
            };

            let prefixoLinha = TextoPaginaFunctions.getVersao(this.linha).prefixo;
            prefixoLinha = prefixoLinha ? prefixoLinha : "";

            let textoLinha = TextoPaginaFunctions.getVersao(this.linha).texto;
            textoLinha = textoLinha ? textoLinha : "";

            this.linha.trechosPrefixo = montarTrechosTexto(
                prefixoLinha,
                0,
                intervalosSublinhar,
                intervalosPreencher
            );
            this.linha.trechosTexto = montarTrechosTexto(
                textoLinha,
                prefixoLinha.length,
                intervalosSublinhar,
                intervalosPreencher
            );

            this.changeDetector.markForCheck();
        };

        const realcarMarcacoes = this.realcarMarcacoes;
        const realcarComentarios = this.realcarComentarios;
        const realcarMnemonicos = this.realcarMnemonicos;
        const realcarGrifos = this.linha.grifos && this.linha.grifos.length > 0;
        const realcarResultadosBusca =
      (this.linha.resultadosBusca && this.linha.resultadosBusca.length > 0) ||
      this.realceBuscaRapida;

        this.linha.trechosPrefixo = new Array<TrechoTexto>();
        this.linha.trechosTexto = new Array<TrechoTexto>();

        if (
            !realcarMarcacoes &&
      !realcarComentarios &&
      !realcarMnemonicos &&
      !realcarGrifos &&
      !realcarResultadosBusca
        ) {
            carregarApenasTexto();
        } else {
            carregarComRealces();
        }
    }

    private preferencias_changed(config: ConfiguracoesUsuario) {
        const recarregar = true;

        if (!config) {
            return;
        }
        this.posicaoComentario = config.parametrosCaneta.posicionamentoComentario;
        this.posicaoMnemonico = config.parametrosCaneta.posicionamentoMnemonico;

        if (recarregar) {
            this.carregarTexto();
        }
    }



}
