import { Component, OnInit, OnDestroy } from "@angular/core";
import { TextoPagina } from "../../models/pagina/TextoPagina";
import {
    BuscaPanelParameters,
} from "../leitor-content-panelbusca/busca-panel.parameters";
import { BuscaService } from "../../services/busca.service";
import { Subscription } from "rxjs";
import { Guia } from "../../models/Guia";
import { ParametrosCaneta } from "../../models/UserData";
import { UsuarioGuiasService } from "../../services/data-services/usuario.guias.service";
import { ConteudoService } from "../../services/conteudo.service";
import { Item } from "../../models/Item";
import { Versao } from "../../models/Versao";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { UsuarioMarcacoesService } from "../../services/data-services/usuario.marcacoes.service";
import { UsuarioComentariosService } from "../../services/data-services/usuario.comentarios.service";
import { UsuarioGrifosService } from "../../services/data-services/usuario.grifos.service";
import { Marcacao } from "../../models/Marcacao";
import { Comentario } from "../../models/Comentario";
import { Grifo } from "../../models/Grifo";
import { IDatasource } from "ngx-ui-scroll";
import { UsuarioEstatisticasService } from "../../services/data-services/usuario.estatisticas.service";

@Component({
    selector: "app-leitor-content-panelbuscaavancada",
    templateUrl: "./leitor-content-panelbuscaavancada.component.html",
    styleUrls: ["./leitor-content-panelbuscaavancada.component.scss"],
})
export class LeitorContentPanelBuscaAvancadaComponent
implements OnInit, OnDestroy {


    paginas = new Array<PaginaResultadoBusca>();
    indexPaginaAtual: number;
    carregando: boolean;
    textoBotaoPaginador: string;
    opcoesCoresPonteiros: ParametrosCaneta;
    marcacoes: Marcacao[];
    comentarios: Comentario[];
    canetas: Grifo[];
    linhasLidas: string[];


    datasource: IDatasource = {
        get: (index, count, success) => {
            const itens= [];

            if (this.linhas.length === 0) {
                success([]);
            } else {
                for (let i = index; i <= index + count - 1; i++) {
                    const linha = this.linhas[i];
                    if (linha) {
                        itens.push(linha);
                    }
                }

                success(itens);
            }
        },
        settings: {
            startIndex: 0,
        },
    };
    private subscriptions: Subscription[] = [];

    private _busca: BuscaPanelParameters;

    constructor(
        private buscaService: BuscaService,
        private conteudoService: ConteudoService,
        private usuarioGuiasService: UsuarioGuiasService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private usuarioMarcacoesService: UsuarioMarcacoesService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioGrifosService: UsuarioGrifosService,
        private usuarioEstatisticasService: UsuarioEstatisticasService
    ) { }

    private get linhas() {
        const retorno = [];

        if (!this.paginas || !this.paginas[this.indexPaginaAtual]) {
            return retorno;
        }

        this.paginas[this.indexPaginaAtual].nodes.forEach((node) => {
            const cabecalho = new CabecalhoGrupoBusca();
            cabecalho.breadcrumb = node.breadcrumb;
            cabecalho.idNavegar = node.itens[0].id;

            retorno.push(cabecalho);

            node.itens.forEach((item) => {
                retorno.push(item);
            });
        });

        return retorno;
    }


    async ngOnInit(): Promise<void> {
        this.subscriptions.push(
            this.buscaService.getBusca().subscribe(async (c) => {
                if (!c) {
                    return;
                }
                this._busca = c;
                await this.refresh();
            })
        );

        this.subscriptions.push(
            this.usuarioPreferenciasService
                .getConfiguracoes()
                .subscribe((p) => this.preferencias_changed(p))
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }



    btnLeft_click() {
        let paginaAtual = this.indexPaginaAtual;

        if (paginaAtual === 0) {
            paginaAtual = this.paginas.length - 1;
        } else {
            paginaAtual--;
        }

        this.carregarPagina(paginaAtual)
            .then(() => { })
            .catch((err) => {
                throw err;
            });
    }

    btnRight_click() {
        let paginaAtual = this.indexPaginaAtual;

        if (paginaAtual === this.paginas.length - 1) {
            paginaAtual = 0;
        } else {
            paginaAtual++;
        }

        this.carregarPagina(paginaAtual)
            .then(() => { })
            .catch((err) => {
                throw err;
            });
    }

    alterarPagina(pagina: PaginaResultadoBusca): void {
        const paginaAtual = this.paginas.findIndex((p) => p.idLei === pagina.idLei);
        if (this.indexPaginaAtual !== paginaAtual) {
            this.carregarPagina(paginaAtual)
                .then(() => { })
                .catch((err) => {
                    throw err;
                });
        }
    }

    carregarPagina(indexLei: number): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            this._busca.idPagina = indexLei;

            this.buscaService
                .buscar(this._busca)
                .then(() => {
                    this.indexPaginaAtual = indexLei;
                    onsuccess();
                })
                .catch((err) => onerror(err));
        });
    }

    async abrirEmNovaGuia(pagina: PaginaResultadoBusca, id: string) {
        // Salvar status busca avançada
        await this.buscaService.salvarBusca();

        const guia = new Guia();
        guia.idLei = pagina.idLei;
        guia.titulo = pagina.tituloLei;

        await this.usuarioEstatisticasService.alterarPosicaoLeitura(
            pagina.idLei,
            id
        );
        await this.usuarioGuiasService.novaGuia(guia);
        await this.buscaService.fecharPainelBusca(false);
    }

    instanceofClass(instance: any): string {
        return instance instanceof CabecalhoGrupoBusca
            ? "CabecalhoGrupoBusca"
            : "TextoPagina";
    }

    private preferencias_changed(p: ConfiguracoesUsuario): void {
        this.opcoesCoresPonteiros = p ? p.parametrosCaneta : null;
    }

    private async refresh(): Promise<void> {
        if (!this._busca || !this._busca.buscarTodosDocumentos) {
            this.paginas = null;
        } else {
            this.carregando = true;
            this.paginas = [];

            this._busca.resultadosBuscaWeb.leisEncontradas.forEach((lei) => {
                const pagina = new PaginaResultadoBusca();

                pagina.idLei = lei.id;
                pagina.tituloLei = lei.titulo;
                pagina.dataAtualizacaoLei = lei.DataHoraUltimaModificacao;

                this.paginas.push(pagina);
            });

            this.indexPaginaAtual = this.paginas.findIndex(
                (pagina) =>
                    pagina.idLei === this._busca.resultadosBuscaWeb.resultadoAtual.id
            );

            const paginaAtual = this.paginas[this.indexPaginaAtual];

            this.marcacoes = await this.usuarioMarcacoesService.buscarLei(
                paginaAtual.idLei
            );
            this.comentarios = await this.usuarioComentariosService.buscarLei(
                paginaAtual.idLei
            );
            this.canetas = await this.usuarioGrifosService.buscarLei(
                paginaAtual.idLei
            );

            if (this._busca && this._busca.resultadosBuscaWeb) {
                this._busca.resultadosBuscaWeb.nodes.forEach((nodeResponse) => {
                    const nodeResultado = new NodeResultadoBusca();
                    nodeResultado.breadcrumb = nodeResponse.breadcrumb.filter((b) => b);

                    nodeResponse.children.forEach((child) => {
                        const item = new Item();

                        item.id = child.id;
                        item.idLei = paginaAtual.idLei;
                        item.tipoItem = child.tipo;

                        const versao = new Versao();
                        versao.prefixo = child.prefixo;
                        versao.texto = child.texto;
                        item.versoes.push(versao);

                        const linha = new TextoPagina(item);
                        linha.idLei = paginaAtual.idLei;
                        linha.tituloLei = paginaAtual.tituloLei;

                        linha.marcacoesProva = this.marcacoes.filter(
                            (m) =>
                                m.range.idItens.findIndex((i) => i.idItem === child.id) > -1
                        );
                        linha.comentarios = this.comentarios.filter(
                            (c) =>
                                c.range.idItens.findIndex((i) => i.idItem === child.id) > -1
                        );
                        linha.grifos = this.canetas.filter((c) => c.idLei === child.id);

                        nodeResultado.itens.push(linha);
                    });

                    paginaAtual.nodes.push(nodeResultado);
                });
            }

            if (this._busca) {
                this.textoBotaoPaginador =
          this._busca.resultadosBuscaWeb.resultadoAtual.titulo;
                this.paginas[this.indexPaginaAtual] = paginaAtual;
            } else {
                this.paginas = null;
            }

            this.carregando = false;
        }
    }


}
export class NodeResultadoBusca {
    breadcrumb: string[];
    itens: TextoPagina[] = [];
}

export class CabecalhoGrupoBusca {
    breadcrumb: string[];
    idNavegar: string;
}

export class PaginaResultadoBusca {
    idLei: string;
    tituloLei: string;
    dataAtualizacaoLei: Date;
    baixada: boolean;
    nodes: NodeResultadoBusca[] = [];
}

