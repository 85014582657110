import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core";

import { ItemLookupOrdenacaoNovaGuia } from "src/app/models/lei/item.lookup.ordenacao.nova.guia";
import { Observable, of, Subscription } from "rxjs";
import { LeiLookup } from "src/app/models/lei/lei.lookup";
import { UsuarioEstatisticasService } from "src/app/services/data-services/usuario.estatisticas.service";
import { Guia } from "src/app/models/Guia";
import { UsuarioGuiasService } from "src/app/services/data-services/usuario.guias.service";
import { BuscaService } from "src/app/services/busca.service";
import { BuscaPanelParameters } from "../../leitor-content-panelbusca/busca-panel.parameters";
import { StatusService } from "src/app/services/status.service";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { UiService } from "src/app/services/ui.service";
import { PopupsService } from "../../popups/popups.service";
import { modosOrdenacao } from "src/app/constants/modosOrdenacao";
import { NavegacaoPesquisaLeisService } from "./navegacao-pesquisa-leis.service";

@Component({
    selector: "app-navegacao-pesquisa-leis",
    templateUrl: "./navegacao-pesquisa-leis.component.html",
    styleUrls: ["./navegacao-pesquisa-leis.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavegacaoPesquisaLeisComponent
implements OnInit, AfterViewChecked {
    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
    @ViewChild("serchViewOverlay") serchViewOverlay: ElementRef;

    public overlayWith$: Observable<number> = of(0);
    public isOverlayOpen = false;

    public opcoesModoOrdenacao = modosOrdenacao;
    public modoOrdenacao$: Observable<ItemLookupOrdenacaoNovaGuia> = of(
        modosOrdenacao[3]
    );
    public searchText = "";
    public listaLeis$: Observable<LeiLookup[]> = of([]);
    public listaRecentSearche$: Observable<string[]> = of([]);
    public appOffline$: Observable<boolean> = of(false);
    public exibirNavegacaoPesquisaMobile$: Observable<boolean> = of(false);
    private subscriptions: Subscription[] = [];

    constructor(
        private navegacaoPesquisaLeisService: NavegacaoPesquisaLeisService,
        private usuarioEstatisticasService: UsuarioEstatisticasService,
        private usuarioGuiasService: UsuarioGuiasService,
        private buscaService: BuscaService,
        private statusService: StatusService,
        private uiService: UiService,
        private popupsService: PopupsService
    ) {}

    ngAfterViewChecked(): void {
        this.overlayGetMinWith();
    }

    ngOnInit(): void {
        this.listaLeis$ = this.navegacaoPesquisaLeisService.getLeisLookup();
        this.modoOrdenacao$ = this.navegacaoPesquisaLeisService.getModoOrdenacao();
        this.appOffline$ = this.statusService.getAppOffline();

        this.listaRecentSearche$ =
      this.navegacaoPesquisaLeisService.getRecentSearches();
        this.subscriptions.push(
            this.navegacaoPesquisaLeisService
                .getExibirNavegacaoPesquisaMobile()
                .subscribe((exibir) => {
                    if (exibir) {
                        this.overlayOpenMobile();
                    }
                })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    public searchText_Change(): void {
        this.navegacaoPesquisaLeisService.alterarTextoBusca(this.searchText);
    }

    searchText_clear() {
        this.searchText = "";
        this.searchText_Change();
    }

    removeRecentSearche(item: string) {
        this.navegacaoPesquisaLeisService.removeRecentSearche(item);
    }

    public toggleFiltroFavoritos() {
        this.navegacaoPesquisaLeisService.favoritos_toggle();
    }

    public async favoritoAlterardo(item: LeiLookup) {
        const estatisticas = await this.usuarioEstatisticasService.marcarFavorito(
            item.id
        );
        item.favorita = estatisticas.favorito;
    }

    public async alterarModoOrdenacao(
        novoModo?: ItemLookupOrdenacaoNovaGuia
    ): Promise<void> {
        if (!novoModo) {
            novoModo = modosOrdenacao[3];
        }
        await this.navegacaoPesquisaLeisService.alterarModoOrdenacao(novoModo);
    }

    public overlayOpen() {
        this.isOverlayOpen = !this.isOverlayOpen;
        if (this.uiService.isMobile) {
            this.navegacaoPesquisaLeisService.updateExibirNavegacaoPesquisaMobile();
        }
    }

    public overlayOpenMobile() {
        this.isOverlayOpen = !this.isOverlayOpen;
    }

    public overlayGetMinWith() {
        this.overlayWith$ = of(this.serchViewOverlay?.nativeElement?.clientWidth);
    }

    public async selecionar(item: LeiLookup): Promise<void> {
        this.overlayOpen();
        const guia = new Guia();
        guia.idLei = item.id;
        guia.titulo = item.titulo;
        await this.usuarioGuiasService.novaGuia(guia);
    }

    public abrirBusca(): void {
        this.buscaService.abrirPainelBusca(null);
        const params = new BuscaPanelParameters();
        params.textoBuscar = this.searchText;
        params.buscarTodosDocumentos = false;
    }

    async getNextData(event) {
        if (event === 0 || this.navegacaoPesquisaLeisService.valuePagina.has(-1)) {
            return;
        }

        const final = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();

        if (this.searchText.length === 0 && event >= total && final === total) {
            await this.navegacaoPesquisaLeisService.LeisPaginada();
        }
        if (this.searchText.length >= 3 && event >= total && final === total) {
            await this.navegacaoPesquisaLeisService.LeisPesquisaPaginada();
        }
    }
}
