export class MaskHelper {
    public static celular = "(00)000000000";

    public static mesAno = "00/00";

    public static diaMesAno = "00/00/0000";

    public static cpf = "000.000.000-00";

    public static cep = "00000-000";

    public static cartaoCredito = "0000 0000 0000 0000";

    public static cvv = "000";

    private static DiaMesAnoFunc(valor) {
        if (!valor) {
            return [
                /[0-3]/,
                /\d/,
                "/",
                /[0-1]/,
                /\d/,
                "/",
                /[0-2]/,
                /\d/,
                /\d/,
                /\d/,
            ];
        }
    }
}
