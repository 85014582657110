import { Injectable } from "@angular/core";
import { StorageHelper } from "../../helpers/storage.helper";
import { ProvaDatasource, ItemProvaDatasource } from "../../models/UserData";
import {
    OperacaoSignalR,
    SignalrService,
    EnumTipoObjeto,
} from "../signalr.service";

@Injectable()
export class UsuarioProvasService {
    readonly databaseName = "userdata";
    readonly instituicoesCollectionName = "instituicoes";
    readonly bancasCollectionName = "bancas";
    readonly anosCollectionName = "anos";
    readonly tiposCollectionName = "tipos";
    readonly cargosCollectionName = "cargos";

    constructor(private signalrService: SignalrService) {
        signalrService
            .getMensagem()
            .subscribe((msg) => this.onMessageReceived(msg));
    }


    public fromNuvem(provaDatasource: ProvaDatasource): Promise<void> {
        return new Promise((onsuccess) => {
            StorageHelper.upsertMany(
                provaDatasource.instituicoes,
                this.databaseName,
                this.instituicoesCollectionName,
                false
            )
                .then(() => {
                    StorageHelper.upsertMany(
                        provaDatasource.bancas,
                        this.databaseName,
                        this.bancasCollectionName,
                        false
                    )
                        .then(() => {
                            StorageHelper.upsertMany(
                                provaDatasource.anos,
                                this.databaseName,
                                this.anosCollectionName,
                                false
                            )
                                .then(() => {
                                    StorageHelper.upsertMany(
                                        provaDatasource.tipos,
                                        this.databaseName,
                                        this.tiposCollectionName,
                                        false
                                    )
                                        .then(() => {
                                            StorageHelper.upsertMany(
                                                provaDatasource.cargos,
                                                this.databaseName,
                                                this.cargosCollectionName,
                                                false
                                            )
                                                .then(() => {
                                                    onsuccess();
                                                })
                                                .catch((err) => {
                                                    throw err;
                                                });
                                        })
                                        .catch((err) => {
                                            throw err;
                                        });
                                })
                                .catch((err) => {
                                    throw err;
                                });
                        })
                        .catch((err) => {
                            throw err;
                        });
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public incluirInstituicao(
        instituicao: ItemProvaDatasource,
        audit = true,
        sync = true
    ): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsert(
                instituicao,
                this.databaseName,
                this.instituicoesCollectionName,
                audit
            )
                .then((retorno) => {
                    if (sync) {
                        const mensagem = new OperacaoSignalR();
                        mensagem.dados = instituicao;
                        this.signalrService.enviarMensagem(
                            mensagem,
                            EnumTipoObjeto.Instituicao
                        );
                    }

                    onsuccess();
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public incluirBanca(
        banca: ItemProvaDatasource,
        audit = true,
        sync = true
    ): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsert(
                banca,
                this.databaseName,
                this.bancasCollectionName,
                audit
            )
                .then((retorno) => {
                    if (sync) {
                        const mensagem = new OperacaoSignalR();
                        mensagem.dados = banca;
                        this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Banca);
                    }

                    onsuccess();
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public incluirAno(
        ano: ItemProvaDatasource,
        audit = true,
        sync = true
    ): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsert(
                ano,
                this.databaseName,
                this.anosCollectionName,
                audit
            )
                .then((retorno) => {
                    if (sync) {
                        const mensagem = new OperacaoSignalR();
                        mensagem.dados = ano;
                        this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Ano);
                    }

                    onsuccess();
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public incluirTipo(
        tipo: ItemProvaDatasource,
        audit = true,
        sync = true
    ): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsert(
                tipo,
                this.databaseName,
                this.tiposCollectionName,
                audit
            )
                .then((retorno) => {
                    if (sync) {
                        const mensagem = new OperacaoSignalR();
                        mensagem.dados = tipo;
                        this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Tipo);
                    }

                    onsuccess();
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public incluirCargo(
        cargo: ItemProvaDatasource,
        audit = true,
        sync = true
    ): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsert(
                cargo,
                this.databaseName,
                this.cargosCollectionName,
                audit
            )
                .then((retorno) => {
                    if (sync) {
                        const mensagem = new OperacaoSignalR();
                        mensagem.dados = cargo;
                        this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Cargo);
                    }

                    onsuccess();
                })
                .catch((err) => {
                    throw err;
                });
        });
    }

    public carregarDatasource(): Promise<ProvaDatasource> {
        return new Promise((onsuccess, onerror) => {
            const consultas = new Array<Promise<any>>();
            const provaDatasource = new ProvaDatasource();

            consultas.push(
                StorageHelper.list<ItemProvaDatasource>(
                    this.databaseName,
                    this.instituicoesCollectionName
                ).then((lista) => (provaDatasource.instituicoes = lista))
            );

            consultas.push(
                StorageHelper.list<ItemProvaDatasource>(
                    this.databaseName,
                    this.bancasCollectionName
                ).then((lista) => (provaDatasource.bancas = lista))
            );

            consultas.push(
                StorageHelper.list<ItemProvaDatasource>(
                    this.databaseName,
                    this.anosCollectionName
                ).then((lista) => (provaDatasource.anos = lista))
            );

            consultas.push(
                StorageHelper.list<ItemProvaDatasource>(
                    this.databaseName,
                    this.tiposCollectionName
                ).then((lista) => (provaDatasource.tipos = lista))
            );

            consultas.push(
                StorageHelper.list<ItemProvaDatasource>(
                    this.databaseName,
                    this.cargosCollectionName
                ).then((lista) => (provaDatasource.cargos = lista))
            );

            Promise.all(consultas)
                .then(() => onsuccess(provaDatasource))
                .catch((err) => {
                    throw err;
                });
        });
    }
    private onMessageReceived(msg: OperacaoSignalR): void {
        if (!msg) {
            return;
        }

        if (msg.tipoObjeto === EnumTipoObjeto.Instituicao) {
            this.incluirInstituicao(msg.dados, false);
        } else if (msg.tipoObjeto === EnumTipoObjeto.Banca) {
            this.incluirBanca(msg.dados, false);
        } else if (msg.tipoObjeto === EnumTipoObjeto.Ano) {
            this.incluirAno(msg.dados, false);
        } else if (msg.tipoObjeto === EnumTipoObjeto.Tipo) {
            this.incluirTipo(msg.dados, false);
        } else if (msg.tipoObjeto === EnumTipoObjeto.Cargo) {
            this.incluirCargo(msg.dados, false);
        }
    }

}
