import { UsuarioReferenciaService } from "./../../../../services/data-services/usuario.referencia.service";
import { Referencia } from "src/app/models/Referencia";
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    HostListener,
    Directive,
    Renderer2,
    ViewChild,
    OnDestroy,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from "@angular/core";
import { Comentario } from "../../../../models/Comentario";
import { TagConteudo } from "../../../../interfaces/TagConteudo";
import { HoverService } from "../../../../services/hover.service";
import { UsuarioComentariosService } from "../../../../services/data-services/usuario.comentarios.service";
import { TextoPagina } from "../../../../models/pagina/TextoPagina";

// azure card 358 Desativar botão caiu em prova
import { ProvaDatasource, ParametrosCaneta } from "../../../../models/UserData";
import { UsuarioMarcacoesService } from "../../../../services/data-services/usuario.marcacoes.service";
import { TagPickerComponent } from "../../placeholder-paineis/painel-marcacoes-prova/tag-picker/tag-picker.component";
import { MatDialog } from "@angular/material/dialog";
import { SimNaoDialogComponent } from "../../../dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { Observable, of, Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../../../services/data-services/usuario.preferencias.service";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";
import { ReferenciaGerenciada } from "src/app/models/ReferenciaGerenciada";
import { ConfiguracoesUsuario } from "src/app/models/usuario/ConfiguracoesUsuario";
import { FuncoesProva } from "src/app/models/FuncoesProva";
import { Marcacao } from "src/app/models/Marcacao";
import { ProvaDados } from "src/app/models/ProvaDados";

@Directive({
    selector: "[appResizableDiv]",
})
export class ResizableDivDirective implements OnDestroy {
    @Output() resize = new EventEmitter();

    elId: string;

    constructor(private renderer: Renderer2) {}

    @HostListener("mousedown", ["$event.target"])
    onMouseDown(el) {
        this.elId = el.id;
    }

    @HostListener("document:mouseup")
    onMouseUp() {
        this.ngOnDestroy();
    }

    ngOnDestroy() {
        if (this.elId) {
            const comentDiv = document.getElementById(this.elId);

            if (
                comentDiv.style.width.length > 0 &&
        comentDiv.style.height.length > 0
            ) {
                this.resize.emit({
                    width: comentDiv.style.width,
                    height: comentDiv.style.height,
                });
                this.elId = null;
            }
        }
    }
}

@Directive({
    selector: "[appAutoGrowDiv]",
})
export class AutoGrowDivDirective {
    private _buffer: HTMLTextAreaElement;

    @HostListener("input", ["$event"])
    onInput(ev: any): void {
        const textArea = <HTMLTextAreaElement>ev.target;
        this.resizeTxtAreaComentarios(textArea);
    }

    public resizeTxtAreaComentarios(targ: HTMLTextAreaElement) {
        if (this._buffer == null) {
            this._buffer = document.createElement("textarea");
            this._buffer.style.border = "none";
            this._buffer.style.height = "0";
            this._buffer.style.overflow = "hidden";
            this._buffer.style.padding = "0";
            this._buffer.style.position = "absolute";
            this._buffer.style.left = "0";
            this._buffer.style.top = "0";
            this._buffer.style.zIndex = "-1";
            document.body.appendChild(this._buffer);
        }

        const cs = window.getComputedStyle(targ);
        const pl = parseInt(cs.paddingLeft, 10);
        const pr = parseInt(cs.paddingRight, 10);
        let lh = parseInt(cs.lineHeight, 10);

        // [cs.lineHeight] may return 'normal', which means line height = font size.
        if (isNaN(lh)) {
            lh = parseInt(cs.fontSize, 10);
        }

        // Copy content width.
        this._buffer.style.width = targ.clientWidth - pl - pr + "px";

        // Copy text properties.
        this._buffer.style.font = cs.font;
        this._buffer.style.letterSpacing = cs.letterSpacing;
        this._buffer.style.whiteSpace = cs.whiteSpace;
        this._buffer.style.wordBreak = cs.wordBreak;
        this._buffer.style.wordSpacing = cs.wordSpacing;
        this._buffer.style.wordWrap = cs.wordWrap;

        // Copy value.
        this._buffer.value = targ.value;

        let result = Math.floor(this._buffer.scrollHeight / lh);
        if (result === 0) {
            result = 1;
        }

        const div = document.getElementById(
            targ.id.replace("coment-sup-input", "coment-sup-div").toString()
        );

        // limitar 15 quebras de linha por comentario
        if (result > 15) {
            targ.value = targ.value.substring(0, targ.value.length - 1);
            result = 15;
        }

        targ.style.cssText = "height:auto; padding:0";
        if (result === 1) {
            div.style.height = 25 + "px";
        } else {
            div.style.height = 25 + 12.5 * result + "px";
        }
        targ.style.height = "100%";
    }
}

@Directive({
    selector: "[appAutoWidht]",
})
export class AutoWidhtDirective implements AfterViewInit {
    private _texto: string;

    constructor(private el: ElementRef) {}

    @Input() set textoAutoWidhtDirective(val: string) {
        this._texto = val;

        if (!this.el.nativeElement.id) {
            return;
        }
        this.resize(this.el.nativeElement.id, this._texto);
    }

    @HostListener("input", ["$event"])
    onInput(ev: Event): void {
        ev.preventDefault();
        const input = <HTMLInputElement>ev.target;
        this.resize(input.id, input.value);
    }

    ngAfterViewInit() {
        if (!this.el || !this._texto) {
            return;
        }

        this.resize(this.el.nativeElement.id, this._texto);
    }

    public resize(id, texto) {
        const hideEl = document.getElementById("hide-resize-helper");
        hideEl.textContent = texto;

        const item = document.getElementById(id);
        if (id.indexOf("mne") !== -1) {
            hideEl.textContent = texto.toUpperCase();
        }

        const width = hideEl.offsetWidth + 45;
        this.el.nativeElement.style.width = width.toString() + "px";
    }
}

export interface MarcacoesGroup {
    tipo: string;
    marcacoes: ProvaDados[];
}

@Component({
    selector: "app-marcacao-superior",
    templateUrl: "./marcacao-superior.component.html",
    styleUrls: ["./marcacao-superior.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarcacaoSuperiorComponent implements OnInit, OnDestroy {

    @Input() opcoesPonteiros: ParametrosCaneta;
    @Input() posicaoAtual: string;
    @Output() on_salvarReferencia = new EventEmitter<Referencia>();

    public linha: TextoPagina;

    configuracoes$: Observable<ConfiguracoesUsuario> = of(
        new ConfiguracoesUsuario()
    );

    public provaEditando: Marcacao;
    public provaNova = false;
    public provaTextoBuscando: string;
    public carregandoOpcoesProva = true;
    public opcoesProva: ProvaDatasource;
    public funcoesProva = FuncoesProva;

    public provasExistentes = new Array<MarcacoesGroup>();
    public referenciagerenciadasReadOnly;

    public marginTop: number;
    public marginBottom: number;

    public isAdm = false;

    private _provasExistentes = new Array<MarcacoesGroup>();
    private _bufferInputWidht: HTMLSpanElement;
    private subscriptions: Subscription[] = [];
    private criarReferencia: Referencia;


    constructor(
        private changeDetector: ChangeDetectorRef,
        private hoverService: HoverService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioReferenciaService: UsuarioReferenciaService,
        private usuarioMarcacaoService: UsuarioMarcacoesService,
        public dialog: MatDialog,
        public featureFlagService: FeatureFlagService
    ) {

    }


    get getCriarReferencia() {
        return this.criarReferencia;
    }
    get isCriarReferencia() {
        return this.criarReferencia ? true : false;
    }


    public get any() {
        return (
            this.comentarios.length > 0 ||
      this.mnemonicos.length > 0 ||
      this.marcacoesLinhaAtual.length > 0
        );
    }

    public get comentarios() {
        return this.comentariosLinhaAtual.filter((x) => x.mnemonico === false);
    }
    public get mnemonicos() {
        return this.comentariosLinhaAtual.filter((x) => x.mnemonico === true);
    }

    public get comentariosGerenciados() {
        return this.comentariosGerenciadosLinhaAtual.filter(
            (x) => x.mnemonico === false
        );
    }
    public get mnemonicosGerenciados() {
        return this.comentariosGerenciadosLinhaAtual.filter(
            (x) => x.mnemonico === true
        );
    }

    get comentariosLinhaAtual(): Comentario[] {
        if (this.linha.comentarios && this.linha.comentarios.length > 0) {
            if (this.posicaoAtual === "Acima") {
                return this.linha.comentarios.filter(
                    (x) => x.range.idItens[0].idItem === this.linha.id
                );
            } else {
                return this.linha.comentarios.filter(
                    (x) =>
                        x.range.idItens[x.range.idItens.length - 1].idItem === this.linha.id
                );
            }
        } else {
            return new Array<Comentario>();
        }
    }

    get comentariosGerenciadosLinhaAtual(): Comentario[] {
        if (
            this.linha.comentariosGerenciados &&
      this.linha.comentariosGerenciados.length > 0
        ) {
            if (this.posicaoAtual === "Acima") {
                return this.linha.comentariosGerenciados.filter(
                    (x) => x.range.idItens[0].idItem === this.linha.id
                );
            } else {
                return this.linha.comentariosGerenciados.filter(
                    (x) =>
                        x.range.idItens[x.range.idItens.length - 1].idItem === this.linha.id
                );
            }
        } else {
            return new Array<Comentario>();
        }
    }

    public get marcacoesLinhaAtual(): Marcacao[] {
        if (this.linha.marcacoesProva && this.linha.marcacoesProva.length > 0) {
            if (this.posicaoAtual === "Acima") {
                return this.linha.marcacoesProva.filter(
                    (x) => x.range.idItens[0].idItem === this.linha.id
                );
            } else {
                return this.linha.marcacoesProva.filter(
                    (x) =>
                        x.range.idItens[x.range.idItens.length - 1].idItem === this.linha.id
                );
            }
        } else {
            return new Array<Marcacao>();
        }
    }

    public get referenciaLinhaAtual(): Referencia[] {
        if (this.linha.referencias && this.linha.referencias.length > 0) {
            if (this.posicaoAtual === "Abaixo") {
                return this.linha.referencias.filter(
                    (x) =>
                        x.links?.find((e) => e.idItem === this.linha.id)?.idItem ===
            this.linha.id
                );
            }
        } else {
            return new Array<Referencia>();
        }
    }

    public get referenciagerenciadasLinhaAtual(): ReferenciaGerenciada[] {
        if (
            this.linha.referenciasGerenciado &&
      this.linha.referenciasGerenciado.length > 0
        ) {
            if (this.posicaoAtual === "Abaixo") {
                this.referenciagerenciadasReadOnly = true;
                return this.linha.referenciasGerenciado.filter(
                    (x) => x.idItem === this.linha.id
                );
            }
        } else {
            this.referenciagerenciadasReadOnly = false;
            return new Array<ReferenciaGerenciada>();
        }
    }

    @Input() set setLinha(value: TextoPagina) {
        this.linha = value;
    }

    @Input() set setCriarReferencia(referencia: Referencia) {
        this.criarReferencia = referencia;
    }


    @ViewChild("instituicaoElement") set instituicaoElement(
        inst: TagPickerComponent
    ) {
        if (!inst) {
            return;
        }
        inst.focus = true;
    }
    @ViewChild("controlProvaExistente") set controlProvaExistente(
        inpu: ElementRef
    ) {
        if (!inpu) {
            return;
        }

        inpu.nativeElement.focus();
    }

    ngOnInit() {
        switch (this.posicaoAtual) {
            case "Acima":
                this.marginBottom = -7;
                this.marginTop = 3;
                break;
            case "Abaixo":
                this.marginBottom = 2;
                this.marginTop = -5;
                break;
        }

        this.subscriptions.push(
            this.usuarioPreferenciasService
                .getConfiguracoes()
                .pipe()
                .subscribe(() => this.changeDetector.markForCheck())
        );
        this.subscriptions.push(
            this.usuarioComentariosService
                .getmodificadoComentario()
                .subscribe((x) => this.changeDetector.markForCheck())
        );
        this.subscriptions.push(
            this.usuarioMarcacaoService
                .getModificadoMarcacao()
                .subscribe((x) => this.changeDetector.markForCheck())
        );
        this.subscriptions.push(
            this.hoverService
                .getItens()
                .subscribe((x) => this.changeDetector.markForCheck())
        );
        this.subscriptions.push(
            this.usuarioReferenciaService
                .getModificadoReferencia()
                .subscribe((x) => this.changeDetector.markForCheck())
        );
        this.isAdm = this.featureFlagService.IsAdm(
            this.usuarioPreferenciasService.getConfiguracoesvalue?.email
        );

        this.configuracoes$ = this.usuarioPreferenciasService.getConfiguracoes();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    // #region Comentarios

    salvarComentario(comentario) {
        this.atualizarComentario(comentario);
    }

    public realcarComentario(idComentario: string): void {
        const col = new Array<TagConteudo>();

        const coment = this.linha.comentarios.find((x) => x.id === idComentario);
        const tag = new TagConteudo(null, coment);
        col.push(tag);

        this.hoverService.destacar(col);
        this.changeDetector.markForCheck();
    }

    public confirmarDelecaoComentario(comentario: Comentario) {
        const tipoComentario = comentario.mnemonico ? "mnemonico" : "comentário";
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja mesmo excluir o " + tipoComentario + "?",
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.usuarioComentariosService.remover(comentario);
            }
        });
    }

    public atualizarComentario(c: Comentario, size: any = null) {
        this.hoverService.destacar(null);

        let largura: number;
        if (c.texto.length > 0) {
            this.usuarioComentariosService.atualizar(c, true);
        } else {
            this.usuarioComentariosService.remover(c);
        }

        this.changeDetector.markForCheck();
    }

    public getWidthOfText(texto: string) {
        this._bufferInputWidht = document.createElement("span");
        this._bufferInputWidht.style.border = "none";
        this._bufferInputWidht.style.height = "0";
        this._bufferInputWidht.style.overflow = "hidden";
        this._bufferInputWidht.style.padding = "0";
        this._bufferInputWidht.style.position = "absolute";
        this._bufferInputWidht.style.left = "0";
        this._bufferInputWidht.style.top = "0";
        this._bufferInputWidht.style.zIndex = "-1";
        this._bufferInputWidht.style.fontSize = "10pt";
        document.body.appendChild(this._bufferInputWidht);

        this._bufferInputWidht.textContent = texto;

        const width = this._bufferInputWidht.offsetWidth + 40;
        return width;
    }

    // #endregion

    public confirmarDelecaoProva(prova: Marcacao) {
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja mesmo excluir a marcação de prova?",
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.usuarioMarcacaoService.remover(prova);
            }
        });
    }

    public realcarProva(idComentario: string): void {
        const col = new Array<TagConteudo>();

        const prova = this.linha.marcacoesProva.find((x) => x.id === idComentario);
        const tag = new TagConteudo(prova, null);
        col.push(tag);

        this.hoverService.destacar(col);
    }

    public filtroMarcacaoProva(value: string) {
        if (value && value.length > 0) {
            value = value.toLowerCase();
            this.provasExistentes = new Array<MarcacoesGroup>();
            this.provasExistentes.push({
                tipo: this._provasExistentes[1].tipo,
                marcacoes: this._provasExistentes[1].marcacoes.filter(
                    (x) =>
                        FuncoesProva.getLabelProva(x)
                            .toLowerCase()
                            .indexOf(this.provaTextoBuscando.toLowerCase()) !== -1
                ),
            });
        } else {
            this.provasExistentes = this._provasExistentes;
        }
    }

    // public selecionarProvaExistente(dados: ProvaDados, targ: HTMLElement) {
    //   this.provaEditando.dados = dados;
    //   this.focusLeaveProva(targ);
    // }

    // #endregion

    // #region Comum

    public blurRealce(): void {
        this.hoverService.destacar(null);
    }
    public deletaReferencia(referencia: Referencia) {
        this.usuarioReferenciaService.remover(referencia, this.linha.id);
        this.linha.referencias.splice(
            this.linha.referencias.indexOf(referencia),
            1
        );
        this.changeDetector.markForCheck();
    }


    public salvarReferencia(referencia: Referencia) {
        this.on_salvarReferencia.emit(referencia);

        this.criarReferencia = null;

        this.changeDetector.markForCheck();
    }

    public fechaReferencia() {
        this.criarReferencia = null;
    }

    // #endregion
}
