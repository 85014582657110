import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
import { UsuarioApontamentosService } from "./data-services/usuario.apontamentos.service";
import { Guia } from "../models/Guia";
import { LeiRepositorio } from "../repositorios/lei.repositorio";
import { Apontamento } from "../models/Apontamento";
import { ApontamentoListaPorData } from "../models/ApontamentoListaPorData";

@Injectable()
export class TimerService {
    private titulo: string;
    private guiasIdLeis: string;
    private apontamentos: Apontamento[] = [];
    private apontamentos$ = new BehaviorSubject<Apontamento[]>(this.apontamentos);
    private apontamentoAtual: Apontamento = null;
    private apontamentoAtual$ = new BehaviorSubject<Apontamento>(
        this.apontamentoAtual
    );
    private apontamentosListaPorData: ApontamentoListaPorData[] = [];
    private apontamentosListaPorData$ = new BehaviorSubject<
    ApontamentoListaPorData[]
    >(this.apontamentosListaPorData);

    constructor(
        private usuarioGuiaService: UsuarioGuiasService,
        private usuarioApontamentosService: UsuarioApontamentosService,
        private leiRepositorio: LeiRepositorio
    ) {
        this.usuarioGuiaService.getGuiaAtiva().subscribe((guia) => {
            this.guias_subscribe(guia);
        });
    }


    public async carregarApontamento(guiasIdLeis: string) {
        this.apontamentoAtual = null;
        this.usuarioApontamentosService.buscar(guiasIdLeis).then((apontamentos) => {
            const hoje = new Date();
            const dia = hoje.getDate();
            const mes = hoje.getMonth() + 1;
            const ano = hoje.getFullYear();
            this.apontamentoAtual =
        apontamentos.find(
            (ap) => ap.dia === dia && ap.mes === mes && ap.ano === ano
        ) ?? new Apontamento();
            this.apontamentoAtual.descricaoConteudo = this.titulo;

            this.updateApontamentoAtual();
        });
    }

    public async carregarApontamentosLei(guiasIdLeis: string) {
        this.apontamentos = [];
        this.usuarioApontamentosService.buscar(guiasIdLeis).then((apontamentos) => {
            this.apontamentos = apontamentos;
            this.apontamentos.sort(
                (a, b) =>
                    new Date(b.dataHoraModificacao).getTime() -
          new Date(a.dataHoraModificacao).getTime()
            );

            this.updateApontamentos();
        });
    }

    public async carregarApontamentosData() {
        this.apontamentos = [];
        this.usuarioApontamentosService.buscar().then((apontamentos) => {
            const hoje = new Date();
            const dia = hoje.getDate();
            const mes = hoje.getMonth() + 1;
            const ano = hoje.getFullYear();

            apontamentos = apontamentos.filter(
                (apontamento) =>
                    apontamento?.controleTempo?.length > 0 &&
          apontamento?.anotacoes?.length > 0
            );

            // apontamentos
            //   .filter((ap) => ap.dia === dia && ap.mes === mes && ap.ano === ano)
            //   .forEach((ap) =>
            //     this.leiRepositorio.carregarItemLookup(ap.idLei).then((lei) => {
            //       ap.descricaoConteudo = lei.titulo;
            //       this.apontamentos.push(ap);
            //     })
            //   );

            apontamentos
                .filter((ap) => ap.dia === dia && ap.mes === mes && ap.ano === ano)
                .map(async (ap) => {
                    this.leiRepositorio
                        .carregarItemLookup(ap.idLei)
                        .then((lei) => (ap.descricaoConteudo = lei.titulo));

                    this.apontamentos.push(ap);
                });

            this.apontamentos.sort(
                (a, b) =>
                    new Date(b.dataHoraModificacao).getTime() -
          new Date(a.dataHoraModificacao).getTime()
            );

            this.updateApontamentos();
        });
    }

    public async carregarApontamentosListaPorData() {
        this.apontamentosListaPorData = [];
        this.usuarioApontamentosService.buscar().then((apontamentos) => {
            apontamentos = apontamentos.filter(
                (apontamento) =>
                    apontamento?.controleTempo?.length > 0 &&
          apontamento?.anotacoes?.length > 0 &&
          apontamento?.idLei
            );

            apontamentos.sort(
                (a, b) =>
                    new Date(b.dataHoraModificacao).getTime() -
          new Date(a.dataHoraModificacao).getTime()
            );

            apontamentos.map(async (ap) => {
                this.leiRepositorio
                    .carregarItemLookup(ap.idLei)
                    .then((lei) => (ap.descricaoConteudo = lei.titulo));

                return ap;
            });

            const datasApontamento = apontamentos.map((apontamento) =>
                new Date(apontamento.dataHoraModificacao).toDateString()
            );

            const unique = [...new Set(datasApontamento)];

            unique.forEach((data) => {
                const normalizeData = new Date(data);

                this.apontamentosListaPorData.push({
                    dataApontamento: normalizeData,
                    apontamentos: apontamentos.filter(
                        (apontamento) =>
                            new Date(apontamento.dataHoraModificacao).getDate() ===
                normalizeData.getDate() &&
              new Date(apontamento.dataHoraModificacao).getMonth() ===
                normalizeData.getMonth() &&
              new Date(apontamento.dataHoraModificacao).getFullYear() ===
                normalizeData.getFullYear()
                    ),
                });
            });
            this.updateApontamentosListaPorData();
        });
    }

    public async salvarApontamento(apontamento: Apontamento) {
        apontamento.idLei = this.guiasIdLeis;
        await this.usuarioApontamentosService.atualizar(apontamento);
        await this.carregarApontamentosData();
        await this.carregarApontamentosListaPorData();
    }

    updateApontamentosListaPorData() {
        this.apontamentosListaPorData$.next(this.apontamentosListaPorData);
    }

    getApontamentosListaPorData(): Observable<ApontamentoListaPorData[]> {
        return this.apontamentosListaPorData$.asObservable();
    }
    updateApontamentos() {
        this.apontamentos$.next(this.apontamentos);
    }

    getApontamentos(): Observable<Apontamento[]> {
        return this.apontamentos$.asObservable();
    }

    updateApontamentoAtual() {
        this.apontamentoAtual$.next(this.apontamentoAtual);
    }

    getApontamentoAtual(): Observable<Apontamento> {
        return this.apontamentoAtual$.asObservable();
    }

    private async guias_subscribe(guia: Guia) {
        if (!guia?.idLei) {
            return;
        }

        if (this.guiasIdLeis !== guia.idLei) {
            await this.carregarApontamentosListaPorData();
            await this.carregarApontamentosData();
        }
        this.guiasIdLeis = guia.idLei;
        this.titulo = guia.titulo;
        await this.carregarApontamento(this.guiasIdLeis);
    }
}




