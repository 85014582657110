import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ApplicationInsightsService } from "./application-insights.service";
import { IErrorService } from "@microsoft/applicationinsights-angularplugin-js";


@Injectable()
export class ErrorHandlerService implements IErrorService {
  constructor(private applicationInsightsService: ApplicationInsightsService) {}

  ngOnDestroy() {}

  //ApplicationInsights
  handleError(error: any) {
    let customErro: Error = new Error();
    customErro.name = ` ${error?.name}`;
    customErro.stack = ` ${error?.stack}`;
    customErro.message = `App-SVM-Error: ${error?.message}`;
    this.applicationInsightsService.logException(customErro);
  }
}
