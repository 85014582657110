<h3 mat-dialog-title class="title-group-config">Artigos comentados:</h3>
<div mat-dialog-content>
  <mat-form-field class="form-content" floatLabel="always">
    <textarea matInput hidden></textarea>

    <mat-slide-toggle
      class="toggle-group-config"
      [ngModel]="
        (configuracoes$ | async).preferenciasUsuario.exibirApenasItensComentados
      "
      (ngModelChange)="AlteraItensComentados($event)"
      color="primary"
      labelPosition="before"
    >
      Apenas itens comentados
    </mat-slide-toggle>

    <mat-slide-toggle
      class="toggle-group-config"
      [ngModel]="
        (configuracoes$ | async).preferenciasUsuario.exibirMeusComentarios
      "
      (ngModelChange)="AlteraMeusComentarios($event)"
      color="primary"
      labelPosition="before"
    >
      Meus comentários
    </mat-slide-toggle>

    <mat-slide-toggle
      class="toggle-group-config"
      [ngModel]="
        (configuracoes$ | async).preferenciasUsuario.exibirComentariosSvm
      "
      (ngModelChange)="AlteraComentariosSvm($event)"
      color="primary"
      labelPosition="before"
    >
      Comentários SVM
    </mat-slide-toggle>

    <mat-divider></mat-divider>

    <mat-slide-toggle
      class="toggle-group-config"
      [ngModel]="
        (configuracoes$ | async).preferenciasUsuario.exibirItensRevogados
      "
      (ngModelChange)="AlteraItensRevogados($event)"
      color="primary"
      labelPosition="before"
    >
      Artigos Revogados
    </mat-slide-toggle>
  </mat-form-field>
</div>
<div class="button-group-content" mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onCancel()">Fechar</button>
  <button mat-raised-button color="primary" cdkFocusInitial (click)="onSave()">
    Salvar
  </button>
</div>
