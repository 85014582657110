<div class="leitor-side-menu-container">
  <div class="leitor-side-menu">
    <div class="leitor-side-menu-button">
      <app-button-leitor-side-menu
        *ngIf="!(this.breakpointMobileLg$ | async)"
        (click)="btnBuscaClick()"
        [opcao]="buscaSelecao"
        [ativo]="painelBuscaAberto"
      ></app-button-leitor-side-menu>

      <app-button-leitor-side-menu
        *ngIf="!(this.breakpointMobileLg$ | async)"
        (click)="toggle(ferramentaSelecao)"
        [opcao]="ferramentaSelecao"
        [ativo]="
          opcaoSelecionada?.modo === ferramentaSelecao?.modo &&
          opcaoSelecionada?.tipo === ferramentaSelecao?.tipo
        "
      ></app-button-leitor-side-menu>

      <app-button-leitor-side-menu
        (click)="toggle(ferramentaBorracha)"
        [opcao]="ferramentaBorracha"
        [ativo]="
          opcaoSelecionada?.modo === ferramentaBorracha?.modo &&
          opcaoSelecionada?.tipo === ferramentaBorracha?.tipo
        "
      ></app-button-leitor-side-menu>
    </div>

    <mat-divider
      class="menu-divider"
      [vertical]="!breakpointMobileLg$"
    ></mat-divider>

    <div class="leitor-side-menu-button-scrolling">
      <div *ngFor="let opcao of opcoesExibir" class="leitor-side-menu-button">
        <app-button-leitor-side-menu
          [opcao]="opcao"
          (toggleButtonLeitor)="toggle($event)"
          [ativo]="
            opcaoSelecionada?.modo === opcao.modo &&
            opcaoSelecionada?.tipo === opcao.tipo
          "
        ></app-button-leitor-side-menu>
      </div>
    </div>
  </div>
</div>
