import { Component, OnDestroy, OnInit } from "@angular/core";
import { ConteudoService } from "../../../services/conteudo.service";
import { Observable, of, Subscription } from "rxjs";
import { Conteudo } from "../../../models/pagina/conteudo";
import { TextoPagina } from "../../../models/pagina/TextoPagina";
import { TipoItem, FuncoesItem } from "../../../models/Item";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { PopupsService } from "../popups.service";
import { IindiceSistematicoItemModel } from "src/app/interfaces/IindiceSistematicoItemModel";
import { IndiceSistematicoItemModel } from "src/app/models/IndiceSistematicoItemModel";

@Component({
    selector: "app-indice-sistematico",
    templateUrl: "./indice-sistematico.component.html",
    styleUrls: [
        "./indice-sistematico.component.scss",
        "../../../../styles/formatacao-texto.scss",
    ],
})
export class IndiceSistematicoComponent implements OnInit, OnDestroy {

    indiceSistematicoCompleto = new Array<IndiceSistematicoItemModel>();
    treeControl = new NestedTreeControl<IndiceSistematicoItemModel>(
        (node) => node.children
    );

    dataSource = new MatTreeNestedDataSource<IndiceSistematicoItemModel>();
    public carregandoConteudo$: Observable<boolean> = of(false);



    private _subscriptions = Array<Subscription>();

    constructor(
        private conteudoSerivce: ConteudoService,
        private popupsService: PopupsService
    ) {}

    hasChild = (_: number, node: IindiceSistematicoItemModel) =>
        !!node.children && node.children.length > 0;

    ngOnInit(): void {
        this._subscriptions.push(
            this.conteudoSerivce
                .getConteudo()
                .subscribe((c) => this.SubscribeConteudo(c))
        );
        this.carregandoConteudo$ = this.conteudoSerivce.getCarregando();
    }

    ngOnDestroy() {
        this._subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    irParaItem(node: IndiceSistematicoItemModel) {
        this.popupsService.fecharPopups();
        this.conteudoSerivce.alterarIndexFoco(node.index);
    }

    private SubscribeConteudo(c: Conteudo) {
        if (!c) {
            return;
        }

        this.gerarIndice(c.linhas);

        this.dataSource.data = this.indiceSistematicoCompleto;
    }

    private gerarIndice(linhas: Array<TextoPagina>) {
        this.indiceSistematicoCompleto = new Array<IndiceSistematicoItemModel>();
        const indiceSistematico = new Array<IndiceSistematicoItemModel>();

        // Verificar se é um dos tipos que serão incluídos no índice e se não é do tipo citação
        const reg = /(preambulo|parte|livro|titulo|ementa|capitulo|secao|subsecao)/gi;
        const linhasIndice = linhas
            .filter(
                (x) =>
                    x.tipoTexto.match(reg) &&
          x.versoes[x.indexVersao].revogado == false &&
          x.tipoTexto.toLowerCase().indexOf("citacao") === -1
            )
            .map(
                (x) =>
          <IndiceSistematicoItemModel>{
              itemId: x.id,
              texto: [
                  x.versoes[x.indexVersao].prefixo,
                  x.versoes[x.indexVersao].texto,
              ].join(""),
              index: linhas.indexOf(x),
              tipoItem: FuncoesItem.identificarTipoItem(x),
              // tipoItemCssClass: x.tipoTexto,
              children: new Array<IndiceSistematicoItemModel>(),
          }
            );

        // Variveis para controle de relacionamento entre itens
        const ultimoItemPorTipo = new Array<IndiceSistematicoItemModel>();

        linhasIndice.forEach((l) => {
            for (
                let index = ultimoItemPorTipo.length - 1;
            // Partes, Livros e titulos sempre serão root do indice, não serão filhos de ninguém
                !l.itemParentId &&
        l.tipoItem.valueOf() > TipoItem.Titulo.valueOf() &&
        index > 0;
                index--
            ) {
                if (
                    ultimoItemPorTipo[index].tipoItem.valueOf() < l.tipoItem.valueOf()
                ) {
                    l.itemParentId = ultimoItemPorTipo[index].itemId;
                }
            }

            // Atualizar utlimo item por tipo
            const ultimoItemTipoIndex = ultimoItemPorTipo.findIndex(
                (x) => x.tipoItem === l.tipoItem
            );
            if (ultimoItemTipoIndex === -1) {
                ultimoItemPorTipo.push(l);
            } else {
                ultimoItemPorTipo.splice(
                    ultimoItemPorTipo.findIndex((x) => x.tipoItem === l.tipoItem),
                    1,
                    l
                );
            }

            indiceSistematico.push(l);
        });

        indiceSistematico.forEach((l) => {
            l.children = indiceSistematico.filter((x) => l.itemId === x.itemParentId);
            if (!l.itemParentId) {
                this.indiceSistematicoCompleto.push(l);
            }
        });
    }


}




