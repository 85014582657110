import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SimNaoDialogComponent } from "src/app/components/dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { ReferenciaDialogComponent } from "src/app/components/popups/referencia-dialog/referencia-dialog.component";
import { StringHelper } from "src/app/helpers/string.helper";
import {
    Referencia,
    ReferenciaCabecalho,
    TipoReferencia,
} from "src/app/models/Referencia";
import { ReferenciaGerenciada } from "src/app/models/ReferenciaGerenciada";
import { UsuarioReferenciaService } from "src/app/services/data-services/usuario.referencia.service";
import { StatusService } from "src/app/services/status.service";
import { TextFormatingService } from "src/app/services/ui/textFormating.service";
@Component({
    selector: "app-editor-referencia",
    templateUrl: "./editor-referencia.component.html",
    styleUrls: ["./editor-referencia.component.scss"],
})
export class EditorReferenciaComponent implements OnInit, OnDestroy {
    @Input() referencia: Referencia;
    @Input() referencias: Referencia[];
    @Input() referenciaReadOnly: boolean;
    @Output() on_salvarReferencia = new EventEmitter<Referencia>();
    @Output() on_fechaReferencia = new EventEmitter<Referencia>();
    @Output() on_deletaReferencia = new EventEmitter<Referencia>();

    public fontIncrement: Observable<number>;
    public carregandoReferencias = false;
    public carregandoReferenciasPorId = false;
    public NovaReferencia = false;
    public Referenciasexistentes: ReferenciaCabecalho[] = [];
    public opcao: Referencia;
    public referenciaControl = new FormControl();
    public referenciaOptions: Observable<any>;
    public referenciaAlterada = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private changeDetector: ChangeDetectorRef,
        public dialog: MatDialog,
        private textFormatingService: TextFormatingService,
        private usuarioReferenciaService: UsuarioReferenciaService,
        private statusService: StatusService
    ) {}
    @Input() set referenciasGerenciadasReadOnly(referenciasGerenciadasReadOnly: ReferenciaGerenciada[]) {
        this.referencias =Referencia.referenciaParceGerenciada( referenciasGerenciadasReadOnly);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }



    async ngOnInit(): Promise<void> {
        this.fontIncrement = this.textFormatingService.getFontIncrement();

        this.referenciaOptions = this.referenciaControl.valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.ref)),
            map((ref) =>
                ref ? this._filter(ref) : this.Referenciasexistentes?.slice()
            )
        );
    }

    displayFn(referencia: ReferenciaCabecalho): string {
        return referencia && referencia.ref ? referencia.ref : "";
    }



    public confirmarDelecaoReferencia(referencia: Referencia) {
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja mesmo excluir a referência?",
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.on_deletaReferencia.emit(referencia);
                this.changeDetector.markForCheck();
            }
        });
    }

    salvarReferencia() {
        if (
            this.referencia?.conteudo &&
      this.referencia?.ref &&
      Number.isInteger(this.referencia?.tipo)
        ) {
            const cont = this.opcao?.links.findIndex(
                (x) =>
                    x.idItem === this.referencia.links[0].idItem &&
          x.idLei === this.referencia.links[0].idLei
            );
            if (cont === -1) {
                this.referencia.links.push(...this.opcao.links);
            } else if (cont) {
                this.referencia.links = this.opcao.links;
            }

            this.on_salvarReferencia.emit(this.referencia);
            this.opcao = null;
            this.referencia = null;
            this.carregandoReferencias = false;
            this.carregandoReferenciasPorId = false;
            this.NovaReferencia = false;
            this.referenciaAlterada = false;
            this.changeDetector.markForCheck();
        }
    }

    fechaReferencia() {
        this.on_fechaReferencia.emit();
        this.opcao = null;
        this.referencia = null;
        this.carregandoReferencias = false;
        this.carregandoReferenciasPorId = false;
        this.NovaReferencia = false;
        this.referenciaAlterada = false;
        this.changeDetector.markForCheck();
    }

    public confirmarCloseReferencia() {
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja mesmo fechar a tela de criação de referência?",
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.fechaReferencia();
                this.opcao = null;
                this.referencia = null;
                this.carregandoReferencias = false;
                this.carregandoReferenciasPorId = false;
                this.NovaReferencia = false;
                this.referenciaAlterada = false;
                this.changeDetector.markForCheck();
            }
        });
    }

    openDialog(referencia: Referencia): void {
        const dialogRef = this.dialog.open(ReferenciaDialogComponent, {
            data: {
                referencia: referencia,
                tipo: Referencia.identificarTipoReferencia(referencia.tipo),
                referenciaReadOnly: this.referenciaReadOnly,
            },
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }
    tipoReferencia(item: TipoReferencia) {
        return Referencia.identificarTipoReferencia(item);
    }

    public atualizarDicas(dica: string) {
        this.referencia.conteudo = dica;
    }

    public async buscarReferencias() {
        this.Referenciasexistentes = this.statusService.isAppOnline
            ? await this.usuarioReferenciaService.carregarReferenciasCabecalho()
            : await this.usuarioReferenciaService.buscarReferenciasCabecalhoLocal();
        this.carregandoReferencias = true;
        this.changeDetector.detectChanges();

        return false;
    }

    public async selecionarReferenciaExistente(opcao: ReferenciaCabecalho) {
        this.carregandoReferenciasPorId = true;
        const referencia = this.statusService.isAppOnline
            ? await this.usuarioReferenciaService.carregarReferenciaPorId(opcao.id)
            : await this.usuarioReferenciaService.buscarReferenciaLocalPorId(
                opcao.id
            );

        this.opcao = new Referencia({
            id: referencia.id,
            dataHoraModificacao: opcao.dataHoraModificacao,
            removido: referencia.removido,
            conteudo: referencia.conteudo,
            tipo: referencia.tipo,
            ref: referencia.ref,
            link: referencia.link,
            links: this.referencia.links,
        });
        this.NovaReferencia = true;
        this.referencia = referencia;
        this.changeDetector.detectChanges();
        this.carregandoReferenciasPorId = false;
        this.referenciaAlterada = true;
    }

    public selecionarTipoReferenciaExistente(opcao: any) {
        this.referencia.tipo = Referencia.identificarTipoReferenciaString(
            opcao.value
        );

        this.changeDetector.markForCheck();
    }

    public gerarNovareferencia() {
        this.NovaReferencia = true;
        const referen = new Referencia();

        referen.links = this.referencia.links;

        this.referencia = referen;
        this.referenciaAlterada = true;
        this.changeDetector.markForCheck();
    }

    private _filter(ref: string): ReferenciaCabecalho[] {
        const filterValue = StringHelper.Normalize(ref);

        return this.Referenciasexistentes.filter((option) =>
            StringHelper.Normalize(option.ref).includes(filterValue)
        );
    }
}
