import {
    Component,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../app.config";
import { LoggingService } from "../../../services/logging.service";
import { ContatoIndicacao, StatusIndicacaoModel } from "src/app/models/ContatoIndicacao";
import { DadosPessoaisServidorModel } from "src/app/models/PagamentoModel";

@Component({
    selector: "app-perfil",
    templateUrl: "./perfil.component.html",
    styleUrls: ["../popups-styles.scss", "./perfil.component.scss"],
})
export class PerfilComponent implements OnInit, OnDestroy {
    @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);
    public email: string;
    public model: DadosPessoaisServidorModel;
    public modelOriginal: DadosPessoaisServidorModel;
    public carregando: boolean;
    public salvando: boolean;

    private _validarMudancas = false;
    constructor(
        public snackBar: MatSnackBar,
        private httpClient: HttpClient,
        private loggingService: LoggingService
    ) {}


    public get ocupado(): boolean {
        return this.salvando || this.carregando ? true : false;
    }

    public get validarMudancas() {
        return this._validarMudancas;
    }

    public set validarMudancas(value) {
        this._validarMudancas = value;
        this.confirmarAlteracoes.next(value);
    }


    public ngOnInit(): void {
        this.carregarModel();
    }
    public ngOnDestroy(): void {
        this.model = null;
        this.modelOriginal = null;
    }

    public carregarModel(): any {
        const carregarWebService = (): Promise<DadosPessoaisServidorModel> => new Promise((onSuccess) => {
            this.httpClient
                .get(AppConfig.apiEndpoint + "/DadosCadastroUsuario")
                .toPromise()
                .then((response: any) => {
                    const retorno = new DadosPessoaisServidorModel();
                    retorno.trial = Number(response.valorCobranca) < 0;

                    retorno.resumoCartao.bandeiraCartao = response.bandeira;
                    retorno.resumoCartao.finalCartao = response.finalCartao;

                    retorno.dadosPessoais.cpf = response.cpf;
                    retorno.dadosPessoais.telefone = response.telefone;
                    retorno.dadosPessoais.planoContratadoId =
              response.planoContratadoId;
                    retorno.dadosPessoais.planoCancelado = response.planoCancelado;
                    retorno.dadosPessoais.dataProximaCobranca = new Date(
                        response.dataProximaCobranca
                    );
                    retorno.dadosPessoais.ValorDescontoIndicacao =
              response.valorDescontoIndicacao;
                    retorno.dadosPessoais.ValorCobranca = response.valorCobranca;

                    if (response.indicacoes) {
                        retorno.dadosPessoais.indicacoes = new Array<ContatoIndicacao>();
                        response.indicacoes.forEach((indicacao) => {
                            const i = new ContatoIndicacao();

                            i.nome = indicacao.nome;
                            i.email = indicacao.email;
                            i.dataEnvio = indicacao.dataEnvio
                                ? new Date(indicacao.dataEnvio)
                                : null;
                            i.dataCadastro = indicacao.dataCadastro
                                ? new Date(indicacao.dataCadastro)
                                : null;
                            i.status = StatusIndicacaoModel.status.find(
                                (s) => s.codigo === indicacao.status
                            );

                            retorno.dadosPessoais.indicacoes.push(i);
                        });
                    }

                    onSuccess(retorno);
                })
                .catch((err) => {
                    throw err;
                });
        });

        this.carregando = true;
        this.model = null;
        this.modelOriginal = null;

        carregarWebService()
            .then((result) => {
                this.model = result;
                this.modelOriginal = result;
                this.carregando = false;
            })
            .catch((err) => {
                this.snackBar.open(err, "OK!");
                this.carregando = false;
                throw err;
            });
    }



    public childForm_saving(msgCarregamento = false): void {
        if (msgCarregamento) {
            this.carregando = true;
        } else {
            this.salvando = true;
        }
    }

    public childForm_saved(msg, msgCarregamento = false): void {
        this.loggingService.LogEvent("Editar Pefil", null, null);

        this.validarMudancas = false;
        this.snackBar.open(msg, "OK!", {
            duration: 10000,
        });

        if (msgCarregamento) {
            this.carregando = false;
        } else {
            this.salvando = false;
        }

        this.carregarModel();
    }
}
