<div
  [style.backgroundImage]="backgroundImage"
  [style.backgroundColor]="backgroundColor"
  [style.color]="foreground"
  class="container flex-column leitor-content-placeholder"
>
  <app-navbar class="navbar-container"></app-navbar>

  <app-leitor-content class="leitor-content-container"></app-leitor-content>

  <app-leitor-statusbar
    class="statusbar-placeholder"
    *ngIf="configuracoes && !(breakpointMobileLg$|async)"
  ></app-leitor-statusbar>
</div>
