import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subject, Observable } from "rxjs";

@Injectable()
export class IndicatorsService {
  private alert;
  private alert$ = new Subject<any>();
  private loadingInterstitial;
  private loadingInterstitial$ = new Subject<any>();

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.updateLoadingInterstitial();

        this.updateAlert();
      }
    });
  }

  showInterstitial() {
    this.loadingInterstitial = {};
    this.updateLoadingInterstitial();
  }

  hideInterstitial() {
    this.loadingInterstitial;
    this.updateLoadingInterstitial();
  }

  alertSuccess(message: string) {
    this.alert = { type: "success", text: message };
    this.updateAlert();
  }

  alertError(message: string) {
    this.alert = { type: "error", text: message };
    this.updateAlert();
  }

  updateAlert() {
    this.alert$.next(this.alert);
  }

  getAlert(): Observable<boolean> {
    return this.alert$.asObservable();
  }

  updateLoadingInterstitial() {
    this.loadingInterstitial$.next(this.loadingInterstitial);
  }

  getLoadingInterstitial(): Observable<boolean> {
    return this.loadingInterstitial$.asObservable();
  }
}
