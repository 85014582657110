import { Injectable } from "@angular/core";
import {
    Tempo,
    EstatisticasLeitura,
} from "../models/usuario/EstatisticasLeitura";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { Conteudo } from "../models/pagina/conteudo";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
import { UsuarioEstatisticasService } from "./data-services/usuario.estatisticas.service";
import { UsuarioPreferenciasService } from "./data-services/usuario.preferencias.service";
import { Guia } from "../models/Guia";
import { BuscaPanelParameters } from "../components/leitor-content-panelbusca/busca-panel.parameters";
import { TextoPagina } from "../models/pagina/TextoPagina";
import { StatusService } from "./status.service";
import { Lei } from "../models/Lei";
import { UsuarioMarcacoesService } from "./data-services/usuario.marcacoes.service";
import { UsuarioComentariosService } from "./data-services/usuario.comentarios.service";
import { UsuarioGrifosService } from "./data-services/usuario.grifos.service";
import { MatchBuscaTexto } from "../models/MatchBuscaTexto";
import { LeiLookup } from "../models/lei/lei.lookup";
import { LeiRepositorio } from "../repositorios/lei.repositorio";
import { LoggingService } from "./logging.service";
import { KeyValue } from "@angular/common";
import { UsuarioComentariosGerenciadosService } from "./data-services/usuario.comentarios.gerenciados.service";
import { UsuarioReferenciaService } from "./data-services/usuario.referencia.service";
import { UsuarioReferenciasGerenciadasService } from "./data-services/usuario.referencias.gerenciadas.service";
import { map } from "rxjs/operators";
import { StringHelper } from "../helpers/string.helper";
import { FuncoesItem, TipoItem } from "../models/Item";
import { NumberHelper } from "../helpers/numbers.helper";

@Injectable()
export class ConteudoService {
    private carregando = false;
    private carregando$ = new BehaviorSubject<boolean>(this.carregando);

    private conteudo: Conteudo = null;
    private conteudo$ = new Subject<Conteudo>();

    private indexFoco: number = null;
    private indexFoco$ = new BehaviorSubject<number>(this.indexFoco);

    private multipleReadedLinesChanged: any = null;
    private multipleReadedLinesChanged$ = new BehaviorSubject<any>(
        this.multipleReadedLinesChanged
    );

    private leisGuiasCached: LeiGuiacached[] = [];
    private leisGuiasCached$ = new BehaviorSubject<LeiGuiacached[]>(
        this.leisGuiasCached
    );

    private indiceLei$ = this.conteudo$.pipe(
        map((conteudo) =>
            conteudo.linhas.map((linha) => {
                let numero: number = null;
                const versao = linha.versoes[linha.versoes.length - 1];
                const prefixo = versao.prefixo;
                if (
                    (!versao.revogado ||
            this.usuarioPreferenciasService.getConfiguracoesvalue.preferenciasUsuario
                .exibirItensRevogados) &&
          prefixo &&
          (FuncoesItem.identificarTipoItem(linha) === TipoItem.Artigo ||
            FuncoesItem.identificarTipoItem(linha) === TipoItem.Sumula)
                ) {
                    const numPrefixo = StringHelper.RemoverAcentosCaracteresEspeciais(
                        prefixo.toLowerCase()
                    )
                        .replace("artigo", "")
                        .replace("art", "")
                        .replace("sumula", "")
                        .trim();

                    if (NumberHelper.isRomanNumber(numPrefixo.toUpperCase())) {
                        numero = NumberHelper.unRomanize(numPrefixo.toUpperCase());
                    } else {
                        numero = parseInt(numPrefixo.match(/\d+/g)[0], 10);
                    }
                }

                return { key: linha.id, value: numero };
            })
        )
    );

    private guia: Guia;
    private busca: BuscaPanelParameters;

    constructor(
        private usuarioGuiasService: UsuarioGuiasService,
        private usuarioEstatisticasService: UsuarioEstatisticasService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private usuarioMarcacoesService: UsuarioMarcacoesService,
        private usuarioComentariosGerenciadosService: UsuarioComentariosGerenciadosService,
        private usuarioReferenciasGerenciadasService: UsuarioReferenciasGerenciadasService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioReferenciaService: UsuarioReferenciaService,
        private usuarioGrifosService: UsuarioGrifosService,
        private leiRepositorio: LeiRepositorio,
        private statusService: StatusService,
        private loggingService: LoggingService
    ) {
        this.usuarioGuiasService.carregar();
        this.usuarioGuiasService.getGuias().subscribe((guias) => {
            this.guiasChanged(guias);
        });
        this.usuarioGuiasService
            .getGuiaAtiva()
            .subscribe((guia) => this.guiaAtivaChanged(guia));
    }



    public async marcarLido(id: string): Promise<void> {
        const idLei = this.getIdLei();

        this.usuarioEstatisticasService
            .marcarLido(idLei, id)
            .then((estatisticas) => {
                this.leiRepositorio.carregarItemLookup(idLei).then((lei) => {
                    this.calcularProgressoLeiAtual(this.conteudo, lei, estatisticas);

                    this.conteudo.estatisticas = estatisticas;
                    this.conteudo.linhas.find((x) => x.id === id).lida =
            estatisticas.linhasLidas.indexOf(id) > -1;
                    this.updateConteudo();
                });
            });

        this.loggingService.LogEvent("Leitor - Ler linha", null, null);
    }

    public async marcarLidoAteAqui(index: number): Promise<void> {
        const idLei = this.getIdLei();

        let de = -1;
        const ate = index;

        de = this.conteudo.linhas.findIndex(
            (l) => !l.lida && !l.versoes[l.versoes.length - 1].revogado
        );

        const linhasAlterar = this.conteudo.linhas
            .filter(
                (l) =>
                    l.index >= de &&
          l.index <= ate &&
          !l.lida &&
          !l.versoes[l.versoes.length - 1].revogado
            )
            .map((l) => l.id);

        this.usuarioEstatisticasService
            .marcarVariosLidos(idLei, linhasAlterar)
            .then((estatisticas) => {
                this.leiRepositorio.carregarItemLookup(idLei).then((lei) => {
                    this.calcularProgressoLeiAtual(this.conteudo, lei, estatisticas);
                    this.conteudo.estatisticas = estatisticas;
                    this.updateConteudo();
                    this.updateMultipleReadedLinesChanged();
                });
            });

        this.loggingService.LogEvent("Leitor - Ler várias linhas", null, null);
    }

    public alterarMatchFocado(match: MatchBuscaTexto): void {
        if (!match) {
            return;
        }

        const busca = this.conteudo ? this.conteudo.busca : new Conteudo().busca;

        busca.matchResultadoBuscaFoco = match;

        this.conteudo.busca = busca;

        this.updateConteudo();
    }

    public updateCarregando() {
        this.carregando$.next(this.carregando);
    }

    public getCarregando(): Observable<boolean> {
        return this.carregando$.asObservable();
    }
    public updateConteudo() {
        this.conteudo$.next(this.conteudo);
    }

    public getConteudo(): Observable<Conteudo> {
        return this.conteudo$.asObservable();
    }

    public updateIndexFoco() {
        this.indexFoco$.next(this.indexFoco);
    }

    public getIndexFoco(): Observable<number> {
        return this.indexFoco$.asObservable();
    }

    public updateMultipleReadedLinesChanged() {
        this.multipleReadedLinesChanged$.next(this.multipleReadedLinesChanged);
    }

    public getMultipleReadedLinesChanged(): Observable<any> {
        return this.multipleReadedLinesChanged$.asObservable();
    }

    public updateLeisGuiasCached() {
        this.leisGuiasCached$.next(this.leisGuiasCached);
    }

    public getLeisGuiasCached(): Observable<LeiGuiacached[]> {
        return this.leisGuiasCached$.asObservable();
    }

    public getIndiceLei(): Observable<KeyValue<string, number>[]> {
        return this.indiceLei$;
    }

    public alterarIndexFoco(index: number) {
        this.indexFoco = index;
        this.updateIndexFoco();
    }

    public getIdLei(): string {
        return this.conteudo?.busca?.resultadosBuscaWeb?.resultadoAtual?.id
            ? this.conteudo.busca.resultadosBuscaWeb.resultadoAtual.id
            : this.conteudo.idLei;
    }  private async guiaAtivaChanged(guia: Guia) {
        if (!guia) {
            return;
        }

        this.carregando = true;
        this.updateCarregando();
        this.guia = guia;

        await this.carregarLei(this.guia.id);

        this.updateConteudo();
        this.carregando = false;
        this.updateCarregando();
    }

    private guiasChanged(guias) {
        for (let index = 0; index < this.leisGuiasCached.length; index++) {
            if (
                guias.findIndex((g) => g.id === this.leisGuiasCached[index].idGuia) ===
      -1
            ) {
                this.leisGuiasCached.splice(index, 1);
            }
        }
        this.updateLeisGuiasCached();
    }

    private async carregarLei(idGuia: string): Promise<void> {
        if (!this.conteudo) {
            this.conteudo = new Conteudo();
        }

        if (this.guia) {
            this.conteudo.novaGuia = this.guia.idLei ? false : true;
        }

        this.conteudo.idGuia = this.guia.id;
        this.conteudo.busca = null;

        this.conteudo.idLei = this.guia.idLei;
        this.conteudo.tituloGuia = this.guia.titulo;
        let idLei = this.getIdLei();

        if (this.conteudo.busca && !this.busca) {
            idLei = this.conteudo.idLei;
        }

        if (!idLei) {
            this.conteudo.linhas = [];
            this.conteudo.progressoLeitura = null;
            this.conteudo.estatisticas = null;
            this.conteudo.urlFonteLei = null;

            return;
        } else {
            const iLeiCache = this.leisGuiasCached.findIndex(
                (l) => l.lei && l.lei.id === idLei
            );
            try {
                const lei: Lei =
        iLeiCache !== -1
            ? this.leisGuiasCached[iLeiCache].lei
            : await this.leiRepositorio.carregarLei(idLei);

                const [
                    marcacoes,
                    comentarios,
                    referencias,
                    grifos,
                    estatisticas,
                    comentariosGerenciado,
                    referenciasGerenciado,
                ] = await Promise.all([
                    this.usuarioMarcacoesService.buscarLei(idLei),
                    this.usuarioComentariosService.carregarComentariosPorLei(idLei),
                    this.usuarioReferenciaService.carregarReferenciasPorLei(idLei),
                    this.usuarioGrifosService.carregarGrifosPorLei(idLei),

                    this.usuarioEstatisticasService.carregarEstatisticasServicePorLei(
                        idLei
                    ),

                    this.usuarioComentariosGerenciadosService.carregarComentariosGerenciadosPorLei(
                        idLei
                    ),
                    this.usuarioReferenciasGerenciadasService.carregarReferenciasGerenciadasPorLei(
                        idLei
                    ),
                ]);

                if (iLeiCache === -1 && !this.conteudo.busca) {
                    this.leisGuiasCached.push({ idGuia: idGuia, lei: lei });
                }

                this.conteudo.linhas = lei.itens.map((i) => new TextoPagina(i));
                this.conteudo.tipoDocumento = lei.tipoDocumento;
                this.conteudo.urlFonteLei = lei.url;

                for (let index = 0; index < this.conteudo.linhas.length; index++) {
                    this.conteudo.linhas[index].index = index;
                    this.conteudo.linhas[index].idLei = idLei;

                    this.conteudo.linhas[index].marcacoesProva = marcacoes.filter((m) => {
                        const idLinha = this.conteudo.linhas[index].id;
                        // provas devem ser exibidas em todas versões
                        return (
                            m.range.idItens.findIndex((id) => id.idItem === idLinha) !== -1
                        );
                    });
                    this.conteudo.linhas[index].referencias = referencias.filter((r) => {
                        const idLinha = this.conteudo.linhas[index].id;

                        return (
                            r.links?.find((e) => e.idItem === idLinha)?.idItem === idLinha
                        );
                    });

                    this.conteudo.linhas[index].comentarios = comentarios.filter((c) => {
                        const idLinha = this.conteudo.linhas[index].id;
                        // comentarios devem ser exibidas em todas versões
                        return (
                            c.range.idItens.findIndex((id) => id.idItem === idLinha) !== -1
                        );
                    });

                    this.conteudo.linhas[index].grifos = grifos.filter((g) => {
                        const idLinha = this.conteudo.linhas[index].id;
                        const versao = this.conteudo.linhas[index].indexVersao;

                        return g.idItem === idLinha && g.idImportacao === versao;
                    });
                }
                this.conteudo.estatisticas = estatisticas;
                this.conteudo = await this.calcularProgressoLeiAtual(
                    this.conteudo,
                    lei,
                    estatisticas
                );

                if (comentariosGerenciado) {
                    for (let index = 0; index < this.conteudo.linhas.length; index++) {
                        this.conteudo.linhas[index].index = index;
                        this.conteudo.linhas[index].idLei = idLei;

                        this.conteudo.linhas[index].comentariosGerenciados =
            comentariosGerenciado.comentarios.filter((c) => {
                const idLinha = this.conteudo.linhas[index].id;

                return (
                    c.range.idItens.findIndex((id) => id.idItem === idLinha) !==
                -1
                );
            });
                    }
                }

                if (referenciasGerenciado) {
                    for (let index = 0; index < this.conteudo.linhas.length; index++) {
                        this.conteudo.linhas[index].index = index;
                        this.conteudo.linhas[index].idLei = idLei;

                        this.conteudo.linhas[index].referenciasGerenciado =
            referenciasGerenciado.referencias.filter((c) => {
                const idLinha = this.conteudo.linhas[index].id;

                return c.idItem === idLinha;
            });
                    }
                }
            } catch (err) {
                this.statusService.setMessage("Não foi possivel carregar a lei");
                throw err;
            }
        }
    }

    private async calcularProgressoLeiAtual(
        conteudo: Conteudo,
        lei: LeiLookup | Lei,
        estatisticas: EstatisticasLeitura
    ): Promise<Conteudo> {
        const guia = this.usuarioGuiasService.valueGuiaAtiva;

        const configuracoes = this.usuarioPreferenciasService.getConfiguracoesvalue;
        if (guia && guia.idLei && configuracoes && configuracoes.palavrasMinuto) {
            const palavrasMinuto = configuracoes.palavrasMinuto;

            const progresso = new ProgressoLeitura();
            const qntlinhasLidas =
      estatisticas && estatisticas.linhasLidas
          ? estatisticas.linhasLidas.length
          : 0;

            progresso.progresso = EstatisticasLeitura.CalcularProgresso(
                lei.quantidadeItens,
                qntlinhasLidas
            );
            progresso.tempoRestante = EstatisticasLeitura.CalcularTempoRestante(
                palavrasMinuto,
                progresso.progresso,
                lei.quantidadePalavras
            );

            conteudo.progressoLeitura = progresso;
        } else {
            conteudo.progressoLeitura = null;
        }

        return conteudo;
    }
}

export class ProgressoLeitura {
    public progresso: number;
    public tempoRestante: Tempo;
}

export class LeiGuiacached {
    public lei: Lei;
    public idGuia: string;
}
