import { EntitiesHelper } from "../helpers/entities.helper";
import { TempoApontamento } from "./TempoApontamento";
import { BaseObj } from "./UserdataSync";

export class Apontamento extends BaseObj {
    public id: string;

    public dia: number;
    public mes: number;
    public ano: number;

    public idConjunto: string;
    public idLei: string;

    public anotacoes: string[];
    public controleTempo: TempoApontamento[];
    public descricaoConteudo: string;

    public constructor() {
        super();

        this.id = EntitiesHelper.generateGuid();

        this.anotacoes = new Array<string>();
        this.controleTempo = new Array<TempoApontamento>();

        const hoje = new Date();
        this.dia = hoje.getDate();
        this.mes = hoje.getMonth() + 1;
        this.ano = hoje.getFullYear();
    }
}
