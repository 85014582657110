import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { UiService } from "../../services/ui.service";
import { compatibleBrowsers } from "src/app/constants/compatibleBrowsers";

@Component({
    selector: "app-incompatible-browser",
    templateUrl: "./incompatible-browser.component.html",
    styleUrls: ["./incompatible-browser.component.scss"]
})
export class IncompatibleBrowserComponent implements AfterViewInit {

    @ViewChild("wrapper")
    wrapperRef: ElementRef;
    browsers = compatibleBrowsers;
    alturaTela = 0;


    marginTopWrapper: number;

    constructor(private uiService: UiService) { }

    ngAfterViewInit() {
        this.uiService.getAlturaTela().subscribe(a => this.alturaTelaChanged(a));
    }

    alturaTelaChanged(altura: number): void {
        this.alturaTela = altura;
        const el = <HTMLDivElement>this.wrapperRef.nativeElement;

        const alturaPainel = el.clientHeight;

        this.marginTopWrapper = (this.alturaTela - alturaPainel) / 2;
    }

}

