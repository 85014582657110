import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SimNaoDialogComponent } from "src/app/components/dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { Comentario } from "src/app/models/Comentario";
import { UsuarioComentariosService } from "src/app/services/data-services/usuario.comentarios.service";
import { UiService } from "src/app/services/ui.service";

@Component({
    selector: "app-titulo",
    templateUrl: "./titulo.component.html",
    styleUrls: ["./titulo.component.scss"],
})
export class TituloComponent {
    @Input() public titulo: Comentario;
    @Input() public novoTitulo: boolean;
    @Input() public corTitulo: string;
    @Input() public currentTextoTitulo: string;
    @Input() public editandoTitulo: boolean;

    @Output() tituloCommited = new EventEmitter();
    @Output() tituloCancel = new EventEmitter();
    @Output() changeEditando = new EventEmitter();


    public editorElement: HTMLDivElement = null;
    public viewerElement: HTMLSpanElement = null;
    public limiteCaracteresTitulo = 45;
    private cancelled = false;

    constructor(
        public dialog: MatDialog,
        private usuarioComentariosService: UsuarioComentariosService,
        private changeDetector: ChangeDetectorRef,
        public uiService: UiService
    ) { }

    @ViewChild("viewer", { static: false }) set viewer(viewer: ElementRef) {
        this.viewerElement = viewer ? <HTMLSpanElement>viewer.nativeElement : null;
        if (this.viewerElement) {
        } else {
            this.viewerElement = null;
        }
    }

    @ViewChild("editor", { static: false }) set editor(editor: ElementRef) {
        this.editorElement = editor ? <HTMLDivElement>editor.nativeElement : null;
        if (this.editorElement) {

            if (!this.uiService.isBreakpointMobileLg) {
                this.editorElement.focus();
            }

        } else {
            this.editorElement = null;
        }
    }

    enterPressed(e) {
        if (this.titulo) {
            e.preventDefault();
            e.stopPropagation();
            this.editorElement.blur();
        }
    }

    escPressed() {
        this.cancelled = true;
        this.editorElement.blur();
    }

    commit() {
        if (this.editorElement) {
            const innerHTML = this.editorElement.innerHTML;
            const textData = innerHTML;

            this.titulo.texto = textData;

            // 4 = padding atual definido no css
            this.titulo.alturaExibicao = this.editorElement.clientHeight - 4;
            this.titulo.larguraExibicao = this.editorElement.clientWidth - 4;
        }

        this.tituloCommited.emit(this.titulo);
    }

    cancel() {
        this.tituloCancel.emit();
    }

    public confirmarDelecaoComentario(event: MouseEvent, comentario: Comentario) {
        event.preventDefault();
        event.stopPropagation();

        const tipoComentario = "título";
        const dialogRef = this.dialog.open(SimNaoDialogComponent, {
            width: "250px",
            data: "Deseja mesmo excluir o " + tipoComentario + "?",
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.usuarioComentariosService.remover(comentario);
            }
        });
    }

    onBlur() {
        this.cancelled =
      this.cancelled ||
      this.editorElement.innerHTML.length === 0 ||
      this.editorElement.innerHTML === this.currentTextoTitulo;

        if (!this.novoTitulo) {
            this.mouseupEdicao();
        }

        if (this.cancelled) {
            this.cancel();
        } else {
            this.commit();
        }
    }

    keyPressed(e: KeyboardEvent) {
        const tamanhoTexto = (!this.editorElement || !this.editorElement.innerHTML) ? 0 : this.editorElement?.innerText?.length;
        if (

            tamanhoTexto >= this.limiteCaracteresTitulo
        ) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.changeDetector.detectChanges();
    }

    mouseupEdicao() {
        if (!this.editandoTitulo) {
            this.cancelled = false;
        }

        this.changeEditando.emit();
    }



    onPaste(e: ClipboardEvent) {
        e.preventDefault();
        let clipboardData = e.clipboardData.getData("text/plain");

        if (this.titulo) {
            clipboardData = clipboardData.replace(/\n|\r/g, "");
        }

        this.titulo.texto += clipboardData;
        this.titulo.texto = this.titulo.texto.substring(
            0,
            this.limiteCaracteresTitulo
        );
    }

}
