import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { AppConfig } from "../../../app.config";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { Subscription } from "rxjs";
import { StatusService } from "../../../services/status.service";
import { PopupsService } from "../popups.service";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { MatDialog } from "@angular/material/dialog";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { ErrorHandlerService } from "../../../services/errorHandler.service";
import { ErrorLogParameters } from "../../../models/error/errorLogParameters";
import { FreshDeskTicketPriority } from "src/app/models/FreshDeskTicketPriority";
import { ErroPopupDataModel } from "src/app/models/ErroPopupDataModel";
import { ErroPopUpComponent } from "../mensagem/erro-popup.component";

@Component({
    selector: "app-feedback",
    templateUrl: "./feedback.component.html",
    styleUrls: ["../popups-styles.scss", "./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit, OnDestroy {
    @ViewChild(MatButtonToggleGroup) btnGroup;
    public textoFeedback: string;
    public configuracoes: ConfiguracoesUsuario;

    private _subscribePreferencias: Subscription;

    private headers = new HttpHeaders({
        "content-type": "application/json",
    });
    constructor(
        private httpClient: HttpClient,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private dialog: MatDialog,
        private statusService: StatusService,
        private popupSerice: PopupsService,
        private guiasService: UsuarioGuiasService,
        private errorHandlerService: ErrorHandlerService
    ) { }

    ngOnInit(): void {
        this._subscribePreferencias =
      this.usuarioPreferenciasService.getConfiguracoes().subscribe((c) =>
          this.subscribePreferencias(c)
      );
    }

    ngOnDestroy(): void {
        this._subscribePreferencias.unsubscribe();
    }

    public criarTicket(): void {
        if (
            !this.btnGroup.value ||
      !this.textoFeedback ||
      (this.textoFeedback && this.textoFeedback.match(/^ *$/) !== null)
        ) {
            const data = new ErroPopupDataModel();
            data.titulo = "Feedback inválido";
            data.mensagem =
        "Por favor selecione uma das opções e escreva um comentário para enviar um feedback";
            this.dialog.open(ErroPopUpComponent, {
                width: "450px",
                data: data,
            });

            return;
        }

        let priority = FreshDeskTicketPriority.Low;
        switch (this.btnGroup.value) {
            case "feedback_Bom":
                priority = FreshDeskTicketPriority.Low;
                break;
            case "feedback_Regular":
                priority = FreshDeskTicketPriority.Medium;
                break;
            case "feedback_Ruim":
                priority = FreshDeskTicketPriority.High;
                break;
        }

        const idLei = this.guiasService.valueGuiaAtiva.idLei
            ? this.guiasService.valueGuiaAtiva.idLei
            : "Nova Guia";
        const dadosAdicionais = "<br/><br/><bold>Lei aberta: " + idLei + "</bold>";

        // const url = AppConfig.freshdeskEndpoint + "tickets";
        // const body = new FreshDeskTicketModel({
        //     email: this.configuracoes.email,
        //     subject: this.btnGroup.value,
        //     description:
        // this.textoFeedback.replace("\r\n", "<br/>").replace("\n", "<br/>") +
        // dadosAdicionais,
        //     status: FreshDeskTicketStatus.Open,
        //     priority: priority,
        // });

        // let headers = new HttpHeaders();
        // headers = headers.append(
        //     "authorization",
        //     "Basic " + AppConfig.freshdeskKey64Based
        // );
        /// versao antiga nova abaixo
        // this.httpClient
        //   .post(url, body, { headers: headers })
        //   .toPromise()
        //   .then((result) => {
        //     this.statusService.setMessage("Feedback enviado com sucesso!");
        //     this.popupSerice.fecharPopups();
        //   })
        //   .catch((error) => {
        //     this.statusService.setMessage(
        //       "Erro ao enviar feedback. Tente novamente mais tarde"
        //     );
        //     this.popupSerice.fecharPopups();

        //     let param = new Array<ErrorLogParameters>();
        //     param.push({ name: "TipoFeedback", value: this.btnGroup.value });
        //     param.push({ name: "mensagemFeedback", value: this.textoFeedback });
        //     this.errorHandlerService.handleError(error);
        //   });
        const description = this.textoFeedback.replace("\r\n", "<br/>").replace("\n", "<br/>") + dadosAdicionais;

        this.httpClient
            .post(
                AppConfig.apiEndpoint + "/api/suporte/",
                `"${description + "::" + this.btnGroup.value}"`,
                { headers: this.headers }
            )
            .toPromise()
            .then((res) => {
                this.statusService.setMessage("Feedback enviado com sucesso!");
                this.popupSerice.fecharPopups();
            })
            .catch((err) => {
                const params = new Array<ErrorLogParameters>();
                params.push({
                    name: "mensagem", value: this.textoFeedback.replace("\r\n", "<br/>").replace("\n", "<br/>") +
                dadosAdicionais
                });
                this.errorHandlerService.handleError(err);

                this.statusService.setMessage(
                    "Erro ao enviar feedback. Tente novamente mais tarde"
                );
                this.popupSerice.fecharPopups();

                const param = new Array<ErrorLogParameters>();
                param.push({ name: "TipoFeedback", value: this.btnGroup.value });
                param.push({ name: "mensagemFeedback", value: this.textoFeedback });
                this.errorHandlerService.handleError(err);
            });
    }

    public btnCancelar_click() {
        this.popupSerice.fecharPopups();
    }

    private subscribePreferencias(c: ConfiguracoesUsuario) {
        if (!c) {
            return;
        }
        this.configuracoes = c;
    }
}

