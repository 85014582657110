import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { UsuarioPreferenciasService } from "../data-services/usuario.preferencias.service";

export class TextStyle {
    types: string[] = [];
    fontsize: number;
    default: boolean;
}

export const TextStyles: TextStyle[] = [
    { types: [], fontsize: 10, default: true }, // converter px para pt
    {
        types: ["introducao-busca", "tituloprincipal"],
        fontsize: 17,
        default: false,
    },
    { types: ["parte"], fontsize: 14, default: false },
    { types: ["livro"], fontsize: 13, default: false },
    { types: ["ato"], fontsize: 13, default: false },
    { types: ["titulo"], fontsize: 11, default: false },
    { types: ["capitulo"], fontsize: 10, default: false },
    { types: ["preambulo"], fontsize: 11, default: false },
    { types: ["sumula"], fontsize: 12, default: false },
];

@Injectable()
export class TextFormatingService {
    private podeAumentar = false;
    private podeAumentar$ = new BehaviorSubject<boolean>(this.podeAumentar);

    private podeDiminuir = false;
    private podeDiminuir$ = new BehaviorSubject<boolean>(this.podeDiminuir);

    private fontIncrement = 0;
    private fontIncrement$ = new BehaviorSubject<number>(this.fontIncrement);

    constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {
        usuarioPreferenciasService.getConfiguracoes().subscribe((c) => {
            if (c) {
                this.fontIncrement = c.fontSize;
                this.updateFontIncrement();
            } else {
                this.fontIncrement = 0;
                this.updateFontIncrement();
            }

            this.podeDiminuir = this.fontIncrement > -3;
            this.updatePodeDiminuir();
            this.podeAumentar = this.fontIncrement < 8;
            this.updatePodeAumentar();
        });
    }



    increaseFontSize() {
        const fontsize = this.fontIncrement + 1;
        this.updateFontSize(fontsize);
    }

    decreaseFontSize() {
        const fontsize = this.fontIncrement - 1;
        this.updateFontSize(fontsize);
    }

    updatePodeAumentar() {
        this.podeAumentar$.next(this.podeAumentar);
    }

    getPodeAumentar(): Observable<boolean> {
        return this.podeAumentar$.asObservable();
    }
    updatePodeDiminuir() {
        this.podeDiminuir$.next(this.podeDiminuir);
    }

    getPodeDiminuir(): Observable<boolean> {
        return this.podeDiminuir$.asObservable();
    }

    updateFontIncrement() {
        this.fontIncrement$.next(this.fontIncrement);
    }

    getFontIncrement(): Observable<number> {
        return this.fontIncrement$.asObservable();
    }

    private updateFontSize(fontsize: number) {
        this.fontIncrement = fontsize;

        this.updateFontIncrement();

        const config = this.usuarioPreferenciasService.getConfiguracoesvalue;
        config.fontSize = fontsize;

        this.usuarioPreferenciasService.alterarConfiguracoes(config);
    }
}
