import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { UiService } from "../../../services/ui.service";
import { MatDialog } from "@angular/material/dialog";

import { Tema } from "../../../models/tema";
import { TemasApp } from "../../../constants/lista.temas";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { DialogoService } from "../dialogo/dialogo.service";
import { Router } from "@angular/router";
import { PopupsService } from "../popups.service";
import { versoes } from "../../../changelog";
import { DataControleRepositorio } from "../../../repositorios/dataControle.repositorio";
import { EnumTipoDataControle } from "../../../models/dataControle";
import { StatusService } from "../../../services/status.service";
import { LoggingService } from "../../../services/logging.service";
import { Observable, of } from "rxjs";
import { StorageHelper } from "../../../helpers/storage.helper";
import { PreferenciasModel } from "../../../models/PreferenciasModel";
import { VersaoApp } from "../../../models/VersaoApp";
import { ColorPickerComponent } from "../../dialogs/color-picker/color-picker.component";
import { PaletaCoresModel } from "../../../models/PaletaCoresModel";
import { DialogoModel } from "src/app/models/DialogoModel";

@Component({
    selector: "app-preferencias",
    templateUrl: "./preferencias.component.html",
    styleUrls: ["../popups-styles.scss", "./preferencias.component.scss"],
})
export class PreferenciasComponent implements OnInit {
    @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

    public appOffline$: Observable<boolean> = of(false);
    public currentYear: string;
    public posicionamentoMarcacoes = new Array<string>("Acima", "Abaixo");
    public modoRealceCanetas = new Array<string>("Grifar", "Sublinhar");
    public detalhesVersao: Array<string>;
    public model: PreferenciasModel;
    public localDataCompare: string;
    public versoes: VersaoApp[];
    public  isAlterado = false;
    private validarMudancas = false;

    constructor(
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private popupsService: PopupsService,
        private uiService: UiService,
        private dialogoService: DialogoService,
        private router: Router,
        public dialog: MatDialog,
        private dataControleRepositorio: DataControleRepositorio,
        public statusService: StatusService,
        private loggingService: LoggingService

    ) {
        this.appOffline$ = this.statusService.getAppOffline();
        this.currentYear = new Date().getFullYear().toString();
    }

    public ngOnInit() {
        this.carregarModel();
    }



    public modoOfflineChanged() {
        if (!this.statusService.isAppOnline) {
            const model = new DialogoModel();

            model.titulo = "Modo 100% offline";
            model.mensagem =
        "Antes de continuar, todas as leis deverão ser baixadas, isso pode demorar um pouco. Deseja continuar?";

            model.incluirOpcao("Sim", "primary", async () => {
                await this.iniciarModoOffline();
                this.dialogoService.fecharDialogo();
            });

            model.incluirOpcao("Não", "warn", () => {
                this.dialogoService.fecharDialogo();
            });

            this.dialogoService.mostrarDialogo(model);
        } else {
            this.dataControleRepositorio.salvar({
                tipo: EnumTipoDataControle.DataVerificacaoModoOffline,
                data: null,
            });
        }
    }

    public async iniciarModoOffline() {
        await this.dataControleRepositorio.salvar({
            tipo: EnumTipoDataControle.DataVerificacaoModoOffline,
            data: null,
        });

        this.cancelar();
        this.tentarFechar(false);
        this.popupsService.fecharPopups();
    }

    public alterarTema(tema: Tema): void {
        if (this.model.temaSelecionado !== tema) {
            this.isAlterado = true;
            this.alterado(true);
            this.model.temaSelecionado = tema;
            this.uiService.alterarTema(this.model.temaSelecionado);
        } else {
            this.isAlterado = false;
            this.alterado(false);
        }
    }

    public cancelar(): void {
        this.uiService.alterarTema(this.model.temaOriginal);
        this.carregarModel();

        this.isAlterado = false;
        this.alterado(false);
    }

    public salvar(): void{
        this.loggingService.LogEvent("Editar preferências", null, null);

        const prefs = this.usuarioPreferenciasService.getConfiguracoesvalue;

        prefs.idTema =
      TemasApp.indexOf(this.model?.temaSelecionado) ?? prefs.idTema;

        prefs.palavrasMinuto =
      this.model?.palavrasPorMinuto ?? prefs.palavrasMinuto;
        prefs.perfilUsuario =
      this.model?.opcaoModoUsoSelecionada?.tipo ?? prefs.perfilUsuario;

        prefs.parametrosCaneta.corTagComentario =
      this.model?.marcadores[0]?.cor ?? prefs.parametrosCaneta.corTagComentario;
        prefs.parametrosCaneta.realcarComentarios =
      this.model?.marcadores[0]?.sublinhar ??
      prefs.parametrosCaneta.realcarComentarios;
        prefs.parametrosCaneta.posicionamentoComentario =
      this.model?.marcadores[0]?.posicionamento ??
      prefs.parametrosCaneta.posicionamentoComentario;

        prefs.parametrosCaneta.corTagMnemonico =
      this.model?.marcadores[1]?.cor ?? prefs.parametrosCaneta.corTagMnemonico;
        prefs.parametrosCaneta.realcarMnemonicos =
      this.model?.marcadores[1]?.sublinhar ??
      prefs.parametrosCaneta.realcarMnemonicos;
        prefs.parametrosCaneta.posicionamentoMnemonico =
      this.model?.marcadores[1]?.posicionamento ??
      prefs.parametrosCaneta.posicionamentoMnemonico;

        prefs.parametrosCaneta.corCaneta1 =
      this.model?.grifos[0]?.cor ?? prefs.parametrosCaneta.corCaneta1;
        prefs.parametrosCaneta.descricaoCaneta1 =
      this.model?.grifos[0]?.apelido ?? prefs.parametrosCaneta.descricaoCaneta1;
        prefs.parametrosCaneta.modoRealceCaneta1 =
      this.model?.grifos[0]?.modoRealce ??
      prefs.parametrosCaneta.modoRealceCaneta1;

        prefs.parametrosCaneta.corCaneta2 =
      this.model?.grifos[1]?.cor ?? prefs.parametrosCaneta.corCaneta2;
        prefs.parametrosCaneta.descricaoCaneta2 =
      this.model?.grifos[1]?.apelido ?? prefs.parametrosCaneta.descricaoCaneta2;
        prefs.parametrosCaneta.modoRealceCaneta2 =
      this.model?.grifos[1]?.modoRealce ??
      prefs.parametrosCaneta.modoRealceCaneta2;

        prefs.parametrosCaneta.corCaneta3 =
      this.model?.grifos[2]?.cor ?? prefs.parametrosCaneta.corCaneta3;
        prefs.parametrosCaneta.descricaoCaneta3 =
      this.model?.grifos[2]?.apelido ?? prefs.parametrosCaneta.descricaoCaneta3;
        prefs.parametrosCaneta.modoRealceCaneta3 =
      this.model?.grifos[2]?.modoRealce ??
      prefs.parametrosCaneta.modoRealceCaneta3;

        prefs.parametrosCaneta.corCaneta4 =
      this.model?.grifos[3]?.cor ?? prefs.parametrosCaneta.corCaneta4;
        prefs.parametrosCaneta.descricaoCaneta4 =
      this.model?.grifos[3]?.apelido ?? prefs.parametrosCaneta.descricaoCaneta4;
        prefs.parametrosCaneta.modoRealceCaneta4 =
      this.model?.grifos[3]?.modoRealce ??
      prefs.parametrosCaneta.modoRealceCaneta4;

        prefs.parametrosCaneta.corCaneta5 =
      this.model?.grifos[4]?.cor ?? prefs.parametrosCaneta.corCaneta5;
        prefs.parametrosCaneta.descricaoCaneta5 =
      this.model?.grifos[4]?.apelido ?? prefs.parametrosCaneta.descricaoCaneta5;
        prefs.parametrosCaneta.modoRealceCaneta5 =
      this.model?.grifos[4]?.modoRealce ??
      prefs.parametrosCaneta.modoRealceCaneta5;

        prefs.preferenciasUsuario.larguraPapel =
      this.model?.opcaoLarguraPapel ?? prefs.preferenciasUsuario.larguraPapel;

        this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
        this.carregarModel();
    }

    public resetarConfiguracoes(): void {
        this.loggingService.LogEvent("Editar preferências", null, null);

        const prefs = this.usuarioPreferenciasService.getConfiguracoesvalue;

        prefs.idTema = TemasApp.indexOf(this.model.temaSelecionado);

        prefs.palavrasMinuto = this.model.palavrasPorMinuto;
        prefs.perfilUsuario = this.model.opcaoModoUsoSelecionada.tipo;

        prefs.preferenciasUsuario.larguraPapel = this.model.opcaoLarguraPapel;

        this.usuarioPreferenciasService.resetarPreferenciasUsuario();
        this.carregarModel();
    }

    public tentarFechar(salvar: boolean): void {
        if (salvar) {
            this.salvar();
        } else {
            this.cancelar();
        }
    }

    public alterado(valida: boolean): void {
        this.confirmarAlteracoes.emit(valida);
        this.validarMudancas = valida;
    }

    public colorPick(item): void {
        const model = new PaletaCoresModel();
        model.tipo = item.tipoMarcacao;
        model.corAtual = item.cor;

        const dialogRef = this.dialog.open(ColorPickerComponent, {
            data: model,
            width: "250px",
        });
        const sColorPicker  = dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (item.cor !== result) {
                    this.isAlterado = true;
                    this.alterado(true);
                    item.cor = result;
                } else {
                    this.isAlterado = false;
                    this.alterado(false);
                }
            }

            sColorPicker .unsubscribe();
        });
    }

    public btnAutofixClick(): void {
        try {
            const model = new DialogoModel();

            model.titulo = "Autofix";
            model.mensagem =
        "Para realizar correções será realizado um logoff um pouco mais demorado. Deseja continuar?";

            model.incluirOpcao("Sim", "primary", async () => {
                this.loggingService.LogEvent("Prefências - Autofix", null, null);

                await StorageHelper.deletarDBs();
                localStorage.clear();
                // window.location.href = "auth/logoff?clear=true";
                this.router.navigate(["auth/logoff"]);
            });

            model.incluirOpcao("Não", "warn", () => {
                this.dialogoService.fecharDialogo();
            });

            this.dialogoService.mostrarDialogo(model);
        } catch (error) {
            throw new Error(
                `Erro em preferenciasComponent.btnAutofixClick ${error?.message}`
            );
        }
    }

    public updatedRating(rating: number): void {
        if (this.model.palavrasPorMinuto !== rating) {
            this.isAlterado = true;
            this.alterado(true);
            this.model.palavrasPorMinuto = rating;
        } else {
            this.isAlterado = false;
            this.alterado(false);
        }
    }

    public dataChanged(): void{
        this.isAlterado = true;
        this.alterado(true);
    }
    private carregarModel() {
        this.model = new PreferenciasModel(
            this.usuarioPreferenciasService.getConfiguracoesvalue
        );
        this.carregarVersoes();
    }

    private carregarVersoes() {
        const listaVersoes: VersaoApp[] = [];

        versoes.forEach((v) => {
            const versao: VersaoApp = { numero: v.numero, alteracoes: v.alteracoes };
            versao.numero = v.numero;
            listaVersoes.push(versao);
        });

        this.versoes = listaVersoes;
    }
}



