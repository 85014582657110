<mat-accordion
  class="leitor-side-tools-align"
  multi
  (mouseleave)="accordion.closeAll(); openHover = false"
  (mouseenter)="openHover = true"
  [ngClass]="{ openHover: openHover, closeHover: !openHover }"
>
  <!-- -->

  <mat-expansion-panel
    (opened)="panelindice = true"
    (closed)="panelindice = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon svgIcon="list-tree" class="leitor-side-tools-icon"></mat-icon>

        <p class="leitor-side-texto">Índice</p>
        <mat-icon
          [svgIcon]="panelindice ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-indice-sistematico
      class="leitor-side-tools-indice-sistematico"
    ></app-indice-sistematico>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="paneltimer = true"
    (closed)="paneltimer = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon svgIcon="timer" class="leitor-side-tools-icon"></mat-icon>
        <p class="leitor-side-texto">Timer</p>
        <mat-icon
          [svgIcon]="paneltimer ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-timer class="timer-wrapper"></app-timer>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="panelbrain = true"
    (closed)="panelbrain = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon svgIcon="brain" class="leitor-side-tools-icon"></mat-icon>
        <p class="leitor-side-texto">
          {{ openHover ? "Dicas de estudo" : "Dicas" }}
        </p>
        <mat-icon
          [svgIcon]="panelbrain ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>conteudo</p>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="panelcircle = true"
    (closed)="panelcircle = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon
          svgIcon="circle-nodes"
          class="leitor-side-tools-icon"
        ></mat-icon>
        <p class="leitor-side-texto">Referências</p>
        <mat-icon
          [svgIcon]="panelcircle ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>conteudo</p>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="panelmessage = true"
    (closed)="panelmessage = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon
          svgIcon="message-lines"
          class="leitor-side-tools-icon"
        ></mat-icon>
        <p class="leitor-side-texto">Comentários</p>
        <mat-icon
          [svgIcon]="panelmessage ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>conteudo</p>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="paneltags = true"
    (closed)="paneltags = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon svgIcon="tags" class="leitor-side-tools-icon"></mat-icon>
        <p class="leitor-side-texto">Tags</p>

        <mat-icon
          [svgIcon]="paneltags ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>conteudo</p>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="panelplay = true"
    (closed)="panelplay = false"
    hideToggle
    class="leitor-side-tools-expansion-pane"
  >
    <mat-expansion-panel-header
      class="leitor-side-tools-expansion-pane-header all"
    >
      <mat-panel-title>
        <mat-icon
          svgIcon="clapperboard-play"
          class="leitor-side-tools-icon"
        ></mat-icon>
        <p class="leitor-side-texto">Mídias</p>
        <mat-icon
          [svgIcon]="panelplay ? 'chevron-up' : 'chevron-down'"
          class="leitor-side-tools-icon-chevron"
        >
        </mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>conteudo</p>
  </mat-expansion-panel>
</mat-accordion>
