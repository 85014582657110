import { AbstractControl, ValidationErrors } from "@angular/forms";

export class ValidatorsHelper {

    public static emailValidation(control: AbstractControl): ValidationErrors | null {
        const email = control.value;
        const reg = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");

        if (!reg.exec(email)) {
            const retorno = { key: "E-mail inválido" } as ValidationErrors;
            return retorno;
        } else {
            return null;
        }
    }

    public static emailValidationIndicacao(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === "") {
            return null;
        }

        const email = control.value;
        const reg = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");

        if (!reg.exec(email)) {
            const retorno = { key: "E-mail inválido" } as ValidationErrors;
            return retorno;
        } else {
            return null;
        }
    }


    static MatchPassword(AC: AbstractControl) {
        const password = AC.get("senha").value; // to get value in input tag
        const confirmPassword = AC.get("confirmaSenha").value; // to get value in input tag
        if (password !== confirmPassword) {
            AC.get("confirmaSenha").setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }

}

