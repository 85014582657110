import { ItemVersaoRangeSelecao } from "./ItemVersaoRangeSelecao";

export class Selector {
    start: number;
    end: number;
    selected: Array<ItemVersaoRangeSelecao>;
    selectedSourceId: string;
    idConjunto: string;
    idLei: string;
    clientX: number;
}
