import { ContatoIndicacao } from "./ContatoIndicacao";
import { DadosPessoaisModel } from "./PagamentoModel";

export class DadosPessoais {
    public cpf: string;
    public telefone: string;
    public dataProximaCobranca: Date;
    public ValorDescontoIndicacao: number;
    public ValorCobranca: number;
    public indicacoes: ContatoIndicacao[];
    public planoContratadoId: number;
    public planoCancelado: boolean;

    public static fromDadosPessoaisModel(
        model: DadosPessoaisModel
    ): DadosPessoais {
        const retorno = new DadosPessoais();

        retorno.cpf = model.cpf;
        retorno.telefone = model.telefone;

        return retorno;
    }
}
