import { LeitorContentParameters } from "./LeitorContentParameters";
import { LeitorToolbarParameters } from "./LeitorToolbarParameters";

export class LeitorParameters {
    leitorContentParameters: LeitorContentParameters;
    leitorToolbarParameters: LeitorToolbarParameters;

    constructor() {
        this.leitorContentParameters = new LeitorContentParameters();
        this.leitorToolbarParameters = new LeitorToolbarParameters();
    }
}









