import { Observable, of } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { Apontamento } from "src/app/models/Apontamento";
import { ApontamentoListaPorData } from "src/app/models/ApontamentoListaPorData";
import { TempoApontamento } from "src/app/models/TempoApontamento";
import { TimerService } from "src/app/services/timer.service";

@Component({
    selector: "app-timer",
    templateUrl: "./timer.component.html",
    styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnInit {
    public apontamentos$: Observable<Apontamento[]> = of([]);
    public apontamentosListaPorData$: Observable<ApontamentoListaPorData[]> = of(
        []
    );
    public apontamentosTempoTotal$: Observable<string> = of("");
    public sec: any = "0" + 0;
    public min: any = "0" + 0;
    public hr: any = "0" + 0;

    public hoje = new Date();
    public startTimer = null;
    public running = false;
    public textoAnotacao: string = null;
    public apontamento: Apontamento;

    constructor(public timerService: TimerService) {}

    ngOnInit() {
        this.apontamentos$ = this.timerService.getApontamentos();

        this.apontamentosListaPorData$ =
      this.timerService.getApontamentosListaPorData();

        this.timerService
            .getApontamentoAtual()
            .subscribe(
                (apontamento) => (this.apontamento = apontamento ?? new Apontamento())
            );

        this.apontamentosTempoTotal$ = this.apontamentos$.pipe(
            map((items) => {
                if (items.length === 0) {
                    return "00:00:00";
                }

                let tempoSegundos = 0;

                items.forEach((tempo) => {
                    if (
                        tempo?.controleTempo[0]?.inicio &&
            tempo?.controleTempo[0]?.termino
                    ) {
                        tempoSegundos += Math.floor(
                            (tempo?.controleTempo[0]?.termino -
                tempo?.controleTempo[0]?.inicio) /
                1000
                        );
                    }
                });

                let strTempo = "00:00:00";

                if (tempoSegundos) {
                    const s = tempoSegundos;

                    let horas = `${Math.floor(s / 60 / 60)}`;
                    let minutos = `${Math.floor((s / 60) % 60)}`;
                    let segundos = `${Math.floor(s % 60)}`;

                    horas = horas.length === 1 ? `0${horas}` : horas;
                    minutos = minutos.length === 1 ? `0${minutos}` : minutos;
                    segundos = segundos.length === 1 ? `0${segundos}` : segundos;

                    strTempo = `${horas}:${minutos}:${segundos}`;
                }

                return strTempo;
            })
        );
    }

    public start() {
        // eslint-disable-next-line no-debugger
        debugger;
        if (
            this.startTimer == null &&
      !this.apontamento?.controleTempo[0]?.inicio
        ) {
            if (!this.apontamento?.controleTempo[0]) {
                this.apontamento.controleTempo.push(new TempoApontamento());
            }
            this.apontamento.controleTempo[0].inicio = new Date().getTime();
        }
        if (!this.running) {
            this.running = true;
            this.startTimer = setInterval(() => {
                this.sec++;
                this.sec = this.sec < 10 ? "0" + this.sec : this.sec;

                if (this.sec === 60) {
                    this.min++;
                    this.min = this.min < 10 ? "0" + this.min : this.min;
                    this.sec = "0" + 0;
                }

                if (this.min === 60) {
                    this.hr++;
                    this.hr = this.hr < 10 ? "0" + this.hr : this.hr;
                    this.min = "0" + 0;
                }
            }, 1000);
        } else {
            this.stop();
        }
    }

    stop(): void {
        // eslint-disable-next-line no-debugger
        debugger;
        clearInterval(this.startTimer);
        this.running = false;
    }

    async reset() {
        // eslint-disable-next-line no-debugger
        debugger;
        clearInterval(this.startTimer);
        this.running = false;

        if (this.textoAnotacao) {
            const newData = new Date();

            if (this.apontamento?.controleTempo[0]?.termino) {
                newData.setTime(this.apontamento.controleTempo[0].termino);

                newData.setHours(parseInt(this.hr, 10) + newData.getHours());
                newData.setMinutes(parseInt(this.min, 10) + newData.getMinutes());
                newData.setSeconds(parseInt(this.sec, 10) + newData.getSeconds());

                this.apontamento.controleTempo[0].termino = newData.getTime();
            } else {
                this.apontamento.controleTempo[0].termino = newData.getTime();
            }

            this.apontamento.anotacoes[0] = this.textoAnotacao;
            await this.timerService.salvarApontamento(this.apontamento);
        }

        this.textoAnotacao = null;
        this.startTimer = null;
        this.sec = "0" + 0;
        this.min = "0" + 0;
        this.hr = "0" + 0;
    }

    // tempo(tempo) {
    //   if (!tempo) return;

    //   const tempoSegundos = Math.floor((tempo.termino - tempo.inicio) / 1000);
    //   let strTempo = "00:00:00";

    //   if (tempoSegundos) {
    //     const s = tempoSegundos;

    //     let horas = `${Math.floor(s / 60 / 60)}`;
    //     let minutos = `${Math.floor((s / 60) % 60)}`;
    //     let segundos = `${Math.floor(s % 60)}`;

    //     horas = horas.length === 1 ? `0${horas}` : horas;
    //     minutos = minutos.length === 1 ? `0${minutos}` : minutos;
    //     segundos = segundos.length === 1 ? `0${segundos}` : segundos;

    //     strTempo = `${horas}:${minutos}:${segundos}`;
    //   }

    //   return strTempo;
    // }

    // tempoTotal(tempos: Apontamento[]) {
    //   if (tempos.length == 0) return;

    //   let tempoSegundos = 0;

    //   tempos.forEach((tempo) => {
    //     if (tempo?.controleTempo[0]?.inicio && tempo?.controleTempo[0]?.termino)
    //       tempoSegundos += Math.floor(
    //         (tempo?.controleTempo[0]?.termino - tempo?.controleTempo[0]?.inicio) /
    //           1000
    //       );
    //   });

    //   let strTempo = "00:00:00";

    //   if (tempoSegundos) {
    //     const s = tempoSegundos;

    //     let horas = `${Math.floor(s / 60 / 60)}`;
    //     let minutos = `${Math.floor((s / 60) % 60)}`;
    //     let segundos = `${Math.floor(s % 60)}`;

    //     horas = horas.length === 1 ? `0${horas}` : horas;
    //     minutos = minutos.length === 1 ? `0${minutos}` : minutos;
    //     segundos = segundos.length === 1 ? `0${segundos}` : segundos;

    //     strTempo = `${horas}:${minutos}:${segundos}`;
    //   }

    //   return strTempo;
    // }
}
