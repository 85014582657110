import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class BrowserCompatibleGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (!incompatible()) {
            this.router.navigate(["/"]);
            return false;
        }

        return true;
    }
}

@Injectable({
    providedIn: "root"
})
export class BrowserNotCompatibleGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (incompatible()) {
            this.router.navigate(["/compatibility-error"]);
            return false;
        }

        return true;
    }
}

export function incompatible() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    const LocalStorageSuportado = typeof Storage !== "undefined";
    const IndexedDbSuportado = window.indexedDB ? true : false;
    const WebSocketsSuportado = "WebSocket" in window;

    return isIEOrEdge || !LocalStorageSuportado || !IndexedDbSuportado || !WebSocketsSuportado;
}
